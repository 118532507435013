// @import "../../assets/variableStyles.scss";

// #banner {
//   border: 2px solid transparent;
//   height: 480px;

//   @include mobile {
//     height: 270px;

//   }

 
//   img {
//     height: 24em;
//     width: 100%;
//      height: 800px;
//     object-position: center;
//     object-fit: cover;

//     @include mobile {
//       height: 270px;

//     }
//   }
// }

// .fh-main-cc2 {
//   @include minitab {
//     margin-top: 1%;
//   }

//   @include mobile {
//     display: block;
//     margin-top: 0%;
//   }
// }
// .gradient-overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//  // background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.3) 0%,  rgba(0, 0, 0, 0) 0%);

//   // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
//   pointer-events: none;
//   /* Allows interaction with the underlying elements */
//   // background: linear-gradient(270deg,rgba(5,16,54,.5),#051036 72.43%);
//   opacity: .80;
// }
@import "../../assets/variableStyles.scss";

#banner {

  // .carousel {
  //   margin-top: -31px;
  //   height: 21em;
  //   width: 100%;
  //   min-height: 230px;
  //   position: relative;
  //   background-color: #2f81ed;
  //   background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  //   @include mobile {
  //     display: none;
  //   }
  //   .carousel-inner {
  //     height: 100%;
  //     display: none;
  //   }
  // }
  // .banner-heading {
  //   max-width: 850px;
  //   margin: auto;
  //   padding: 40px 50px;
  // }
  img {
    height: 24em;
    width: 100%;
    min-height: 495px;
    object-position: center;
    object-fit: cover;
  }
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: radial-gradient(circle, rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0.3) 0%,  rgba(0, 0, 0, 0) 0%);

  // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  /* Allows interaction with the underlying elements */
  // background: linear-gradient(270deg,rgba(5,16,54,.5),#051036 72.43%);
  opacity: .80;
}

.fh-main-cc2 {
  height: 550px;
  overflow: hidden;
  @include minitab {
    display: block !important;
  }

  @include mobile {
    display: none !important;
  }
}