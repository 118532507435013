// // .bus-scene {
// //     width: 100%;
// //     height: 200px;
// //     perspective: 1000px;
// //   }

// //   .bus-carousel-container {
// //     width: 100%;
// //     height: 100%;
// //     position: relative;
// //     transform-style: preserve-3d;
// //     transition: transform 1s;
// //   }

// //   .bus-carousel-item {
// //     width: 100px; /* Adjust width as needed */
// //     height: 150px; /* Adjust height as needed */
// //     position: absolute;
// //     transform-origin: 50% 50% -288px;
// //     transition: transform 0.5s;
// //   }

// //   .bus-carousel-item:nth-child(1) { transform: rotateY(0deg) translateZ(288px); }
// //   .bus-carousel-item:nth-child(2) { transform: rotateY(30deg) translateZ(288px); }
// //   /* Add more rotations for additional carousel items */

// //   img {
// //     max-width: 100%;
// //     max-height: 100%;
// //   }

// // another



// // .bus-carousel-container {
// //     width: 100%;
// //     overflow-x: hidden;
// //     position: relative;
// //   }
  
// //   .bus-carousel-inner {
// //     display: flex;
// //     animation: scrollCarousel 20s linear infinite;
// //     white-space: nowrap;
// //   }
  
// //   .bus-carousel-item {
// //     width: 100px; /* Initial width */
// //     height: auto;
// //     text-align: center;
// //     margin-right: 20px; /* Adjust margin as needed */
// //     transition: width 0.5s;
// //     flex-shrink: 0;
// //   }
  
// //   .bus-carousel-item img {
// //     width: 100%;
// //     height: auto;
// //   }
  
// //   @keyframes scrollCarousel {
// //     0%, 100% {
// //       transform: translateX(0%);
// //     }
// //     50% {
// //       transform: translateX(-50%);
// //     }
// //   }
  
// //   .bus-carousel-item:first-child,
// //   .bus-carousel-item:last-child {
// //     width: 50px; /* Adjust width for the beginning and end */
// //   }
  
// //   .bus-carousel-item:nth-child(2),
// //   .bus-carousel-item:nth-last-child(2) {
// //     width: 70px; /* Adjust width for the adjacent to beginning and end */
// //   }
  
// //   .bus-carousel-item:nth-child(3),
// //   .bus-carousel-item:nth-last-child(3) {
// //     width: 90px; /* Adjust width for the second adjacent to beginning and end */
// //   }
  

// //// another
// /// 
// .bus-carousel-container {
//     width: 100%;
//     overflow-x: auto;
//     white-space: nowrap;
//     -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
//   }
  
//   .bus-carousel-inner {
//     display: inline-flex;
//     animation: scrollCarousel 20s linear infinite;
//   }
  
//   .bus-carousel-item {
//     width: 100px; /* Initial width */
//     height: auto;
//     text-align: center;
//     margin-right: 20px; /* Adjust margin as needed */
//     flex-shrink: 0;
//   }
  
//   .bus-carousel-item img {
//     width: 100%;
//     height: auto;
//     transition: transform 0.5s;
//   }
  
//   @keyframes scrollCarousel {
//     0% {
//       transform: translateX(0);
//     }
//     100% {
//       transform: translateX(-100%);
//     }
//   }
  
//   .bus-carousel-item:nth-child(6) img {
//     transform: scale(1.2); /* Enlarge the image in the center */
//   }
  
//   .bus-carousel-item:first-child img,
//   .bus-carousel-item:last-child img {
//     transform: scale(0.8); /* Scale down the images at the ends */
//   }
  
//   @media screen and (min-width: 768px) {
//     .bus-carousel-item {
//       width: 80px; /* Adjust width for larger screens */
//     }
//   }
  
//   @media screen and (min-width: 1200px) {
//     .bus-carousel-item {
//       width: 60px; /* Adjust width for even larger screens */
//     }
//   }
  
.bus-carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.tickets-book-trv {
  margin-bottom: 20px;
}

.bus-carousel-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-in-out;
}

.bus-carousel-item {
  flex: 0 0 20%; /* Adjust the width of each item */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  // opacity: 0.5; /* Default opacity for non-center items */
}

.bus-carousel-item.center {
  transform: scale(1.2); /* Scale up the center item */
  opacity: 1; /* Full opacity for the center item */
}

.bus-carousel-item.adjacent {
  // opacity: 0.75; /* Slightly higher opacity for adjacent items */
}

.buses-rtc-img {
  width: 100%;
  height: auto;
}
