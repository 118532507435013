.retrive-btn{
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    display: block;
    border: none;
    cursor: pointer;
    color: #fff;
    text-align: center;
    padding: 6px 24px!important;
    
    font-weight: 400!important;
}
.cancel-modal{
    width: 900px !important;
}
.refence-tic .ant-descriptions-item-label {
    font-weight: bold;
}