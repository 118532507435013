@import "../../../assets/variableStyles.scss";

.busDetails_wrapper {
  .card_wrapper {
    margin-bottom: 16px;
    .busname {
      font-size: 18px;
      font-weight: 500;
      color:#330065;
      @include mobile {
        font-size: 16px;
      }
    }
    p {
      margin-bottom: 0;
    }
    .fromto_wrapper,
    .time_wrapper,
    .points_wrapper {
      display: flex;
      // align-items: center;
      justify-content: space-around;
      @include mobile {
        justify-content: space-between;
      }
    }
    .fromto_wrapper {
      .fromto {
        font-size: 16px;
        font-weight: 600;
      }
      .fa{
        margin-top: 8px;
      }
    }

    .bus-timings {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .du-fast {
          position: relative;
          flex: 1;
          color: #555;
          font-size: 10px;
          font-weight: 600;
          border-top: 2px solid #0775e2;
          margin: 0 5px;
          padding-bottom: 5px;
          span {
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            width: fit-content;
            margin: 0 auto;
            background: white;
            padding: 0 5px;
          }
        }
        // .du-fast::before {
        //   content: "";
        //   background-color: #0775e2;
        //   width: 80%;
        //   height: 2px;
        //   left: 0;
        //   right: 0;
        //   margin: 0 auto;
        //   top: 40%;
        //   position: absolute;
        // }
        li {
          margin-bottom: 5px;
          text-align: start;
          display: inline-block;
          color: #1c2b39;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .hr {
      border: 1px solid #eaebee;
      margin: 10px 0;
    }
    .points_wrapper {
      .point {
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        color: #969dac;
        max-width: 100px;
      }
      .loc {
        font-weight: 600;
        max-width: 140px;
      }
      .fa {
        font-size: 12px;
        margin-top: 24px;
      }
    }
    .busType {
      font-weight: 600;
      font-size: 12px;
       color: #969dac;
    }
    .opdetail {
       color: #969dac;
      .optitle {
        font-weight: 600;
        font-size: 12px;
      }
    }
    .pricetitle {
      color: #969dac;
      font-weight: 600;
      font-size: 12px;
      text-align: right;
    }
    .infotext {
      font-size: 12px;
      margin-bottom: 0;
      text-align: right;
      color: #969dac;
    }
    .price {
      font-weight: 600;
      text-align: right;
    }
  }
}
.journeyDate {
  float: right;
  margin-right: 16px;
  color: black;
  font-weight: bolder;
}
.gap-removed {
  row-gap: 0px !important;
}

.journeyDate .return {
  margin-right: 6px !important;
}

.ardjestment-of-checkoutcheck{
   margin-top: -2.7%;
  margin-left: 3%;
  @include mobile{
     margin-left: 7%;
    margin-top: -8%;
  }
}
.div-buschk-work{
   display: flex;
  width: 28%;
  justify-content: space-between;
  margin-left: 2%;
  // margin-top: -20px;
  @include mobile{
    width: 91%;
    margin-top: 0;
  }
}
.boardingpointmob{
  @include mobile{
    width: 50%;
  }
}