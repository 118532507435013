@import "../../../assets/variableStyles.scss";

.hotels_search_container {
  background-color: #f6f7f8;

  .hotel_banner {
    position: relative;
    z-index: 1;
    padding-top: 5.5%;

    .heading_text {
      position: absolute;
      z-index: 2;
      left: 50%;
      transform: translate(-50%, 50%);
      text-align: center;
      width: 100%;
      max-width: 1050px;
      top: 30%;

      @include mobile {
        position: static;
        transform: none;
        //background-color: #002172;
        padding-top: 17% !important;
      }

      h2 {
        color: #fff;
        font-family: "Open Sans";
        font-size: 45px;
        font-weight: 700;

        text-align: center;
        cursor: pointer;

        text-shadow: 3px 3px 4px grey;

        @media screen and (max-width: 768px) {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0;
        }
      }

      ul {
        display: flex;
        justify-content: center;

        li {
          position: relative;
          padding-right: 40px;
          font-size: 18px;
          color: #fff;

          &:not(:last-child)::after {
            content: " ";
            background-color: #fff;
            width: 5px;
            height: 5px;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            right: 20px;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        top: 8px;
        padding: 24px 16px;
        bottom: auto;
      }
    }

    .hotel_search {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      max-width: 1139px;

      @include mobile {
        position: static;
        transform: none;
      }
    }

    .hotels_search_box {
      border-radius: 0;

      @include mobile {
        border-bottom: 2px solid #dcdee3;
      }
    }
  }

  .hotels_advantages {
    max-width: 990px;
    margin: 70px auto;

    .hotels-advantages-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .hotels-adv-box {
        display: flex;
        align-items: center;
        flex-basis: 45%;
        flex-shrink: 0;
        flex-grow: 0;

        img {
          filter: drop-shadow(0px 0px 5px #35459c);
          border-radius: 10px;
        }

        .hotels-adv-box-wrapper {
          padding-left: 15px;

          p {
            font-weight: 700;
            margin-bottom: 3px;

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }

          small {
            font-size: 90%;
            font-family: "Open Sans";
          }

          @media screen and (max-width: 768px) {
            text-align: center;
            width: 90%;
            line-height: 1.3;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    @include mobile {
      padding-top: 0px;
    }
  }

  .queries_about_hotels {
    background-color: #f6f7f8;

    .query-container {
      max-width: 990px;
      margin: auto;
      padding: 56px 0;

      .query-hotels-heading {
        text-align: center;
        color: #1c2b39;

        h2 {
          font-size: 27px;
          line-height: 35px;
          font-weight: 300;
          font-family: "Open Sans";
        }

        p {
          font-size: 18px;
          margin-bottom: 30px;
        }

        @include mobile {
          padding: 0 15px;
        }
      }

      .query-hotels-body {
        display: flex;
        flex-wrap: wrap;


        .query-box {
          // box-shadow: 0 1px 4px 1px grey;
          background: linear-gradient(347deg, #35459c82, #bd0c2133);
          margin-bottom: 25px;
          padding: 0;
          margin-right: 8px;
          position: relative;
          overflow: hidden;



          .light {
            width: 20px;
            border-radius: 6px;
            height: 20px;
            background-color: #ffffffa7;
            box-shadow: 2px 3px 8px rgb(147, 147, 147);
            /* Light color */
            position: absolute;
            animation: snakeMove 10s linear infinite;
            opacity: 0.8;
            /* Adjust opacity as needed */
          }

          @keyframes snakeMove {
            0% {
              left: 0;
              top: 0;
            }

            25% {
              left: calc(100% - 2px);
              top: calc(50% - 1px);
            }

            50% {
              left: 0;
              top: calc(100% - 2px);
            }

            75% {
              left: calc(50% - 1px);
              top: calc(50% - 1px);
            }

            100% {
              left: 0;
              top: 0;
            }
          }

          .light2 {
            width: 20px;
            border-radius: 6px;
            height: 20px;
            background-color: #ffffff76;
            box-shadow: 2px 3px 8px rgb(147, 147, 147);
            /* Light color */
            position: absolute;
            animation: snakeMoveb 5s linear infinite;
            opacity: 0.8;
            /* Adjust opacity as needed */
          }

          @keyframes snakeMoveb {
            0% {
              right: 0;
              bottom: 0;
            }

            25% {
              right: calc(100% - 2px);
              bottom: calc(50% - 1px);
            }

            50% {
              right: 0;
              bottom: calc(100% - 2px);
            }

            75% {
              right: calc(50% - 1px);
              bottom: calc(50% - 1px);
            }

            100% {
              right: 0;
              bottom: 0;
            }
          }

          .pulse {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 46%;
            background-color: #bfc6eda4;
            /* Adjust color and opacity as needed */
            animation: pulseAnimation 2s ease-in-out infinite;
          }

          @keyframes pulseAnimation {
            0% {
              transform: scale(0.8);
              opacity: 0.8;
            }

            50% {
              transform: scale(1.6);
              opacity: 0.2;
            }

            100% {
              transform: scale(0.8);
              opacity: 0.8;
            }
          }

          .cloud {
            position: absolute;
            top: 50%;
            left: -200px;
            /* Start the cloud animation from outside the container */
            width: 200px;
            height: 100px;
            background-color: rgba(104, 0, 0, 0.8);
            /* Cloud color with transparency */
            border-radius: 50%;
            animation: cloudAnimation 30s linear infinite;
          }

          @keyframes cloudAnimation {
            0% {
              transform: translateX(-200px);
            }

            100% {
              transform: translateX(100%);
            }
          }

          .pulse-1 {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            background-color: rgba(255, 255, 255, 0.557);
            /* Adjust opacity as needed */
            border-radius: 5%;
            transform: translate(-50%, -50%);
            animation: pulseAnimat 2s ease-in-out infinite;
            pointer-events: none;
            /* Prevent the pulse from affecting interactivity */
          }

          @keyframes pulseAnimat {
            0% {
              width: 0;
              height: 0;
              opacity: 0.4;
            }

            50% {
              width: 100%;
              height: 100%;
              opacity: 0;
            }

            100% {
              width: 0;
              height: 0;
              opacity: 0;
            }
          }

          .particle-container {
            position: absolute;
            top: 50%;
            left: 20%;
            width: 100%;
            height: 100%;
            pointer-events: none;
            /* Prevent particles from affecting interactivity */
          }

          .particle {
            position: absolute;
            width: 15px;
            /* Adjust particle size as needed */
            height: 15px;
            /* Adjust particle size as needed */
            background-color: #35459c;
            // box-shadow: 3px 3px 8px #c7c7c7a8;
            /* Particle color */
            border-radius: 10%;
            opacity: 0.8;
            /* Adjust opacity as needed */
            animation: particleAnimation 10s linear infinite;
          }

          @keyframes particleAnimation {
            0% {
              top: 0;
              transform: translateY(0) rotate(0deg);
              opacity: 0.8;
            }

            50% {
              top: 60%;
              transform: translateY(-1000px) rotate(120deg);
              opacity: 0;
            }

            100% {
              top: 0;
              transform: translateY(0) rotate(0deg);
              opacity: 0.8;
            }
          }

          .water-surface {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 85, 255, 0.2) 0%, rgba(62, 223, 255, 0) 20%, rgba(66, 104, 255, 0.32) 40%, rgba(253, 255, 239, 0.5) 60%, rgba(242, 99, 255, 0.8) 80%, rgba(105, 136, 228, 0.7) 100%);
            animation: rippleAnimation 2s linear infinite alternate;
            // rgba(156, 189, 255, 0.2) 0%, rgba(62, 223, 255, 0.3) 20%, rgba(103, 122, 198, 0.5) 40%, rgba(210, 211, 202, 0.6) 60%, rgba(110, 7, 112, 0.4) 80%, rgba(76, 108, 202, 0.3) 100%);
          }

          @keyframes rippleAnimation {
            0% {
              background-size: 100% 100%;
            }

            100% {
              background-size: 100% 200%;
            }
          }

          .box-head {
            img {
              width: 100%;
              height: 100%;
              max-height: 157px;
              object-fit: cover;
            }
          }

          .box-body {
            padding: 20px;

            a {
              color: #35459c;
              font-weight: 700;
              font-size: 16px;

              &:hover {
                text-decoration: underline;
              }
            }

            p {
              font-size: 14px;
              margin-bottom: 0;
              margin-top: 16px;
              line-height: 1.5;
              // font-family: 'FontAwesome';
              color: #111111;
              text-shadow: 2px 3px 5px grey;

              @include mobile {
                font-size: 16px;
              }
            }

            .ht-img-l {
              opacity: 0.18;
              width: 113px;
              position: absolute;
              /* top: 205px; */
              bottom: -10px;
            }
          }

          &:hover {
            box-shadow: 1px 3px 4px grey;
          }
        }

        .full-box {
          padding: inherit;
          padding-top: 20px;

          h3 {
            font-size: 13px;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1;
          }

          .box-body {
            padding-left: initial;

            [class*="col-"] {
              @include mobile {
                margin-bottom: 1rem;
              }
            }

            a {
              color: #bd0c21;
              font-size: 14px;
              font-weight: initial;

              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
      }

      .query-hotels-footer {
        text-align: center;

        button {
          font-weight: 700;
          border-radius: 3px;
          padding: 8px 23px;
        }
      }
    }
  }



  .hovered-link:hover {
    .price-deals-24 {
      color: #bd0c21 !important;
    }

    .animate__flash {
      animation: unset;
    }
  }
}

.cart-shopping-img {
  width: 100%;
  height: 100%;
  max-height: 228px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.cart-shopping-img:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
}

.cart-h4 {
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  color: #fff !important;
  font-weight: bold;
}

.card-container-1 {
  max-width: 990px;
  margin: auto !important;
  padding: 56px 0 !important;
}

.heading-hotel {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 25px;
}

.heading-hotel h3 {
  text-align: center;
  font-size: 24px;
  color: #1c2b39;

  font-weight: 600;
}

.heading-hotel p {
  text-align: center;
  font-size: 18px;
}

.states-hotel {
  font-size: 13px;

  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

.cart-h4 h4 {
  text-align: center;
  font-size: 20px;
  line-height: 1.1;
  text-shadow: 0px 0px 5px rgba(66, 103, 178, 0.75);
  color: #ffffff;
  font-weight: 700;
}

.row-second-1 {
  margin-top: 25px;
}

// .hotel_banner {
//   @include minitab {
//     background-image: linear-gradient(to right, #bd0c215f, #35459c62);
//     padding-bottom: 10px;
//     padding-top: 9%;
//   }
// }

@media screen and (max-width: 767px) {
  .hotel_banner {
    // background-color: #2f81ed;
    background-image: linear-gradient(to right, #bd0c215f, #35459c62);
    padding-bottom: 10px;
    padding-top: 0%;
  }
}


// @-webkit-keyframes flash {
//   0%,
//   50%,
//   to {
//       opacity: 1;
//   }
//   25%,
//   75% {
//       opacity: 0;
//   }
// }
@keyframes color-change {
  0% {
    color: #000;
  }

  50% {
    color: #2f81ed;
  }

  100% {
    color: #bd0c21;
  }
}

.animate__flash {
  // -webkit-animation-name: flash;
  animation: color-change .7s linear infinite;


}

// .animate__animated {
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-duration: var(--animate-duration);
//   animation-duration: var(--animate-duration);
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
// }
// .animate__animated.animate__infinite {
//   -webkit-animation-iteration-count: infinite;
//   animation-iteration-count: infinite;
// }
.imgbanner-ht {
  // display: flex;
  justify-content: center;

}

.active-ht-tag {
  // position: relative;
  // margin: auto 20% auto 11%;
  // margin-top: -170px;
  // margin-bottom: 145px;
  position: absolute;
  margin: 45px auto auto auto;

  @include tab {

    margin: 18px auto auto auto;
  }

  .act-hdr-tag {
    font-size: 16px;
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 2px black;

  }

  .active-tg {
    display: flex;

  }

  .active-t {
    background: white;
    padding: 0 8px;
    border-radius: 15px;
    box-shadow: 1px 3px 7px #6e6e6e;
    margin-right: 10px;

    .anc-tab {
      color: #000 !important;
    }
  }

  @include mobile {
    display: none;
  }

}

///////
// .active-ht-tag {
//   position: relative;
//   margin: auto 20% auto 11%;
//   margin-top: -170px;
//   margin-bottom: 145px;

//   .act-hdr-tag {
//     font-size: 16px;
//     color: white;
//     font-weight: 600;
//     text-shadow: 1px 1px 2px black;

//   }

//   .active-tg {
//     display: flex;

//   }

//   .active-t {
//     background: #ffffff66;
//     padding: 0 8px;
//     border-radius: 2px;
//     box-shadow: 1px 1px 7px #000000;
//     margin-right: 10px;

//     .anc-tab {
//       color: #ffffff !important;
//     }
//   }

//   @include mobile {
//     display: none;
//   }
// }

.mainn-section-tophotels {
  .slick-initialized {

    .slick-prev:before,
    .slick-next:before {
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #c4c4c4;
      opacity: 0.75;
      padding: 25%;
      border-radius: 50%;
      background-color: rgb(0, 57, 143);
    }
  }
}

.active-ht-tag {
  // position: relative;
  // margin: auto 20% auto 11%;
  // margin-top: -170px;
  // margin-bottom: 145px;
  position: absolute;
  margin: 45px auto auto auto;

  @include tab {

    margin: 18px auto auto auto;
  }

  .act-hdr-tag {
    font-size: 16px;
    color: white;
    font-weight: 600;
    text-shadow: 1px 1px 2px black;

  }

  .active-tg {
    display: flex;

  }

  .active-t {
    background: #ffffff66;
    padding: 0 8px;
    border-radius: 2px;
    box-shadow: 1px 1px 7px #000000;
    margin-right: 10px;

    .anc-tab {
      color: #ffffff !important;
    }
  }

  @include mobile {
    display: none;
  }
}