@import "../../../assets/variableStyles.scss";

.trip-contents {
   display: flex;
  justify-content: space-around;
  padding: 10px 0px;

   @include mobile {
    padding-left: 5px;
  }

  &:hover {
    background: var(--leg-hover-color, rgba(234, 235, 238, 0.6));
  }

  input {
    margin-right: 10px;
  }
}
.pointer_cur{
  cursor: pointer !important;
 }

 
.trip-content-left {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }
}

.trip-arr-dep {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 20px;

  @include mobile {
    flex-direction: column;
  }
}

.timings {
  i {
    color: #1c2b39;
    font-size: 15px;
    padding: 1px 5px;
  }
}

.duration {
  padding-left: 20px;
  text-align: center;

  @include mobile {
    padding-left: 5px;
  }

  i {
    @include mobile {
      display: none;
    }
  }
}

.show-more-btn {
  padding: 4px 4px 0px 80px;

  @include mobile {
    padding: 4px 4px 0px 26px;
  }

  button {
    border: solid 1px #2e0000;
    color: #fff;
    line-height: 25px;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      background: none;
      color: #739deb;
      font-weight: 700;
    }

    i {
      color: #2e0000;
      font-weight: 700;
    }
  }
}

.share-section {
  font-size: 17px;
  color: #007baa;
  display: block;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-top: 3px;

  span {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.currencyType {
  color: #2e0000;
  font-size: 15px;
  letter-spacing: 1px;
  padding-right: 4px;

  @include mobile {
    font-size: 15px;
  }
}

.netfare {
  font-size: 15px;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.different-amenities {
  font-size: 16px;
  color: rgb(29, 160, 35);

   
  @include mobile {
   }
}

 
.ament-tooltip {
  padding-left: 40px;
}

.amenities-tooltip .amenities-tooltip>.tooltip-inner {
   min-width: 450px !important;

  @include mobile {
    max-width: 300px !important;
  }
}

.ant-tooltip {
  max-width: auto !important;

   @include mobile {
    max-width: 300px !important;
  }
}

 
.tooltip-content {
  display: flex;
  justify-content: center;
  padding: 20px 20px !important;

   .flight-amenities {
    padding: 0px 5px;

    .flight-src-dest {
      h6 {
        color: #fff;
        padding-bottom: 10px;
      }
    }

    .amenity-types {
      p {
        line-height: 1.4;
        font-size: 12px;

        i {
          padding-right: 6px;
        }
      }

      .aminities-color {
        color: #1da023;
        font-size: 12px;
      }
    }
  }
}

/* Tooltip-styles end */

.trip-total-details {
  @include mobile {
    order: 1;
  }
}

.trip-choose-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-left: solid 1px #ccc;

  @include mobile {
    order: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    text-align: left;
    padding: 0px 30px;
  }
}

/*********** Antd styles ********/
.flight-results-container {

   .ant-card-body {
    padding: 0;
  }

  .ant-row {
    @include mobile {
      flex-wrap: wrap-reverse;
    }
  }

  .flight-trip-details {
     background-color: #fff;

    @include mobile {
      border: none;
    }

    height: 150px;
  

    .itinary-details {
      display: flex;

      .itinary-flight-name {
        width: 20%;

        @include mobile {
          width: 78%;
          margin-bottom: 12px;
        }

        img {
          width: 50px;

          @include mobile {
            width: 35px;
            align-items: center;
          }
        }
      }

      .fromTo {
        width: 65%;

        @include mobile {
          flex: 1;
          width: 100%;
          padding-top: 10px;
        }

        .cities-from-to {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
           position: relative;

          @include mobile {
            width: 94%;
          }

          .loc_wrapper,
          .terminal_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 10px;
            font-weight: 600;

            @include mobile {
              p {
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }

  .code-line {
    width: 50%;
    height: 2.4px;
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    margin: auto;

    @include mobile {
      font-size: 12px;
      padding: 0px 15px;
      align-items: center;
      text-align: center;
      width: 10%;
    }
  }

  .flight-class-details {
    width: 25%;
    text-align: end;

    @include mobile {
      width: fit-content;
      position: absolute;
      top: 18px;
      right: 12px;
    }
  }
}

/******************* Flight Choose button section ***********/
.choose-btn-wrapper {
  background: #fff;
  margin-bottom: 10px;
  height: 100%;
 
}

.choose-btn-block {
   width: 119%;
  text-align: center;
  padding-top: 9%;
  display: flex;
   justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: -20%;

  @include mobile {
     flex-direction: row;
    width: 100%;
    text-align: start;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }

  .choose-btn-price {
    margin-bottom: 3px;

    @include mobile {
      margin-bottom: 0;
      margin-top: 12px;
      margin-left: 82px;
    }
  }
}

.choose-btn-section {
  @include mobile {
    order: 1;
    width: 17%;
    margin-top: 2%;
  }
}

.btn-section {
  @include mobile {
    display: flex;

    .share-section {
      margin-right: 10px;
    }
  }
}

.choose-button {
  border-radius: 2px;
  height: 31px;
  width: 105px;
  border: 1px solid #2e0000;
  color: #2e0000 ;
  border-radius: 96px;
  font-size: calc(var(--font-scale, 1)*13px);
  position: relative;
  padding: 0 8px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #daebff;

  @include mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 23px;
  }
}

/******************* Flight Timings ***********/
.flight-timings {
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding: 2px 0;
  justify-content: space-between;

  @include mobile {
    align-items: baseline;
  }

  .plane-timing-details {
    display: flex;
    width: 100%;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  @include mobile {
    margin-left: 0;
  }

  .plane-timings {
     display: flex;

    @include mobile {
      margin-bottom: 10px;
    }

    .timings-container {
      margin-right: 20px;

      @include mobile {
        margin-right: 0;
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        margin-right: 5px;

        p {
          margin-bottom: 0;
          font-size: 13px;
          font-weight: bold;
         
        }
      }
    }
  }

  .travel-time {
    display: flex;
    align-items: center;
    width: auto;

    p {
      margin-bottom: 0;
      margin-left: 5px;
      display: flex;
      align-items: center;
      color: #ccc;

      @include mobile {
        font-size: 12px;
      }

      span {
        margin-right: 5px;
        font-size: 20px;

        @include mobile {
          font-size: 15px;
          margin-right: 0;
        }
      }
    }
  }

  .facility-icons {
    width: 35%;

    span {
      display: flex;

      p {
        margin-right: 15px;
        margin-bottom: 0;
        color: #1da023;
        font-size: 20px;

        @include mobile {
          font-size: 15px;
        }
      }
    }
  }

  .details-btn {
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: flex-end;

    p {
      margin-bottom: 0;
      margin-left: 5px;
    }

    .details-text {
      font-size: 13px;
      color: #2e0000;
      margin-left: 15px;
      cursor: pointer;
    }

    .details-bag-icon {
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
}

.flight-details-modal {
  width: 840px !important;

  .ant-modal-header {
    .ant-modal-title {
      color: white;
    }
  }

  .ant-modal-body {
    padding: 0;
    max-height: 500px;
    overflow-y: scroll;
  }

  .ant-modal-footer {
    display: none;
  }

  .all-times-local {
    padding: 16px 24px;
    border-bottom: 1px solid #ccc;

    @include mobile {
      text-align: center;
      padding: 0 15px 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .from-to-modal-details {
    padding: 16px 24px;

    @include mobile {
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .more-details-row {
    align-items: center;

    @include mobile {
      align-items: stretch;
      flex-direction: column;
    }

    .cities-names {
      @include mobile {
        width: 100%;
      }
    }

    .more-details-list {
      &:nth-child(2) {
        @include mobile {
          text-align: end;
        }
      }

      p {
        &:nth-child(1) {
          font-size: 25px;
          text-transform: uppercase;
          color: #00164d;
          margin-bottom: 0;

          @include mobile {
            font-size: 20px;

            span {
              display: block;
            }
          }
        }

        &:nth-child(2) {
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            span {
              display: block;
            }
          }
        }
      }
    }

    .more-details-list-to {
      @include mobile {
        text-align: end;
      }
    }

    .more-details-line {
      height: 2px;
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      margin: auto;
      margin-left: -15px;

      @include mobile {
        margin-left: 0;
      }
    }

    .trip-duration-time {
      @include mobile {
        display: flex;
        align-items: center;
        align-self: center;
      }

      p {
        &:nth-child(1) {
          color: #969dac;
          margin-bottom: 0;
          font-size: 12px;

          @include mobile {
            margin-right: 10px;
          }
        }

        &:nth-child(2) {
          color: #00164d;
          margin-bottom: 0;
        }
      }
    }
  }

  .total-flight-details {
    padding: 16px 24px 16px 60px;
    background-color: #f6f7f8;

    @include mobile {
      padding: 15px 15px 15px 22px;
    }

    .to-fro-flight-details {
      display: flex;
      position: relative;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #2e0000;
        position: absolute;
        left: -33px;
        top: 4px;

        @include mobile {
          left: -16px;
        }
      }

      .from-city-flight-details {
        margin-right: 25px;

        @include mobile {
          display: flex;
          margin-right: 0;
        }
      }
    }

    .collapsed-card {
      padding: 25px 0;
      position: relative;

      @include mobile {
        padding: 10px 0;
      }

      &::before {
        content: "";
        height: 100%;
        position: absolute;
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        width: 3px;
        left: -29px;
        top: 0;

        @include mobile {
          left: -12px;
        }
      }

      .ant-card-body {
        padding: 0;
      }

      .ant-collapse {
        border: none;
      }

      .ant-collapse-item {
        border: none;
      }

      .ant-collapse-header {
        background-color: white;
      }

      .ant-collapse-content-box {
        @include mobile {
          padding: 0 10px 10px;
        }
      }
    }
  }
}

.acc-body {
  .acc-body-section {
    display: flex;
    padding: 7px 2px;
    align-items: baseline;

    @include mobile {
      font-size: 12px;
    }

    .icon {
      margin-right: 7px;
      color: #1da023;
    }

    p {
      color: #1da023;
    }

    &:nth-child(1) {
      padding-right: 25px;

      @include mobile {
        padding-right: 0;
      }
    }

    .refundable {
      color: #1da023;
      padding-left: 12px;
    }

    .non-refundable {
      color: red;
      padding-left: 12px;
    }
  }

  .seat-sec {
    i {
      padding-right: 7px;
      color: #505c75;
    }

    p {
      color: #505c75;
    }
  }

  .flight-class {
    .ec-class {
      padding-left: 52px;
    }

    span {
      font-weight: 700;
    }
  }
}

.tooltip-data {
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  p {
    font-size: 12px;
    color: #fff;
    margin-bottom: 0;
  }
}

.ant-tooltip-content {
  min-width: 100% !important;
  border-radius: 10px;

  .ant-tooltip-inner {
    padding: 10px 15px;
  }
}

.ant-tooltip {
  max-width: 100% !important;
}

.luggage-tooltip {
  p {
    color: #1da023;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;

    .ant-icon,
    i {
      font-size: 18px;
      margin-right: 5px;
    }

    &:nth-child(1) {
      color: #fff;
    }

    &:nth-child(2) {
      color: #fff;
    }
  }
}

.collapse-header {
  .flight-icon {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }

    .flight-names-block {
      p {
        font-size: 12px;
        margin-bottom: 0;

        &:nth-child(1) {
          font-size: 20px;

          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }

  .ant-row {
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .modal-flight-icon {
    i {
      margin-right: 10px;
      font-size: 18px;

      @include mobile {
        font-size: 15px;
      }
    }

    span {
      @include mobile {
        font-size: 13px;
      }
    }
  }

  .timer-flight-icon {
    span {
      margin-right: 10px;

      @include mobile {
        font-size: 13px;
      }
    }

    .icon {
      font-size: 18px;

      @include mobile {
        font-size: 15px;
      }
    }
  }
}

.combined-view {
   height: 100%;

  .flight-trip-details {
    padding: 0px 15px;
  }
}

/*********** Seperate View ******/
.seperate-view {
  margin-bottom: 10px;

  .right-flight-details {
    border-bottom: 1px solid #ccc;
    padding: 15px;

    &:last-child {
      border: none;
    }
  }
}

.break-journey-city {
  border: 1px solid #2e0000;
  position: relative;
  margin: 0 5px;
  flex: 1;

  .inner_wrapper {
    position: absolute;
    width: 100%;
    text-align: center;

    .stop_points {
      display: inline-block;
      margin: 5px;
    }
  }

  .code-line-break {
    height: 7px;
    width: 7px;
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    border-radius: 50%;
    margin: auto;
    margin-top: 12px;

    &::before {
      content: "";
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      width: 20%;
       height: 2px;
      left: 50px;
      position: absolute;

      @include mobile {
        width: 10%;
        left: 45px;
      }
    }

    &::after {
      content: "";
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      width: 20%;
       height: 2px;
      right: 47px;
      position: absolute;

      @include mobile {
        width: 10%;
        right: 45px;
      }
    }
  }

  .break-city-name {
    font-size: 12px;
    text-transform: uppercase;

    &::before {
      position: absolute;
      height: 7px;
      top: -7px;
      width: 7px;
      margin: 0 2px;
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      border-radius: 50%;

      border: 4px solid white;
      box-sizing: content-box;
      content: "";
    }
  }
}

.change-timer {
  background-color: #eaebee;
  padding: 24px 60px;
  display: flex;
  align-items: center;

  .change-text {
    color: #e0760b;
    font-weight: 600;
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      font-size: 20px;
    }

    .icon-text {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }

  .time-for-interchange {
    font-size: 13px;
  }
}

.combined-flight-results-container {
  height: 100%;

  .combined-flight-card {
    height: 100%;
    background-color: white;
    border: none;

    .ant-card-body {
      height: 100%;

      .ant-row {
        height: 100%;
      }

      .flight-trip-details {
        height: 100%;
      }
    }

    .flight_details_row {
      display: inline-flex;

      .flight_img_details {

         .flight_img_details_sub {
          display: inline-flex;

          span {
            width: 20%;
            height: 20%;
          }
        }
      }

      .common_flight_headings_sub {
        padding: 12px 35px;

        .upper_headings {
          font-size: calc(var(--font-scale, 1)*15px);
           width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 700;
          margin: 0px 0px 0px;

        }


        .lower_heading {
          margin-top: -17px;
          font-size: 12px;
          margin: 0px 0px 0px;
        }

        .diff_line_segments {
          border-bottom: 3px solid rgb(81, 226, 194);
          width: 70px;
          height: 2px;
          position: absolute;
          top: 35px;

           z-index: 1;

          .segments {
            width: 8px;
            height: 8px;
            border: 2px solid #e7e7e7;
            display: inline-block;
            background-color: #959595;
            position: relative;
            z-index: 2;
            margin: 0 2px;
            border-radius: 20px;
            top: -11px;
            left: 20px;
          }

        }
      }
    }

    .flightdeatils_drop {
      
      background-color: #f9f9f9;

      .flightdeatils_drop_text {
         color: #2e0000;
      
      }

    }
  }
}


.flightdeatils_drop {
  border: 2px solid transparent;
  padding: 0%;
   background-color: #f9f9f9;

  @include mobile {
    margin-top: -1%;
  }

  .flightdeatils_drop_text {
     color: #2e0000;
    margin-left: 2%;
   
  }

}

.share-modal {
  width: 290px !important;
  border-radius: 10px;
}

.share-link-icons {
  display: flex;

  .common-share-icons {
    margin: 15px 30px;
    text-align: center;

    .anticon {
      font-size: 25px;
      color: #2e0000;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.flight-infoModal {
  text-align: center;

  .ant-modal-body {
    padding: 18px !important;
    text-align: center;
  }

  .ant-btn {
    margin-top: 15px;
  }
}

/* Dec-10-2020 */
.itinary-flight-name p {
  margin-bottom: 0px !important;
}

.combined-flight-results-container .ant-card-bordered {
  background-color: rgb(255, 255, 255) !important;
  //border-radius: 8px;
  // border: 0 solid #f0f0f0 !important;
}

.share-price-mail {
  display: flex;
}

/*---22/07/2020---*/
.token-number-flught {
  display: flex;
}

.flight-syt-time {
  position: absolute;
  top: 62px;
  left: 205px;
}

.details-bag-icon {
  display: flex;
  margin-bottom: 0px;
  line-height: 13px;
  align-items: center;
  margin-bottom: 0;
  color: #2e0000;
  font-size: 13px;
}

.details-bag-icon {
  position: absolute;
  top: 69px;
  right: 14px;
}

@media screen and (max-width: 768px) {
  .itinary-details {
    display: block !important;
  }

  .refundText {
    position: absolute;
    top: 54px;
    right: 172px;
    font-size: 11px !important;
  }

  .flight-syt-time {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }

  .details-bag-icon {
    top: 72px !important;
  }

  .flight-timings .facility-icons {
    width: auto !important;
  }

  .flight-timings {
    margin-top: 10px;
    margin-bottom: -20px;
  }
}
.promo-strip{
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
   display: none;
@include mobile{
  max-width: 100%;
  overflow: hidden;
}
}
.promoData {
  text-align:left;
  margin-top: 10px;
  padding: 3px 15px;
  width: 100%;
  white-space: nowrap;
  
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
   color: rgb(255, 255, 255);
  min-height: 22px;
  font-size: 12px;

   @include mobile {
    border-radius: 8px;

  }

}

.timing_wrapper {
  border: 2px solid transparent;
  margin-left: 8%;

  @include mobile {
    margin-left: 0%;
  }
}

.flightCardWrapper {

  .FarDetailsCollapse {

    .row-Headings {
      padding: 0px 11px;
      font-size: 10px;
      font-weight: 900;
      color: #1c2b39;
      text-transform: uppercase;
      background-color: #f3f6f9;
      border: 2px solid;
    }

    .row-Data {
      padding: 15px 0px 15px 11px;

      justify-content: center;
      font-size: 12px;

      box-shadow: 0 0 2px 0 #d7dce2;

      .fareRules-btn {
        cursor: pointer;
      }

      .fareRules-btn:hover {
        color: #2e0000;
      }

      .FareTypeName {
        margin: 0 !important;
        font-size: 14px;
        font-weight: 900;
      }

      .FareTypeName:before {
        content: "";
        position: absolute;
        left: -11px;
        top: 3px;
        width: 4px;
        height: 16px;
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      }

      .sub-FareTypeName {
        margin: 0 !important;
      }
    }

    .price {
      font-size: 18px;
      font-weight: 600;
    }

    .bottom-airline-text {
      p {
        font-size: 13px;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .ant-collapse-content-box {
    padding: 0px 5px !important;
  }

  .FarDetailsCollapse-Mobile {
    display: none;

    @include mobile {
      display: block;
    }

    .row-Data {
      padding: 15px 17px 15px 17px;
      justify-content: center;
      font-size: 12px;
      color: #8e93ab;
      box-shadow: 0 0 2px 0 #d7dce2;

      .text {
        color: #1c2b39 !important;
      }
    }

    .ant-collapse-header {
      display: none;
    }

    .FareTypeName,
    .price {
      margin: 0 !important;
      font-size: 14px;
      font-weight: 900;
      color: #1c2b39 !important;
    }

    .FareTypeName:before {
      content: "";
      position: absolute;
      left: -17px;
      top: 3px;
      width: 4px;
      height: 16px;
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    }

    .choose-web-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    span.fare-type-icon {
      padding-right: 5px;
    }
  }
}

.flights-btn-view {
  background-color: rgba(0, 0, 0, 0.03);
   border-radius: 0 0 8px 8px;
  box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
}





.FarDetailsCollapse_none {
  display: none;
}

.FareDetails_Heading {
  display: flex;
  padding: 10px 0;
  background-color: #f3f6f9;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 10px;

  @include mobile {
    display: none;
  }

}

.Faredetails_content {
  box-shadow: 0 0 2px 0 #d7dce2;
  padding: 12px 0;

  .FareTypeName {
    font-size: 14px;
    margin: 0px 0px 3px;
    font-weight: 900;
  }

  .sub-FareTypeName {
    font-size: 12px;
    font-family: sans-serif;
    margin: 0px 0px 0px;
  }

  .text {
    font-family: sans-serif;
    font-size: 12px;
  }

 

}

.View_Canceldetails {
  background-color: #fff;
  border: 1px solid #2e0000;
  color: #2e0000;
  font-weight: 700;
  // padding: 5px 20px;
  // height: 32px;
  padding: 1%;
  border-radius: 34px;
}

.static_text {
  font-family: sans-serif;
  font-size: 13px;
  padding: 10px 10px;
}

.view-pricecolss1 {
  .Fare_text {
    font-size: 16px;
    color: #000000;
    font-weight: 900;
  }
}


.flight_deatails {
  background-color: #fafbfc;

  .flight_details_headings {
    display: inline-flex;

    .active_tab {
      background-color: #fff;
      border: 1px solid #2e0000;
      color: #000000D9;
      font-weight: 700;
      padding: 5px 20px;
      height: 32px;
    }
  }

  .flightdetails_content {
    margin-top: 20px;
    border: 1px solid #dfdfdf;
    ;

    .Journey_heading {
      .journey_route {
        border-bottom: 1px solid #dfdfdf;
        padding: 12px 10px;
        color: #000;
        font-weight: 700;
      }
    }

    .flight_logo_up1 {
      padding: 10px 10px;
       width: 24%;
      display: flex;
      display: none;

      @include mobile {
        display: block;
      }
    }

    .flight_logo_up {
      padding: 10px 10px;
      width: 25%;
      display: flex;
      display: block;

      @include mobile {
        display: none;
      }

      .flight_logo {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .airLine {
        color: #000000;
        margin-right: 10px;
      }

      .flight_number {
        color: #6d7278;
      }
    }

    .flight_timing {
      padding: 10px 10px;
      display: inline-flex;
      width: 100%;

      .origin_details {
        width: 30%;

        @include mobile {
          width: 38%;
        }

        .dest {
          font-size: 12px;
          font-family: sans-serif;
        }

        .arrival {
          margin: 0;
          padding: 0;
        }


        .time {
          font-size: 18px;
          color: #000;
          font-weight: 900;
          margin: 0;
          padding: 0;
        }

        .date_time {
           font-size: 12px;
          font-weight: 700;
        }
      }

      .destination_details {
        margin-left: 50px;
        width: 30%;

        @include mobile {
          margin-left: 0px;
          width: 37%;
        }

        .dest {
          font-size: 12px;
          font-family: sans-serif;
        }

        .arrival {
          margin: 0;
          padding: 0;
        }

        .time {
          font-size: 18px;
          color: #000;
          font-weight: 900;
          margin: 0;
          padding: 0;
        }

        .date_time {
           font-size: 12px;
          font-weight: 700;
        }
      }

      .duration {
        width: 25%;

        @include mobile {
          width: 24%;
        }

        .diff_line {
          border-bottom: 3px solid #979797;
          width: 45px;
          height: 2px;
          position: absolute;
          left: 30px;
           margin: 0;
          top: 25px;
           z-index: 1;

        }
      }
    }

    .change_planes {
      text-align: center;
      border-top-style: dotted;
       border-color: #d7d7d7;
      margin: 25px 0;

      .change_planes_border {
        border-radius: 25px;
        background-color: #f7f7f7;
        padding: 10px 19px;
        display: inline-block;
        position: relative;
        top: -21px;
        color: #cf8100;
        font-weight: 700;
        font-size: calc(var(--font-scale, 1)*12px);
      }
    }

  }
}

.class-name {
  background: #FBF6CA;
  text-align: center;
  font-weight: 600;
  padding: 8px 0;
  font-size: 14px;
  margin: 0;
}

.main-flightres-box {
  border: 2px solid transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: white;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 8px;
}



 

.flightres_img_details {
  border: 1px solid transparent;
  display: block;

  @include tab {
    width: 10%;
  }

  @include mobile {
    width: 20%;
    display: none;
  }
}

.flightres_img_details1 {
  border: 1px solid transparent;
  display: none;

  @include mobile {
    width: 15%;
    display: block;
  }
}

.common_flight_headings {
  border: 2px solid transparent;
   text-align: center;
  width: 25%;

  @include mobile {
    width: 27%;
  }
}

.common_flight_headings1 {
  border: 2px solid transparent;
  width: 12%;
  text-align: center;
  display: block;

  @include mobile {
    display: none;
  }
}

.arrow-flightres {
  border: 1px solid black;
}

.airline-img {
  width: 70%;
  height: 90%;

  @include minitab {
    width: 100%;
    height: 75%;
  }

  @include tab {
    width: 100%;
    height: 80%;
  }

  @include mobile {
    width: 100%;
    height: auto;
  }
}

.flightseg-for-mob {
  display: none;

  @include mobile {
    display: block;
    width: 100%;
    border: 2px solid transparent;
    display: flex;
  }
}

.arrowarroe {
  position: absolute;
  left: 70.5%;
  top: 27px;
  overflow: hidden;

  @include tab {
    left: 69.5%;
    top: 25px;
  }

  @include mobile {
    left: 66.5%;
    top: 24.5px;
  }
}

.fare_type {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;

  .couponcard {
    border: 1px solid #cccccc;
    float: left;
    min-height: 50px;
    border-radius: 4px;
    box-shadow: 0px 0 5px rgba(0, 0, 0, .15);

    .couponcard_header {
      background: #FBF6CA;
      text-align: center;
      font-weight: 600;
      padding: 8px 0;
      font-size: 14px;
      margin: 0;
    }

    .couponcard_fare {
      text-align: center;
      font-weight: 600;
      padding: 8px 0;
      font-size: 14px;
      margin: 0;
    }

    width: 24%;
  }
}

.fare_btn {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #2e0000;
  border: 1px solid #2e0000;
  border-radius: 20px;
  padding: 3px 10px;
  text-decoration: none;
  margin-top: 5px;
  float: left;
  cursor: pointer;
  height: 25px;
}

.baggage-table {
  border: 1px solid rgba(194, 194, 194, 0.605);
  margin-top: 26px;
  width: 100%;
  border-radius: 6px;


  .baggage-header {
    border: 1px solid rgba(194, 194, 194, 0.605);
    width: 100%;
    height: 30px;
    background: rgba(232, 232, 232, 0.322);
    padding-top: 6px;
    padding-left: 40px;
    border-radius: 4px;


    .header {
      display: inline-flex;

      h4 {
        font-size: 12px;
        margin-left: 65px;
        padding: 0 20px;
        align-items: end;
        font-weight: 600;
        color: grey;
      }

    }

  }

}

.cancel_flight_details {
  border: 1px solid #CCC;
  margin-top: 25px;

  .inner {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #CCC;

    .txt {
      text-align: right;
    }
  }
}

.tab-X-one {
  position: absolute;
  top: 27px;
  left: 96%;
}

.combine-roundtrip-view {

  .ant-col {
     @include mobile {
      width: 100%;
    }
  }
}

.view-pricecolss {
  border: 2px solid transparent;
  width: 18%;

  @include minitab {
    width: 16%;
  }

  @include mobile {
    width: 100%;
  }
}

.view-pricecolss1 {
  border: 2px solid transparent;
  width: 12%;

  @include tab {
    width: 11%;
  }

  @include mobile {
    width: 100%;
  }
}

.view-pricecolss2 {
  border: 2px solid transparent;
  width: 20%;

  @include minitab {
    width: 19%;
  }

  @include tab {
    width: 18%;
  }

  @include mobile {
    width: 100%;
  }
}

.view-pricecolss3 {
  border: 2px solid transparent;
  width: 12%;

  @include minitab {
    width: 17%;
  }

  @include tab {
    width: 17%;
  }

  @include mobile {
    width: 100%;
  }
}
.View_Cancel-detailss{
 margin-left: 2%;
  color: #2e0000;
  font-size: 13px;
}