.cartypes-value {
  width: 520px !important;

  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-header {
    background-image: linear-gradient(to right, #320063, #0f76bb) !important;
    border-radius: 15px;
  }
}
