@import "../../../assets/variableStyles.scss";

.busDetails_wrapper {
  .card_wrapper {
    margin-bottom: 16px;

    .busname {
      font-size: 18px;
      font-weight: 500;
      color: #bd0c21;

      @include mobile {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 0;
    }

    .fromto_wrapper,
    .time_wrapper,
    .points_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;

      @include mobile {
        justify-content: space-between;
      }
    }

    .fromto_wrapper {
      .fromto {
        font-size: 16px;
        font-weight: 600;
        color: #35459c;
        text-overflow: ellipsis;
        max-width: 110px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .journeyDate {
      color: #1c2b39;
      font-size: 16px;
      font-weight: 600;
    }

    .bus-timings {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .du-fast {
          position: relative;
          flex: 1;
          color: #555;
          font-size: 10px;
          font-weight: 600;
          border-top: 2px solid #bd0c21;
          margin: 0 5px;
          padding-bottom: 5px;

          span {
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
            width: fit-content;
            margin: 0 auto;
            background: white;
            padding: 0 5px;
          }
        }

        // .du-fast::before {
        //   content: "";
        //   background-color: #bd0c21;
        //   width: 80%;
        //   height: 2px;
        //   left: 0;
        //   right: 0;
        //   margin: 0 auto;
        //   top: 40%;
        //   position: absolute;
        // }
        li {
          margin-bottom: 5px;
          text-align: start;
          display: inline-block;
          color: #1c2b39;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .hr {
      border: 1px solid #eaebee;
      margin: 10px 0;
    }

    .points_wrapper {
      .point {
        font-weight: 600;
        font-size: 12px;
        color: #969dac;
      }

      .loc {
        font-weight: 600;
      }

      .fa {
        font-size: 12px;
      }
    }

    .busType {
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      color: #969dac;
    }

    .opdetail {
      text-align: center;
      color: #969dac;

      .optitle {
        font-weight: 600;
        font-size: 12px;
      }
    }

    .pricetitle {
      color: #969dac;
      font-weight: 600;
      font-size: 12px;
      text-align: right;
    }

    .infotext {
      font-size: 12px;
      margin-bottom: 0;
      text-align: right;
      color: #969dac;
    }

    .price {
      font-weight: 600;
      text-align: right;
    }

    .tran-car {
      border: 1px solid #35459c;
      border-radius: 6px;

      .car-type-tr {
        font-size: 16px;
        padding: 0px 5px;
        font-weight: 500;
        white-space: nowrap;
        background: linear-gradient(to right, #bd0c21, #35459c);
        color: #f0f0f0;
        padding-right: 14px;
        // border-bottom-right-radius: 26px;
        border-radius: 4px 0px 26px;
        max-width: 98%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: fit-content;
      }
    }
  }
  .fromto_wrapper{
    .from{
      font-size: 12px;
    font-weight: 600;
    color: #35459c;
    text-overflow: ellipsis;
    max-width: 230px;
    // overflow: hidden;
    // white-space: nowrap;
    }
    
  }
}

.check-car {
  border: 1px solid #35459c;
  border-radius: 4px;


  .carname {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(to right, #68f392, #0ab1f6);
    padding: 1px 18px 2px 4px;
    border-bottom-right-radius: 25px;
    max-width: fit-content;

  }

  .info-car {

    justify-content: space-around;
  }

  .Cartype-t {
    font-size: 14px;
    font-weight: 600;
    color: #35459c;
  }

  .Cartype-i {
    font-size: 14px;
    color: #6b6b6b;
    text-overflow: ellipsis;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .car-driver {
    display: flex;

    .dri-1 {
      font-weight: 600;
      font-size: 14px;
    }

    .dri-2 {
      font-size: 14px;
      color: #35459c;
    }
  }

  .amme {
    display: flex;

    .am-1 {
      font-weight: 600;
      font-size: 14px;
    }

  }

  .am-2 {
    color: #35459c;
    font-size: 14px;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
  }
}

.ant-tooltip-inner {
  color: #35459c !important;
}