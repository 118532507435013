@import "../../assets/variableStyles.scss";


.modifySelect {
  @include mobile {
    margin: 10px 0;
  }
}

.react-datepicker-component {
  .react-datepicker-input {
    input {
      background: #fff !important;
      padding-left: 38px !important;
      padding-right: 0 !important;
    }

    .button-wrapper {
      position: absolute !important;
      left: 0 !important;
      right: initial !important;

      i {
        color: #2e0000;
      }
    }
  }

  .react-datepicker-input.has-value {
    input {
      font-weight: 100 !important;
      color: #505c75 !important;
    }
  }
}

 
.flight_search_box {
  input {
    border-color: #bcd5f5;
  }

  background: #fff;
  padding: 20px 20px 15px 20px;
  border-radius: 5px;
  margin-top: 10px;

  @include mobile {
    padding: 15px;
  }

  .flight-header {
    margin-bottom: 4px;

    label {
      display: flex;
      align-items: flex-end;
      font-size: 14px;

      &:active {
        color: #1c2b39;
        font-weight: 500;
      }
    }

    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }

      .col {
        padding-left: 0;

        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 500;
          line-height: 19px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;
      position: relative;

      label {
        font-size: 11px;
        font-weight: 500;
        color: #1c2b39;
        margin-bottom: 0;
      }

      .flight-search-btn {
        background-color: #e2076a;
        color: #fff;
        border-color: transparent;

        &:hover {
          background-color: #eb5196;
        }
      }

      .city-increment {
        position: absolute;
        right: -20px;
        top: 50%;
      }
    }

    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: FontAwesome;
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          &:hover {
            color: red !important;
          }
        }
      }
    }
  }

  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .place-box {
      width: 36% !important;
      padding-right: 10px;
      padding-left: 10px;
    }

    .departure-date-box {
      width: 23%;
    }

    .city-increment {
      margin-left: 10px !important;
      align-self: center;
    }
  }
}

.plus-icon {
  font-size: 25px;
}

.flight-search {
  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-size: 11px;
      font-weight: 500;
      color: #1c2b39;
      height: 20px;
      display: none;
    }
  }

  .originwrapper {
    position: relative;

    .arrow-flight-inr {
      position: absolute;
      bottom: 0;
      top: 30%;
      right: -12px;
      z-index: 20;
      transition: all 0.5s ease 0s;
      cursor: pointer;

      @include mobile {
        bottom: 0;
        top: 80%;
        right: 15px;
        transform: rotate(90deg);
      }
    }

    .arrow-flight-inr:hover {
      transform: rotate(360deg);

      @include mobile {
        transform: rotate(450deg);
      }
    }
  }
}

.flight-passenger {
  .pax-modal {
    position: absolute;
    z-index: 1;
    left: -101px;
    background: #fff;
    width: 280px;

    @include mobile {
      left: 0;
    }

    .pax-modal-wrapper {
      padding: 10px 20px;
      border: 1px solid #bcd5f5;

      @include mobile {
        padding: 10px;
      }

      li {
        display: flex;
        list-style-type: none;
        border-bottom: 1px solid #dcdee3;
        margin-bottom: 2px;
        margin-top: 8px;
        padding-bottom: 8px;

        &:last-child {
          border-bottom-color: transparent;
        }

        .pax-label {
          flex-grow: 1;
          text-align: start;

          p {
            margin-bottom: -6px;
            font-size: 16px;
            font-weight: bold;
            color: #1c2b39;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            color: #969dac;
          }
        }

        .pax-count {
          flex-grow: 1;
          align-self: baseline;
          display: flex;
          justify-content: space-around;
          align-self: center;

          .fa {
            background-color: white;
            border: 1px solid rgba(170, 165, 165, 0.867);
            padding: 4px;
            color: rgb(0, 0, 0);
            line-height: 1;
            vertical-align: sub;
          }

          .fa-minus {
           
            border-radius: 2px;
          }

          .fa-plus {
          
            border-radius: 2px;
          }
        }
      }

      .pax-ready-btn {
        border-radius: 3px;
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        padding: 6px;
        color: white;
        font-size: 11px;
        font-weight: 500;
        border: 2px solid #2e0000;
        margin-top: 20px;

        &:hover {
        
          background: #fff;
          color: #2e0000;
        }

        @include mobile {
          margin: 12px 0 5px 0;
        }
      }
    }
  }
}

.primary-btn {
  cursor: pointer;
  background-color: #363a45;
   text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  outline: 0;
  border: 0;
  font-weight: 500;
  border: 1px solid #f6f9fc !important;
 

  &:focus {
    border-color: #006bb1 !important;
  }
}

.from-to-inputs {
  .ant-row {
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item-control {
      width: 100%;
    }
  }
}

.mr-none {
  margin-right: 0 !important;

  .mr-t {
    @include mobile {
      margin-top: 10px;
    }
  }
}

.class-container {
  display: flex;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  .select_wrapper {
    padding: 0 5px;
  }

  .class-type {
    font-size: 11px;
    margin-right: 7px;

    @include mobile {
      font-weight: 500;
    }

    .ant-radio-wrapper {
      @include mobile {
        width: 100%;
      }
    }
  }

  .ant-select-selector {
    border: none !important;
    font-size: 12px;
    font-weight: 500;
  }
}

 

/*-----flight--search--css-13-05--*/
.flight-bg-side {
  .ant-form-item {
    margin-bottom: 0px !important;

    .ant-select-lg {
      border-right: 0px solid #d6d3d3;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }

      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector {
        height: 47px !important;
        padding: 14px 11px;
        padding-bottom: 0px;
        border-color: transparent !important;
        border: 0px solid !important;
        border-right-width: 0px !important;
        color: #bfbfbf;
        border: 1px solid #dfdfe1 !important;

        .ant-select-selection-search-input {
          height: 60px !important;
          color: #000000;
        }

        .ant-select-selection-placeholder {
          border: 0;
          background: none;
          font-weight: 500;
          font-size: 14px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000000;
        }

        .ant-select-selection-item {
          border: 0;
          background: none;
          font-weight: 500;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }
      }
    }

    .ant-picker-large {
      padding: 27px 11px 6.5px !important;
       height: 47px;
      border: none;
      color: #bfbfbf;
       border: 1px solid #dfdfe1 !important;
      border-radius: 0px;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }

      .ant-picker-input {
        input {
          color: #000;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .ant-picker-input input::placeholder {
        color: black;
      }
    }

    .ant-input-lg {
      height: 47px;
      padding: 20px 11px;
      padding-bottom: 0px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      color: #000;
      border: 1px solid #f6f9fc !important;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      color: #ff2121 !important;
      font-weight: 500;

      @include mobile {
        display: none;
      }
    }
  }

  .date-ret-mar {
    margin-right: 1%;
  }

  .destination {
    margin-right: 1%;

    .ant-form-item {
      .ant-select-lg {
        .ant-select-selector {
          padding-left: 16px;

          @include mobile {
            padding-left: 11px;
          }

          .ant-select-selection-search {
            padding-left: 5px;

            @include mobile {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .originwrapper {
    .ant-form-item {
      .ant-select-lg {
        border-radius: 2px 0 0 2px;
      }
    }
  }

  .ant-btn {
    width: 100%;
    height: 50px;
    border-radius: 0 2px 2px 0;
    margin-top: -1%;

    @include mobile {
      border-radius: 2px;
    }
  }
}

.pax-count-info {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 66px;
}

.f-primary {
  margin-top: 0px !important;
  width: 100%;
  height: 66px;
}

.bdr-icon-f {
  font-weight: 500;
  border-radius: 20px;
  padding-left: 8px;
  color: #000;
}

.tripTypeActive {
  background-color: #ffebcf;
  color: #000;
}

.returnDate {

  .ant-picker.ant-picker-disabled,
  .ant-picker-input>input[disabled] {
    cursor: pointer !important;
  }
}

 
.primary-btn:hover {
  background-color: #1e4167 !important;
  color: white !important;
}

.class-container {
  display: inline-flex;
  width: 25%;
  text-align: center;
  height: 35px;
  border: 2px solid transparent;

  @include mobile {
    margin-top: 2%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

}

.trip-container {
  flex: 1;
  box-sizing: border-box;
   text-align: initial;

  @include mobile {
    width: 30%;
  }

  .ant-select-selector {
    background-color: transparent;
  }

  .ant-select-selection-item {
    font-size: 14px;
    color: #363a45;
  }
}

.cabin-container {
  flex: 1;
   text-align: initial;

  @include mobile {
    width: 40%;
  }

  .ant-select-selector {
    background-color: transparent;
  }

  .ant-select-selection-item {
    font-size: 14px;
    color: #363a45;

  }
}


.lowest-ne-p {
  font-size: 34px;
  font-weight: 500;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  text-align: center;

  @include mobile {
    font-size: 16px !important;
  }
}

/*20/09/2021*/
.agent-tt-btn {
  border: 1px solid #c3c1c1;
}

.second-agent-plan {
  padding-top: 25px;
}

.button-default-v3 {
  position: absolute;
  right: 28px;
}


.train-search-btn .ant-picker-input input::placeholder {
  color: black;
}

.ant-picker-large {
  padding: 9.5px 11px 6.5px;
  margin-top: -0.5%;
}

.custom-mob-calendar {
  z-index: 999999999;

  .custom-mob-calendar-title {
    display: none;
  }

  @include mobile {
    height: 100%;
    width: 98%;

    margin: 0 auto;
    right: 0px !important;

    left: 0px !important;
    top: 0px !important;

    .custom-mob-calendar-title {
      display: block;
    }

    .ant-picker-panel-container {
      width: 100%;

      .ant-picker-panel {
        width: 85%;
        margin: 0 auto;
        display: block;
      }

      .ant-picker-date-panel,
      .ant-picker-content {
        width: 100%;
      }
    }
  }
}

.flight-bg-target {
  background-color: #ffffff !important;
  padding: 15px 14px;
  margin-top: 4%;
  border-radius: 12px;
  @include ipadpro{
    margin-top: 9%;
    border-radius: 12px;
  }
  @include minitab{
    margin-top: 12%;
    border-radius: 17px;
  }
  @include tab{
    margin-top: 12%;
    border-radius: 17px;
  }
 }

.for-to-labflisearch {
  font-size: 13px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-family: sans-serif;
  margin-left: 5%;
  color: #363a45;

  @include mobile {
    margin-left: 3.5%;
  }
}

.for-to-labflisearch1 {
  font-size: 13px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-family: sans-serif;
  margin-left: 3%;
  color: #363a45;

  @include mobile {
    margin-left: 3.5%;
  }
}

.for-to-labflisearch3 {
  font-size: 13px;
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-family: sans-serif;
  margin-left: 3%;
  color: #363a45;

  @include mobile {
    margin-left: 3.5%;
  }
}

.form-st-reg-dropdown {
  .classTypeSelect {
    .ant-select-selector {
      background-color: transparent;

      .ant-select-selection-item {
        font-size: 14px;
        font-weight: 500;
        color: #363a45;
      }
    }
  }
}


.swip-btn-flight-work {
  border: 1px solid #dfdfe1;
  text-align: center;
  padding-top: 10px;
  height: 47px;

  @include mobile {
    position: absolute;
    left: 90%;
    top: 29%;
    z-index: 1;
    border: none;

    img {
      transform: rotate(92deg);
    }
  }
}

.date-multi-city-work {
  border: 1px solid transparent;
  margin-left: 1.5%;
  margin-bottom: 1%;
  width: 30%;

  @include mobile {
    margin-left: 0.7%;
    margin-bottom: 2%;
    width: 99%;
  }
}

.primary-btn:focus {
  background-color: #164998 !important;
  color: white !important;
}

.ori-des-multi-ffiels {
  border: 2px solid transparent;
  width: 33%;

  @include mobile {
    width: 100%;
  }
}