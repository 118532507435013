$primary: #274581;

.activities-ticket {
 
  .pax-title {
    font-weight: 600;
  }

  .passenger-details {
    margin-bottom: 10px;

    p {
      margin-bottom: 5px;
      color: $primary;
    }

    .img-hol-pic {
      border: 1px solid grey;
      padding: 4px;
    }
  }

  .tour-timeline {
    position: relative;
    padding: 20px 0;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 3px;
      margin-left: -1.5px;
      background-color: #e9ecef;
    }

    .tour-details-panel {
      max-width: calc(100% - 70px);
      padding: 5px;
      margin-left: auto;
      margin-bottom: 10px;
    }

    .tour-timeline-item {
      position: relative;
      margin-bottom: 20px;

      &::after,
      &::before {
        content: " ";
        display: table;
      }

      &::after {
        clear: both;
      }

      .tour-timeline-badge {
        z-index: 10;
        position: absolute;
        top: 0px;
        left: 50%;
        width: 40px;
        height: 40px;
        margin-left: -20px;
        border-radius: 50% 50% 50% 50%;
        text-align: center;
        font-size: 12px;
        line-height: 40px;
        color: #fff;
        overflow: hidden;
      }

      .tour-timeline-panel {
        float: left;
        position: relative;
        width: 46%;
        padding: 20px;
        border: 1px solid #e9ecef;
        border-radius: 2px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
        .tour-timeline-title {
          color: $primary;
        }

        .subtitle {
          margin-bottom: 5px;
        }

        .tour-timeline-body {
          margin-top: 15px;
        }
      }

    }
  }

  .tour-timeline-panel {
    .card-details-info-cont {
      padding: 20px 40px;
    }

    .fare-rules-holiday {

      .price-h-card {
        border: 1px solid grey;
        padding: 20px;
      }
    }
  }


  .tour-timeline>.tour-timeline-item.tour-timeline-inverted>.tour-timeline-panel {
    float: right;
  }

  .tour-timeline>.tour-timeline-item.tour-timeline-inverted>.tour-timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
  }

  .tour-timeline>.tour-timeline-item.tour-timeline-inverted>.tour-timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
  }

  .tour-timeline-badge.primary {
    background-color: #7460ee;
  }

  .tour-timeline-badge.success {
    background-color: #39c449;
  }

  .tour-timeline-badge.warning {
    background-color: #ffbc34;
  }

  .tour-timeline-badge.danger {
    background-color: #f62d51;
  }

  .tour-timeline-badge.info {
    background-color: #009efb;
  }

  .tour-timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
  }

  .tour-timeline-body>p,
  .tour-timeline-body>ul {
    margin-bottom: 0;
  }

  .tour-timeline-left:before {
    left: 30px;
  }

  .tour-timeline-left>.tour-timeline-item>.tour-timeline-badge {
    left: 30px;
    top: 9px;
  }

  .tour-timeline-left>.tour-timeline-item>.tour-timeline-panel {
    width: calc(100% - 70px);
  }

  .terms-panel {
    padding: 10px;

    .terms-title {
      color: #274581;
    }

    .terms-description {
      .terms-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 1px;
      }
    }
  }
}
.fare-rules-holiday{
  
  .price-h-card{
    border: 1px solid rgb(180, 180, 180);
    border-radius: 4px;
    padding: 20px;
    background: rgb(244, 252, 255);
    margin-top: 10px;
    
    p{
      display: flex;
    justify-content: space-between;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    font: 400;
    span{
      font-size: 14px;
      font-weight: 700;
      color: #2c519c;
    }
    }
  }
}
.card-details-info-cont {
  p {
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #000000;

    span {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 700;
      color: #2c519c;
    }

  }
}

.cnt-inf-hdr {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #062c79;
  font-size: 18px;


}

.pack-ovr {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #062c79;
  font-size: 18px;
  margin-top: 10px;

}

.pack-overview {
  
  background: linear-gradient(to bottom, rgb(200, 228, 255), rgb(255, 232, 232));
  border-radius: 4px;
  padding: 8px;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
}

.bord {
  border: 1px solid lightblue;
}