@import "../../assets/variableStyles.scss";

.trip_type {
    background: hsla(0, 0%, 100%, .1);
    border-radius: 4px;
    margin-right: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-height: 46px;
}

.popover {
    width: 100px;
    height: 100px;
}



.modify_search_bar {
    .multicity_modify {
 
        .plus-icon {
            position: absolute;
            top: 100px;
            left: -25px;
            color: white;
         }

        margin-top: -20px;

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 4px;
            background: hsl(0, 0%, 100%);
            height: 50px;
            background-color: hsl(0, 0%, 100%);
            border: none;
            color: #000;
            padding-top: 18px;
            padding-left: -12px;
            margin-left: 7px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            margin-right: -15px;
            margin-top: -15%;


        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
            color: #fff;
        }

        .ant-picker {
            margin-top: -11px;
            border-radius: 4px;
            background: hsl(0, 0%, 100%);
            height: 50px;
            background-color: hsl(0, 0%, 100%);
            border: none;
            color: #000;
            padding-top: 18px;
            padding-left: -12px;
            margin-left: 3px;

            ::placeholder {
                color: #fff;

            }

            // color: #fff;
            .ant-picker-suffix {
                display: none;
            }

            .ant-picker-input>input {
                font-size: 14px;
                color: #000;
                margin-left: -6px;
                margin-top: 7px;
            }
        }

        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            cursor: auto;
            padding: 37px 1px;

        }

        label {
            position: absolute;
            top: 20px;
            left: 5px;
            color: var(--color-primary, #2e0000);
            padding: 3px 13px;
            font-weight: 700;
            font-size: 12px;
        }
    }

    .From_Auto {
        margin-left: 1px;

        .source_a {
            color: var(--color-primary, #2e0000);
            padding: 3px 13px;
            font-weight: 700;
            font-size: 12px;
            position: absolute;
            z-index: 11;
        }


        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 4px;
            background: hsla(0, 0%, 100%, 0.964);
            height: 50px;
            background-color: hsl(0, 0%, 100%);
            border: none;
            color: #000;
            padding-top: 18px;
            padding-left: -12px;
            margin-left: 7px;
        }

        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            cursor: auto;
            padding: 37px 1px;
        }
    }

    .depart_date {
        margin-left: 7px;

        .source_a {
            color: var(--color-primary, #2e0000);
            padding: 3px 13px;
            font-weight: 700;
            font-size: 12px;
            position: absolute;
            z-index: 11;
        }

        .ant-picker {
            border-radius: 4px;
            background: hsl(0, 0%, 100%);
            height: 50px;
            background-color: hsl(0, 0%, 100%);
            border: none;
            color: #000;
            padding-top: 18px;
            padding-left: -12px;
            margin-left: 7px;

            // color: #fff;
            .ant-picker-suffix {
                display: none;
            }

            .ant-picker-input>input {
                font-size: 14px;
                color: #000;
                margin-left: -6px;
                margin-top: 7px;
            }
        }
    }

    .passenger_class {
        margin-left: 7px;

        .source_a {
            color: var(--color-primary, #2e0000);
            padding: 3px 10px;
            font-weight: 700;
            font-size: 12px;
            position: absolute;
            z-index: 11;
        }

        .ant-input {
            border-radius: 4px;
            background: hsl(0, 0%, 100%);
            height: 50px;
            background-color: hsl(0, 0%, 100%);
            border: none;
            color: #000;
            padding-top: 18px;
            padding-left: -12px;
            margin-left: 7px;
            font-size: 14px;
        }
    }

    .form_trip_type {
        border-radius: 4px;
        background: hsla(0, 0%, 100%, .1);
        height: 50px;
        background-color: hsla(0, 0%, 100%, .1);

        .trip {

            color: var(--color-primary, #2e0000);
            padding: 2px 7px;
            font-weight: 700;
            font-size: 12px;

        }

        .trip_type_container {
            .ant-input {
                background-color: transparent;
                border: none;
                color: #fff;
                position: absolute;
                top: -13px;
                left: -3px;
                font-size: 16px;
               
            }

            .dropdown_triptype {
                background-color: #fff;
                color: #000000;
                left: -45px;
                width: 100px;
                height: 80px;
                top: 25px;
                position: absolute;
                border-radius: 5px;

                ul {
                    padding: 5px 5px;

                    li {
                        margin-top: 2px;
                        cursor: pointer;

                        &:hover {
                            background-color: #e5eef4;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }

        .trip_type_way {
            color: #fff;
            font-size: calc(var(--font-scale, 1)*16px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: 23px;
            left: 5px;
        }
    }


    .button_modify {
        margin-top: 8px;

        .primary-btn {
             width: 176px;
            height: 50px;
            margin-left: 30px;
            background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
            border: 0;
            border-radius: 5px;
            box-shadow: 0 1px 7px 0 #0003;
            color: #fff;
            cursor: pointer;
            font-weight: 900;
            outline: 0;
             margin-top: -8px;
            text-align: center;
            text-transform: uppercase;

            @include minitab {
                width: 107px;
            }

            @include tab {
                width: 107px;
            }
        }
    }

    .fare_div {
        margin-top: -22px;

        .faretype_container {
            display: inline-flex;
            margin-bottom: 16px;

            span {
                color: #fff;
                font-size: 12px;
                font-weight: 700;
                 margin-top: 7px;

                .ant-checkbox-wrapper {
                    margin-top: -30px;
                    margin-right: 5px;
                }
            }

            .Faretypes_list li {
                height: 35px;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                background: #213952;
                color: #fff;
                padding: 8px 12px;
                font-size: calc(var(--font-scale, 1)*12px);
                font-weight: 700;
                margin-right: 1px;
                position: relative;
            }
        }
    }

}

.travel_class {
    ul {

        li {
            color: "#fff";
            font-weight: 900;
            display: inline-flex;
            width: 30%;
            padding: 5px 5px;
            padding-left: 17px;
            border-radius: 5px;
             cursor: pointer;
         }

    }
}

 
.multicity-main-box {
    border: 2px solid transparent;
    width: 127%;
    margin-top: 4%;
    color: white;
}

.visible {
    display: none;
}

.for-to-labflisearch {
    display: block;
}

.date-multi-city-workvis {
    border: 1px solid transparent;
}

.date-multi-city-work1 {
    border: 1px solid #dfdfe1;
}
 