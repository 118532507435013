.blackborder {
    width: 100px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
}

.top-hotel-imag-lik {
    width: 100%;
    height: 370px;
    //object-fit: cover; /* Maintain aspect ratio */
}

.main-head-img {
    // border: 1px solid red;
    width: 92%; 
    margin: 2%; 
    position: relative;
    overflow: hidden;
    // display: flex;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
}
.main-head-img1112{
    display: flex;
}
.main-head-img1111{
    border: 1px solid transparent;
    width: 60%;
}

.top-hotels-name-main-div {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 40%;
    border: 4px solid transparent;
    // background: linear-gradient(360deg, #292929 30%, transparent);
    // background: rgba(255, 255, 255, 0.589); 
    color: rgb(0, 0, 0);
    font-weight: bolder;
    // font-size: 20px;
    text-align: center;

    .ht-name {
        font-size: 18px;
        margin-bottom: 0;
    }

    .fromAmnt {
        font-size: 14px;
       // background: #4b82da;
        color: rgb(26, 26, 26);
        .amnt-frm{
            color: #ffffff;
            // text-shadow: 1px 1px 3px rgb(214, 214, 214);
        }
    }
}
