@import "../../assets/variableStyles.scss";
.combined-search-block1{
    border: 2px solid red;
}
.search-block1 {
    border: 2px solid red;
    z-index: 2;
}

.class-container-for-tab1 {
    border: 2px solid transparent;
    justify-content: space-evenly;
    flex-wrap: wrap;
    display: flex;
    width: 25%;
    padding-bottom: 1%;
    margin-left: 0%;
      @include mobile{
        width: 100%;
        margin-bottom: 5%;
        margin-left: 0%;
    }
}

.tabs-css-design1 {
    border-bottom: 2px solid transparent;
    width: 33%;
    z-index: 1;
   
    text-align: center;
    color: black;

    @include mobile{
        padding: 0.5%;
    }
}
.class-container-for-tab1 :hover{
   
  background-color: rgb(223, 223, 223);

}
.fa-plane:hover {
    transition: none;
    transform: none;
}
.fa-bus:hover {
    transition: none;
    transform: none;
}
.fa-hotel:hover {
    transition: none;
    transform: none;
}
.search-body2{
    margin-top: -5%;
     @include mobile{
        margin-top: 1%;
    }
}
.active2{
   
    border-bottom: 1px solid black;
    background-color: #f0f0f0;
    color: black;

    @include mobile{
   
    border-bottom: 1px solid black;
    background-color: #f0f0f0;


    }
}