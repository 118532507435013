.filter-elements {
  text-align: left;
  padding: 0 15px;
}
.stops {
  border-top: solid 1px #c3bdbd;
  padding: 15px 5px;
  line-height: 30px;
  .stops-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.side-bar {
  // border: #dedada 1px solid;
  background-color: #fff;
  border-radius: 3px;
  // float: left;
}
.clearall {
  color: #a062ce;
  float: right;
  font-size: 12px;
}
.filter-top-bar {
  padding: 15px 5px;
}
.filter-price {
  padding: 2px;
  float: right;
}
.clear-div {
  clear: both;
}
.stops-text {
  font-weight: 700;
}
.outbound-text {
  font-size: 11px;
  font-weight: 600;
  color: gray;
}
.results-count {
  font-size: 21px;
  font-family: "Open Sans" ;
}

@media screen and (max-width: 768px) {
  .filter-section-bus {
    display: none;
  }
}
@media screen and (max-width: 820px) {
  .filter-section-bus {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .filter-section-bus {
    display: none;
  }
}
.transfer-filters {
  .transfer-result-indicator {
    p {
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .overall-filter-container {
    .overall-filter-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;

      .filter-text {
        font-weight: bold;
        color: #000;
        margin-bottom: 0px;
      }

      .clear-text {
        cursor: pointer;
        font-size: 12px;
        // color: #bd0c21;
        color: #0775e2;
        margin-bottom: 0px;

        &:hover {
          border: 1px solid #000078;
          border-radius: 4px;
          // padding: 0 2px;
        }

        &:active {
          // background-color: #d0d0ff;
          color: #000078;
          font-weight: 600;
        }
      }
    }

    .overall-filter-body {
      .ant-collapse {
        background: transparent;
        border: transparent;

        .ant-collapse-item {
          border: transparent;

          .ant-collapse-header {
            padding: 12px 0;
            font-weight: bold;
            color: #000;
            // .anticon {
            //     width: 100%;
            //     text-align: end;
            //     left: 0;
            // }
          }
        }
      }

      .stops-filter,
      .slider-filter,
      .airlines-filter,
      .fare-filter,
      .fare-types-filter,
      .connecting-locations {
        .ant-collapse-content-box {
          padding: 16px 0;
        }
      }
    }
  }

  .filter-labels {
    p {
      margin-bottom: 0;

      span {
        &:nth-child(2) {
          margin-left: 15px;
          font-size: 12px;
          font-weight: normal;
          // color: #bd0c21;
          color: #0775e2;
        }
      }
    }
  }

  .ant-collapse-header {
    .ant-collapse-extra {
      span {
        margin-right: 15px;
        font-size: 12px;
        font-weight: normal;
        color: #bd0c21;
        color: #0775e2;

        &:hover {
          background: #0775e2;
          border-radius: 3px;
          color: #000078;
          padding: 0 1.5px;

        }

        &:active {
          color: #000;
          font-weight: 600;
        }

      }
    }
  }

  .ant-collapse-content-box {
    .ant-checkbox-wrapper {
      font-size: 13px;
      display: flex;
      width: 100%;
    }

    .ant-checkbox+span {
      flex: 1;
    }

    .slider-label {
      span {
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }
}

.slider-icon-1 .ant-slider {
  margin: 10px 8px !important;
}

.slider-icon-1 .input-range {
  height: 1rem;
  position: relative;
  width: 93%;
}

.slider-icon-1 .input-range__slider:active {
  transform: scale(1);
}

.slider-icon-1 .input-range__slider {
  margin-left: 0rem;
  margin-top: -0.65rem;
}

.slider-icon-1 .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: 0px;
  left: auto;
  bottom: 13px;
}

.flight-filters .ant-slider-handle {
  background: #008cff;
  border: 1px solid #008cff;
}

.flight-filters .ant-slider-track {
  background-image: linear-gradient(to right, #320063,#0f76bb);
}

.flight-filters .ant-slider:hover {
  border-color: #008cff !important;
}

.flight-filters .ant-slider:hover .ant-slider-track {
  background-color: #008cff !important;
}

.flight-filters .input-range__slider {
  width: 14px;
  height: 14px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #ffff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #bd0c21;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #bd0c21;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}




.check-box-css {
  .ant-checkbox-inner {
    width: 0px;
    height: 0px;
  }
}

.label-nav-set {
  border: 1px solid rgb(165, 165, 165);
  height: 50px;
  width: 24%;
  text-align: center;
  margin: 0;
  // background: #e4e4e4d6;
  background: linear-gradient(to right, #eeeeee, #a8dcff);

  // background: #e3dcdd;
  &:hover {
    // background: linear-gradient(to right, #bd0c2100 50%, #35459cbe);
    box-shadow: 2px 3px 2px rgba(128, 128, 128, 0.76);

    p {
      color: #000000;
      font-weight: 600;
    }
  }

  &:active {
    background: linear-gradient(to right, #bd0c21b0, #35459cbe);

    p {
      color: #fff;
    }
  }

  .time-txt {
    // margin: 0;
    color: #000;
    font-weight: 600;
    font-family: "open sans" !important;


  }

}
@media screen and (max-width:820px){
  .Transfer-filters-fun {
    display: none !important;
    width: 0%;
  }
  .Transfer-resut-fun {
    width: 100% !important ;
  }
}
@media screen and (max-width:768px){
  .Transfer-filters-fun {
    display: none !important;
    width: 0%;
  }
  .Transfer-resut-fun {
    width: 100% !important;
  }
}
@media screen and (max-width:767px){
  .Transfer-filters-fun {
    display: none !important;
    width: 0%;
  }
  .Transfer-resut-fun {
    width: 100% !important;
  }
}
@media screen and (max-width:1024px){
  .Transfer-filters-fun {
    display: none !important;
  }
  .Transfer-resut-fun {
    width: 100% !important;
  }
}