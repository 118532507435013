@import "../../../assets//variableStyles.scss";

.hotel-preview-container {
  .form-body {
    background-color: #ffffff;

    .form-body-container {
      max-width: 1100px;
      padding: 15px;
      margin: auto;

      .hotel-card-wrapper {
        // border-radius: 8px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        margin: 20px 0;
        padding: 16px;

        .ant-card-body {
          padding: 0;
        }
      }
    }

    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;

      @include mobile {
        padding: 16px;
      }

      .book-pay-tc {
        display: flex;

        span {
          color: red;
        }

        p {
          margin-bottom: 0;
        }
      }

      .book-pay-offers {
        display: flex;

        p {
          font-size: 15px;

          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }

      .book-pay-btn {
        button {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129)); 
          color: #fff;
          border: none;
          font-size: 17px;
          font-weight: 600;
          padding: 8px 17px;
          height: 100%;

          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }

        button:hover {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129)); 
          color: #fff;
        }

        .book-and-content {
          padding-top: 20px;

          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }

    .selection-tab {
      display: flex;
      margin: 5px 0 10px 0;

      .l {
        width: 312px;
        height: 2px;
        background: rgb(177, 186, 199);
        margin: 14px 4px;
      }
    }

    .sel-3 {

      margin: 0 0 0 10px !important;

    }

    .sel {
      margin: 0 10px;
    }

    .sel,
    .sel-3 {
      display: flex;
      align-items: center;

      .a {
        border: 1px solid rgb(0, 157, 255);
        border-radius: 50%;
        background: white;
        padding: 2.5px 9px;
        margin-right: 4px;
      }

      .a1 {
        .fa {
          font-size: 34px;
          color: #0059ff;
        }
      }

      .b {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        font-family: Avenir Next, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        color: #320063;
      }
    }

    .sel div {
      margin-right: 5px;
    }
  }

  .lne-3 {
    height: 8px;
    background-image: linear-gradient(to bottom,
        #344d6e8c,
        #ffffff00);
    position: sticky;
    top: 127px;
    z-index: 1;
  }
}

.guest-hdr {
  font-size: 20px;
  color: #320063;
  font-weight: 700;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

}

.passenger-info {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 4px;

  thead {
    background: #dbf1ff;

    th {
      font-size: 18px;
      font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
      font-weight: 600;
    }
  }

  tbody {
    td {
      font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
      color: #320063;
      font-size: 16px;
      font-weight: 400;

    }
  }
}

.book-pay-btn {
  margin-left: -24%;

  .btn {
    height: 50px;
    width: 400px;
    border-radius: 6px;

    @include mobile {
      height: 50px;
      width: 300px;
      border-radius: 6px;
    }
  }

  @include tab {
    margin-left: 10%;
  }

  // @media screen and (max-width: 768px) {
  //   margin-left: 20%;
  // }
  @include mobile {
    margin-left: 5%;
  }
}