@import "../../../../assets/variableStyles.scss";

.car-box-results {
  width: fit-content;
  display: -webkit-box;
  display: flex;
  padding: 0 4px;
  -webkit-box-align: center;
  align-items: center;
  height: 74px;
  border-radius: 4px;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
  position: relative;
}
.car-data-mo{
  .table-container {
    overflow-x: auto;
  }
  
  .my-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .my-table th, .my-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .my-table th {
    background-color: #f2f2f2;
  }
}

.car-data-mo {
  .table-container {
    overflow-x: auto;
  }

  .my-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .my-table th,
  .my-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .my-table th {
    background-color: #f2f2f2;
  }
}

.car-price-img-1 {
  width: 60px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: cover;
}

.car-one-text {
  display: flex;

  flex-direction: column;
  margin-left: 6px;
}

@media screen and (max-width: 767px){
.cars-ratings-act {
    padding-bottom: 13%;
    padding-top: 5%;
}
}

.car-one-text .car-compact {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
}
.Transfer-resut-fun {
  width: 75%;
}
.Transfer-filters-fun {
  width: 25%;
  display: block;
}
.car-one-text .car-compact-price {
  font-size: 12px;
  color: #999;
}

.cars-margins {
  margin-top: 25px;
}

.filters-cars-fvt {
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 4px;
}

.filters-cars-fvt .group-cars {
  /*   padding: 20px 0; */
  /*  border-bottom: 1px solid #eee; */
  margin-bottom: -1px;
}

.car-seats {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}

.results-cars-trt {
  margin-top: 25px;
}

.seats-more {
  margin-bottom: 0px !important;
}

.cars-acvtt {
  border-radius: 4px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  margin-left: 10px;
}

.results-tp p {
  line-height: 14px;
  font-size: 12px;
  color: #999;
  padding: 5px 10px;
  margin-bottom: 0px !important;
}

.results-tp span {
  line-height: 14px;
  font-size: 12px;
  color: #999;
  padding: 5px 10px;
  margin-bottom: 0px !important;
}

.price-lh {
  text-align: right;
}

.nisha-cars {
  margin-bottom: 2px;
  line-height: 24px;
  word-break: break-word;
  font-size: 18px;
  font-weight: 700;
  color: #bd0c21 !important;
  vertical-align: middle;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sub-header-info {
  b {
    color: #bd0c21 !important;
  }
}

.seat-label-cars {
  padding-top: 8px;
}

.seat-label-cars li {
  float: left;
  margin: 0 20px 0 0px;
  font-size: 14px;
}

.cost-of-values {
  align-items: flex-end;

  flex-direction: column;
  float: right;
  margin-top: 5px;
  padding-left: 36px;
  white-space: nowrap;

  @include mobile {
    padding-left: 7px;
  }
}

.filters-cars-fvt .ant-collapse {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.filters-cars-fvt .anticon {
  width: 100%;
  text-align: end !important;
  left: 0 !important;
  right: 0px;
}

.filters-cars-fvt .ant-collapse-header {
  padding-left: 0px !important;
  padding: 0px 0px !important;
  font-weight: bold;
  color: #000;
}

.filters-cars-fvt .ant-collapse-content-box {
  padding: 16px 0px !important;
}

.filters-cars-fvt .ant-collapse-item {
  border-bottom: 0px solid #d9d9d9 !important;
}

@media screen and (max-width: 768px) {
  .car-box-results {
    width: 100%;
    margin-bottom: 8px;
  }

  .cars-btn-price {
    justify-content: flex-end;
    border-left: 0px solid #f5f5f5;
    height: 43px;
    padding-top: 0px !important;

  }

  .seat-label-cars li {
    float: left;
    margin: 0px 9px 0 0px;
    font-size: 13px;
  }

  .car-image {
    width: 80% !important;
  }

  .seat-label-cars {
    padding-top: 0px;
  }

  .cost-of-values {
    font-size: 14px;
  }

  .seats-chair-certi img {
    display: none !important;
  }
}

/* car--rental--details--css */

.location-pickup {
  background: #fafafa;
  border-radius: 4px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.pick-rule {
  margin: 16px;
}

.pick-drop-tittle {
  margin: 4px 0;
  position: relative;
  padding: 0 0 0 20px;
}

.pick-drop-tittle::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #46b925;
  border-radius: 50%;
}

.pick-drop-rule-mark {
  margin: 4px 0;
  color: #0a9967;
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 0 20px;
}

.pick-drop-date {
  margin: 8px 0;

  padding: 0 0 0 20px;
}

.pick-rule-1 {
  margin: 16px;
}

.pick-drop-tittle-1 {
  margin: 4px 0;
  position: relative;
  padding: 0 0 0 20px;
}

.pick-drop-tittle-1::before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #bd0c21;
  border-radius: 50%;
}

.pick-drop-tittle-1::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  -webkit-transform: translate(50%, -100%);
  transform: translate(50%, -100%);
  width: 1px;
  height: 59px;
  background-color: #46b925;
}

.pick-drop-rule-mark-1 {
  margin: 4px 0;
  color: #0a9967;
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 0 20px;
}

.pick-drop-date-1 {
  margin: 8px 0;

  padding: 0 0 0 20px;
}

.pick-rule-details {
  margin: 16px;

  padding: 0 0 0 20px;
}

.car-modal-details {
  width: 800px !important;
}

.pick-up-info .ant-tabs-tab {
  font-size: 19px;
}

.address-book li {
  padding-bottom: 6px;
  font-size: 15px;
}

.second-datils-card {
  margin-top: 20px;
}

.pkg-details {
  outline: none;
  color: #bd0c21;
  font-weight: bold;
  margin-bottom: 0px;
  cursor: pointer;
}

.insurance-details-car {
  background-color: #f4f8fe;
  padding: 20px 24px;
}

.loading-size-de {
  border-radius: 4px;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #eee;
  margin-top: 20px;
}

.inclusions-1 {
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 15px;
}

.inclusions-2 {
  margin: 20px;
}

.des-part {
  color: #bd0c21;
  cursor: pointer;
  flex-wrap: wrap;
}

.des-part::after {
  content: "";
  border-top: 1px solid #f5f5f5;

  margin: 20px 0;
}

.flex-new {
  flex-wrap: wrap;
  margin-top: 6px;
  display: block;
  margin-bottom: 7px;
}

.des-part-1 {
  color: #bd0c21;
  cursor: pointer;
  flex-wrap: wrap;
  box-sizing: initial;
  padding: 6px 12px;
  border-radius: 2px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  color: #f58b1b;
  background-color: #fff3e0;
  cursor: pointer;
}

.fuel-car-de {
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  padding: 10px 10px;
}

.offers-milege {
  margin-top: 25px;
}

.fuel-car-full {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #ff5722;
  padding: 10px 10px;
}

.fuel-car-full-1 {
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background: #fff;
  font-size: 13px;
  line-height: 20px;
  border: 1px solid #ff5722;
  padding: 10px 4px;
}

.gps-eng {
  margin-bottom: 0px;
  font-weight: 700;
}

.add-ons-bg {
  align-items: center;
  border-radius: 4px;
  background-color: #f4f8fe;
  padding: 10px 10px;
  border: 1px solid #f4f8fe;
  cursor: pointer;
}

.price-flex {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: end;
  align-items: flex-end;
}

.cars-ratings-act {
  border-top: 1px solid #eee;
  background-color: white;
  .tran-hdr{
    display: flex;
  }

  .tran-hdr {
    display: flex;
  }

  .car-image {
    height: 140px;
    object-fit: contain;
    width: 166px;
    border: 2px solid #35459c;
    border-radius: 4px;

    &:hover {
      box-shadow: 1px 2px 2px grey;
      object-fit: cover;
      // transition: .4s;
    }
  }

  @media screen and(max-width:1024px) {
    .car-image {
      width: 111px;
    }

    .debtndrops {
      margin-right: -17px;
    }

    .packages-num {
      margin-right: -12px;
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  margin-bottom: 10px;
  border-radius: 8px;
  padding: 24px 20px;
  box-shadow: 0 2px 5px 0 #13296833;

  @include mobile {
    padding-bottom: 13%;
    padding-top: 5%;
  }

  .price-drops {
    display: flex;
    -webkit-box-pack: end;
    justify-content: left;

    .cost-of-values {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .debtndrops {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;

    .button-wid-work {
      @include mobile {
        width: 100%;
      }
    }
  }
  .fare-chges-work{
    @include mobile{
      width: 100%;
      text-align: center;
    }
  }

  .sub-header-info,
  .extra-params,
  .seats-chair {
    font-size: 14px;
  }

  .extra-params ul {
    padding-left: 20px;
    width: 50%;

    li {
      list-style: disc;
    }
  }

  .packages-num {
    color: #35459c;
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
    margin-bottom: 12px;
  }
}

.seats-chair-certi {
  img {
    display: block;
    margin: 0 auto;
  }

  p {
    margin-bottom: 0px;
    font-size: 12px;
    text-align: center;
  }
}

.sorting-car-v1 {
  border-radius: 8px;
  padding: 10px 10px;
  box-shadow: 0 2px 5px 0 #13296833;
  margin-bottom: 10px;
  margin-left: 8px;

  .sorting-menu-car {
    li {
      display: inline-block;
      padding: 0px 13px;
      font-size: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .sorting-car-v1 {
    display: none;
  }

  .seats-chair {
    padding-top: 0px;
  }

  .seats-chair-certi {
    padding-top: 0px;
  }

  .seats-chair-certi p {
    display: none;
  }

  .packages-num {
    font-size: 14px;
    position: absolute;
    //top: -94px;
    right: 0;
  }

  .cars-ratings-act {

    .pt-3,
    .py-3 {
      padding-bottom: 0rem !important;
      padding-top: 0rem !important;
    }
  }
}

@media screen and (max-width: 820px) {
  .sorting-car-v1 {
    display: none;
  }
}

.sorting-car-v1 .results-sort-block {
  display: flex;
  padding: 6px !important;
}

.CarDoor {
  width: 17px;
  margin-bottom: 4px;
}
.CarFare1 {
  .ant-popover-inner {
    border-radius: 10px;
    border: 1px solid #000;
  }

  p {
    margin-bottom: 5px;
    font-family: inherit;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0px 0px !important;
    span{
      color: #35459c;
      font-weight: 600;
    }
  }

  .ant-popover-inner-content {
    width: fit-content;
    font-size: 11px;

    // @include mobile {
    //   width: 300px;
    // }
  }
}

.CarFare1 {
  .ant-popover-inner {
    border-radius: 10px;
    border: 1px solid #000;
  }

  p {
    margin-bottom: 5px;
    font-family: inherit;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0px 0px !important;

    span {
      color: #35459c;
      font-weight: 600;
    }
  }

  .ant-popover-inner-content {
    width: fit-content;
    font-size: 11px;

    // @include mobile {
    //   width: 300px;
    // }
  }
}

.CarFare {
  .ant-popover-inner {
    border-radius: 10px;
    border: 1px solid #000;
  }

  p {
    margin-bottom: 5px;
    font-family: inherit;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0px 0px !important;

    span {
      color: #35459c;
      font-weight: 600;
    }
  }

  .ant-popover-inner-content {
    width: 400px;
    font-size: 11px;

    @include mobile {
      width: 300px;
    }
  }
}

.cancleDetails {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #bd0c21;

  @include mobile {
    justify-content: left !important;
    padding-left: 3%;
  }
}
.main-box-transfer{
  width: 58%;
  @include mobile{
    width: 100%;
  }
}