.contacts-value-de-retrive {
  .ant-select-selector {
    background-color: #f1f2f6 !important;
    border: 0 !important;
    .ant-select-selection-item {
      align-items: center !important;
      display: flex !important;
    }
  }
}
