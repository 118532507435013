@import "../../../assets/variableStyles.scss";

.booking-option-cart {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 0px;

  margin-top: 0px;
}
.lin{
  height: 8px;
  background-image: linear-gradient(to bottom,
  #aaaaaa8c ,
  #ffffff00); 
  //position: sticky;
  top: 124px;
  z-index: 1;
  @include mobile{
    top: 162px;
  }
}
.line{
  height: 8px;
  background-image: linear-gradient(to bottom,
  #aaaaaa8c ,
  #ffffff00); 
  //position: sticky;
  top: 124px;
  z-index: 1;
  @include mobile{
    top: 161px;
  }
}
// box-shadow: 0 0 10px 0 #6f717733;
/* .booking-option-cart::before {
    content: '';
    position: absolute;
    top: 35.5%;
    left: 15px;
    width: 5px;
    height: 20px;
    background-color: #ff5722;
    border-radius: 5px;
    transform: translateY(-50%);
} */
.contact-info {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 20px;
}

/* .contact-info::before {
    content: '';
    position: absolute;
    top: 35.5%;
    left: 15px;
    width: 5px;
    height: 20px;
    background-color: #ff5722;
    border-radius: 5px;
    transform: translateY(-50%);
} */

.total-booking {
  padding: 12px 24px;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  word-wrap: break-word;
  background-color: #f5f5f5;
}
.doument-name {
  height: 33px;
}
.form-rules {
  margin-top: 25px;
}
.now-total-btn {
  text-align: right;
}
.payment-complete {
  margin-top: 25px;
}

.info-item li {
  display: inline-block;
}
.date-credits {
  width: 215px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item-icon {
  width: 30px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item {
  width: 140px;
  line-height: 18px;
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.date-item-1 {
  width: 140px;
  line-height: 18px;
  font-size: 14px;
  color: #000000;
  text-align: right;
  font-weight: 600;
}
.item-ctrd-two {
  margin-top: 25px;
}
.total-pay-cost-v {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722;
}
.rupee-cost {
  font-size: 20px !important;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722 !important;
}
@media only screen and (max-width: 767px) {
  .mobile-offset {
    margin-left: 0% !important;
  }
  .mobile-row-res {
    margin-top: 0% !important;
  }
  .nextactivity {
    padding-left: 0px !important;
  }
}
.details-offer-card {
  margin-top: 25px;
}
.payment-boottom {
  margin-bottom: 0px !important;
}
.total-card-bar .ant-collapse-borderless {
  background-color: transparent !important;
}

.total-cost-user {
  border-bottom: 1px solid #b5b5b5;
}
.offdata {
  color: red;
}
.offdata-1 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.dataicon {
  font-size: 700;
  font-size: 18px;
}
.barselecticon {
  width: 97% !important;
}
.checkout-activity-card {
  border-radius: 8px;
  background: linear-gradient(to left, rgb(117, 209, 255),rgba(120, 255, 129, 0.511));
  box-shadow: 0 2px 5px 0 #13296833;
 
  &:hover{
    box-shadow: 0 5px 10px 0 #13296833;
  }

  .info-item-flexbox {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-bottom: 4px;
      flex: 1;
    }
  }
  .ant-form-item-control-input-content {
    .ant-checkbox-wrapper {
      text-align: left;
    }
  }

  .search-criteria {
    margin: 0;
    border: 0;
    padding: 0;
    .bottom {
      background: #f9ecdb;
      padding: 5px 10px;
    }
  }
  .form-rules{
    .ant-form-item-label{
      label{
        font-weight: 600;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;;
      }
    }
  }
}
.activites-cheout-nav {
  background: #ec8333;
  padding: 15px 0;
}
.nextactivity {
  padding-left: 5px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0px;
}
.nextactivity a {
  color: #ffffff;
}
.activi-leftbat {
  padding-left: 8px;
  padding-right: 8px;
}

.fare-summary-card {
  padding: 5px;
  position: sticky;
  top: 138px;
  margin-left: 10px;


  .right {
    margin-bottom: 5px;
  }
  .left {
    margin-bottom: 5px;
    text-align: end;
  }
  .big-text {
    font-size: 19px;
    font-weight: 600;
  }
  // @media screen and (max-width: 859px) {
  // width: 260px;
  // .right {
    
  // }
  // .left {
  //   margin-bottom: 5px;
    
  // }
  // .big-text {
  //   font-size: 19px;
  //   font-weight: 600;
  // }

  

}

.holiday-book-btn {
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  background-color: #ec8333;
  border: none;
  height: 38px;
  border: 1px solid #ec8333;
  border-radius: 5px;
}
.holiday-book-btn:hover {
  // border: 1px solid #ec8333;
  color: #000000;
  background-color: #ffffff;
}
.carousel-wrapper {
  max-height: 250px;
  margin-bottom: 10px;
  .checkout-slider-img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
  }
}
.holiday-total{
  position: sticky;
  top: 40px;
  margin-left: 10px;
  .total-card{
    background-color: rgba(241, 248, 255, 0.699) !important;
    border: 1px solid rgba(150, 149, 149, 0.642);
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 #13296833;
    .para{
      padding-top: 5px;
      margin-left: 5px;
      padding-left: 6px;
    }
  //  border: 2px solid red;
   
  }
  @media screen and (max-width: 768px) {
   margin: 0;
  
  }
  p{
   display: inline-block;
   margin:4px 10px 1px 14px;
   font-size: 14px;
   
  }
  p.left{
   margin-left: 40px;
  }
  .Grand-ttl{
    // border: 1px solid green;
    background: rgba(201, 194, 194, 0.32);
    border-top:1px solid rgba(150, 149, 149, 0.642); ;
    p{
      font-size: 16px;
      font-weight: 800;
      color: #635656;
      margin: 6px 22px 6px 15px;
    }
  }
  .promo_wrap{
    margin-top: 30px;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 #13296833;
    padding: 8px 3px 0 4px;
    background: rgba(241, 248, 255, 0.699);

    .promo-sub{
      font-size: 12px;
      padding-top: 1px;
      // border: 1px solid rgba(128, 128, 128, 0.564);
      // border-radius: 8px;
      // box-shadow: 0 2px 5px 0 #13296833
      border-bottom: 1px solid rgba(218, 218, 218, 0.605);
      margin-bottom: 0;
//  
    }
    .promo-codes{
     
      
      display: inline-flex;
      .promo-1{
      
        width: 70%;
        // margin-left: 15px;
      }
      .promo-2{
        margin-left: 5px;
       
        // border-radius: none !important;
        Button{
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          &:hover{
            // border: 1px solid red;
           
            color:rgb(0, 0, 0);
          }
          
        }
      }
    }
  } 
  .promo-offers{
    // border: 1px solid rgb(255, 0, 0);
    // border-radius: 6px;
    // box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
    background: rgba(241, 248, 255, 0.699);
  .promo-cp-coupons{
   
    // padding: 5px 20px;
    // margin: 10px 5px;
    // border-top: 1px solid rgba(165, 222, 255, 0.523);
    // width: 100%;
    // height: 100vh;
    // background: #f0fff3;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .inline-cp-promo{
      // background: linear-gradient(135deg, #f58153b0, #f3e252cc);
      // color: #fff;
      // background: linear-gradient(to top, rgb(115, 196, 254) ,rgba(232, 247, 255, 0.728));
      text-align: center;
      padding: 6px 10px;
      margin: 4px 0 4px 0;
      // padding-bottom: 20px;
      border-radius: 6px;
      // box-shadow: 0 10px 10px 0 rgba(0,0,0,0.15);
      position: relative;
      width: 230px;
      height: 55px;
      &:hover{
        box-shadow: 0 2px 5px 0 #022ea833;
        background: linear-gradient(to top, rgb(188, 227, 255) ,rgba(232, 247, 255, 0.728));
      }
  .promo-key-cp{
   color: rgb(0, 102, 255);
   font-weight: 600;
   font-size: 16px;
   
   line-height: 1.5rem;
  //  word-spacing: 1px;
    border: none;
    margin-top: -8px;
    
  }
  .save-cp-offer{
    color: rgb(3, 3, 3);
    position: absolute;
    margin-top: 10px;
    margin-left: 36px;
  }
    
  //   .circle1, .circle2{
  //     background: whitesmoke;
  //     width: 20px;
  //     height: 20px;
  //     border-radius: 50%;
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     // z-index: 1;
  
  // }
  // .circle1{
  //     left: -10px;
  // }
  // .circle2{
  //     right: -10px;
  // }
}
  
  }
}
  .holiday-pack{
    box-shadow: 0 2px 5px 0 #13296833;
   
    
  }

}


.sectionn-work-for-mob-only{
  display: none;
  @include mobile{
    display: block;
  }
}