@import "../../../assets/variableStyles.scss";

.flighttic-info-d1 {
  position: relative;
  //height: 150px;
}

.tic-gredient-f {
  background-image: linear-gradient(to right, #3e5151, #decba4);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
}

.tic-city-name-d2 {
  color: #fff;
  font-weight: bold;
  padding-top: 40px;
}

.city-from-d2 {
  display: flex;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 7px;
}

.city-f3 p {
  margin-bottom: -7px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 14px 0px 0px;
}

.card-tic-f3 {
  border: 1px solid #e2e2e2;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;
}

.city-f3 span {
  color: #a8a8a8;
  font-size: 13px;
}

.last-date-d3 {
  // padding-top: 21px;
  padding-left: 21px;
  word-break: break-all;
  min-width: 150px;
}

.card-tic-f3 .ant-card-body {
  padding: 9px !important;
}

.row-top-f4 {
  padding-top: 10px;
}

.cnfrom-f3 {
  color: #26b265 !important;
}

.city-f4 p {
  margin-bottom: -7px;
  font-size: 16px;
  font-weight: bold;
}

.city-f4 span {
  color: #a8a8a8;
  font-size: 13px;
}

.card-tic-f1 {
  border: 1px solid #d3d3d3;
  margin-top: 20px;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;
}

.card-tic-f1 .ant-card-body {
  padding: 9px !important;
}

.city-tt-f4 p {
  margin-bottom: -4px;
  font-size: 16px;
  font-weight: bold;
}

.city-tt-f4 span {
  color: #a8a8a8;
  font-size: 13px;
}

.logo-f3-text {
  display: flex;
}

.sg-logo-flight {
  display: block;
  padding-left: 10px;
}

.info-flight-t22 {
  padding-bottom: 10px;
}

.city-tt-f5 p {
  margin-bottom: -4px;
  font-size: 14px;

  color: #000;
}

.city-tt-f5 span {
  color: #a8a8a8;
  font-size: 14px;
}

.city-from-d3 {
  display: flex;
  // justify-content: space-between;
  // .ciy-dot{
  //   width: 30px;
  //   display: flex;
  //   justify-content: space-around;
  // }
}

.city-f55 p {
  margin-bottom: -7px;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 5px 0px 5px;
  // @include mobile{
  //   width: fit-content;
  // }
}

.card-tic-f55 {
  border: 1px solid #dedede;
}

.city-f55 span {
  color: #a8a8a8;
  font-size: 13px;
}

.dotted-line-fli{
  border: 1px solid #d3d3d3;
  border-style: dotted;
  // max-width: 100px;
  width: 100px;
  margin-top: 14px;
  margin-bottom: 8px !important;
  display: flex;
    max-width: -webkit-fill-available;
}

.city-f55 {
  text-align: center;
  width: 30%;
    
}

.baggagge-f4 {
  display: flex;
}

.baggagge-icon-4 {
  background: #f2f2f2;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  text-align: center;
}

.baggagge-icon-4 .fa {
  text-align: center;
  line-height: 28px;
  font-size: 20px;
}

.info-line-bag {
  padding-left: 10px;
}

.baggagge-bottom-sec {
  padding-top: 25px;
}

.allowance-baggage {
  padding-left: 10px;
}

.baggagge-icon-5 .fa {
  color: #ea1b37;
  font-size: 20px;
}

.allowance-baggage span {
  color: #ea1b37;
}

.city-tt-f6 p {
  margin-bottom: -4px;
  font-size: 14px;
  color: #000;
}

.baggagge-cacel {
  display: flex;
}

.baggagge-f5 {
  display: flex;
  justify-content: end;
}

.baggagge-f5 p {
  color: #ea1b37;
}

.policy-city {
  color: #000 !important;
  margin-bottom: 3px;
}

.allowance-baggage-1 span {
  color: #d3d3d3;
  font-size: 13px;
}

.baggagge-f43 span {
  color: #d3d3d3;
  font-size: 13px;
}

.card-tic-f2 {
  border: 1px solid #d3d3d3;
  margin-top: 20px;
  box-shadow: 0 1px 1px 0 rgb(19, 41, 104 / 20);
  border-radius: 6px;
  th{
    color: #9d9d9d;
  }
}

.contact-detail-hot-det {
  display: block;

  @include mobile {
    display: none;
  }
}

.card-tic-f2 .ant-card-body {
  padding: 9px !important;
}

.contact-person-d4 p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: bold;
}

.contact-person-d4 span {
  color: #d3d3d3;
}

.details-airlines-contact {
  padding-top: 0px;
}

.modify-contact-btn {
  text-align: end;
}

.tic-btn-contact2 {
  background: #ea1b37 !important;
  text-align: center;

  color: #fff;
  font-size: 14px;
  outline: 0;
  border: 0;
  font-weight: 600;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .city-from-d3 {
    display: block;
    display: flex;
    justify-content: left;
    margin-top: 4%;
  }

  .dotted-line-fli {
    width: 100%;
    margin-top: 5px;
  }

  .sg-logo-flight {
    padding-left: 0px;
    display: flex;

    p {
      border: 2px solid transparent;
      margin: 0px 0px 0px;
      margin-left: 4%;
    }
  }
}

.booking-flight-details-info {
  border: 0;

  .termCon_wrapper {
    padding: 0 10px;
    text-align: justify;

    p {
      font-size: 14px;
      margin-bottom: 2px;
    }

    .description {
      margin-left: 10px;

      .title {
        font-weight: 800;
        font-size: 14px !important;
      }

      p {
        font-size: 13px !important;
      }
    }

    .cancellation-wrapper {
      max-width: 400px;
      margin: 0 auto;
      width: 100%;

      .policytitle {
        text-align: center;
        font-weight: 800;
      }
    }
  }

  .farerules {
    div {
      font-size: 14px;
    }
  }
}

.flight-ticket {
  .actionable-buttons .trip-type-selector span {
    color: #000000 !important;
  }

  .ant-card-bordered {
    margin-bottom: 15px;
  }
}
// .contact-detail-flight-det{
//   display: block;
//   @include mobile{
//     display: none;
//   }
// }
// .contact-detail-flight-det1{
//   display: block;
//   @include mobile{
//     display: none;
//   }
// }
.booking-flight-details-info {
    .termCon_wrapper {
      padding: 0 10px;
      text-align: justify;
  
      p {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .description {
        margin-left: 10px;
        .title {
          font-weight: 800;
        }
      }
      .cancellation-wrapper {
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
         .policytitle {
          text-align: center;
          font-weight: 800;
        }
      }
    }
  
    .farerules {
      div {
        font-size: 14px;
      }
    }
  }
  .word-break{
  // line-break: auto;
  // white-space: initial;
  overflow-wrap: break-word;
  }
  .contact-details-hot-det{
    display: block;
    @include mobile{
      display: none;
    }
  }
  .contact-details-hot-det1{
    display: none;
    @include mobile{
      display: block;
    }
  }
  .email-contactdet-det{
    white-space: nowrap; 
    width: 148px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    // border: 1px solid #000000;
  }

  .ourteam-bg{
    @include mobile{
      padding: 0px !important;
    }
  }