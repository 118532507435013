.holiday-slider-wrapper {
  max-width: 100%;
  margin: 0 auto;
  h3 {
    font-weight: 700;
    color: #444 !important;
    font-size: 20px;
  }
  h4 {
    font-weight: 700;
    color: #444 !important;
    font-size: 16px;
  }
  .offer-card {
    border: 3px dashed rgba(122, 116, 116, 0.125) !important;

    .offer-card-title {
      font-weight: 700;
      font-size: 1rem;
      color: #444 !important;
    }
    .offer-card-img {
      width: 60px;
      height: 60px;
      border: 1px solid #ddd3d3;
      border-radius: 50%;
      object-fit: cover;
    }
    .offer-card-body {
      padding: 2.225rem;
      small {
        color: rgba(133, 130, 130, 1);
      }
    }
  }
  /* the slides */
  .slick-slide {
    padding: 0 10px;
  }
  .slick-arrow {
    top: -23px;
  }
  /* the parent */
  .slick-list {
    padding: 0 -10px;
  }
  .slick-prev {
    left: 89%;
  }
  .slick-next {
    right: 30px !important;
  }
  .slick-next::before,
  .slick-prev::before {
    color: #000;
    font-weight: 600;

    line-height: 8px;
    opacity: 1;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    position: absolute;
    display: block;
    height: 40px !important;
    width: 40px !important;
    line-height: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px #00000057;
    border-radius: 50%;
  }

  .holiday-image img {
    height: 100%;
    width: 100%;
  }

  .holiday-image {
    height: 150px;
    overflow: hidden;
    width: 100%;
  }
  .orange-color {
    min-height: 250px;
  }
  .holiday-overview {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
    position: absolute;
    bottom: 3px;
    p {
      margin-bottom: 0;

      margin-top: 10px;
      padding: 2px 10px;
      border-radius: 5px;
      background: #ec8333;
      color: #ffffff;
    }
  }
}
