@import "../../assets/variableStyles.scss";

.offerSlider {
  margin-bottom: 30px !important;
  max-width: 1100px;
  margin: 0px auto;
  padding: 0 15px;
 

  .offer-wrapper {
    padding: 0 0px;

    /* the slides */
    .slick-slide {
      padding: 0 10px;
      height: 167px !important;
    }

    /* the parent */
    .slick-list {
      padding: 0 -10px;
    }

    .slick-prev {
      left: 92% !important;
      top: -28px;
    }

    .slick-next {
      right: 0px !important;
      top: -28px;
    }

    .slick-next::before,
    .slick-prev::before {
      color: #fff !important;
      font-size: 15px !important;
      font-weight: 400 !important;

      line-height: 8px;
      opacity: 1;
    }

    .slick-prev::before {
      content: "\f060" !important;
      font-family: "FontAwesome";
    }

    .slick-next::before {
      content: "\f061" !important;
      font-family: "FontAwesome";
    }

    .slick-prev,
    .slick-next {
      z-index: 10;
      position: absolute;
      display: block;
      height: 40px;
      width: 40px;
      line-height: 0px;

      background: #007bff;
      border-color: #007bff;

      box-shadow: 0px 0px 14px 0px #00000057;

      border-radius: 3px;
    }

    .card-offer-body {
      padding: 5px 5px 5px 5px;

    }

    .card-offer-title {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    .card-offer {
      border-radius: 9.5px;
      cursor: pointer;
      box-shadow: 0 1px 1px 0 rgb(0, 0, 0 / 19%);


      .card-offer-image-box {
        width: 40%;
        height: 100%;
        overflow: hidden;
        border-radius: 9.5px;
      }

      img {
        border-radius: 9.5px;
        // border-top-right-radius: 9.5px;
        width: 100%;
        // max-width: 272px;
        // min-width: 272px;
        height: 100%;
        // object-fit: cover;

        transition: all .5s;
      }

      img:hover {
        transform: scale(1.5);
        // width: 110%;
        // height: 110%;
      }
    }
  }

  .offers-discounts-v {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    font-family: Ubuntu, sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
  }

  .card-wrapper {
    .card {
      padding: 0;
      width: 100% !important;
      // width: fit-content;
      margin: 5px auto;
      height: 185px;

      @include mobile {
        height: 200px;
      }
    }
  }
}

.item-para {
  color: #000;
}

.offer-wrapper {
  .slick-list {
    height: 390px;

    @include mobile {
      height: 210px;
    }
  }
  // .slick-track{
  //   display: initial;
  // }
}
