.fixpercen {
  padding-top: 30px;
}
ex1 {
  width: 500px;
  margin: auto;

}
.hiddenfields{
  width:400px;
}
