@import "../../assets/variableStyles.scss";

.social-login {
  text-align: center;
}

.social-registration {
  display: flex;
  justify-content: center;
  padding: 10px 0px;

  Button {
    background-color: #fff;
    color: #000;
    border: none;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 2em !important;
    margin-left: 10px !important;

    &:hover {
      background-color: #fff;
    }

    .fbImg {
      max-width: 20px;
      margin-right: 5px;
    }
  }

  .facebook-btn {
    margin: 0px !important;
  }
}

.register-form {
  .input-size {
    max-width: 425px;
    margin: auto;

    .password-input {
      border-right: solid 1px #fff;
    }

    .pwd-append {
      background: #fff;
      border-left: solid 1px #fff;
      height: 52px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

    }

    .error-msg {
      background: #da4c4b;
      color: #fff;
      padding: 3px 0px;
      margin-top: -11px;
      font-size: 14px;
      text-align: left;
      padding-left: 5px;
    }

    input {
      margin-bottom: 10px;
      font-size: 17px;
      padding: 25px 15px;
    }

    .agree-terms {
      display: flex;
    }

    .terms-privacy {
      color: #007bff;
      padding: 0px 4px;
    }
  }

  .check-terms-privacy {
    line-height: 1;

    .check-agree {
      display: flex;

      .agree-text {
        text-align: left;
        line-height: 1.5;
      }
    }
  }

  .unlock-offers {
    text-align: left;

    .check-offer {
      display: flex;

      p {
        margin-bottom: 0px;
      }
    }
  }

  .register-btn {
    background-color: #e2076a;
    border: none;
    font-size: 22px;
    font-weight: 600;
    padding: 8px 1px;
    margin: auto;
    margin-top: 18px;
    margin-bottom: 26px;
    max-width: 425px;

    &:hover {
      background-color: #e2076a;
    }

    @include mobile {
      margin-bottom: 20px;
    }
  }

  .forgot-text1 {
    text-align: center;
    color: #ffffff;

    .txtHy {
      color: #ffff;
      /* Text color */
      cursor: pointer;

      /* Change cursor to pointer */
      &:hover {
        font-size: 2px;
        /* Increase font size on hover */
        transition: font-size 0.2s;
        /* Smooth transition for font size change */
      }
    }

  }

  .forgot-link {
    text-align: center;
    color: #174998;

    &:hover {
      text-decoration: underline;
    }
  }
}

.register-acc {
  display: flex;
  justify-content: space-evenly;

  a {
    color: #000;
  }
}

hr {
  max-width: 425px;
}

.forgot-password {
  text-align: center;

  @include mobile {
    padding: 0;
  }

  .dont-remember-text {
    font-weight: 600;
    font-size: 18px;
  }

  .label-text {
    p {
      font-size: 16px;
    }
  }

  .back-to-login {
    margin-top: 10px;
  }
}

#hiddenPassword {
  display: none;
}

#loginForm {
  .ant-col {
    margin-left: 0 !important;
  }
}

.login-btn {
  background: #0123ad;
  background-image: linear-gradient(to right, #320063, #0f76bb);
  border-color: #320063 !important;
}

.pop-mobi-img {
  width: 100%;
  border-radius: 20px;
  padding-top: 30px;
}

.login-modal .ant-modal-body {
  padding: 0px !important;
}

.login-modal .ant-modal-content {
  border-radius: 18px !important;
}

// @media screen and (max-width: 768px) {
//   .img-pop-login {
//     flex-direction: column-reverse !important;
//   }
// }

.login-text h3 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 2px 2px 3px black;

  @include mobile {
    color: #320063;
    text-shadow: none;
  }
}

.remember-modal .ant-modal-content {
  border-radius: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
}

.ant-form-item-explain {
  text-align: left;
}

/*Popup--login--css*/
.login-user-popup21 {
  display: flex;

  @include mobile {
    display: none;
  }
}

.img-pop-login {
  padding: 30px !important;
  // background: #174998;
  background: url('../../assets/images/travel.jpg');
  color: #fff;
  border-radius: 4px;
  // color: #fff;
  // border-bottom-left-radius: 18px;
  // border-top-left-radius: 18px;
  // background-color: rgba(0, 0, 0, 0.8); // Semi-transparent dark overlay
  // background-image: linear-gradient(to right, #667eea, #764ba2); // Gradient colors for glass effect
  // backdrop-filter: blur(8px);

  .icons {
    svg {
      font-size: 25px;
      margin: 0 10px 0 0;
    }
  }

  .section-left {
    .align-items-baseline {
      text-align: left;
    }

    div {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 0px;
  }

  .login-user-box {
    // background: #ffffffdb;
    border-radius: 3px;
    box-shadow: 1px 5px 8px #404040;
    backdrop-filter: blur(4px);


    .scial {
      .line-with-or-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 8px 0 0 0;
        /* Adjust margin as needed */
      }

      .line {
        flex-grow: 1;
        height: 1px;
        background-color: #ccc;
        /* Line color */
      }

      .or {
        margin: 0 10px;
        /* Spacing around 'OR' text */
        font-weight: bold;
        color: #ffffff;
        /* Text color */
      }
    }
  }

  .txt-hdr-log {
    display: none;
    font-size: 20px;
    font-weight: 600;
    text-shadow: 2px 3px 6px black;

    @include mobile {
      display: block;
      margin-bottom: 8px;
    }
  }

  .logo-login {
    width: 100px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    // padding-right: 8px;
    // box-shadow: 2px 2px 8px rgb(36, 36, 36);

  }

}

.background-container {
  display: flex;

  .backg-lg-image {
    position: absolute;
    width: 700px;
    height: 100%;
  }
}