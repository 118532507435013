@import "../../../assets/variableStyles.scss";

.flight-near-dates {
  @include mobile {
    display: none;
  }
  .card-header {
    background: #fff;
    .acc-header-sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-sec {
        display: flex;
        i {
          padding-right: 11px;
          color: #2e0000;
          padding-top: 2px;
        }
        p {
          margin-bottom: 0px;
        }
      }
      .right-sec {
        i {
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }
  .acc-body-sec {
    background-color: #f6f7f8 !important;
    .nearby-accordion {
      padding: 15px 25px;
    }
    .week-earlier-later {
      display: flex;
      justify-content: space-between;
      .week-head {
        display: flex;
        i {
          padding: 0px 7px;
          font-size: 22px;
          color: #2e0000;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
    .week-days-table {
      margin-top: 13px;
      .week-table-head {
        background: #eaebee;
        tr {
          td {
            text-align: center;
            padding: 2px;
            line-height: 1.4;
            cursor: pointer;
            &:hover {
              background-color: #eaf2fc;
            }
            span {
              font-size: 10px;
              color: #1c2b39;
            }
            p {
              margin-bottom: 0px;
              font-size: 12px;
              font-weight: 800;
            }
          }
        }
      }
      .week-table-body {
        background: #fff;
        tr {
          td {
            // border: none;
            text-align: center;
            color: #1da023;
            padding: 2px;
            cursor: pointer;
            &:hover {
              background-color: #eaf2fc;
            }
            .flight-price {
              display: flex;
              justify-content: center;
              font-size: 11px;
              padding-top: 7px;
              margin-bottom: -8px;
              p {
                font-weight: 800;
                padding-left: 4px;
              }
            }
          }
        }
      }
    }
    .week-days-table-round-trip {
      margin-top: 13px;

      .week-table-head {
        background: #eaebee;
        tr {
          td {
            text-align: center;
            padding: 2px;
            line-height: 1.4;
            cursor: pointer;
            &:hover {
              background-color: #eaf2fc;
            }
            span {
              font-size: 10px;
              color: #1c2b39;
            }
            p {
              margin-bottom: 0px;
              font-size: 12px;
              font-weight: 800;
            }
          }
        }
      }
      .week-table-body {
        background: #fff;
        tr {
          td {
            // border: none;
            text-align: center;
            color: #1da023;
            padding: 2px;
            cursor: pointer;
            &:hover {
              background-color: #eaf2fc;
            }
            .flight-price {
              display: flex;
              justify-content: center;
              font-size: 11px;
              padding-top: 7px;
              margin-bottom: -8px;
              p {
                font-weight: 800;
                padding-left: 4px;
              }
            }
          }
          th {
            background: #eaebee;
            text-align: center;
            padding: 2px;
            line-height: 1.4;
            cursor: pointer;
            span {
              font-size: 10px;
              color: #1c2b39;
            }
            p {
              margin-bottom: 0px;
              font-size: 12px;
              font-weight: 800;
            }
          }
        }
      }
    }

    .pax-price-text {
      p {
        color: #969dac;
        font-size: 11px;
        margin-top: 15px;
        margin-bottom: 0;
      }
    }
  }
}
/************* Antd Styles ******/
.flight-search-results-sec {
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: #f6f7f8 !important;
  }
  .ant-table {
    overflow: hidden;
  }
  .oneway-table {
    margin-bottom: 15px;
  }
  .ant-collapse {
    background-color: #fff;
  }
}
.week-earlier-later {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .week-head {
    display: flex;
    i {
      padding: 0px 7px;
      font-size: 22px;
      color: #2e0000;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
.pax-price-text {
  p {
    color: #969dac;
    font-size: 11px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}
.heading-font .ant-collapse-header {
  font-weight: 700;
}

.fare-Slider {
  .slick-list {
    border-radius: 8px !important;
  }
  width: 900px;
  margin: 0 auto;
  box-shadow: 0 4px 7px 0 #8a6b7633 !important;
  border-radius: 6px;
  @include tab{
    width: 100%;
  }
  @include mobile{
    width: 100%;
  }
  .fare-slider-card {
    justify-content: center;
    padding: 10px 0px;

    margin: 0 auto;
    align-items: center;

    background-size: cover;
    background-position: center;

    background-blend-mode: overlay;
    background-color: #fff;
    border-left: 1px solid #eaeaea;
    border-bottom: 2px solid #fff;
    text-align: center;
    p {
      margin: 0;
      font-size: 13px;
    }
    .date {
      color: #320063;
      font-weight: 600;
    }
    .fetchFare {
      color: #2e0000;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: #000 !important;
  }

  .slick-prev:before {
    content: "\f053" !important;
    font-family: "FontAwesome" !important;
  }
  .slick-prev {
    left: -20px;
  }
  .slick-next {
    right: -20px;
  }
  .slick-next:before {
    content: "\f054" !important;
    font-family: "FontAwesome";
  }

  .fare-slider-card:hover {
    border-bottom: 2px solid #2e0000;
  }
}
