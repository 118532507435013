#container {
 // width: 100%;
  //height: 50vh;
  //background: #17b262;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  position: absolute;
  left: 50%;
  top: 35%;
  z-index:99;
}
.loader {
  position: relative;
  width: 120px;
  height: 120px;
  font-size: 24px;
  color: black;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader::after {
  content: '';
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  bottom: -40px;
  border-radius: 50%;
  border-top: 12px solid rgba(255, 255, 255, 0.2);
  border-right: 12px solid rgba(255, 255, 255, 0.2);
  border-bottom: 12px solid rgba(255, 255, 255, 0.2);
  border-left: 12px solid black;
  transform: translateZ(0);
  animation: load 1.2s infinite linear;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}