@import "../../../assets/variableStyles.scss";

.ant-upload-list {
  @include mobile {
    display: flex;
    justify-content: center;
  }
}

.visaCheckout {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;

  .visaDetailCard {
    border-radius: 5px;

    .visa-details {
      p {
        margin: 0 !important;
      }
    }

    .overflow-hidden {
      border: 1px solid #05425c;
      position: absolute;
      right: 9px;
      height: 255px;
      width: 190px;
      border-radius: 4px;

      @include mobile {
        border: 1px solid #0ab1f600;
        position: relative;
        right: 0px;
        height: 0px;
        width: 0px;
        border-radius: 4px;
      }
    }

    .visaDetailImage {
      /* height: 285px; */
      width: 100%;
      /* width: 170px; */
      object-fit: cover;
      height: 100%;
    }

    .Details-card {
      overflow: hidden;


      .ant-card-body {
        padding: 24px;
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .ant-card {
    border-radius: 8px;
  }

  .card_wrapper,
  .pax-info-wrapper,
  .paxcontact_wrapper {
    margin-bottom: 24px;
  }

}

// .pax-info-wrapper{
//   .ant-form-item-label{
//     label{
//   .pax-label{
//     color: #0ab1f6 !important;
//     font-size: 16px !important;
//   }
// }
// }
// }

@media screen and (max-width: 768px) {
  .visaDetailImage {
    width: 100% !important;
    border-radius: 5px !important;
    height: auto !important;
  }

  .visaCheckout {
    .ant-card-body {
      padding: 24px !important;
    }
  }
}

.agreement-card {
  .ant-form label {
    display: flex;
    justify-content: center;
  }

  .btn_wrapper {
    display: flex;
    justify-content: center;
  }

  .btn_wrapper button {
    height: auto;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: linear-gradient(90deg, #320063, #0f76bb) !important;
    color: #fff;
    border: none;
    border-radius: 6px;
  }
}

.pax-info-visa {
  border: 1px solid #0ab1f6;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 30px;

  .hdr-pax {
    background: linear-gradient(to right, #68f392, #0ab1f6);
    padding: 1px 15px 1px 6px;
    max-width: fit-content;
    color: #fff;
    margin-top: -15px;
    border-radius: 4px;
  }
}

.note-upload {
  margin-bottom: 30px;
  cursor: pointer;

  .hdr-note {
    background: #0ab1f6;
    color: #fff;
    font-weight: 600;
    border: 1px solid #0ab1f6;
    padding: 1px 6px;
    border-radius: 6px;
  }

  .dat {
    color: #68f392;
    font-size: 16px;
  }
}

.visa-details1 {
  border: 1px solid #0ab1f6;
  border-radius: 4px;
  max-height: 260px;
  overflow: auto;

  .tit-1 {
    color: #fafafa;
    border-bottom-right-radius: 6pc;
    background: linear-gradient(to right, #68f392, #0ab1f6);
    max-width: fit-content;
    padding: 0 20px 0 4px;
    border-top-left-radius: 4px;
    position: sticky;
    top: 0;
  }

  p {
    strong {
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
    }

    span {
      color: #0ab1f6;
      font-size: 16px;
    }
  }
}

.doc-info {
  border: 1px solid #0ab1f6;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 30px;
  background: rgba(235, 235, 235, 0.742);

  .info-tit {
    background: linear-gradient(to right, #68f392, #0ab1f6);
    padding: 1px 15px 1px 6px;
    max-width: fit-content;
    color: #fff;
    margin-top: -15px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
  }
}

.upload-img-11 {
  justify-content: space-between;
  cursor: pointer;
}

.paxcontact_wrapper {
  //border: 1px solid #0ab1f6;
  padding: 4px;
  border-radius: 4px;

  .title {
    background: linear-gradient(to right, #68f392, #0ab1f6);
    padding: 1px 15px 1px 6px;
    max-width: fit-content;
    color: #fff;
    margin-top: -15px;
    border-radius: 4px;
  }
}


.choose-pay-visa {
  .choose-pay {
    border: 1px solid #0ab1f6;
    border-radius: 4px;

    .ant-form-item-label {
      background: linear-gradient(to right, #68f392, #0ab1f6);
      color: #fff !important;
      font-size: 16px;
      border-radius: 4px;
      border-top-right-radius: 0;
      padding: 1px 20px 1px 1px;
      /* margin-top: 11px; */
      max-width: fit-content;
      border-bottom-right-radius: 30px;

      label {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}

.img-upload {
  .ant-modal-title {
    color: #fff;
  }
}

.book-pay-btns {
  display: flex;
  justify-content: space-evenly;

  .holiday-book-btn {
    padding: 2px 40px;
  }
}

.phno {
  .ant-select-item {
    padding: 2px 1px;

    .ant-select-item-option-content {
      overflow: visible !important;
      display: flex;
      max-width: 30px;
    }
  }

  .ant-select-selector {
    padding: 0 5px;
  }
}

.visa-opt {
  .ant-select-item-option-content {
    overflow: visible !important;
    display: flex;
    max-width: 30px;
  }
}

.main-row-chec-page {
  .col-main-check-page {
    .ant-input-group-addon {
      width: 25%;
    }
  }
}