$primary: #350367;
$secondry: #05004e;

.unauthorized {
  .ant-result-icon {
    display: block;
  }

  img {
    max-width: 250px;
  }

  .ant-result-icon {
    margin-bottom: 0;
  }

  .ant-result-title {
    color: #ff0303;
    font-weight: 800;
    font-size: 42px;
  }

  .ant-result-subtitle {
    font-weight: 600;
  }

  .ant-btn {
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    display: block;
    margin: 0 auto;

    &:hover {
      background: $secondry !important;
      background-color: $secondry !important;
    }
  }
}

.error-404 {
  .ant-btn {
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    border-color: #320063 ;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    display: block;

    &:hover {
      background: #000 !important;
      background-color: #000 !important;
    }
  }
}

.no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 28px;
  padding-top: 28px;
}

.backto-homebtn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
}

.text-results-found {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}