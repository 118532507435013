@import "../../assets/variableStyles.scss";
 .home_carousel {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding-top: 1%;  
  padding-bottom: 1%;
border-radius: 10px;
  h3 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 40px;
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    @include mobile {
      font-size: 24px;
      margin: 30px 0;
      line-height: 30px;
    }
  }
  .carousel-section {
     .right-layer {
      position: absolute;
      height: 100%;
      width: 17%;
      z-index: 1;
      background-color: white;
      opacity: 0.5;
    }
    .left-layer {
      position: absolute;
      height: 100%;
      width: 17%;
      z-index: 1;
      background-color: white;
      opacity: 0.5;
      right: 0;
    }
 
  }
}
.carousel-img {
  width: 100%;
  height: 290px;
}
.deals .carousel-img {
  @include mobile {
    height: 220px;
  }
}
.hotel-slide {
  background-color: #e2076a;
}
.bulb-slide {
  background-color: #00d2e8;
}
.flight-bulb {
  &:hover {
    .hotel-letter {
      display: none;
    }
    .hovered-travel-content {
      display: block;
      padding: 30px 0;
      ul {
        li {
          margin-bottom: 15px;
          a {
            color: #fff;
            position: relative;
            &::before {
              height: 0;
              width: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: solid transparent;
              border-left-color: white;
              content: "";
              position: absolute;
              left: -6px;
              top: 6px;
            }
          }
        }
      }
    }
  }
}
.custom-slide {
  color: #ffffff;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    line-height: 120%;
    font-size: 24px;
    text-align: center;
  }
  .hotel-letter {
    font-size: 70px;
    border: solid 7px #fff;
    border-radius: 50%;
    padding: 0px 30px;
  }
  .hotel-letter-icon {
    padding: 0 15px;
  }
  .cash-icon-carousel {
    padding: 0 20px;
  }
}
.slide-wrapper {
  position: relative;

  .slide-over-layer {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    position: absolute;
    // height: 100%;
    // width: 100%;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
  }
  .check-details-btn {
    display: none;
  }
  &:hover {
    .slide-hoverlayer {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ) !important;
      // z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }
    .check-details-btn {
      display: block;
      .btn-styles {
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        font-weight: 900;
        letter-spacing: 1px;
        margin-top: 18px;
        border-radius: 3px;
        text-align: center;
        border: none;
        color: #fff;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 23px 30px;
      }
    }
  }

  img {
    transition: opacity 0.3s;
    // opacity: 1;
    z-index: 1;
  }
  .content-on-slide {
    font-family: Ubuntu, sans-serif;
    .top-text {
      position: absolute;
      top: 10px;
      left: 20px;
      color: #fff;
      text-transform: uppercase;
    }
    .bottom-text {
      position: absolute;
      bottom: 10px;
      left: 20px;
      color: #fff;
      hr {
        color: #fff;
      }
      .hr-top {
        p {
          font-size: 17px !important;
        }
        .text-flight {
          display: flex;
          align-items: center;
           i {
            font-size: 25px;
            @include mobile {
              font-size: 18px;
            }
          }
          h5 {
            color: #fff;
            font-weight: 700;
            letter-spacing: 0.5px;
            padding-left: 10px;
            font-size: 25px;
            @include mobile {
              font-size: 18px;
            }
          }
        }
      }
      .price-bottom-hr {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: solid 1px #fff;
        padding-top: 10px;
        h6 {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          @include mobile {
            font-size: 12px;
          }
        }
        span {
          padding: 0px 6px 3px 10px;
          font-size: 27px;
          font-weight: 800;
          @include mobile {
            font-size: 18px;
          }
        }
        p {
          font-size: 13px;
          font-weight: 800;
        }
      }
    }
  }
}

/* ============  customize the react slick styles ================= */

.slick-next::before {
  content: "\f105";
  cursor: pointer !important;
  font-size: 30px;
  color: #fff;
  font: normal normal normal 40px/1 FontAwesome;
}
.slick-next {
  cursor: pointer !important;
  right: 30px;
  border: solid 4px #fff;
  border-radius: 50%;
  line-height: 0 !important;
  height: 60px !important;
  width: 60px !important;
  opacity: 1 !important;
  z-index: 1;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-prev::before {
  content: "\f104";
  cursor: pointer !important;
  font-size: 30px;
  font: normal normal normal 40px/1 FontAwesome;
  color: #fff;
}

.slick-prev {
  cursor: pointer !important;
  left: 23px;
  border: solid 4px #fff;
  border-radius: 50%;
  line-height: 0 !important;
  height: 60px !important;
  width: 60px !important;
  opacity: 1 !important;
  z-index: 1;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.hovered-travel-content {
  display: none;
}

.mobile_crousal {
  .slide-wrapper {
     
    padding: 4px;
    .carousel-img {
      width: 100%;
      height: 200px;
    }
  }
  .text-flight {
    h5 {
      font-size: 18px;
    }
    .slick-arrow {
      width: 40px !important;
      height: 40px !important;
    }
    .slick-prev {
      left: 8px !important;
    }
    .slick-next {
      right: 8px !important;
    }
  }

  display: none;
  @include mobile {
    display: block;
  }
  @include tab {
    display: block;
  }
}

.desktop_crousal {
  .slide-wrapper {
 
    padding: 4px;
  }
  @include mobile {
    display: none;
  }
  @include tab {
    display: none;
  }
}

.container1{
  width: 79%;
  margin: auto;
  @include mobile {
 width: 99%;  }
  @include tab {
    width: 99%;  }
}