/* first--ad--open */
.dashboardSkeleton {
    .dashboard {
        padding-left: 0;
        padding-right: 0;

        .ant-card-body {
            padding: 10px 15px;
        }
    }
}

.ad-hom-box-1 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-1 {
    background: #167ee6b8;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-1 span {
    text-align: center;
}

.ad-span-col-1 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

.ad-hom-view-com p {
    color: #9a9a9a;
    text-align: center;
    margin-bottom: 0px;
    font-size: 12px;
}

.ad-hom-view-com h5 {
    margin-bottom: 0px !important;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}

/* first--ad--close */

.ad-hom-box-2 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-2 {
    background: #4caf50bf;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-2 span {
    text-align: center;
}

.ad-span-col-2 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* second--ad--close */

.ad-hom-box-3 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-3 {
    background: #4c5cafc7;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-3 span {
    text-align: center;
}

.ad-span-col-3 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* third--ad--close */

.ad-hom-box-4 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-4 {
    background: #da0c4d9c;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-4 span {
    text-align: center;
}

.ad-span-col-4 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* fourth--ad--close */

.ad-hom-box-5 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-5 {
    background: #00b29794;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-5 span {
    text-align: center;
}

.ad-span-col-5 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* fifth--ad--close */

.ad-hom-box-6 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-6 {
    background: #1d2939ad;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-6 span {
    text-align: center;
}

.ad-span-col-6 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* sixth--ad--close */

.first-chart-count {
    margin-top: 0px;
}

.ad-hom-box-7 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-7 {
    background: #0866c6a1;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-7 span {
    text-align: center;
}

.ad-span-col-7 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

.currency-top {
    .top-co-curr {
        margin: 0;
        position: relative;
        background: #627ef8;
        font-weight: 600;
        color: #ffff;
        padding-left: 10px;
    }
}

/* seven--ad--close */

.ad-hom-box-8 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-curr {
    background: #85f417ad;
    height: 120px;
    width: 25px;
    display: inline-block;
    float: left;
}

.ad-hom-box-8 span {
    text-align: center;
}

.ad-span-col-8 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* eight--ad--close */

.ad-hom-box-9 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-9 {
    background: #17a2b894;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-9 span {
    text-align: center;
}

.ad-span-col-9 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* nine--ad--close */

.ad-hom-box-10 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-10 {
    background: #f764009c;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-10 span {
    text-align: center;
}

.ad-span-col-10 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* ten--ad--close */

.ad-hom-box-11 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-11 {
    background: #0061f294;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-11 span {
    text-align: center;
}

.ad-span-col-11 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* eleven--ad--close */

.ad-hom-box-12 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-12 {
    background: #00ac69a1;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-12 span {
    text-align: center;
}

.ad-span-col-12 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* t12--ad--close */

.ad-hom-box-13 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-13 {
    background: #e5e636ad;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-13 span {
    text-align: center;
}

.ad-span-col-13 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* t13--ad--close */

.ad-hom-box-14 {
    background: #fff;
    margin: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.ad-span-col-14 {
    background: #9e2c779c;
    height: 50px;
    width: 55px;
    display: inline-block;
    float: left;
}

.ad-hom-box-14 span {
    text-align: center;
}

.ad-span-col-14 i {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding-top: 15px;
}

/* t14--ad--close */

.card-count-balance .ant-card-body {
    padding: 6px !important;
}

.flight-new-pagenation .ant-table-pagination {
    display: none;
}

.order-line-bar-fhb {
    border-top: 1px solid #e8e7e7c7;
}

.flight-new-pagenation .ant-table-thead tr th {
    font-weight: 700;
    padding: 5px 10px !important;
}

.flight-new-pagenation .ant-table-tbody>tr>td {
    padding: 9px 10px !important;
}

.flight-new-pagenation .ant-table-content td:first-child {
    font-weight: 700;
}

.added-marin {
    margin: -8px -18px 8px;
    margin-bottom: 3px;
}

.added-marin-1 {
    margin: -8px -14px 8px;
    margin-bottom: 3px;
}

.order-count-cards {
    margin-top: 10px;

    .pie-chart-cir {
        .cir-pie {
            width: 130px;
        }

        // width: 200px;
        display: flex;
        justify-content: space-around;
        // padding-left: 20px;

        .titl-pie {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-left: 4px;

            .tit-pie {
                display: flex;
                margin-left: 5px;
                justify-content: space-between;
            }
        }

        .dat-pie {
            margin-top: 20px;
        }
    }
}

.pie-chart {
    position: relative;
    width: 200px;
    /* Change the width of the pie chart */
    height: 200px;
    /* Change the height of the pie chart */
    border-radius: 50%;
    overflow: hidden;
}

.pie-segment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 50% 100%);
}

/* Add colors for each segment */
.pie-segment:nth-child(1) {
    background-color: #ff5733;
    /* Change color as needed */
}

.pie-segment:nth-child(2) {
    background-color: #33ff57;
    /* Change color as needed */
}