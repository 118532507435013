@import "../../../assets/variableStyles.scss";

.hotel-details {
  background-color: #fff;

  .hotel-details-header {
    .details-header-container {
      .hotel-full-address {
       //background-image: linear-gradient(to left, #333333, #2e0000);
       background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

        color: #fff;
        padding-top: 10%;

        .hotel-header-wrapper {
          max-width: 1156px;
          width: 100%;
          margin: 0 auto;
          padding: 12px 15px;

          .hotel-detail-header-row {
            justify-content: space-between;
            align-items: center;
            margin: 0 !important;

            .hotel-details-header-right {
              .ant-skeleton-paragraph {
                li {
                  &:nth-child(1) {
                    height: 28px;
                    width: 50% !important;
                  }

                  &:nth-child(2) {
                    width: 60% !important;
                  }
                }
              }

              .show-moreskeleton-btn {
                text-align: end;

                .ant-skeleton-button {
                  @include mobile {
                    display: none;
                  }
                }
              }
            }
          }

          .redirect-to-hotels-list {
            a {
              text-decoration: none;
              color: #bcd5f5;
              font-size: 12px;

              &:hover {
                color: #bcd5f5;
              }

              span {
                padding-right: 10px;
                font-size: 14px;
              }
            }
          }

          .hotel-name {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .hotel-name-wrapper {
              h4 {
                color: #000000;
                margin-bottom: -4px;

                sup {
                  margin-left: 10px;
                }

                .starRating {
                  font-size: 17px;
                  color: rgb(246, 149, 45);

                  .ant-rate-star {
                    margin-right: 2px;
                  }
                }

                // .hotel-star {
                //   font-size: 10px;
                //   color: rgb(246, 149, 45);

                //   .ant-rate-star {
                //     margin-right: 10px;
                //     position: 'relative';
                //     padding: 'padding';

                //   }
                // }

                @include mobile {
                  margin-bottom: 0;
                }
              }

              p {
                color: #2e0000;
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                margin-bottom: 0;

                @include mobile {
                  line-height: 18px;
                }
              }
            }

            .show-rooms-btn {
              button {
                border-radius: 3px;
                background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
                border-color: #2e0000;
                color: #fff;
                font-weight: 600;

                i {
                  padding-left: 5px;
                }
              }

              @include mobile {
                display: none;
              }
            }
          }

          @include mobile {
            padding: 12px;
          }
        }
      }

      .sticky-links {
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        // background: #f5f5f5;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 8px;
        display: flex;
        justify-content: space-evenly;

        @include mobile {
          display: none;
        }

        .sticky-links1 {
          width: 19.5%;
          text-align: center;
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          padding: 10px;
          color: #323232;
          font-weight: 550;

          @include mobile {
            display: none;
          }

        }

        .sticky-links1:hover {
          color: black;
        }

        // ul {
        //   display: flex;
        //   margin-bottom: 0;
        //   padding: 15px 0;
        //    display: flex;


        //   li {

        //      a {
        //        text-transform: uppercase;
        //       font-size: 14px;
        //       font-weight: 700;
        //       border-right: 1px solid #eaf2fc;
        //       padding: 0 25px;
        //       border: 2px solid red;

        //       &:first-child {
        //         padding-left: 0;
        //       }
        //     }
        //   }
        // }


      }
    }
  }

  .hotel-detail-images {
    .light-box-wrapper {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      padding: 0 0px;
      padding-bottom: 15px;

      .container {
        padding: 0;

        .feedback-word {
          background-color: #f5f8fd;
          text-align: center;
          padding: 10px 20px;
          border-radius: 3px;
          margin-bottom: 10px;

          p {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 700;
          }
        }

        .map-bg-container {
          width: 260px;
          height: 84px;

          .map-bg {
            background-image: url(../../../assets/images/hotels/map_bg.png);
            width: 100%;
            height: 100%;
            position: relative;

            p {
              margin-bottom: 0;
              background-color: hsla(0, 0%, 100%, 0.8);
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              padding: 4px;
            }

            button {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -20%);
              background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
              border: transparent;
              color: #fff;
              font-size: 12px;
              border-radius: 3px;
              padding: 8px 20px;
              font-weight: 600;

              &:hover {
                background-color: #7aabeb;
              }

              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .hotel-rooms-list {
    background-color: #ffffff;
    margin-top: 2%;

    @include tab {
      margin-top: 26%;
    }
    @include mobile{
      margin-top: 2%;
    }

    .rooms-wrapper {
      max-width: 1164px;
      width: 100%;
      margin: 0 auto;
      padding: 20px 15px;

      .available-rooms-dates {
        margin-bottom: 15px;
      }

      .rooms-list {
        .room-card-wrapper {
          .ant-card-body {
            padding: 10px;

            .room-number-span {
              background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
              padding: 1px 10px;
              margin-bottom: 1px;
              border-radius: 10px;
              display: inline-block;
              color: #fff;
              font-weight: 600;
              font-size: 10px;
            }

            .button-wrapper {
              button {
                background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
                color: #fff;
                border: transparent;
                padding: 5px 20px;
                font-size: 12px;
                font-weight: 600;
                border-radius: 3px;

                &:disabled {
                  cursor: not-allowed;
                }

                @include mobile {
                  order: 3;
                }
              }
            }

            .room-card {
              // margin-bottom: 10px;
              // background-color: #fff;
              // border-radius: 3px;
              // -moz-border-radius: 3px;
              // box-shadow: 0 2px 3px 0 #eaebee;
              // -webkit-box-shadow: 0 2px 3px 0 #eaebee;
              // -moz-box-shadow: 0 2px 3px 0 #eaebee;
              // border-bottom: 2px solid #dcdee3;
              // padding: 16px;
              padding: 2px;
              border-radius: 5px;
              position: relative;
              padding-bottom: 0;

              .room-image-skel {
                height: 100%;

                .ant-skeleton-element,
                .ant-skeleton-image {
                  width: 100%;
                  height: 100%;
                }
              }

              .ant-skeleton-title {
                margin-top: 0;
              }

              .choose-btn-s {
                display: flex;
                flex-direction: column;
                height: 100%;
                align-items: flex-end;
                justify-content: center;

                .ant-skeleton-title {
                  margin: 0 0 5px auto;
                }
              }

              .room-card-wrapper {
                display: flex;
                justify-content: space-between;

                @include smmobile {
                  flex-direction: column;
                }

                .hotel-image-box {
                  .hotel-image {
                    .hotel_image_ {
                      max-width: 100%;
                      min-width: 160px;
                      position: relative;


                      img {
                        max-height: 120px;
                        object-fit: cover;
                      }

                      @include smmobile {
                        max-width: 100%;
                        height: 160px;

                        img {
                          height: 100%;
                        }
                      }

                      .gallery-icon {
                        position: absolute;
                        right: 6px;
                        bottom: 6px;
                        background: rgba(28, 43, 57, 0.6);
                        width: 30px;
                        height: 30px;
                        display: grid;
                        place-items: end;
                        border-radius: 3px;
                        cursor: pointer;

                        i {
                          color: #fff;
                          font-size: 18px;
                        }
                      }
                    }

                    img {
                      width: 100%;
                      border-radius: 3px;
                    }
                  }
                }

                .hotel-info-wrapper {
                  flex: 1;
                  display: flex;

                  @include smmobile {
                    flex-direction: column;
                  }

                  .hotel-room-details {
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    padding: 0 15px;

                    @include smmobile {
                      padding: 10px 0;
                    }

                    .hotel-room-type {
                      .room-title {
                        display: flex;
                        justify-content: space-between;
                        width: 90%;

                        .roomName {
                          color: #00164d;
                          font-weight: 700;
                          margin-bottom: 0;
                        }

                      }

                      .pax-icons {
                        small {
                          padding-right: 5px;
                        }

                        span {
                          padding-right: 5px;
                          padding-left: 5px;
                        }
                      }
                    }

                    .amenities-box {
                      font-size: 12px;

                      ul {
                        li:first-child {
                          color: #1da023;
                        }

                        li {
                          padding-bottom: 4px;

                          i {
                            background: #f5f8fd;
                            -moz-border-radius: 80%;
                            border-radius: 80%;
                            font-size: 9px;
                            padding: 5px;
                            margin-right: 4px;
                          }
                        }
                      }

                    }

                    .d-flex {
                      img {
                        width: 20px;
                        height: 20px;
                        position: relative;
                        margin-top: -10%;
                      }
                    }

                    .description-modal {
                      font-size: 12px;

                      .tooltipWrapper {
                        display: flex;
                        align-items: center;

                        .anticon {
                          padding-right: 5px;
                        }
                      }

                      a {
                        color: #2e0000;

                        &:hover {
                          text-decoration: none;
                        }

                        span {
                          font-size: 8px;
                        }
                      }
                    }
                  }

                  .select-room-btn {
                    text-align: right;

                    @include smmobile {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    }

                    .rooms-left {
                      background: #f6952d;
                      color: #fff;
                      padding: 3px 10px;
                      border-radius: 3px;
                      font-size: 10px;
                      display: inline-block;
                      margin-bottom: 0;
                      line-height: 1.5;
                      vertical-align: bottom;

                      @include mobile {
                        display: none;
                      }
                    }

                    .rooms-left-mobile {
                      @media screen and (min-width: 768px) {
                        display: none;
                      }
                    }

                    .hotel-room-price {
                      margin-bottom: 0;

                      span {
                        font-size: 22px;
                        font-weight: 700;
                        padding-left: 5px;
                      }

                      @include mobile {
                        order: 1;
                      }
                    }

                    .select-button {
                      background: #6b0188;
                    }

                    .active {
                      background: #6b0188 !important;
                    }

                    button {
                      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
                      color: #fff;
                      border: transparent;
                      padding: 5px 20px;
                      font-size: 12px;
                      font-weight: 600;
                      border-radius: 3px;

                      &:hover,
                      &:active {
                        background: #6b0188 !important;
                      }

                      @include mobile {
                        order: 3;
                      }
                    }

                    button:disabled {
                      cursor: not-allowed;
                    }

                    .nightCount {
                      font-size: 12px;
                      color: #969dac;
                      margin-bottom: 6px;

                      @include mobile {
                        display: none;
                      }
                    }

                    // @include mobile {
                    //   display: flex;
                    //   justify-content: space-between;
                    //   background: #f5f8fd;
                    //   padding: 10px;
                    // }
                  }
                }
              }

              @include mobile {
                padding: 15px;
              }
            }
          }
        }

        .showroom-btn-wrapper {
          margin: 30px 0 20px;
          text-align: center;

          button {
            padding: 15px 30px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            border: 1px solid #bcd5f5;
            background-color: #f5f8fd;
            color: #2e0000;
            cursor: pointer;
            -webkit-transition: background-color;
            -moz-transition: background-color;
            transition: background-color;
            font-weight: 700;

            &:hover {
              background-color: #eaf2fc;
              border-color: #7aabeb;
              color: #2e0000;
            }
          }
        }
      }

      @include mobile {
        padding: 15px;
      }
    }
  }

  .hotel-description {
    .hotel-description-wrapper {
      max-width: 1180px;
      width: 100%;
      padding: 0 0px;
      margin: 30px auto;
      // border-top: 1px solid #bcd5f5;
      // border-bottom: 1px solid #bcd5f5;

      .description-block {
        display: flex;

        .description-title {
          flex-grow: 0;
          flex-basis: 23%;
          text-align: center;
          background: #f5f8fd;
          border-right: 5px solid #eaf2fc;
          font-weight: 700;
          padding: 1%;
          height: 100%;
          text-align: left;

        }

        .description-content {
          flex-basis: 77%;
          margin-left: 30px;
          // padding-top: 37px;
          padding-bottom: 30px;
          word-spacing: 2px;
          border-bottom: 1px solid #eaf2fc;
          font-size: 14px;
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          // width: initial;
        }

        p {
          font-size: 14px
        }
      }

      .facilities-list {
        .facilities-block {
          p {
            font-weight: 700;
            font-size: 14px;
          }

          ul {
            li {
              margin-bottom: 8px;
            }
          }
        }
      }

      .checking-timings {
        ul {
          li {
            font-weight: 700;

            i {
              padding-right: 20px;
              margin-bottom: 20px;
            }

            span {
              font-weight: initial;
            }
          }
        }
      }
    }
  }

  .locationWrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    .loc {
      display: flex;
      align-items: center;
      font-size: 16px;

      .anticon {
        padding-right: 8px;
        color: #e2076a;
      }
    }

    .mapWrapper {
      // height: 200px;
      // width: 100%;
      // margin-top: 1%;

     

      .markerWrapper {
        font-size: 18px;

        .anticon {
          color: #e2076a;
        }
      }
    }

    margin-bottom: 24px;
  }

  .hotel-details-footer {
    .details-footer-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #002172;
      padding: 15px 0;
      color: #fff;

      p {
        margin-bottom: 0;
        font-size: 18px;
      }

      button {
        margin-left: 30px;
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        padding: 6px 25px;
      }
    }

    .details-footer-second {
      padding: 20px 0;

      .second-footer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-bottom: 0;
          margin-right: 40px;
        }

        button {
          background: transparent;
          color: #2e0000;
          font-size: 13px;
          padding: 4px 20px;
          border-radius: 3px;
          font-weight: 600;
          margin-right: 30px;

          &:hover {
            background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
            color: #fff;
          }
        }
      }
    }
  }

  .facilities-modal {
    .room-modal {
      .modal-content {
        padding: 30px 20px 40px 20px;
        border-radius: 0 !important;

        // height: 520px !important;
        .modal-header {
          border: none;

          .custom-modal-header {
            display: flex;

            .header-img {
              max-width: 70px;

              img {
                width: 100%;
                border-radius: 3px;
              }
            }

            .header-text {
              padding-left: 20px;

              h4 {
                font-size: 24px;
                font-weight: 700;
                color: #00164d;
              }

              p {
                margin-bottom: 0;
                font-size: 12px;

                i {
                  font-weight: 500;
                  padding-right: 5px;
                }
              }
            }
          }

          button span {
            font-size: 200%;
            color: #505c75;

            &:hover {
              color: #1c2b39;
            }
          }
        }

        .modal-body {
          // max-height: 520px;
          overflow-y: scroll;

          .custom-details-modal-body {
            p {
              color: #1c2b39;
              font-size: 14px;
              font-weight: 700;
            }

            ul {
              padding-left: 15px;

              li {
                list-style-type: disc;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.mapWrapper {
  height: 200px;
  width: 100%;
  margin-top: 1%;

  @include tab {
    position: absolute;
    width: 79% !important;

  }
  @include mobile{
    position: relative;
    width: 100% !important;
  }
}

.hotel_imge_ {
  padding: 4px 0;
  background: #f8fcff;

  img {
    border: 1px solid #54b4f8;
    max-width: 300px;
    min-width: 160px;
    // position: relative;
    // padding: 6px;
    height: 200px;
    // max-height: 120px;
    // object-fit: cover;
    transition: .2s;

    &:hover {
      padding: 6px;
      border: 1px solid #0071c2;
    }
  }
}

/* oct--10--2020-changes */
@media screen and (max-width: 767px) {
  .hotel-details .hotel-details-header .details-header-container .sticky-links {
    display: block !important;
  }

  .hotel-details .hotel-details-header .details-header-container .sticky-links ul li a {
    padding: 0 9px !important;
  }

  .hotel-details .hotel-detail-images .light-box-wrapper {
    height: auto !important;
  }

  .hotel-details .hotel-rooms-list {
    margin-top: 22px;
  }

  .hotel-details .hotel-description .hotel-description-wrapper .description-block .description-title {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .hotel-details .hotel-details-header .details-header-container .hotel-full-address .hotel-header-wrapper {
    margin-top: 47px;
  }
}

.tooltipWrapper {
  max-width: 300px;

  p {
    margin-bottom: 2px;
  }
}
.carousel-images-1 {
  max-height: 230px;
  object-fit: cover;
  width: 100%;

  @include tab {
    max-height: 200px;
  }
}

/* dec-14--2020 */
@media screen and (max-width: 768px) {
  .hotel-detail-images .carousel-wrapper .carousel.carousel-slider {
    // max-height: 200px !important;
    margin-bottom: -5px !important;
  }

  .gallery-icon {
    bottom: 44px !important;
    right: 6px !important;
    height: 35px !important;
    width: 34px !important;
  }
}

.rooms-available-sta {
  background: linear-gradient(to right, #3881d6 57%, transparent);
  max-width: 330px;
  padding: 3px 20px 1px 10px;
  color: #ededed;
}

.rooms-available-sta::after {
  content: "";
  // border-bottom: 2px solid #592653;
  width: 50px;
  margin-top: 10px;
  display: block;

}

/*--24-01-2021--*/

.cp-hotel-inf0-top {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0 / 10%);
  padding: 10px 10px;
  margin-bottom: 20px;

  .hotel-details-carsl-img {

    .ant-skeleton,
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
}

.check-out-cp-panel {
  padding-left: 7px;
}

.cp-hotel-inf0-top .carousel .slide img {
  border-radius: 8px;
}

.check-out-cp-panel h4 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 900;
}

.cp-details-check {
  display: flex;
}

.check-rating-cp {
  padding-left: 10px;
}

.check-rating-cp li {
  display: inline-block;
  padding: 0px 3px;
}

.cp-details-check p {
  margin-bottom: 0px;
}

.cp-location-address p {
  margin-bottom: 0px;
  word-break: break-word;
  font-size: 13px;
}

.cp-location-address span {
  font-size: 14px;
}

.book-cp-with {
  padding-top: 25px;
}

.book-cp-with p {
  margin-bottom: 0px;
  font-size: 14px;
}

.book-cp-with h5 {
  font-weight: bold;
  font-size: 18px;
}

.cp-bdr-info {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 8px 6px 6px;
}

/*--25-08-2021--*/
.cp-gift-img-text {
  display: flex;
  justify-content: space-between;
  background-color: #d7fae2;
  border: 1px solid #ddd;
  margin-top: 9px;
  border-radius: 8px;
  height: 204px;
}

.img-text-cp-level {
  text-align: center;
}

.img-text-cp-level img {
  width: 85px;
  height: 85px;
  margin-bottom: 5px;
}

.more-panel-cp {
  margin-bottom: 0px;
  font-weight: 700;
}

.login-bal-cp {
  margin-bottom: 0px;
  font-size: 13px;
}

.promo-tariff-cp {
  font-size: 14px;
}

.img-text-cp-level {
  width: 400px;
}

.cp-app-regions {
  padding-left: 14px;
}

.rates-best-regions {
  padding-top: 13px;
}

.cp-rooms-guest {
  padding-left: 10px;
}

.cp-location-date p {
  margin-bottom: 3px;
  font-size: 13px;
  color: #00a19c;
}

.cp-location-date {
  padding-bottom: 0px;
}

.cp-location-date span {
  //font-weight: 600;
  color: #ea5167;
}

.data-book-with-us {
  margin-top: 12px;
}

.rooms-out-in-cp p {
  margin-bottom: 3px;
}

.rooms-out-in-cp {
  padding-bottom: 0px;
}

.cp-best-book-fee {
  font-weight: 700;
  color: #2e0000;
}

.fees-cp {
  font-size: 14px;
}

.cp-hotel-inf0-top .carousel .thumbs-wrapper {
  margin: -20px 0px -25px 0px !important;
}

.cp-book-this {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  border: 0;
  border-radius: 10px;
  //box-shadow: 0 1px 7px 0 rgb(0, 0, 0 / 20%);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
}

.cp-book-this:hover span {
  color: #2e0000 !important;
  // background: #2e000069;
}

.cp-book-this .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
}

.cp-other-rooms {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 8px 10px;
}

.book-other-cp {
  color: #2e0000;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 600;
  padding-top: 10px;
  cursor: pointer;
}

.guest-bottom {
  padding-bottom: 5px;
}

.cp-best-book-fee-1 {
  font-weight: 700;
  color: #2e0000 !important;
  font-size: 15px !important;
}

.cp-book-this span {
  color: #ffffff !important;
}

@media screen and (max-width: 768px) {
  .cp-hotel-inf0-top .carousel .thumbs-wrapper {
    margin: 10px 0px 0px 0px !important;
  }

  .img-text-cp-level {
    width: 100%;
  }

  .cp-rooms-guest {
    margin-top: 13px;
  }

  .sticky-links ul li a {
    font-size: 12px !important;
  }
}

.color-blue {
  color: #2e0000;
}

.carousel li.thumb {
  max-height: 60px;
}

.carousel-wrapper .carousel.carousel-slider {
  height: auto !important;
  // max-height: 270px !important;
  // max-height: 340px !important;
  margin-bottom: 24px;
}

.cp-slider-hotel .carousel .slide {
  background: transparent !important;
}

@media screen and (max-width: 768px) {
  .details-main-2 .sticky-links ul li {
    display: block !important;
  }

  .details-main-2 .sticky-links ul {
    display: block !important;
  }
}

.modal-popup-cancellation {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  box-shadow: 0 1px 3px 0 rgb(164, 164, 164);
  padding: 1%;
  border-radius: 7px;
  border-left: 6px solid red;

  @include mobile {
    width: 100%;
  }
}

.modal-popup-cancellation1 {
  width: 40%;
  border: 2px solid transparent;

  @include mobile {
    width: 100%;
  }
}

.modal-popup-cancellation2 {
  width: 5%;
  border: 2px solid transparent;
  padding-top: 2%;

  @include mobile {
    width: 100%;
  }
}

.heading-part-modal-cancellation {
  border: 2px solid transparent;
  width: 90%;
  margin: auto;
  margin-bottom: 2%;

  @include mobile {
    width: 100%;
  }
}





.tabs-left-bar .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  min-width: 50%;
  height: 100%;
  left: 20%;
  transform: translate3d(0%, 0, 0) !important;
}

.modal-content {
  height: 575px;
}

.tabs-left-bar .modal-content {
  height: 50% !important;
  overflow-y: auto !important;
}

.tabs-left-bar .modal-body {
  padding: 15px 15px 80px !important;
}

/*Left*/
.tabs-left-bar .modal-dialog {
  //  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
}

.tabs-left-bar.fade.in .modal-dialog {
  left: 0 !important;
}

.tabs-left-bar .modal-header {
  display: block !important;
  padding: 5px 16px;
}

/*diss--css--*/
.tabs-left-bar-hotel .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  min-width: 500px;
  height: 100%;
  right: 0 !important;
  transform: translate3d(0%, 0, 0) !important;
}

.tabs-left-bar-hotel .modal-content {
  height: 100% !important;
  overflow-y: auto !important;
}

.tabs-left-bar-hotel .modal-body {
  padding: 15px 15px 80px !important;
}

/*Left*/
.tabs-left-bar-hotel .modal-dialog {
  //  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
}

.tabs-left-bar-hotel.fade.in .modal-dialog {
  left: 0 !important;
}

.tabs-left-bar-hotel .modal-header {
  display: block !important;
}

.modal-css-direction-popup {
  .ant-modal-close-x {
    color: black;
  }

  .ant-modal-footer {
    opacity: 0;
  }
}

.roomlastcancel {
  font-size: 12px;
  color: #e72727;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.roomlastcancel1 {
  font-size: 12px;
  color: #e72727;
  // padding-left: 20px;
  font-weight: 500;
  margin-bottom: 0;
}


.hotel-star {
  .ant-rate {
    font-size: 14px;
  }
}

.hotel-det-card-main-bbox {
  width: 75.5%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @include mobile {
    width: 100%;
  }
}

.hotel-det-card-main-bbox1 {
  width: 25%;
  margin-top: 18px;

  @include mobile {
    width: 100%;
  }
}

.hotel-det-card-main-bbox2 {
  width: 73%;

  @include mobile {
    width: 100%;
  }
}

.Bookthis-now-main-div {
  margin-top: -9%;
  margin-bottom: 4%;

  @include mobile {
    margin-top: 0%;
    margin-bottom: 2%;
  }
}

.main-det-aft-img {
  width: 83%;
  margin: auto;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  p {
    font-size: 14px;
  }

  @include mobile {
    width: 100%;
  }
}

.main-det-aft-img1 {
  width: 70%;

  @include mobile {
    width: 100%;
  }
}

.description-title1 {
  flex-grow: 0;
  flex-basis: 23%;
  text-align: center;
  background: #f5f8fd;
  border-right: 5px solid #eaf2fc;
  font-weight: 700;
  padding: 1%;
  height: 100%;
  text-align: left;
  width: 20%;

}

.card-arrangemov-pc {
  display: none;

  // @include mobile {
  //   display: block;
  // }
}

.card-arrangemov-pc1 {
  display: block;
  // border: 2px solid rgb(216, 216, 216);

  // @include mobile {
  //   display: none;
  // }

  table {
    border: 1px solid transparent;
    width: 100%;
  }

  .header-hot {
    @include mobile {
      display: none;
    }
  }

  tr,
  td {
    border-bottom: 1px solid #3881d6;
    border-left: 1px solid #3881d6;
    border-right: 1px solid #3881d6;
    text-align: center;

    @include mobile {
      display: block;
      border-top: 1px solid #3881d6;
    }

    .res-last-cell {
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      .res-btn {
        background: #3881d6;
        color: white;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        font-weight: 700;

        &:hover {
          background: #0065ac;
        }
      }
    }

    .incl-rms-brek {
      font-size: 14px;
      font-weight: 700;
      color: green;
      font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

    }
  }

  .card-hot {
    @include mobile {
      margin: 20px 0;
    }
  }

  th {
    border: 1px solid #2e0000;
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    color: white;
    font-weight: bold;
    text-align: center;
    height: 45px;
  }

  .rmname {
    font-weight: 700;
    color: #0071c2;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

    &:hover {
      color: #004678;
    }
  }
}