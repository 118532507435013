@import "../../assets/variableStyles.scss";

.coupon-wallet {
  .coupon-wallet-wrapper {
     padding: 20px 0;
    .coupon-card {
      background-color: #fff;
      margin-top: -8px;
      border-radius: 15px;
      border: solid 1px #ccc;
      overflow: hidden;
      .valid-from {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        font-size: 14px;
        padding: 10px 0;
      }

      .redeem-btn {
        text-align: center;
        .ant-btn {
          background-color: #1e4167;
          color: #fff;
          border-radius: 15px;
          margin: 10px 0;
        }
        .ant-btn-text[disabled] {
          background-color: rgba(0, 0, 0, 0.2);
          color: rgba(0, 0, 0, 0.3);
        }
      }
      .coupon-content {
        padding: 10px;
        .coupon-code {
          text-align: center;
          p,
          h2 {
            margin-bottom: 15px;
          }
          h2 {
            font-size: 18px;
            margin-top: 5px;
          }
          .codes {
            p {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
.wallect-ac-21 {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
}
