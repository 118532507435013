@import "~bootstrap/scss/bootstrap";
@import "../../assets/variableStyles.scss";

.register-section {

   .g-recaptcha div {
    margin: 0 auto;
  }

  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  color: #fff;

  .reg-container {
    max-width: 1140px;
    margin: auto;
    padding: 25px 10px;
    text-align: center;

    @include mobile {
      padding: 20px 6px;
      margin-top: 50px;
    }

    h2 {
      font-size: 30px;
      color: #fff;
 
      @include mobile {
        font-size: 25px;
      }
    }

    .register-row {
      padding: 15px 0px;

      @include mobile {
        padding: 0px;
      }

      .register-left-section {
        margin-left: 50px;

        @include mobile {
          display: none;
        }

        .left-item {
          padding: 10px 5px;
          text-align: left;
          display: flex;
          align-items: baseline;

          span {
            font-size: 20px;
            margin-right: 10px;
            margin-top: 10px;
          }

          p {
            font-size: 20px;
          }
        }
      }

      .register-card {
        @include mobile {
       
        }

        .ant-card-body {
          padding: 15px 24px;

          @include mobile {
            padding: 10px;
          }
        }

        .register-card-body {
          color: #000;

          @include mobile {
            padding: 0px 15px 15px;
          }

          .ant-form-item {
            margin-bottom: 14px;
          }

          .register-btn-container {
            margin-bottom: 0;
          }

          h3 {
            @include mobile {
              color: #000000;
            }
          }

          p {
            margin-bottom: 0;

            @include mobile {
              color: #000;
            }

            span {
              color: #000;
            }

            .unlock-text {
              @include mobile {
                color: #fff;
              }
            }
          }

          .social-registration {
            display: flex;
            justify-content: space-evenly;

            Button {
              background-color: #fff;
              color: #000;
               border: none;
               box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
              margin-top: 0px !important;
              margin-left: 10px !important;
              display: flex;
              flex-direction: row;
              padding: 13px;

              .fbImg {
                max-width: 20px;
                margin-right: 5px;
              }
            }

            .facebook-btn {
              margin: 0px !important;
             }
          }

          .register-form {
            .input-size {
            
              margin: auto;

              .password-input {
                border-right: solid 1px #fff;
              }

              .pwd-append {
                background: #fff;
                border-left: solid 1px #fff;
                height: 52px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                i {
                  @include mobile {
                    color: #000;
                  }
                }
              }

              .error-msg {
                background: #da4c4b;
                color: #fff;
                padding: 3px 0px;
                margin-top: -11px;
                font-size: 14px;
                text-align: left;
                padding-left: 5px;
              }

              input {
                margin-bottom: 10px;
                font-size: 17px;
                padding: 25px 15px;
              }

              .agree-terms {
                display: flex;
              }

              .terms-privacy {
                color: #007bff;
                padding: 0px 4px;
              }
            }

            .check-terms-privacy {
              line-height: 1;

              .check-agree {
                display: flex;

                .agree-text {
                  text-align: left;
                  line-height: 1.5;
                }
              }
            }

            .unlock-offers {
              text-align: left;

              .check-offer {
                display: flex;

                p {
                  margin-bottom: 0px;
                }
              }
            }

            .register-btn {
              background-color: #e2076a;
              border: none;
              font-size: 22px;
              font-weight: 600;
              max-width: 313px;
              padding: 8px 1px;
              margin: auto;
              margin-top: 22px;
              margin-bottom: 0px;

              @include mobile {
                margin-bottom: 20px;
              }
            }
          }

          .ant-col {
            margin-left: 0 !important;
            text-align: center;

            button {
              width: 100%;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .register-footer {
      font-size: 0.8rem;

      filter: Alpha(Opacity=40);
      color: #fff;
      text-align: center;
      margin: 25px 30px 0 30px;

      @include mobile {
        margin: 0 10px;
      }
    }
  }
}

.right-panel {
  @include mobile {
    order: 0;
  }
}

.acc-section {
  @include mobile {
    order: 2;
  }
}

.footer-section {
  @include mobile {
    order: 1;
  }
}

.login-alternatives {
  display: flex;
  margin: 15px 0;

  .fb-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    @include mobile {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-bottom: 0;

      @include mobile {
        color: #000 !important;
        margin-left: 10px;
      }
    }

    img {
      height: 20px;
      text-align: center;
    }
  }

  .google-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    @include mobile {
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-bottom: 0;

      @include mobile {
        color: #000 !important;
        margin-left: 10px;
      }
    }

    img {
      height: 20px;
      text-align: center;
    }
  }
}

.or-text {
  p {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}

.register-section {
  .ant-form-item {
    .ant-form-item-control {
      min-width: 100%;
    }
  }
}

.ant-input-affix-wrapper::before {
  display: none;
}

.ant-form {

  //   padding: 10px 100px;
  @include mobile {
    padding: 0;
  }
}

.login-text {
  text-align: center;
}

.forgot-text {
  font-size: 14px;
  color: #2e0000;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}

.login-alternatives {
  display: flex;
  margin: 15px 0;

  .fb-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
    margin-right: 10px;

    p {
      margin-bottom: 0;
    }

    img {
      height: 20px;
      text-align: center;
    }
  }

  .google-btn {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);

    p {
      margin-bottom: 0;
    }

    img {
      height: 20px;
      text-align: center;
    }
  }
}

.or-text {
  p {
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}

.have-account {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    margin-bottom: 25px;
  }

  .acc-text {
    p {
      margin-bottom: 0;
    }
  }

  .login-btn {
    margin-left: 32px;

    button {
      background: none;
      font-size: 18px;
      padding: 5px 20px;
      color: #ffffff;
      border: 1px solid #320063;
      border-radius: 5px;
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      &:hover {
        color: #fff;
        border: 1px solid #fff;
      }
    }
  }
}

 

.register-card {
  border-radius: 8px !important;
}

.right-panel {
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
}

.other-docrr {
  text-align: left;
}

.register-para {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.last-upload {
  text-align: left;
}

.register-sec-dis {
  background-color: #da5200 !important;
  color: #ffffff !important;
}

.upload-multi-img {
  text-align: left;
}

.agent-login-20 .ant-form-item {
  margin-bottom: 5px;
}

.agent-login-21 .ant-form-item {
  margin-bottom: 8px;
  margin-top: -16px;
}

.agent-login-22 .ant-form-item {
  margin-bottom: 0px;
  line-height: 0;
}

.agency-21-trip {
  padding-top: 15px;
}

.agent-card-21 {
  margin-bottom: 5px !important;
  margin-top: 9px;
  text-align: center;
}

.agency-22-trip {
  margin-bottom: 5px !important;
  margin-top: 12px;
}

.details-agent-bb {
  margin-bottom: 10px;
}

.upload-img-11 {
  display: flex;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .upload-img-11 {
    display: block;
  }
}