.blog-containe-wdh {
  margin-bottom: 20px;
}

.img-blog-1 {
  background-image: url("../../assets/images/blog-sec-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  margin-top: 20px;
  padding-top: 447px;
  position: relative;
  background-position: center;
  border-radius: 10px;
}
.img-blog-1:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}
.img-blog-2:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}
.img-blog-3:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease;
}

.imp-text-blog {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  z-index: 1;
}
.blog-travel {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
}
.travel-width-text {
  width: 80%;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.blog-btn {
  background-color: #e2076a !important;
  border-color: #e2076a !important;
  color: #ffffff;
}

/* two--image--start-- */

.img-blog-2 {
  background-image: url("../../assets/images/side-banner-tr-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 102px;
  width: 100%;
  margin-top: 20px;
  padding-top: 226px;
  position: relative;
  background-position: center;
  margin-left: 30px;
  border-radius: 10px;
}

.padding-left-rm {
  margin-left: 0px !important;
}

.padding-left-add {
  margin-left: 30px !important;
}
.imp-text-blog-1 {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 1;
}
.blog-travel-1 {
  text-align: left;
  color: #ffffff;
  font-size: 20px;
}
.travel-width-text-1 {
  width: 75%;
}

.bd-hr {
  border-color: #ffffff;
}
.blog-read {
  color: #ffffff;
  font-weight: 600 !important;
  cursor: pointer;
}

.img-blog-3 {
  background-image: url("../../assets/images/side-banner-tr-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 102px;
  width: 100%;
  margin-top: 7px;
  padding-top: 214px;
  position: relative;
  background-position: center;
  margin-left: 30px;
  border-radius: 10px;
}

.imp-text-blog-2 {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 1;
}
.blog-travel-2 {
  text-align: left;
  color: #ffffff;
  font-size: 20px;
}
.travel-width-text-2 {
  width: 75%;
}

@media screen and (max-width: 768px) {
  .travel-width-text {
    width: 99%;
  }
  .padding-left-add {
    margin-left: 0px !important;
  }
  .blog-travel {
    font-size: 22px;
  }
  .blog-containe-wdh {
    width: 100% !important;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .img-blog-3 {
    margin-left: 0px;
    width: 100%;
  }

  .img-blog-2 {
    margin-left: 0px;
    margin-top: 7px;
    width: 100%;
  }

  .travel-width-text-1 {
    width: 100%;
  }

  .travel-width-text-2 {
    width: 100%;
  }
}
