@import "../../../assets/variableStyles.scss";

.slider-img-holidays {
  width: 100% !important;
  max-height: 460px;
}

.holidays-near {
  margin-top: 30px;
  font-family: "GT Eesti Pro Display", Arial, sans-serif !important;
}

.price-gua {
  background: transparent !important;
  color: #000000 !important;
  border-color: transparent !important;
  padding: 0px 0px !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.035) !important;
}

.price-gua:focus,
.price-gua:hover {
  border-color: transparent !important;
}

.ant-popover-inner-content {
  width: 251px;
}

.all-near-places-1 {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  background-color: white !important;
  border: solid 1px #e2076a !important;
  color: #e2076a !important;
  border-radius: 2px !important;
  height: auto !important;
  width: 100%;
}

.all-near-places-1:hover {
  background-color: #e2076a !important;
  color: #ffffff !important;
}

.review-holi li {
  float: left;
  padding: 10px 10px;
}

.star-rating {
  font-size: 16px;
  color: #ffa628;
}

.border-top-line {
  border-top: 1px solid #eee;
}

.border-top-line-1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.cancel-no-policY {
  padding-top: 20px;
}

.cancel-no-policY li {
  float: left;
  padding: 10px 43px;
}

.largest-value li {
  padding: 10px;
}

.act_booking_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: #333333;
}

.package-type {
  padding-top: 38px;
}

.select-date-option {
  background-color: #f0f6f9 !important;
}

.act_unit__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333;
}

.act_unit {
  line-height: 15px;
  font-size: 12px;
  color: #ffa628;
}

.rating-number {
  display: flex;
}

.market_price {
  line-height: 21px;
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-top: 10px;
}

.selling_price {
  margin-left: 8px;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #ff5722;
  margin-top: 10px;
}

.pkg-terms li {
  padding-bottom: 10px;
}

.side_package__keywords {
  padding: 20px;
  border-radius: 2px;
  background-color: #fff;
}

.keywords-insta li {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
}

.side_package__main {
  position: relative;
  margin-top: 16px;
  padding-bottom: 50px;
  max-height: calc(100vh - 110px - 20px - 80px - 20px - 40px - 20px - 26px - 30px);
  max-height: 220px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.datail-pkg::before {
  content: "";
  height: 16px;
  width: 4px;
  background: #e64340;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.datail-pkg {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333;
  position: relative;
  padding-left: 14px;
}

.trip-uses li {
  margin-bottom: 8px !important;
}

.booking-option {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 16px;
  padding-top: 10px;
}

.booking-option::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 20px;
  background-color: #ff5722;
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.option-sec {
  margin-top: 20px;
}

.child-person {
  margin-top: 25px;
}

.div--price_total {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #ff5722;
}

.cart-btn {
  display: flex;
}

.addtocart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ffa628;
  height: 48px;
}

.book-cart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ff5722;
  height: 48px;
}

.img-boottom-para {
  margin-top: 15px;
}

.ramoji-film-city-maps {
  width: 100%;
  height: 350px;
}

.start-review-rating li {
  float: left;
  padding: 0px 7px;
  font-size: 40px;
  font-weight: bold;
  color: #424242;
}

.review-count {
  font-size: 18px !important;
  line-height: 73px;
  color: #666;
}

.start-review-rating li .fa {
  color: #ffa628;
  font-size: 23px;
}

.gallery-wrapper {
  margin-top: 30px;

  padding: 16px;

  background-color: #e4f3fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gallery-wrapper .gallery-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.slider-img-holidays-1 {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  cursor: pointer;
}

.view-more-photos {
  text-align: right;
  margin-bottom: 20px;
}

.forms-rating {
  margin-top: 20px;
}

.five-star {
  color: #ffa628;
  padding: 0px 4px;
}

.rating-button {
  text-align: right;
}

.star-btn-one {
  color: #fff;
  background-color: #ff5722;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 20px;
}

.form-padding {
  padding: 0px 10px;
}

.review-sort {
  display: flex !important;
}

.persons-rating {
  display: flex;
}

.persons-rating p {
  padding: 0px 5px;
}

.help-ful-btn {
  margin-top: 25px;
}

.review-help-center {
  background-color: #ffffff;
  padding: 15px;
}

.date-of-review {
  text-align: right;
}

.recent-review-module {
  padding: 40px 0 32px;
  border-bottom: 1px solid #e0e0e0;
}

.recent-review-module-1 {
  border-bottom: 1px solid #e0e0e0;
}

.center-line {
  margin-bottom: 20px;
}

.ft-top {
  margin-top: 25px;
}

.places-promo-btn {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
}

.credits-p {
  padding-top: 20px;
}

.bread-cubs {
  margin-top: 35px;
}

.bread-cubs .ant-breadcrumb-separator {
  display: none;
}

.bread-cubs .ant-breadcrumb-link {
  padding: 20px 7px;
}

.bread-cubs .ant-breadcrumb-link a {
  color: #1a2b49;
  font-weight: 600;
}

.groupfrom .form-group {
  margin-bottom: 0rem !important;
}

/*css--09-2021*/
.cms-pages-width-places {
  width: 1170px;
}

.new-sec-1key {
  border: 1px solid;
  border-color: #e2e2e2 #e2e2e2 #1fa1d3 #e2e2e2;
  background-color: #fff !important;
  padding: 0px 20px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.activitiestourform {
  .ant-select-selection-item {
    white-space: pre-wrap;
    color: #000;
  }

  .ant-form-item {
    margin-bottom: 4px;
  }
}

.activityInfoTooltip {
  width: fit-content !important;
  max-width: 250px !important;
}

.key-option {
  height: 40px;
  display: table;
}

.key-option .tour-favi {
  font-size: 20px;
  color: #00295d;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  box-shadow: 0 0 6px 1px #888;
  text-align: center;
  float: left;
}

.aling-middle {
  line-height: 40px;
  padding-left: 15px;
}

.key-row-2 {
  padding-top: 25px;
}

.des-row-key {
  margin-top: 25px;
}

.deatils-key {
  font-size: 18px;
}

.inclu li:before {
  color: #98ce44;
  font-family: FontAwesome;
  margin-right: 8px;
  background: 0 0;
  content: "\f00c";
}

.inclu li {
  font-size: 14.5px;
}

.importinfo li {
  font-size: 14.5px;
  list-style: inside;
}

@media screen and (max-width: 768px) {
  .cms-pages-width-places {
    width: 100% !important;
  }

  .key-option {
    margin-bottom: 8px;
  }

  .img-inclusion-c2 img {
    width: 100% !important;
  }
}

.book-cart-all {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ec8333;
  height: 38px;

  float: right;
  border-radius: 5px;
}

.emailflyer {
  text-align: right;
}

.flyerbtn-1 {
  background-color: #ec8333 !important;
  border-color: #fff;
  color: #fff !important;
  height: 40px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
}

.flyerbtn-2 {
  background: #0098c9;
  height: 28px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  color: #ffffff;
}

/*review--page--cs--*/
.tab-content-1 ul {
  width: 100%;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}

.tab-content-1 ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}

.tab-content-1 ul li:last-child {
  border-bottom: 0;
}

.hm_link-1 {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}

.srch_btn-1 {
  background: #fff;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;

  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.review-top {
  padding-top: 25px;
  border-top: 1px solid #ededed;
}

.option_Name {
  width: 400px;
}

.transfer_option {
  width: 150px;
}

.selectDate {
  width: 135px;
}

/*build-activitity*/
@media screen and (max-width: 768px) {
  .group-one-person-1 {
    width: 210px;
  }

  .group-one-person-2 {
    width: 165px;
  }

  .group-one-person-3 {
    width: 116px;
  }

  .group-one-person-4 {
    width: 50px;
  }

  .group-one-person-5 {
    width: 50px;
  }

  .group-one-person-6 {
    width: 50px;
  }
}

.slot-modal-nav .ant-modal-body {
  padding: 0px !important;
}

.time-slot-activity {
  border-bottom: 1px solid #dadada;
  display: inline-flex;
  width: 100%;
  height: 46px;
  background-color: #f7f7f7;
  padding: 15px;
}

.slot-change {
  color: #e01a33 !important;
  margin-left: 15px;
  font-size: 14px !important;
}

.time-slot-activity .fa {
  font-size: 23px;
}

.date-slot {
  font-size: 15px;
}

.tourModal-wrapper {
  padding: 8px 15px;
  color: #345088;
}

.select-slot-time h4 {
  font-size: 18px;
  line-height: 34px;
  padding: 8px 15px;
  color: #f2a818;
  margin-bottom: 0;
}

.select-slot-time-1a h4 {
  font-size: 16px;
  line-height: 10px;
  padding: 8px 0px;
  color: #f2a818;
}

.select-slot-slod-out {
  padding: 0 15px;
}

.select-slot-date-map {
  padding: 8px 15px;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #ededed;
}

.one-slot-book {
  background: #f2eabc;
  color: #1a1a1c;
  min-width: 100px;
  text-align: center;
  padding: 0px 0px;
  word-break: break-word;
  border-radius: 0 0 5px 5px;
  width: 134px;

  .time-slot-available {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #a4d77f;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  .time-slot-unavailable {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #ff0000;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
  }

  .time-slot-limited {
    text-align: center;
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
    background-color: #fed301;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  .priceWrapper {
    padding-top: 6px;
  }
}

.selected {
  border: 2px solid #90d153;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.av-3 {
  color: #fed301;
}

.av-1 {
  color: #a4d77f;
}

.av-2 {
  color: red;
}

.data-adult {
  margin-bottom: 0px;
  padding-top: 6px;
}

.select-slot-date-map .ant-col {
  margin-bottom: 13px;
}

.from-slot-label {
  padding: 8px 15px;
  justify-content: center;
  margin-top: 25px;
}

.from-slot-label .ant-form-item-label {
  font-weight: bold;
}

.total-slot-bbok {
  padding: 8px 15px;
}

.total-slot-bbok p {
  font-size: 22px;
}

.total-slot-bbok .slot-span {
  color: #ec8333 !important;
  font-weight: 700;
}

.slot-check-out {
  text-align: end;
}

.slot-btn {
  background-color: #ec8333 !important;
  border-color: #ec8333 !important;
  font-size: 16px;
  outline: 0;
  border: 0;
  font-weight: 600;
  color: #ffffff;
}

.slot-modal-nav .ant-modal-footer {
  display: none;
}

.slot-collapse .ant-collapse-header {
  font-size: 15px;
  font-weight: 600;
}

.dates-option-slot {
  border-bottom: 1px solid #e6e6e6;
}

.dates-option-slot li {
  border-bottom: 1px solid #e6e6e6;
}

.dates-option-slot li a {
  display: table;
  font-size: 17px;
  line-height: 45px;
  width: 100%;
  color: #333;
  height: 45px;
}

.dates-option-slot li a:hover {
  background: #f4f7f2;
}

.select-time-fun:focus {
  box-shadow: none !important;
}

.select-time-fun {
  color: #ec8333 !important;
  text-decoration: none;
  font-weight: 700;
}

.slot-modal-nav .ant-modal-title {
  color: #ffffff !important;
}

.slot-graph-modal {
  margin-bottom: 0px;
  padding-left: 18%;
}
.manage-markup-section1 {
  margin: 105px auto;
  // border: 2px solid yellow;
  //  border-top: 1px solid rgba(201, 194, 194, 0.562);
 // padding-top: 0%;
@include mobile{
  margin-top: 14%;
}
}
.manage-markup-section1{
  background-color: #ffffff;
  padding: 20px;
  .card-bt-gap {
    margin-bottom: 20px;
  }
  .card-add-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
   
  }
  .reports-btns {
    display: flex;
    justify-content: flex-end !important;

    button {
      margin-right: 17px;
    }
  }
  .table-heading {
    text-align: center;
    p {
      margin-bottom: 0px;
    }
    span {
      color: red;
    }
    .total-amounts {
      margin-top: 20px;
    }
  }
}
.manage-markup-section {
  margin: 0 auto;
  // border: 2px solid yellow;
  //  border-top: 1px solid rgba(201, 194, 194, 0.562);
 // padding-top: 0%;
@include mobile{
  margin-top: 14%;
}

}

.header {
  display: flex;
justify-content: space-between;

  .back-btn {
    color: #02449a;
    align-items: end;
    // margin-left: 500px;
    // justify-content: flex-end;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 2px 0 0;
    &:hover{
color: #0066ff;
    }
    &:active{
      background:  #95bdf1 ;
      border-radius: 4px;
      color: #000f23;
    }

    @include mobile {
      display: none;
    }


  }

}

.holiday-info-card {
  .hdr-titl{
    max-width: 680px;
    @include mobile{
      max-width: none;
    }
  }
  

  .tour-data {
    background:linear-gradient(to right, rgba(113, 212, 255, 0.411),rgba(115, 208, 255, 0.854));
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 #13296833;
    .ant-row{
      @include mobile{
        justify-content: space-around;
      }
    }

  }

  @include mobile {
    display: unset;
  }

 

  .thm-holiday {
    border-radius: 8px;
  }

}

.holiday-details {
  .position-top {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #ffffff;
  }

  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    padding: 0 8px;
    margin-bottom: -0;

    justify-content: space-between;

    align-items: center;

    .left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 10px;

      .headings {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      h2 {
        margin-right: 10px !important;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
    }

    .details-font {
      font-size: 13px;
      font-weight: 400;

      span {
        border: 1px solid #ec8333;
        border-radius: 999px;
        padding: 4px 10px;
        align-items: center;
        color: #525252;
        font-size: 11px;
        font-weight: 600;
        margin: 5px;

        @include mobile {
          display: inline-block;
          padding: 4px 2px;
        }
      }
    }

    .right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      line-height: 20px;
    }

    .text {
      text-transform: uppercase;
      font-size: 12px;
      color: #525252;
    }

    p {
      margin: 0;
    }

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    .fare {
      white-space: nowrap;
    }
  }

  .slick-slide img {
    border-radius: 5px;
  }

  .slider-img-holidays {
    max-height: 350px;
    min-height: 120px;
  }

  .f-weight {
    font-weight: 600;
  }

  .overview-tab {
    .ant-tabs-tab-btn {
      font-weight: 600;
    }
  }

  .sticky-links {
    width: 100%;

    padding: 10px 15px;
    background: #f5f5f5;

    border-radius: 8px;

    ul {
      display: flex;

      li>a {
        font-size: 14px;
        font-weight: 700;
        border-right: 1px solid #eaf2fc;
        padding: 0 25px;
        text-transform: uppercase;
      }
    }
  }

  .holiday-info-card {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 #938a8a;
    padding: 10px 10px;
    margin-bottom: 20px;

    .holiday-information {
      border-radius: 5px;

      .holiday-wrapper {
        font-size: 13px;

        .holiday-itenerary-heading {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          .holiday-itenerary-heading-span {
            border: 1px solid #ec8333;
            border-radius: 999px;
            padding: 4px 10px;
            align-items: center;
            color: #525252;
            font-size: 11px;
            font-weight: 600;
            margin: 5px;

            @include mobile {
              display: inline-block;
              padding: 4px 2px;
            }
          }

        }

        p {
          margin: 0;
        }

        padding: 10px;

        .available-rooms-dates {
          margin-bottom: 15px;
        }

        @include mobile {
          padding: 15px;
        }
      }
    }
  }


  .flight-trip-details {
    border-right: 1px solid #ccc;
    padding: 2px 8px;

    @include mobile {
      border: none;
    }

    // padding: 15px;
    background-image: linear-gradient(180deg, #fafcfd, #fff);
    border-radius: 8px;

    .itinary-details {
      display: flex;
      justify-content: space-between;

      .itinary-flight-name {
        width: 20%;

        .token-number-flight {
          display: flex;

          p {
            font-weight: 700;
            font-size: 13px;
          }
        }


        @include mobile {
          width: 78%;
          margin-bottom: 12px;
        }

        img {
          width: 45px;

          @include mobile {
            width: 35px;
            align-items: center;
          }
        }
      }

      .fromTo {
        width: 50%;

        @include mobile {
          flex: 1;
          width: 100%;
          padding-top: 10px;
        }

        .cities-from-to {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // align-items: center;
          position: relative;

          @include mobile {
            width: 94%;
          }

          .loc_wrapper,
          .terminal_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 9px;
            font-weight: 600;

            @include mobile {
              p {
                margin-bottom: 2px;
              }
            }
          }

          .flight-code {
            font-size: 15px;
          }

          .flight-code-span {
            font-size: 10px;
          }
        }
      }

      .flight-class-details {
        width: 25%;
        text-align: end;

        @include mobile {
          width: fit-content;
          position: absolute;
          top: 18px;
          right: 12px;
        }
      }
    }
  }
}

.search-criteria {
  background: #fff;
  border: 1px solid rgba(156, 170, 179, 0.28);

  border-radius: 4px;
  padding: 15px;
  margin: 0 0 15px 0;
  position: relative;

  .bottom {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    justify-content: space-between;
    display: flex;
    align-items: center;

    ul.left {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      li:first-child {
        padding-left: 0;
      }

      li {
        position: relative;
        padding: 0 15px;
        padding-right: 40px;

        h3 {
          color: #000;
          font-size: 13px;
          font-weight: 400;
          margin: 0;
          text-transform: capitalize;
        }

        span {
          display: block;
          font-size: 14px;

          strong {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.ant-modal-body {
  .search-criteria {
    border: 0 !important;
    margin: 0;
    padding: 0;

    .roomsGuests {
      border: 1px solid #d3cfcf;
      padding: 0 11px;
      border-radius: 4px;
      cursor: pointer;
    }

    .hotel-pax-box li {
      padding: 0 !important;
    }
  }
}

.modify-holiday-modal {
  .ant-modal-close-x {
    width: 30px;
    height: 20px;
    line-height: 1;
    color: #323232;
  }
}

.tab-inclusion-card {
  margin-bottom: 5px;

  .ant-card-head-title {
    font-weight: 600;
  }
}

// .itinearydata-left-tab {
//   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//     color: #ec8333;
//   }
//   .ant-tabs-tab:hover {
//     color: #ec8333;
//   }
// }

.itinearydata-tab {
  .thankyou-panel1 {
    .ant-card {
      background-color: white;
      border-radius: 8px;
      box-shadow: rgb(19, 41, 104 / 20%) 0px 2px 5px 0;
    }

    h3 {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }

    h4 {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 19px;
      font-weight: 900;
      color: rgb(116, 116, 116);
    }

    .scroll-color {
      color: rgb(116, 116, 116);
    }
  }

  .ourteam-bg-panel {
    max-width: 1100px !important;
    margin: auto;
    padding: 0 15px;
    background: transparent !important;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .card-request {
    margin-top: 25px;
  }

  .top-bar-one {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
  }

  .top-bar-one-1 {
    text-align: center;
    padding: 50px 0px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  .ct-flight-card {
    margin-bottom: 20px;

    .hotel-detail-box {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 0px;
    }

    .one-ct-1 {
      color: #000;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 4px;
      font-weight: 900;
    }

    .one-ct-2 {
      color: #000;
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 4px;
      font-weight: 900;
    }

    .one-ct-2-s {
      color: #000;
      font-size: 11px;
      line-height: 14px;
      margin-bottom: 3px;
      font-weight: 500;
    }

    .one-ct-3 {
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 0px;
    }

    .one-ct-tr {
      margin-bottom: 0px;
    }

    .ct-details-btn {
      padding: 0px 12px;
      font-size: 12px;
      border-radius: 15px;
    }

    .transfer-facility {
      padding: 0 12px;
    }
  }

  .inner-ct-card {
    margin-top: 20px;
  }

  .approad-ct {
    display: inline-flex;
    padding: 2px 8px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgb(67, 225, 168), rgb(40, 190, 178));
    border-radius: 8px;
    position: absolute;
    top: 0px;
    left: 9%;
    transform: translateY(-50%);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
  }

  .approad-1 {
    display: inline-flex;
    padding: 2px 8px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    background: linear-gradient(90deg, rgb(67, 225, 168), rgb(40, 190, 178));
    border-radius: 8px;
    position: absolute;
    top: 0px;
    left: 9%;
    transform: translateY(-50%);
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
  }

  .out-ct-policy {
    font-size: 10px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    flex-shrink: 0;
    text-transform: uppercase;
    background: linear-gradient(90deg, rgb(245, 81, 95), rgb(159, 4, 4));
    height: auto;
    padding: 2px 8px;
  }

  .star-rating-icon {
    display: inline-flex;
  }

  .star-rating-icon li {
    display: inline;
    padding: 0px 5px;
  }
}

@media screen and (max-width: 768px) {
  .holidays-header-sp {
    .header {
      display: block !important;
      margin-bottom: -18px !important;
      min-height: 120px !important;


    }

    .headings {
      display: block !important;
    }

    h2 {
      font-size: 15px !important;
    }

    .right {
      display: block !important;
    }

    .search-criteria .bottom {
      display: block !important;
    }

    .search-criteria .bottom ul.left li {
      padding-right: 0px !important;
      padding-left: 0px;
      padding-bottom: 7px;
    }

    .search-criteria .bottom ul.left {
      display: block !important;
    }

    .card {
      padding: 0px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .holidays-sp-mainbar {
    .sticky-links {
      padding: 0px 0px !important;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    ul {
      display: block !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    li {
      display: inline-block;
    }

    a {
      padding: 0px 4px !important;
    }

    .rating-panel-13 ul {
      display: inline-flex !important;
    }
  }

  .criteria-21 {
    li {
      display: block !important;
    }
  }

  .holidauys-d12 {
    padding: 0px !important;

    .slider-img-holidays {
      height: 200px !important;
    }
  }
}

.holidays-sp-mainbar {
  .about-tour {
    .showmore-wrapper {
      font-size: 14px;
    }
  }
}

.itenary-top-content {
  padding: 8px 16px;
  background: #f6f6f6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 7px;

  @include mobile {
    display: none;
  }

  .text-box {
    border: 1px solid #ec8333;
    border-radius: 999px;
    padding: 4px 10px;
    align-items: center;
    color: #525252;
    font-size: 11px;
    font-weight: 600;
    margin: 0px 6px !important;
  }

  .disabled-text-box {
    color: #bdb9b9 !important;
    border: 1px solid #ddd7d2 !important;
  }
}

.holidays-card-wrap {
  background-color: #eaebee;
  border-radius: 5px;
}

.enabledays {
  //color:white;
  //background:#ec8333;
  cursor: pointer;
  margin-top: 10%
}

.disabledays {
  opacity: 0.4;
  margin-top: 10%;
  pointer-events: none;
}

.itinearydata-left-tab {
  .ant-tabs-tab {
    padding: 3px 0;

    .ant-tabs-tab-btn {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-tabs-tab-btn:hover {
      color: #ec8333;
    }
  }

  .ant-tabs-tab-active {
    color: #ec8333;

    .ant-tabs-tab-btn {
      color: #ec8333;
    }
  }

  .ant-tabs-ink-bar {
    background: #ec8333;
  }
}

.holiday-itenerary-heading-span1 {
  background: #ec8333;
  border: 1px solid #ec8333;
  border-radius: 999px;
  padding: 4px 10px;
  align-items: center;
  color: #525252;
  font-size: 11px;
  font-weight: 600;
  margin: 5px;

  @include mobile {
    display: inline-block;
    padding: 4px 2px;
  }

}

.packages-day-night-box {
  margin-top: -3%;
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;
}

.packages-day-night-button {
  padding-top: 10%;
  padding-bottom: 10%;
  margin: 5%;
  border-radius: 15px;
  height: 100%;
  background-color: #ec8333;
  border-color: orange;
  color: white;
}


.holidaybook-modalpopup {
  border: 2px solid transparent;
  width: 700px !important;
  .room-data-info{
    .less{
      background: #c1d7f3;
      color: #000;
      border-radius: 2px;
      padding: 1px 6px;
      margin: 2px;
      cursor: pointer;
      &:active{
        background: #9bc4f9;
        color: rgb(247, 94, 94);
      }
     
    }
    .add{
      background: #c1d7f3;
      color: #000;
      border-radius: 2px;
      padding: 1px 6px;
      margin: 2px;
      cursor: pointer;
      &:active{
        background: #9bc4f9;
        color: rgb(120, 255, 47);
      }
    }
    .room-info{
      margin-left: 5px;
      .info-count{
        display: inline;
        // border: 1px solid rgba(202, 202, 202, 0.486);
        padding: 0 8px;
        border-radius: 2px;
        background: rgba(232, 232, 232, 0.518);
      }
      @media screen and (max-width: 768px) {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      }
    }
    @media screen and (max-width: 768px){
      .ant-col{
        margin:2px 0;
      }
      
    }
    
  }
  .headrs{
    font-size: 14px;
    font-weight: 600;
    color: #4a4a4ae4;
    padding-left: 4px;
    @media screen and (max-width: 768px) {
    display: inline-block;
    
    }
  }
  .package-info{
    h6{
      font-size: 14px;
      font-weight: 600;
      color: #4a4a4ae4;
      margin-top: 10px;
    }
    .ant-radio-group{
      display: inline-grid;
    }
    .ant-radio-wrapper{
      margin-bottom: 5px;
    }
  }

  .ant-modal-content {
    border: 2px solid transparent;
    border-radius: 25px;
    overflow: hidden;

    .ant-modal-close {
      .ant-modal-close-x {
        .ant-modal-close-icon {
          color: black !important;
        }
      }
    }

    .ant-modal-header {
      background: #ffffff !important;

      .ant-modal-title {
        .title-modal-head {
          h6 {
            color: #000 !important;
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
    }

  }
}