@import "../../../assets/variableStyles.scss";

.holidays-font {
  font-size: 30px !important;
}
.holiday-searchform-div{
  // margin-top: -16%;
  margin: -20% 10% 0 10%;
}
.holidays_search_container {
  margin-top: -36px;
}

.holidays_search_container .holidays_banner {
  position: relative;
  z-index: 1;
}

.holidays_search_container .holidays_banner .heading_text {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  width: 100%;
  max-width: 1050px;
  top: 34%;
}

.holidays_search_container .holidays_banner .hotel_search {
  position: absolute;
  bottom: 49px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1040px;
}

.holidays_search_container .holidays_banner .heading_text h2 {
  color: #fff;
  font-weight: 700;
  font-size: 34px;
  font-family: ubuntu;
  margin-bottom: 35px;
}

.holidays_search_container .holidays_advantages {
  max-width: 1140px;
  margin: 70px auto;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box {
  display: flex;
  align-items: center;
  flex-basis: 30%;
  flex-shrink: 0;
  flex-grow: 0;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper {
  padding-left: 15px;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper p {
  font-weight: 700;
  margin-bottom: 3px;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper small {
  font-size: 90%;
  font-family: Ubuntu;
}

.holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box img {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: 50%;
}

.holidays-search {
  background: #f5f5f5 !important;
  padding-top: 50px;
}

.holidays-search-apps {
  background: transparent !important;
}

.top-cities a {
  display: inline-block;
  margin-bottom: 12px;
  color: #666;
  line-height: 16px;
  margin-right: 10px;
}

.top-cities a::after {
  content: "";
  margin-left: 10px;
  border-left: 1px solid #b2b2b2;
  height: 12px;
  display: inline-block;
  vertical-align: middle;
}

.apps-holidays {
  justify-content: space-between;
  padding: 40px 28px 32px 40px;
  background-color: #ffdac4;
  width: 390px;
  height: 100%;
}

.apps-holidays h4 {
  text-align: center;
}

.ios-andriod-app img {
  display: flex;
  margin: 0 auto;
}

.img-travels-1 {
  height: 228px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .holidays_search_container .holidays_advantages .holidays-advantages-wrapper .holidays-adv-box .holidays-adv-box-wrapper {
    text-align: center;
    width: 90%;
    line-height: 1.3;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/*---carosuell--slider--*/
.home_carousel {
  margin-bottom: 30px;

  h3 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    font-family: Ubuntu, sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
  }

  .carousel-section {

    // max-height: 500px;
    .right-layer {
      position: absolute;
      height: 100%;
      width: 17%;
      z-index: 1;
      background-color: white;
      opacity: 0.5;
    }

    .left-layer {
      position: absolute;
      height: 100%;
      width: 17%;
      z-index: 1;
      background-color: white;
      opacity: 0.5;
      right: 0;
    }
  }
}

.carousel-img {
  width: 99%;
  height: 290px;
}

.hotel-slide {
  background-color: #e2076a;
}

.bulb-slide {
  background-color: #00d2e8;
}

.flight-bulb {
  &:hover {
    .hotel-letter {
      display: none;
    }

    .hovered-travel-content {
      display: block;
      padding: 30px 0;

      ul {
        li {
          margin-bottom: 15px;

          a {
            color: #fff;
            position: relative;

            &::before {
              height: 0;
              width: 0;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: solid transparent;
              border-left-color: white;
              content: "";
              position: absolute;
              left: -6px;
              top: 6px;
            }
          }
        }
      }
    }
  }
}

.custom-slide {
  color: #ffffff;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    line-height: 120%;
    font-size: 24px;
    text-align: center;
  }

  .hotel-letter {
    font-size: 70px;
    border: solid 7px #fff;
    border-radius: 50%;
    padding: 0px 30px;
  }

  .hotel-letter-icon {
    padding: 0 15px;
  }

  .cash-icon-carousel {
    padding: 0 20px;
  }
}

.slide-wrapper {
  position: relative;

  .slide-over-layer {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    position: absolute;
    height: 100%;
    width: 99%;
  }

  .check-details-btn {
    display: none;
  }

  &:hover {
    .slide-hoverlayer {
      background: linear-gradient(to right,
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)) !important;
      // z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 99%;
    }

    .check-details-btn {
      display: block;

      .btn-styles {
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        font-weight: 900;
        letter-spacing: 1px;
        margin-top: 18px;
        border-radius: 3px;
        text-align: center;
        border: none;
        color: #fff;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 23px 30px;
      }
    }
  }

  img {
    transition: opacity 0.3s;
    // opacity: 1;
    z-index: 1;
  }

  .content-on-slide {
    font-family: Ubuntu, sans-serif;

    .top-text {
      position: absolute;
      top: 10px;
      left: 20px;
      color: #fff;
      text-transform: uppercase;
    }

    .bottom-text {
      position: absolute;
      bottom: 10px;
      left: 20px;
      color: #fff;

      hr {
        color: #fff;
      }

      .hr-top {
        p {
          font-size: 13px;
        }

        .text-flight {
          display: flex;
          align-items: center;

          // justify-content: space-between;
          i {
            font-size: 25px;
          }

          h5 {
            color: #fff;
            font-weight: 800;
            letter-spacing: 0.5px;
            padding-left: 10px;
            font-size: 25px;
          }
        }
      }

      .price-bottom-hr {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-top: solid 1px #fff;
        padding-top: 10px;

        h6 {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
        }

        span {
          padding: 0px 6px 3px 10px;
          font-size: 28px;
          font-weight: 800;
        }

        p {
          font-size: 13px;
          font-weight: 800;
        }
      }
    }
  }
}

/* ============  customize the react slick styles ================= */
.activities-slider {
  .slick-next::before {
    content: "\f105";
    cursor: pointer !important;
    font-size: 20px !important;
    color: #fff;
    font: normal normal normal 40px/1 FontAwesome;
  }

  .slick-next {
    cursor: pointer !important;
    line-height: 0 !important;
    opacity: 1 !important;
    z-index: 1;
    background: #52c4f1 !important;
    border: 0px;
    border-radius: 0px;
    display: inline-block !important;
    font-size: 34px;
    font-weight: 200;
    padding: 4px 10px;
    height: 30px !important;
    width: 30px !important;
    position: absolute;
    top: 120px;
    right: -27px;
  }

  .slick-prev::before {
    content: "\f104";
    cursor: pointer !important;
    font-size: 20px !important;
    font: normal normal normal 40px/1 FontAwesome;
    color: #fff;
    opacity: 0.75 !important;
  }

  .slick-prev {
    cursor: pointer !important;
    line-height: 0 !important;
    opacity: 1 !important;
    z-index: 1;
    background: #52c4f1 !important;
    border: 0px;
    border-radius: 0px;
    display: inline-block !important;
    font-size: 34px;
    font-weight: 200;
    padding: 4px 10px;
    height: 30px !important;
    width: 30px !important;
    position: absolute;
    top: 120px;
    left: -35px;
  }
}

.hovered-travel-content {
  display: none;
}

.activities-home-page {
  padding-top: 30px;
}

.activities-home-page p {
  padding-top: 6px;
}

.cart-shopping-img-1 {
  width: 97%;
  height: 221px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.cart-shopping-img-1:hover {
  transform: translateY(-4px);
}

.buildyourpackage-bg {
  margin-top: -31px;
  height: 21em;
  width: 100%;
  min-height: 230px;
  position: relative;
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
}

.activity-ccc {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10px;
  padding-bottom: 11px;
}

.acitiv-pans {
  width: 100%;
  // background: #fff;
  padding: 0;
  margin: 0 0;
  border-radius: 3px;
  // max-width: 100%;
}

// .custom-mob-calendar {
//   z-index: 999999999;
//   .custom-mob-calendar-title {
//     display: none;
//   }
//   @include mobile {
//     height: 100%;
//     width: 98%;

//     margin: 0 auto;
//     right: 0px !important;

//     left: 0px !important;
//     top: 0px !important;
//     .custom-mob-calendar-title {
//       display: block;
//     }

//     .ant-picker-panel-container {
//       width: 100%;
//       .ant-picker-panel {
//         width: 85%;
//         margin: 0 auto;
//         display: block;
//       }
//       .ant-picker-date-panel,
//       .ant-picker-content {
//         width: 100%;
//       }
//     }
//   }
// }
.fn-actsinfo h2 {
  text-align: center;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  padding-bottom: 20px;
  font-size: 34px;
  font-weight: 600;
}

.round-acts-panel {
  padding-top: 110px;
}

@media screen and (max-width: 768px) {
  .round-acts-panel {
    padding-top: 0px;
  }

  .fn-actsinfo h2 {
    font-size: 16px;
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .fn-actsinfo {
    padding: 10px 16px !important;
  }

  .calls-btn-wid {
    width: 100%;
  }

  .br-right {
    border-right: 0px solid #d3d3d3 !important;
    border-bottom: 1px solid #d3d3d3 !important;
  }

  .cart-shopping-img-1 {
    margin-bottom: 10px;
    width: 100% !important;
  }
}

.acitiv-pans .ant-form-item {
  margin-bottom: 0px !important;
}

.acitiv-pans .ant-input-lg {
  height: 65px !important;
  padding: 14px 11px !important;
  // padding-bottom:0px !important;
  border: 0 solid !important;
}

.acitiv-pans .ant-picker-large {
  height: 47px;
  border: 0px solid;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;

  // padding-bottom: 0px !important;
  .ant-picker-input {
    margin-top: 9%;
  }
}

.acitiv-pans .search-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
 
}

.new-activity-pro {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 47px;
}

.button-promo-activity {
  width: 100%;
  height: 47px;
}

.br-right {
  border-right: 0px solid #d3d3d3;
}

.second-activity-col {
  padding-top: 25px;
}

.cms-pages-width-activity {
  transform: translate(1%, 0);
  width: 100%;
  max-width: 1040px;
}

.round-acts-panel .ant-form-item-explain .ant-form-item-explain-error {
  color:  #ff2121 !important;
  font-weight: 500 !important;
}

.activity-cols-num .ant-col .ant-form-item-label {
  display: none;
}

.activity-cols-num .ant-select-selector {
  height: 47px !important;
  padding: 14px 11px !important;
  padding-bottom: 0px !important;
  // border-color: transparent !important;
  border-radius: 0px !important;
  border: 1px solid #dfdfe1 !important;
}

.activity-cols-num .ant-select-selection-search {
  line-height: 60px;
  color: #000;
}

.activity-cols-num .ant-select-selection-item {
  color: #000;
  font-weight: 500;
}

.popular-clr-name {
  padding-bottom: 14px;
  font-weight: 600;
  font-size: 16px;
  color: #1c2b39;
}

.trip-help-cc {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  padding-bottom: 13px;
  color: #1c2b39;
  padding-top: 25px;
}

.activity-panel-img-cc {
  text-align: center;
  transition: all 0.5s ease;
}

.activity-panel-img-cc:hover {
  transition: all 0.5s ease;
  box-shadow: 0 1px 3px #000;
  transform: scale(1) perspective(1px);
  backface-visibility: hidden;
  border-radius: 5px;
}

.activity-panel-img-cc p {
  margin-bottom: 0px;
  font-size: 15px;
}

.activity-panel-img-cc span {
  font-weight: bold;
}

.help-support-cc {
  padding-top: 30px;
  padding-bottom: 20px;
}

.activity-cols-num input {
  font-weight: 500;
}

.activity-cols-num .ant-select-selection-placeholder {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

.acitiv-pans input {
  color: #000;
  font-weight: 500;
}

.form-holiday {
  .select-holidayform {
    .ant-select-selection-placeholder {
      padding: 0;
      line-height: 37px;
      transition: all 0.3s;
      font-size: 14px;
    }

    .ant-select-selection-item {
      line-height: 37px;
      font-size: 14px;
    }
  }
}



.mar-lef-holiday {
  margin-left: 1%;

  @include mobile {
    margin-left: 0%;
  }
}

.categori-holidaysearch {
  margin-left: 5%;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-weight: 550;
  font-family: sans-serif;

  @include mobile {
    margin-left: 3.2%;
  }
}

.where-holidaysearch {
  margin-left: 3%;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-weight: 550;
  font-family: sans-serif;

  @include mobile {
    margin-left: 3.2%;
  }
}

.journey-holidaysearch {
  margin-left: 6%;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-weight: 550;
  font-family: sans-serif;

  @include mobile {
    margin-left: 3.2%;
  }
}

// .mar-but-searchhol{
//   margin-top: -1%;
//   @include mobile{
//     margin-top: 1%;
//   }
// }
.holiday-upper-mar-work {
  margin-top: 4%;

  @include mobile {
    margin-top: 0%;
  }
}

// .journeyDate {
.ant-picker-large .ant-picker-input>input {
  font-size: 14px;
}

// }
.my_holiday{
  margin-left: 200px;
}
@media  screen and(max-width:1024px) {
  .my_holiday{
    margin-left: 0px;
  }
}
.mainn-section-tophotels {
  .slick-initialized {

    .slick-prev:before,
    .slick-next:before {
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #c4c4c4;
      opacity: 0.75;
      padding: 25%;
      border-radius: 50%;
      background-color: rgb(0, 57, 143);
    }
  }
}