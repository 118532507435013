@import "../../../assets/variableStyles.scss";
.busFareDetail_wrapper {
  .price_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    @include tab{
      font-size: 12px;
    }
  }
  .total_price {
    font-size: 18px;
    font-weight: 600;
    @include tab{
      font-size: 15px;
    }
    p {
      margin-bottom: 0;
    }
  }
  margin-bottom: 25px;
}

.buspromo_wrapper {
  .name {
    font-size: 18px;
    font-weight: 600;
  }
  .applied {
    color: #330166;
    font-weight: 600;
  }
  .ant-form {
    button {
       background-color: #0123ad;
      background-image: linear-gradient(to left, #00aaff, #78ff81);
            color: #fff;
      border: none;
      font-size: 16px;
    }
  }
}
