.hotel-details-container {
  padding: 20px 20px 18px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 0 solid transparent;
  font-size: 12px;
  -moz-border-radius: 8px;
   -webkit-box-shadow: 0 2px 2px 0 #dcdee3;
  -moz-box-shadow: 0 2px 2px 0 #dcdee3;
  box-shadow: 0 2px 2px 0 #dcdee3;

  .hotel-details-box-wrapper {
    .hotel-details-img-name {
      display: flex;

      .image-details {
        max-width: 70px;

        img {
          width: 100%;
          border-radius: 3px;
        }
      }

      .name-details {
        margin-left: 10px;
        max-width: 211px;

        p {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 1.2;
          font-weight: 700;

          sup {
            font-size: 8px;

            ul {
              padding-top: 10px !important;
            }
          }
        }

        span {
          color: #969dac;
          line-height: 15px;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .tripadv-rating {
      padding-top: 5px;
      padding-bottom: 5px;

      small {
        color: #969dac;
        font-size: 10px;
        display: block;
        border-bottom: 1px solid #dcdee3;
        margin: 11px 0 10px;
        line-height: 17px;
      }

      p {
        margin-bottom: 0;

        span {
          color: #1c2b39;
          line-height: 18px;
          font-size: 10px;
          margin-left: 5px;
        }
      }

      p.rating-description {
        font-weight: 700;
        background: #f3faf4;
        padding: 5px;
        text-align: center;
        margin: 16px 0 3px;
        color: #1da023;
      }
    }

    .stay-details-container {
      .staying-date {
        color: #000000;

        label {
          display: block;
          margin-bottom: 0;
          font-weight: 700;
          color: #505c75;
        }
      }
    }
  }
}

/****** Styles***17-08-2021*****/
.stay-details-container {
  line-height: 32px !important;
  font-weight: bold;
}

.hotel-stay-name {
  font-size: 20px;
}

.number-hotel-spin {
  padding-left: 8px;
}

/****** Styles***18-08-2021*****/
.locaton-hotel-div {
  margin-bottom: 0px;
  color: #999;
  font-size: 1rem;
}

.one-img-hotel img {
  width: 100%;
  border-radius: 4px;
  height: 120px;
}

.disp-table-cell-div {
  width: 100%;
}

.hotel-plan-div {
  display: flex;
  margin-bottom: 10px;

  .starRating {
    font-size: 14px;
  }
}

.hotel-plan-div h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0;
}

.post-div-para {
  margin-bottom: 5px;
  color: #999;
  font-size: 14px;
}

.one-stay-div-hotel {
  width: 100%;
  float: left;
}

.cal-block-div {
   
  color: #666;
}

.room-number-wrapper {
  margin: 10px 0;
}



.checkin-text-div {
  position: relative;
  bottom: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #404040;

}

.bkg-date-div {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  display: block;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  line-height: 24px;
  text-wrap: nowrap;
}

.cb-footer-div {
  background-color: #fff;
  font-size: 0.8571rem;
  line-height: 1.39;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 4px 0;
}

.cp-room-1 {
  display: flex;
}

.room-number-days {
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
}

.spin-days-rooms {
   
  display: flex;
  margin-top: 0px !important;
}

.room-spin-cp {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 7px;
  padding-top: 7px;
  color: #666;
  margin-right: 30px;
}

.room-pkg-spin-cp {
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
}

.cp-box-none {
  display: none;
}

.last-cp-details {
  padding-top: 20px;
}

.inclusion-cp {
  display: flex;
}

.inclusion-cp p {
  margin-bottom: 5px;
  font-size: 16px;
  color: #000000;
}

.inclusion-cp ul {
  padding-bottom: 10px;
}

.inclusion-cp ul li {
  color: #666;
  font-size: 14px;
  line-height: 22px;
}

.view-more-cp {
  margin-bottom: 0px;
  font-size: 14px;
  padding-left: 108px;
}

.tag-cp-img {
  background: #02cb66;
  padding: 2px 10px 2px;
  color: #fff;
  border-radius: 0 20px 20px 0;
  position: absolute;
  z-index: 9;
  left: -8px;
  top: 10px;
  max-width: 90%;
  display: none;
}

.tag-cp-img p {
  margin-bottom: 0px;
  font-size: 15px;
}

.tag-cp-img::after {
  content: "";
  position: absolute;
  left: 0;
  top: -8px;
  width: 0;
  height: 0;
  border: 4px solid #00773b;
  border-top-color: transparent;
  border-left-color: transparent;
}

.hotel-plan-div ul {
  padding-right: 12px;
}

.hotel-plan-div ul li .fa {
  color: #f6952d;
}

.inclusion-cp ul {
  padding-left: 12px;
}

.inclusion-cp ul li .fa {
  color: #f6952d;
}

.cancel-policy-cp {
  margin-top: 10px;
  border: 0;
  padding: 10px;
  background: #f1f1f1;
}

.cancel-policy-cp p {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}

@media only screen and (max-width: 768px) {
  .hotel-plan-div {
    display: block;
    padding-top: 20px;
  }

  .one-stay-div-hotel {
    margin-bottom: 15px;
  }

  .hotel-plan-div ul {
    padding-left: 0px !important;
  }

  .inclusion-cp {
    display: block;
  }

  .inclusion-cp ul {
    padding-left: 0px;
  }

  .inclusion-cp ul {
    padding-left: 0px;
  }

  .view-more-cp {
    padding-left: 0px !important;
  }
}

.hedr {
  h6 {
    font-size: 16px;
    font-weight: 700;
    color: #320063;
  }
}

.cal-top-date-pic {
  margin-top: 25px;
  border-bottom: 1px solid rgba(177, 177, 177, 0.669);
  padding-bottom: 2px;
}

.stay-info-card {
  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;


  }

  p {
    font-weight: 800;
    font-size: 16px;
  }
}

.br-line {
  border: 1px solid #e7e7e7;
}

.sel-room-details {
  margin-top: 10px;

  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;


  }

  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.hotel-name {
  margin-top: -15px;

  h4 {
    font-weight: 700;
    font-size: 22px;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    line-height: 28px;
  }
}