@import "../../../assets/variableStyles.scss";

.hotel-checkout-wrapper {
  .form-header {
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

    .form-header-container {
      max-width: 1100px;
      padding: 0 15px;
      margin: auto;

      .form-header-text {
        padding: 25px 0;

        h2 {
          color: #fff;
          font-size: 24px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
    }
  }

  .form-body {
    background-color: #e8f2fa;

    .form-body-container {
      max-width: 1100px;
      padding: 15px;
      margin: auto;
      padding-top: 10%;

      @include mobile {
        margin-top: 14%;
      }

      .hotel-detail-header-wrapper {
        display: flex;
        align-items: "center";
        justify-content: space-between;

        p {
          margin-bottom: 0px;
        }
      }

      .hotel-card-wrapper {
        // border-radius: 8px;
        // box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        margin: 0 0 15px 0;
        padding: 16px;
        border: 1px solid #e7e7e7;
        // background: #e7fff0;

        .ant-card-body {
          padding: 0;
        }

        &:hover {
          box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        }
      }

      .good-to {

        .hdr-1 {
          span {
            margin-right: 5px;
          }

          font-size: 20px;
          font-weight: 600;
        }
      }

      .form-body-login-content {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 5px #ccc;

        .form-body-login-text {
          p {
            margin-bottom: 0;
          }
        }

        .form-body-login-btns {
          button {
            background-color: transparent;
            border: 1px solid #2e0000;
            padding: 7px 15px;
            border-radius: 3px;
            margin-right: 10px;
            font-size: 14px;

            &:last-child {
              margin-right: 0;
            }

            img {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }

            span {
              vertical-align: middle;
            }

            i {
              font-size: 20px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }

      .guest-dts-form {
        .room-title {
          font-size: 16px;
          font-weight: 600;
          color: #1c2b39;
          margin-top: 10px;
          margin-top: 10px;
        }

        .ant-form {
          .guest-input-wrapper {
            .guestsType {
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              margin-bottom: 8px;
            }

            .ant-input-group-addon {
              width: 35%;
              padding: 0;
            }

            // .ant-form-item-control-input {
            //   .ant-input {
            //     border: 1px solid #bebebe;
            //     border-radius: 4px;
            //     font-family: lato;
            //     font-size: 14px;
            //     font-weight: 400;
            //     height: 40px;
            //     outline: 0;
            //     padding: 0 20px 0 10px;
            //     width: 100%;
            //   }
            // }

            // .ant-select-selector {
            //   border: 1px solid #bebebe !important;
            //   border-radius: 4px !important;
            //   font-family: lato;
            //   font-size: 14px;
            //   font-weight: 400;
            //   height: 40px !important;
            //   outline: 0;
            //   padding: 0 20px 0 10px;
            //   width: 100%;
            // }

            .ant-row {
              .ant-col {
                .ant-form-item-required {
                  font-size: 14px;
                  color: #505c75;
                  // font-weight: 600;
                  // font-size: 14px;
                  // color: #505c75;
                  font-weight: 700 !important;
                  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
                }
              }
            }
          }
        }
      }

      .form-body-contact {
        margin: 30px 0;

        .contact-header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 15px;

          .contact-title {
            h1 {
              font-size: 24px;
              font-weight: 400;
              color: #1c2b39;
            }
          }
        }

        .contact-internal-form {
          background-color: #fff;
          // border-radius: 8px;

          .contact-body {
            padding: 15px;

            .contact-header {
              span {
                font-size: 16px;
                font-weight: 600;
                position: relative;
                color: #1c2b39;

                &:after {
                  content: "";
                  height: 1px;
                  background-color: #bcd5f5;
                  position: absolute;
                  width: 475px;
                  top: 50%;
                  margin-left: 35px;
                  left: 100%;
                }
              }
            }

            .contact-form {
              margin-top: 20px;
              margin-bottom: 10px;

              label {
                font-size: 14px;
                color: #505c75;
                font-weight: 600;
              }

              .form-bed-type {
                margin-bottom: 0;
              }

              .ant-input-group-wrapper {
                .ant-input-group-addon {
                  width: 35%;
                  padding: 0;

                  .ant-select-selector {
                    background-color: inherit !important;
                  }
                }
              }
            }

            .contact-disclaimer {
              p {
                margin-bottom: 0;
                font-size: 12px;
                color: #969dac;
              }
            }
          }

          .contact-footer {
            padding: 15px;
            margin-bottom: 0;
            font-size: 13px;
            color: #505c75;

            i {
              margin-right: 10px;
            }

            .contact-checkbox {
              display: flex;
              align-items: center;

              label {
                margin-left: 5px;
                margin-bottom: 0;
                font-size: 14px;

                span {
                  font-weight: 600;
                  color: #2e0000;
                }
              }
            }

            .bookandpay-btn {
              button {
                font-size: 18px;
                font-weight: 600;
                background-color: #eb5196;
                color: #fff;
                border: none;
                height: auto;
                padding: 10px 30px;
                border-radius: 3px;

                &:hover {
                  background-color: #eb5196;
                }
              }
            }
          }
        }
      }

      .form-important-info {
        margin: 30px 0;

        .contact-header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 15px;

          .contact-title {
            h1 {
              font-size: 24px;
              font-weight: 400;
              color: #1c2b39;
            }
          }
        }

        .contact-internal-form {
          background-color: #fff;
          border-radius: 5px;

          .contact-body {
            padding: 15px;

            .contact-header {
              span {
                font-size: 16px;
                font-weight: 600;
                position: relative;
                color: #1c2b39;

                &:after {
                  content: "";
                  height: 1px;
                  background-color: #bcd5f5;
                  position: absolute;
                  width: 475px;
                  top: 50%;
                  margin-left: 35px;
                  left: 100%;
                }
              }
            }

            .contact-form {
              margin-top: 20px;
              margin-bottom: 10px;

              label {
                font-size: 14px;
                color: #505c75;
              }

              .form-bed-type {
                margin-bottom: 0;
              }
            }

            .contact-disclaimer {
              p {
                margin-bottom: 0;
                font-size: 12px;
                color: #969dac;
              }
            }
          }

          .contact-footer {
            padding: 15px;
            margin-bottom: 0;
            font-size: 13px;
            color: #505c75;

            i {
              margin-right: 10px;
            }

            .contact-checkbox {
              display: flex;
              align-items: center;

              label {
                margin-left: 5px;
                margin-bottom: 0;
                font-size: 14px;

                span {
                  font-weight: 600;
                  color: #2e0000;
                }
              }
            }
          }
        }
      }

      .hotel-info-wrapper {
        border-radius: 6px;

        &:hover {
          box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;

        }
      }

      .hotel-price-wrapper {
        // border: 1px solid #e7e7e7;
        // border-radius: 8px;
        // box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
        // margin-top: 20px;

        // padding: 16px;
        .hotel-details-box {
          margin-bottom: 5px;
        }

        .promo-card {
          margin-top: 5px;
        }

        .grand-total-card {
          .ant-card-body {
            padding: 15px;

            .promo_added_fee {
              padding: 8px 0;
            }

            .total-fare {
              padding: 8px 0;
            }
          }
        }
      }

      // .form-body-right-content {
      //   background-color: transparent;
      //   border-radius: 8px;
      //   margin-bottom: 5px;
      //   .hotel-fair-box {
      //     background-color: #ffffff;
      //     border-radius: 8px;
      //     margin-bottom: 7px;
      //     .accordion {
      //       .cards {
      //         background-color: transparent;
      //         .card-header {
      //           background-color: transparent;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;

      @include mobile {
        padding: 16px;
      }

      .book-pay-tc {

        display: flex;

        span {
          color: red;
        }

        p {
          margin-bottom: 0;
        }
      }

      .book-pay-offers {
        display: flex;

        p {
          font-size: 15px;

          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }

      .book-pay-btn {
        button {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          color: #fff;
          border: none;
          font-size: 20px;
          font-weight: 600;
          padding: 10px 20px;
          height: 100%;

          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }

        button:hover {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          color: #fff;
        }

        .book-and-content {
          padding-top: 20px;

          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }

    .selection-tab {
      display: flex;
      margin: 5px 0 20px 0;

      .l {
        width: 312px;
        height: 2px;
        background: rgb(177, 186, 199);
        margin: 14px 4px;
      }
    }

    .sel-3 {

      margin: 0 0 0 20px !important;

    }

    .sel {
      margin: 0 10px;
    }

    .sel,
    .sel-3 {
      display: flex;
      align-items: center;

      .a {
        border: 1px solid rgb(0, 157, 255);
        border-radius: 50%;
        background: white;
        padding: 2.5px 9px;
        margin-right: 4px;
      }

      .a1 {
        .fa {
          font-size: 34px;
          color: #0059ff;
        }
      }

      .b {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        font-family: Avenir Next, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        color: #320063;
      }
    }

    .sel div {
      margin-right: 5px;
    }
  }
}

/****** Styles *****/

@media screen and (max-width: 768px) {
  .form-body-row {
    display: block !important;
  }

  .form-body-left-block {
    max-width: 100% !important;
  }

  .mobile-diretion-part {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .mobile-diretion-part .ant-form-item {
    margin-bottom: 0px;
  }
}

.lne {
  height: 8px;
  background-image: linear-gradient(to bottom,
      #344d6e8c,
      #ffffff00);
  position: sticky;
  top: 127px;
  z-index: 1;
}

.lne-5 {
  height: 8px;
  // background-image: linear-gradient(to bottom,
  //     #344d6e8c,
  //     #ffffff00);
  position: sticky;
  top: 123px;
  z-index: 1;

  @include tab {
    top: 104px;
    // width: 103%;
  }
}

.lne-5 {
  height: 8px;
  // background-image: linear-gradient(to bottom,
  //     #344d6e8c,
  //     #ffffff00);
  position: sticky;
  top: 123px;
  z-index: 1;

  @include tab {
    top: 104px;
    // width: 103%;
  }
}

.guest-input-wrapper {
  .ant-select-selector {
    .ant-select-selection-item {
      max-width: 100%;

      @include tab {
        max-width: 10px;
      }

      @include mobile {
        max-width: 100%;
      }
    }
  }
}

/****** Styles***17-08-2021*****/

.hotel-checkout-wrapper .hotel-stay-date-row {
  display: flex;
}

.hotel-collaspe-spin {
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 0px solid #d9d9d9;
  // border-radius: 8px;
}

.hotel-collaspe-spin .ant-collapse-header {
  color: #000;
  font-size: 15px;
  font-weight: 900;
}

.booking-summary-div {
  font-size: 20px;
  color: #1c2b39;
  // background-image: linear-gradient(to right, #43264e, #ea2331);

  // -webkit-text-fill-color: transparent;
  // -webkit-background-clip: text;
  // background-color: #fff;

  // background-image: -moz-linear-gradient(left, #43264e, #ea2331);
  // background-image: -ms-linear-gradient(left, #43264e, #ea2331);
  // background-image: -o-linear-gradient(left, #43264e, #ea2331);

  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.hotel-checkout-wrapper .image-details,
.hotel-preview-container .image-details {
  max-width: 210px !important;
}

@media only screen and (max-width: 768px) {

  .hotel-checkout-wrapper .image-details,
  .hotel-preview-container .image-details {
    max-width: 100% !important;
  }
}

.cp-review-book {
  padding-top: 20px;
}

.cp-note-imp {
  background-color: #fffcc7;
  padding: 5px;
}

.cp-info-note {
  padding-top: 10px;
  font-size: 15px;
}

/* holiday checkout page payment bttns */
.book-pay-card {

  margin: 0 30px 0 150px;

  .book-pay-btns {
    Button {
      margin-left: 110px;
      margin-right: 20px;
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      &:active {
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      }

      @include mobile {
        margin: 0;
        margin-left: 30px;
        margin-right: 40px;

      }
    }

    @include mobile {
      display: inline-flex;
      // border: 1px solid green;
    }
  }

  @include mobile {
    margin: 0;

  }
}

.price-card-container {
  // border-radius: 8px;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
  }

  .hotel-cards-details {
    background-color: #ffffff !important;
    // border-radius: 8px !important;

    .price-header {
      // font-weight:bold;
    }


  }


  .price-section {

    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    margin-left: -24px;
    padding: 8px 10px 0 10px;
    margin-right: -24px;

    .grand_total_card_row {
      .head-er {
        margin-left: 12px;
      }

      p {
        font-size: 24px;
        font-weight: 700;
        font-family: Avenir Next, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        line-height: 32px;
      }
    }

    .sub-pr-ce {
      .price-tax {
        position: relative;
        display: flex;
        margin-left: 17px;
        margin-top: 0px;
        font-size: 14px;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        line-height: 20px;
        color: #474747;

        i {
          margin-top: 6px;
        }
      }
    }
  }
}

.hotel-card-wrpr {
  // border-radius: 8px;
  // box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  margin: 0 0 15px 0;
  padding: 16px;
  border: 1px solid #e7e7e7;
  background: #ffffff;

  .ant-card-body {
    padding: 0;
  }

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  }
}

.hotel-checkout-wrapper .form-body .form-body-container .hotel-card-wrapper {

  background: #ffffff;

  .inpt-mail {
    label {
      font-size: 14px;
      color: #505c75;
      font-weight: 700 !important;
      font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;

    }
  }
}

.guest-dts-form {
  .room-title {
    font-size: 16px;
    font-weight: 600;
    color: #1c2b39;
    margin-top: 20px !important;
  }

  .guestsType {
    font-size: 14px !important;
    font-weight: 700;
    margin: 0;
    margin-bottom: 8px;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;

  }

  .guest-input-wrapper {
    .input-lbl {
      label {
        font-size: 14px;
        color: #505c75;
        font-weight: 700 !important;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;
      }
    }
  }

  .guest-input-wrapper-2 {
    border: 1px solid red;
    background: #e0f2ff;

    .inpt-mail {
      label {
        font-size: 14px;
        color: #505c75;
        font-weight: 700 !important;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;

      }
    }
  }


  .ant-form {
    .guest-input-wrapper {
      .guestsType {
        font-size: 14px !important;
        font-weight: 700;
        margin: 0;
        margin-bottom: 8px;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;

      }

      .ant-input-group-addon {
        width: 35%;
        padding: 0;
      }

      // .ant-form-item-control-input {
      //   .ant-input {
      //     border: 1px solid #bebebe;
      //     border-radius: 4px;
      //     font-family: lato;
      //     font-size: 14px;
      //     font-weight: 400;
      //     height: 40px;
      //     outline: 0;
      //     padding: 0 20px 0 10px;
      //     width: 100%;
      //   }
      // }

      // .ant-select-selector {
      //   border: 1px solid #bebebe !important;
      //   border-radius: 4px !important;
      //   font-family: lato;
      //   font-size: 14px;
      //   font-weight: 400;
      //   height: 40px !important;
      //   outline: 0;
      //   padding: 0 20px 0 10px;
      //   width: 100%;
      // }
      .ant-row {
        .ant-col {
          .ant-form-item-required {
            font-size: 14px;
            color: #505c75;
            font-weight: 700 !important;
            font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif !important;

          }
        }
      }
    }
  }
}

.hdr {
  h4 {
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }

  p {
    padding: 4px 8px;
    background: #ddf7dd;
    width: fit-content;
    border-radius: 4px;
    margin-bottom: -6px;

    // margin-top: 5px;
    span {
      color: red;
    }
  }
}

.contact-details {
  border: 1px solid red;
}

.additional-info {
  .guest-details-form {
    background: rgb(244, 255, 244) !important;
  }

  .hdr-info-2 {
    h6 {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.arr-time {
  border: 1px solid #e7e7e7;
  margin-bottom: 20px;
  border-radius: 6px;

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
  }

  .arr-ti {
    background: #ffffff;
    border-radius: 6px;

    .hdr-3 {
      h6 {
        font-size: 20px;
        font-weight: 600;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #320063;
        line-height: 24px;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

        span {
          margin-right: 10px;
          font-size: 24px;
        }

      }
    }
  }
}

.ant-input-group-addon {
  padding: 0px 0px;
  width: 28%;
}

.ant-form button:hover {
  border: 2px solid #2e0000;
  color: #2e0000;
}

// .anticon svg {
//   margin-top: 30%;
//   margin-left: 25%;
// }

.phoneno-directionn-codes {
  .ant-input-group-addon {
    .ant-select-arrow {
      position: absolute;
      right: 22%;
    }
  }
}