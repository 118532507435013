@import "../../../assets/variableStyles.scss";
.hotel-fair-box {
  background-color: #fff;
  // border-radius: 5px;
  margin-bottom: 5px;

  .cards {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .gstdetails,
  .pricedetails {
    background-color: #fff;
    padding: 0.75rem;

    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0;
      color: #1c2b39;
      font-size: 14px;
      text-decoration: none;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        text-decoration: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }

  .cards-body {
    padding: 0.75rem !important;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    span {
      &:nth-child(1) {
        margin-left: 20px;
      }
    }
  }

  .total-fare {
    padding: 15px;

    .price-block {
      display: flex;
      justify-content: space-between;

      .total-text {
        font-size: 18px;
      }

      .price {
        font-size: 18px;

        span {
          font-weight: 600;
        }
      }
    }

    .book-facilities {
      ul {
        margin-bottom: 0;

        li {
          font-size: 12px;
          color: #1da023;

          span {
            border-bottom: 1px dotted #1da023;
          }

          a {
            color: #969dac;
            border-bottom: 1px dotted #969dac;
          }
        }
      }
    }
  }
}

.new-preview-box {
  margin-top: 54px;
}

.hotelpromo_wrapper {
  // border-top: 1px solid rgb(230, 230, 230);
  position: absolute;
  margin-top: 20px;
  width: 345px;
  @include tab{
    width: 100%;
  }

}

.hotelpromo_wrapper {

  // margin-bottom: 20px;
  .hotel-card-wrapper {
    .name {
      font-size: 14px;
      font-weight: 700;
      font-family: Avenir Next, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

    }
  }
}

.add-fee {
  border-top: 1px solid rgb(230, 230, 230);
  margin-top: 20px;

  .hotel-add-wrapper {
    border: 1px solid rgb(235, 235, 235);
    // position: absolute;
    margin-top: 20px;

    &:hover {
      box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;

    }
  }
}

.hdr-add {
  h6 {
    font-size: 18px;
    font-weight: 700;
    font-family: Avenir Next, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    color: #320063;

  }

  .add-fee-amnt {
    background: #ebf3ff;
    width: 344px;
    margin-left: -24px;
    padding: 8px 30px;

    .grand_total_card_row {
      margin-bottom: -10px;
      p{
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

}

.price-card-container {
  border: 1px solid rgb(232, 232, 232);
  margin-top: 20px;
  
}