@import "../../../assets/variableStyles.scss";
.domestic-airsearch {
  margin-bottom: 30px !important;
  max-width: 1100px;
  margin: 0px auto;
  padding: 0 0px;
  @include mobile {
    margin-bottom: -3px !important;
    margin-top: 5px;
  }
  h3 {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 20px;
    font-family: Ubuntu, sans-serif;
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
  }
  .ducarosuel {
    box-shadow: 0 1px 5px rgb(0, 0, 0 / 10%);
    border-radius: 6px;
    padding: 20px;
    justify-content: space-between;
    border: 1px solid #eae8e8;
    margin: 2px 9px 5px;
    height: 180px;
    cursor: pointer;
    transition: all 0.5s ease;
    background: #fff;
  }
  .origin-item-sel {
    width: 100%;
    display: block;
    flex-wrap: nowrap;
    padding-left: 12px;
    border-left: 2px solid #dbe3ef;
    position: relative;
  }
  .origin-item-sel::before {
    content: " ";
    position: absolute;
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    top: 0;
    left: -4px;
  }
  .origin-item-sel::after {
    content: " ";
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: -4px;
  }
  .city-due {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
    height: 16px;
    font-weight: 600;
  }
  .time-due {
    margin: 5px 0 2px;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
  }
  .price_go_test {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-left: 14px;
    align-items: flex-end;
  }
  .starting-test {
    font-size: 18px;
    color: #f34f4f;
    text-align: left;
    display: block;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 0px;
  }
  span {
    width: 100%;
    font-weight: 400;
    display: block;
    color: #444;
    font-size: 12px;
  }
  .rs-price {
    font-size: 20px;
    color: #f34f4f;
  }
}
