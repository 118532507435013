// @import "../../assets/variableStyles.scss";

// .currency-flag-img {
//   max-width: 30px;
//   max-height: 24px;
//   margin-right: 10px;
//   border-radius: 50%;
// }

// .selectedNav {
//   // color: #3cc8ff !important;
//   color: black !important;
//   // border-bottom: 5px solid black;
//   // background-color: #f0f0f0 !important;

// }

// .nav-wallet-wrapper {
//   padding: 5px 12px;

//   .anticon {
//     font-size: 16px;
//   }
// }

// .my-acc-dropdown {
//   padding-top: 5%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;


//   @include tab {
//     padding-top: 7%;
//   }

//   @include mobile {
//     padding-top: 0%;
//   }
// }

// .user-btn-wrapper {
//   // display: contents;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;

//   .anticon {
//     margin: 0 5px;
//   }
// }

// .header-bg {
//   background-color: #363a45;

//   @include minitab {
//     // margin-top: 35%;
//   }
// }


// .nav-transparent-class {
//   background: #ffffff00;
//   // box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
//   position: fixed;
//   z-index: 70;
//   width: 100%;

//   @include mobile {
//     background-color: transparent;
//   }

//   .wrapper {
//     max-width: 1240px;
//     background-color: transparent;
//     margin: 0 auto;
//     padding-left: 15px;
//     padding-right: 15px;

//     .header {
//       width: 100%;
//       background-color: transparent;
//       display: flex;
//       padding-bottom: 3px;

//       .app-logo {
//         .company-logo {
//           height: 100%;
//           display: flex;
//           align-items: center;
//           justify-content: flex-end;
//           padding: 0px 0;

//           //max-width: 85px;
//           @include mobile {
//             justify-content: start;
//             max-width: 80px;
//           }

//           @include smmobile {
//             max-width: 70px;
//           }

//           img {
//             height: 34px;
//             width: auto;
//             margin-top: -3px;
//             margin-bottom: 7px;
//           }
//         }

//         @include mobile {
//           // width: initial;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin: 0;
//         }
//       }

//       .app-links {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding-left: 12px;

//         @include mobile {
//           align-items: flex-start;
//           display: none;

//         }

//         .menu1 {
//           // border: 2px solid red !important;
//           width: 30%;
//           display: flex;
//           justify-content: space-around;
//           // border-left: 1px solid rgb(176, 176, 176);

//           @include tab {
//             width: 35%;
//             // border: none;
//             position: relative;
//             top: 4%;
//             right: 5%;
//             border: none;
//           }

//           @include mobile {
//             display: block;
//           }

//           .currency-text {
//             .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//               background-color: transparent !important;
//             }
//           }

//           .goog-te-combo {
//             background-color: transparent !important;
//             border: none !important;
//             color: white !important;
//           }

//           li {
//             // padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             padding: 5px 0px 0px 10px;
//             font-size: 14px;
//             width: 50%;

//             @include tab {
//               width: 44%;
//             }

//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;

//             }
//           }

//         }

//         ul {
//           display: flex;

//           padding: 0;
//           list-style: none;
//           overflow: hidden;
//           margin-left: auto;

//           @include mobile {
//             display: block;
//           }

//           li {
//             padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             // padding: 0px 0px 0px 10px;
//             font-size: 14px;
//             // width: 40%;
//             text-align: center;


//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;
//               width: 100%;
//               text-align: left;
//             }

//             a {
//               display: block;
//               color: rgb(255, 255, 255);
//               align-items: center;
//               text-decoration: none;
//               font-size: 14px;

//               @include mobile {
//                 color: #ffffff;
//                 display: flex;
//               }

//               .anticon {
//                 margin-left: 8px;
//               }
//             }

//             span {
//               font-size: 14px;
//               color: #ffffff;

//               @include mobile {
//                 color: #ffffff;
//               }
//             }
//           }

//           .custom-drop-down {
//             position: initial;

//             a {
//               color: #fff;
//             }

//             .dropdown-item {
//               color: #000 !important;
//             }
//           }
//         }

//         @media screen and (max-width: 768px) {
//           flex-flow: column;
//           width: 100%;
//           text-align: left;
//         }
//       }
//     }

//     .header li a:hover,
//     .header .menu-btn:hover {
//       color: #bcd5f5;
//       text-decoration: none !important;
//       outline: none !important;
//     }

//     /* menu */
//     .header .menu {
//       max-height: 0;
//       transition: max-height 0.2s ease-out;
//     }

//     /* menu icon */
//     .header {
//       .menu-icon {
//         cursor: pointer;
//         display: inline-block;
//         padding: 28px 20px;
//         position: relative;
//         user-select: none;

//         @include mobile {
//           padding: 28px 0 28px 20px;
//         }

//         .navicon {
//           background: #fff;
//           display: block;
//           height: 1px;
//           position: relative;
//           transition: background 0.2s ease-out;
//           width: 18px;
//         }
//       }

//       .user-icon {
//         display: flex;
//         align-items: center;

//         i {
//           margin-right: 20px;
//           font-size: 20px;
//           color: #fff;
//         }

//         .menu-icon {
//           margin-bottom: 0;
//           padding: 22px 2px 28px 2px;
//           margin-left: 18px;
//         }

//         .menu-text {
//           color: #fff;
//           font-size: 10px;
//           position: absolute;
//           top: 30px;
//           left: -2px;
//         }
//       }
//     }

//     .header .menu-icon .navicon:before,
//     .header .menu-icon .navicon:after {
//       background: #fff;
//       content: "";
//       display: block;
//       height: 100%;
//       position: absolute;
//       transition: all 0.2s ease-out;
//       width: 100%;
//     }

//     .header .menu-icon .navicon:before {
//       top: 5px;
//     }

//     .header .menu-icon .navicon:after {
//       top: -5px;
//     }

//     /* menu btn */
//     .header .menu-btn {
//       display: none;
//     }

//     .header .menu-btn:checked~.menu {
//       max-height: 430px;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon {
//       background: transparent;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:before {
//       transform: rotate(-45deg);
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:after {
//       transform: rotate(45deg);
//     }

//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
//       top: 0;
//     }

//     .iconText {
//       margin-left: 5px;
//     }

//     .service-links {
//       align-items: center;

//       .nav-link {
//         color: white !important;
//         font-size: 16px;

//         i {
//           margin-left: 5px;
//           font-size: 15px;
//         }
//       }
//     }

//     @media (min-width: 992px) {

//       // .header li {
//       //   // float: left;
//       // }
//       // .header li a {
//       //   // padding: 23px 30px;
//       // }
//       .header .menu {
//         max-height: none !important;
//       }

//       .header .menu-icon {
//         display: none;
//       }
//     }

//     @media screen and (max-width: 768px) {
//       .header {
//         padding: 0 15px;
//         display: block;
//         position: fixed;
//         top: 0;
//         left: 0;
//         right: 0;
//         // background: #00164d;
//         background-color: #2f81ed;
//         background-image: linear-gradient(to right, #2f80ed, #56ccf2);
//         border-bottom: 0px solid #ffffff;

//         a {
//           img {
//             margin-top: 5px;
//           }
//         }
//       }
//     }
//   }
// }

// .header-container {
//   background: #ffffff;
//   box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
//   // position: fixed;
//   z-index: 70;
//   width: 100%;
//   margin-top: 10%;
//   display: none;



//   @include tab {
//     margin-top: 15%;
//   }

//   @include mobile {
//     background-color: transparent;
//     margin-top: -10%;
//     display: block;

//   }

//   @include smmobile {
//     margin-top: -5.5%;

//   }

//   @include xsmobile {
//     margin-top: -7%;
//   }



//   .wrapper {
//     max-width: 1240px;
//     background-color: transparent;
//     margin: 0 auto;
//     padding-left: 15px;
//     padding-right: 15px;

//     .header {
//       width: 100%;
//       background-color: transparent;
//       display: flex;
//       padding-bottom: 3px;

//       .app-logo {
//         .company-logo {
//           height: 100%;
//           display: flex;
//           align-items: center;
//           justify-content: flex-end;
//           padding: 0px 0;

//           //max-width: 85px;
//           @include mobile {
//             justify-content: start;
//             max-width: 80px;
//           }

//           @include smmobile {
//             max-width: 70px;
//           }

//           img {
//             height: 34px;
//             width: auto;
//             margin-top: -3px;
//             margin-bottom: 7px;
//           }
//         }

//         @include mobile {
//           // width: initial;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin: 0;
//         }
//       }

//       .app-links {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding-left: 12px;

//         @include mobile {
//           align-items: flex-start;
//           position: absolute;
//           width: 100%;
//           background-color: #363a45;
//           z-index: 11;
//           margin-left: -8%;
//           display: none;
//         }

//         .menu1 {
//           // border: 2px solid red !important;
//           width: 30%;
//           // border-left: 1px solid rgb(176, 176, 176);

//           @include tab {
//             width: 35%;
//             // border: none;
//             position: relative;
//             top: 4%;
//             right: 9%;
//             border: none;
//           }

//           @include mobile {
//             display: block;
//           }

//           .currency-text {
//             .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//               background-color: transparent !important;
//             }
//           }

//           .goog-te-combo {
//             background-color: transparent !important;
//             border: none !important;
//             color: white !important;
//           }

//           li {
//             // padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             padding: 5px 0px 0px 10px;
//             font-size: 14px;
//             width: 50%;

//             @include tab {
//               width: 44%;
//             }

//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;

//             }
//           }

//         }

//         ul {
//           display: flex;

//           padding: 0;
//           list-style: none;
//           overflow: hidden;
//           margin-left: auto;

//           @include mobile {
//             display: block;
//           }

//           li {
//             padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             // padding: 0px 0px 0px 10px;
//             font-size: 14px;
//             // width: 40%;
//             text-align: center;

//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;
//               width: 100%;
//               text-align: left;
//             }

//             a {
//               display: block;
//               color: #000;
//               align-items: center;
//               text-decoration: none;
//               font-size: 16px;

//               @include mobile {
//                 color: #ffffff;
//                 display: flex;
//               }

//               .anticon {
//                 margin-left: 8px;
//               }
//             }

//             span {
//               font-size: 14px;
//               color: #000000;

//               @include mobile {
//                 color: #ffffff;
//               }
//             }
//           }

//           .custom-drop-down {
//             position: initial;

//             a {
//               color: #fff;
//             }

//             .dropdown-item {
//               color: #000 !important;
//             }
//           }
//         }

//         @media screen and (max-width: 768px) {
//           flex-flow: column;
//           width: 100%;
//           text-align: left;
//         }
//       }
//     }

//     .header li a:hover,
//     .header .menu-btn:hover {
//       color: #bcd5f5;
//       text-decoration: none !important;
//       outline: none !important;
//     }

//     /* menu */
//     .header .menu {
//       max-height: 0;
//       transition: max-height 0.2s ease-out;
//     }

//     /* menu icon */
//     .header {
//       .menu-icon {
//         cursor: pointer;
//         display: inline-block;
//         padding: 28px 20px;
//         position: relative;
//         user-select: none;

//         @include mobile {
//           padding: 28px 0 28px 20px;
//         }

//         .navicon {
//           background: #fff;
//           display: block;
//           height: 1px;
//           position: relative;
//           transition: background 0.2s ease-out;
//           width: 18px;
//         }
//       }

//       .user-icon {
//         display: flex;
//         align-items: center;

//         i {
//           margin-right: 20px;
//           font-size: 20px;
//           color: #fff;
//         }

//         .menu-icon {
//           margin-bottom: 0;
//           padding: 22px 2px 28px 2px;
//           margin-left: 18px;
//         }

//         .menu-text {
//           color: #fff;
//           font-size: 10px;
//           position: absolute;
//           top: 30px;
//           left: -2px;
//         }
//       }
//     }

//     .header .menu-icon .navicon:before,
//     .header .menu-icon .navicon:after {
//       background: #fff;
//       content: "";
//       display: block;
//       height: 100%;
//       position: absolute;
//       transition: all 0.2s ease-out;
//       width: 100%;
//     }

//     .header .menu-icon .navicon:before {
//       top: 5px;
//     }

//     .header .menu-icon .navicon:after {
//       top: -5px;
//     }

//     /* menu btn */
//     .header .menu-btn {
//       display: none;
//     }

//     .header .menu-btn:checked~.menu {
//       max-height: 430px;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon {
//       background: transparent;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:before {
//       transform: rotate(-45deg);
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:after {
//       transform: rotate(45deg);
//     }

//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
//       top: 0;
//     }

//     .iconText {
//       margin-left: 5px;
//     }

//     .service-links {
//       align-items: center;

//       .nav-link {
//         color: white !important;
//         font-size: 16px;

//         i {
//           margin-left: 5px;
//           font-size: 15px;
//         }
//       }
//     }

//     @media (min-width: 992px) {

//       // .header li {
//       //   // float: left;
//       // }
//       // .header li a {
//       //   // padding: 23px 30px;
//       // }
//       .header .menu {
//         max-height: none !important;
//       }

//       .header .menu-icon {
//         display: none;
//       }
//     }

//     @media screen and (max-width: 768px) {
//       .header {
//         padding: 0 15px;
//         display: block;
//         // position: fixed;
//         // top: 0;
//         // left: 0;
//         // right: 0;
//         // background: #00164d;
//         background-color: #363a45;
//         // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
//         // border-bottom: 0px solid #ffffff;

//         a {
//           img {
//             margin-top: 5px;
//           }
//         }
//       }
//     }
//   }
// }

// .header-container-relative {
//   background: #ffffff;
//   box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
//   // position: fixed;
//   // z-index: 70;
//   width: 100%;

//   @include mobile {
//     background-color: transparent;
//   }

//   .wrapper {
//     max-width: 1240px;
//     background-color: transparent;
//     margin: 0 auto;
//     padding-left: 15px;
//     padding-right: 15px;

//     @include mobile {
//       width: 100%;
//     }

//     .header {
//       width: 100%;
//       background-color: transparent;
//       display: flex;
//       padding-bottom: 3px;

//       .app-logo {
//         .company-logo {
//           height: 100%;
//           display: flex;
//           align-items: center;
//           justify-content: flex-end;
//           padding: 0px 0;

//           //max-width: 85px;
//           @include mobile {
//             justify-content: start;
//             max-width: 80px;
//           }

//           @include smmobile {
//             max-width: 70px;
//           }

//           img {
//             height: 34px;
//             width: auto;
//             margin-top: -3px;
//             margin-bottom: 7px;
//           }
//         }

//         @include mobile {
//           // width: initial;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin: 0;
//         }
//       }

//       .app-links {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         padding-left: 12px;

//         @include mobile {
//           align-items: flex-start;
//           display: none;

//         }

//         .menu1 {
//           // border: 2px solid red !important;
//           width: 30%;
//           // border-left: 1px solid rgb(176, 176, 176);

//           @include tab {
//             width: 35%;
//             // border: none;
//             position: relative;
//             top: 4%;
//             right: 5%;
//             border: none;
//           }

//           @include mobile {
//             display: block;
//           }

//           .currency-text {
//             .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//               background-color: transparent !important;
//             }
//           }

//           .goog-te-combo {
//             background-color: transparent !important;
//             border: none !important;
//             color: white !important;
//           }

//           li {
//             // padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             padding: 5px 0px 0px 10px;
//             font-size: 14px;
//             width: 50%;

//             @include tab {
//               width: 44%;
//             }

//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;

//             }
//           }

//         }

//         ul {
//           display: flex;

//           padding: 0;
//           list-style: none;
//           overflow: hidden;
//           margin-left: auto;

//           @include mobile {
//             display: block;
//           }

//           li {
//             padding: 15px;
//             display: flex;
//             align-items: center;
//             height: 100%;
//             border-right: 0px solid #c7c7c7;
//             // padding: 0px 0px 0px 10px;
//             font-size: 14px;
//             // width: 40%;
//             text-align: center;

//             @include mobile {
//               padding: 4px 0;
//               border-right: 0px solid #c7c7c7;
//               width: 100%;
//               text-align: left;
//             }

//             a {
//               display: block;
//               color: #ffffff;
//               align-items: center;
//               text-decoration: none;
//               font-size: 14px;

//               @include mobile {
//                 color: #ffffff;
//                 display: flex;
//               }

//               .anticon {
//                 margin-left: 8px;
//               }
//             }

//             span {
//               font-size: 14px;
//               color: #000000;

//               @include mobile {
//                 color: #ffffff;
//               }
//             }
//           }

//           .custom-drop-down {
//             position: initial;

//             a {
//               color: #fff;
//             }

//             .dropdown-item {
//               color: #000 !important;
//             }
//           }
//         }

//         @media screen and (max-width: 768px) {
//           flex-flow: column;
//           width: 100%;
//           text-align: left;
//         }
//       }
//     }

//     .header li a:hover,
//     .header .menu-btn:hover {
//       color: #bcd5f5;
//       text-decoration: none !important;
//       outline: none !important;
//     }

//     /* menu */
//     .header .menu {
//       max-height: 0;
//       transition: max-height 0.2s ease-out;
//     }

//     /* menu icon */
//     .header {
//       .menu-icon {
//         cursor: pointer;
//         display: inline-block;
//         padding: 28px 20px;
//         position: relative;
//         user-select: none;

//         @include mobile {
//           padding: 28px 0 28px 20px;
//         }

//         .navicon {
//           background: #fff;
//           display: block;
//           height: 1px;
//           position: relative;
//           transition: background 0.2s ease-out;
//           width: 18px;
//         }
//       }

//       .user-icon {
//         display: flex;
//         align-items: center;

//         i {
//           margin-right: 20px;
//           font-size: 20px;
//           color: #fff;
//         }

//         .menu-icon {
//           margin-bottom: 0;
//           padding: 22px 2px 28px 2px;
//           margin-left: 18px;
//         }

//         .menu-text {
//           color: #fff;
//           font-size: 10px;
//           position: absolute;
//           top: 30px;
//           left: -2px;
//         }
//       }
//     }

//     .header .menu-icon .navicon:before,
//     .header .menu-icon .navicon:after {
//       background: #fff;
//       content: "";
//       display: block;
//       height: 100%;
//       position: absolute;
//       transition: all 0.2s ease-out;
//       width: 100%;
//     }

//     .header .menu-icon .navicon:before {
//       top: 5px;
//     }

//     .header .menu-icon .navicon:after {
//       top: -5px;
//     }

//     /* menu btn */
//     .header .menu-btn {
//       display: none;
//     }

//     .header .menu-btn:checked~.menu {
//       max-height: 430px;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon {
//       background: transparent;
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:before {
//       transform: rotate(-45deg);
//     }

//     .header .menu-btn:checked~.menu-icon .navicon:after {
//       transform: rotate(45deg);
//     }

//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
//     .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
//       top: 0;
//     }

//     .iconText {
//       margin-left: 5px;
//     }

//     .service-links {
//       align-items: center;

//       .nav-link {
//         color: white !important;
//         font-size: 16px;

//         i {
//           margin-left: 5px;
//           font-size: 15px;
//         }
//       }
//     }

//     @media (min-width: 992px) {

//       // .header li {
//       //   // float: left;
//       // }
//       // .header li a {
//       //   // padding: 23px 30px;
//       // }
//       .header .menu {
//         max-height: none !important;
//       }

//       .header .menu-icon {
//         display: none;
//       }
//     }

//     @media screen and (max-width: 768px) {
//       .header {
//         padding: 0 15px;
//         display: block;
//         position: fixed;
//         top: 0;
//         left: 0;
//         right: 0;
//         // background: #00164d;
//         background-color: #2f81ed;
//         background-image: linear-gradient(to right, #2f80ed, #56ccf2);
//         border-bottom: 0px solid #ffffff;

//         a {
//           img {
//             margin-top: 5px;
//           }
//         }
//       }
//     }
//   }
// }

// .dashboard-menu {
//   max-width: 300px;
// }

// .menu {
//   align-items: center;

//   .currency-text {
//     .ant-select-selector {
//       background-color: transparent !important;
//       border: none !important;
//       box-shadow: none !important;
//     }

//     .ant-select-arrow {
//       display: flex;
//       align-items: center;
//     }
//   }
// }

// .curreny-dropdown {
//   padding: 20px 0 20px 30px;
// }

// .ant-select-dropdown {
//   z-index: 9999999999 !important;
// }

// .login-modal {
//   width: 700px !important;

//   .ant-modal-close-x {
//     color: #000 !important;
//     font-size: 20px !important;
//     line-height: 30px !important;
//   }

//   .ant-modal-footer {
//     display: none;
//   }

//   .close-modal-icon {
//     padding-bottom: 15px;
//     font-size: 20px;
//     color: #000;
//     text-align: end;
//   }

//   .ant-form-item {
//     .ant-form-item-control {
//       min-width: 100%;
//     }
//   }

//   .ant-input-affix-wrapper::before {
//     display: none;
//   }

//   .ant-form {
//     padding: 10px 10px;

//     @include mobile {
//       padding: 10;
//     }
//   }

//   .login-text {
//     text-align: center;
//   }

//   .forgot-text {
//     font-size: 14px;
//     color: #2e0000;
//     text-align: center;
//     cursor: pointer;

//     &:hover {
//       text-decoration: underline;
//     }
//   }

//   .ant-col {
//     margin-left: 0 !important;
//     text-align: center;

//     button {
//       width: 100%;
//       font-size: 18px;
//       font-weight: bold;
//     }
//   }

//   .login-alternatives {
//     display: flex;
//     margin: 15px 0;

//     .fb-btn {
//       width: 50%;
//       text-align: center;
//       padding: 10px 0;
//       box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
//       margin-right: 10px;

//       p {
//         margin-bottom: 0;
//       }

//       img {
//         height: 20px;
//         text-align: center;
//       }
//     }

//     .google-btn {
//       width: 50%;
//       text-align: center;
//       padding: 10px 0;
//       box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);

//       p {
//         margin-bottom: 0;
//       }

//       img {
//         height: 20px;
//         text-align: center;
//       }
//     }
//   }

//   .or-text {
//     p {
//       font-size: 14px;
//       color: #000;
//       text-align: center;
//     }
//   }
// }

// @include mobile {
//   .mob-none {
//     //  display: none !important;
//   }

//   .company-logo img {
//     margin-top: 4px !important;
//     height: 20px !important;
//   }

//   .tab-content ul li {
//     display: inline-grid !important;
//   }

//   .login-register-mob {
//     display: none !important;
//   }
// }

// .mobile-user {
//   color: #000000;
//   font-size: 20px;

//   .mobile-user-icon {
//     display: flex;
//     align-items: center;

//     .anticon {
//       vertical-align: middle !important;
//       padding: 0 5px;
//       margin-right: 5px;
//     }
//   }
// }

// .responive-more {
//   li {
//     a {
//       font-size: 16px;
//       margin-left: 5px;
//       text-decoration: none;
//     }
//   }
// }

// .supportvalue {
//   display: block;
//   text-decoration: none;
//   font-size: 16px;
//   color: #fff !important;
// }

// .cre-amount {
//   color: red !important;
//   font-weight: 600;
// }

// .cre-amount-1 {
//   font-weight: 600;
// }

// .cre-amount-14 {
//   font-weight: 600;
//   background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
//   color: #fff !important;
// }

// /*css--added*/
// .all-top-menu {
//   max-height: 0px;
//   display: flex;
//   float: right;
//   padding-top: 2px;
//   padding-bottom: 25px;
// }

// .all-top-menu li {
//   padding: 0px 5px;
//   font-size: 15px;
// }

// .last-mob-bdr .ant-select-selector {
//   border: 0px solid #d9d9d9 !important;
// }

// .cart-nav-top {
//   padding-top: 0px !important;
// }

// .cart-nav-top a {
//   color: #ffffff !important;
// }

// .menu-bdr-nav .ant-dropdown-menu-item {
//   border-bottom: 1px solid #000;

//   .ant-dropdown-menu-title-content {
//     div {
//       &:hover {
//         color: #bd0c21;
//       }
//     }
//   }
// }

// .balannce-top-ss {
//   padding-top: 4px !important;
// }

// @media screen and (max-width: 768px) {
//   .currency-panel-text {
//     position: absolute;
//     /* z-index: 10; */
//     top: -173px;
//     right: 84px;
//   }

//   .nav-itmes-target {
//     padding: 0px 10px 0px 0px;
//   }

//   #only-mob-user {
//     display: block !important;
//   }

//   .currency-panel-text .ant-select-selector {
//     padding: 0px 4px !important;
//   }
// }

// #only-mob-user {
//   display: none;
// }

// .nav-itmes-target {
//   display: grid !important;
//   text-align: center;

//   @media mobile {
//     display: flex !important;
//   }
// }

// .delas-taget-flight {
//   display: none !important;
// }

// #delas-taget-flight {
//   display: none !important;
// }

// .border-bottom-1 {
//   border-bottom: 1px solid !important;
// }

// .Mega-Menus {
//   .ant-dropdown-menu-item {
//     font-size: 13px !important;
//   }

//   .ant-dropdown-menu-item-group-title {
//     color: #320063;
//     font-weight: 600;
//     font-size: 15px;
//   }

//   ul.ant-dropdown-menu-item-group-list {
//     text-transform: capitalize;
//   }
// }

// .goog-te-gadget .goog-te-combo {
//   border: 1px solid transparent;
//   background-color: transparent;
//   color: rgb(255, 255, 255);

//   option {
//     background-color: #320066;
//   }
// }

// .goog-te-gadget .goog-te-combo:focus {
//   border: 1px solid transparent;
//   background-color: transparent;

// }



// // .selectedNav{
// //  }
// .selectedNav:hover {
//   // border: 2px solid red;
//   color: white;
// }

// .hover-for-all {
//   // border: 1px solid grey;
//   border-radius: 25px;

//   @include mobile {
//     border: 1px solid transparent;
//   }
// }

// .hover-for-all:hover {
//   // border: 1px solid #320063;
// }

// .more-css-class {
//   border: 1px solid grey;
//   border-radius: 25px;
//   text-align: center;
//   margin-left: 1%;

//   @include mobile {
//     border: none;
//     margin-left: 7%;
//   }
// }

// .border-of-options {
//   border: 1px solid grey;
//   border-radius: 25px;
//   text-align: center;
// }

// .hovereffectdrpdwn {
//   background-color: white;
// }

// .hovereffectdrpdwn:hover {
//   background-color: #d2e7ff;
//   // color: white;
//   // border: 1px solid #340167;
//   // border-left: 8px solid #340167;
// }

// .main-header-upper {
//   position: fixed;
//   top: 0;
//   z-index: 70;
//   width: 100%;
// }

// .header-cont-detal {
//   // border: 2px solid transparent;
//   // background-color: #2e0000;
//   background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
//   padding: 0.5%;

//   @include mobile {
//     background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
//   }
// }

// .header-cont-detal1 {
//   width: 95%;
//   margin: auto;
//   border: 2px solid transparent;
//   background-color: transparent;
//   text-align: end;
//   display: flex;

//   @include desktop {
//     width: 100%;
//   }

//   @include tab {
//     width: 100%;
//   }

//   @include mobile {
//     background-color: #00f1bd;
//     text-align: center;
//     width: 100%;
//   }
// }

// .last-mob-bdr {
//   .flag-select-items {
//     .ant-select-selector {
//       background-color: transparent;
//     }
//   }
// }

// .header-wid-logo-one-main {
//   // padding: 0.7%;
//   background-color: #ffffff;
//   display: block;
//   border-bottom: 1px solid white;

//   @include mobile {

//     display: none;
//   }
// }

// .header-wid-logo-one {
//   border: 2px solid transparent;
//   justify-content: space-evenly;
//   display: flex;
//   flex-wrap: wrap;
//   width: 95%;
//   margin: auto;
//   background-color: transparent;

//   @include desktop {
//     width: 100%;
//   }

//   @include tab {
//     width: 100%;
//   }

//   @include mobile {
//     width: 100%;
//   }
// }

// .header-wid-logo-one1 {
//   border: 1px solid transparent;
//   width: 20%;
// }

// .header-wid-logo-one2 {
//   border: 2px solid transparent;
//   width: 38%;
//   padding: 0.4%;

//   .ant-input-group .ant-input {
//     height: 38px;
//     // margin-left: 10%;
//   }

//   .ant-input-group-addon {
//     .ant-input-search-button {
//       border: 2px solid transparent;
//       height: 38px;
//       //  margin-left: -1900%;
//     }
//   }
// }

// .header-wid-logo-one3 {
//   border: 1px solid transparent;
//   width: 80%;
//   padding: 1%;
//   text-align: end;
// }


// // mobile work


// .header-wid-logo-one-main1-pho {
//   padding: 0.7%;
//   background-color: white;
//   display: none;

//   @include mobile {
//     border: 2px solid transparent;
//     display: block;
//     z-index: 11111;
//   }
// }

// .header-wid-logo-one-pho {
//   @include mobile {

//     border: 2px solid transparent;
//     justify-content: space-evenly;
//     display: flex;
//     flex-wrap: wrap;
//     width: 100%;
//     margin: auto;
//     background-color: white;
//   }
// }

// .header-wid-logo-one1-pho {
//   border: 1px solid transparent;

//   @include mobile {
//     width: 40%;

//     img {
//       width: 100%;
//     }
//   }
// }

// .header-wid-logo-one2-pho {
//   @include mobile {
//     border: 2px solid transparent;
//     padding: 0.4%;
//     width: 95%;
//     margin-top: 4%;
//   }


//   .ant-input-group .ant-input {
//     height: 38px;
//     // margin-left: 10%;
//   }

//   .ant-input-group-addon {
//     .ant-input-search-button {
//       border: 2px solid transparent;
//       height: 38px;
//       //  margin-left: -1900%;
//     }
//   }


// }

// .header-wid-logo-one3-pho {
//   @include mobile {
//     width: 40%;
//     border: 1px solid transparent;
//     padding: 1%;
//     text-align: end;
//     justify-content: space-between;
//     display: flex;
//   }
// }

// .menu-head-set {
//   @include mobile {
//     width: 10%;
//   }
// }

// .trip-mar-ri {
//   padding-right: 15%;

//   @include mobile {
//     padding-right: 20%;
//   }
// }

// // .fa-user{
// //   border: 1px solid black;
// //   text-align: center;
// //   padding: 1%;
// //   border-radius: 50%;
// // }
// .menu-nav-icons {
//   width: 35px;
//   height: 5px;
//   background-color: black;
//   margin: 6px 0;
// }




// .scrollable-tabs-container1 {
//   width: 1152px;
//   margin: auto;
//   border: 1px solid transparent;
//   display: none;

//   @include desktop {
//     width: 100%;
//     display: none;
//   }

//   @include tab {
//     width: 100%;
//     //display: block;
//   }

//   @include mobile {
//     width: 100%;
//     display: block;
//   }

//   ul {
//     li {
//       span {
//         font-size: 17px;
//       }
//     }
//   }
// }

// .scrollable-tabs-container {
//   // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
//   // background-color: rgb(219, 216, 216);
//   background-color: white;
//   width: 100%;
//   border-radius: 4px;
//   overflow: hidden;
//   position: relative;
//   margin-top: 10%;
//   padding-bottom: 0%;

//   @include desktop {
//     margin-top: 11.5%;
//   }

//   @include tab {
//     margin-top: 17%;
//   }

//   @include mobile {
//     margin-top: 42%;
//   }

//   @include smmobile {
//     margin-top: 43%;

//   }

//   @include xsmobile {
//     margin-top: 57%;
//   }
// }

// .scrollable-tabs-container svg {
//   width: 20px;
//   height: 20px;
//   padding: auto;
//   cursor: pointer;
//   border-radius: 50%;

// }

// .scrollable-tabs-container ul {
//   display: flex;
//   gap: 6px;
//   // padding: 6px 12px;
//   margin: 0;
//   list-style: none;
//   overflow-x: scroll;
//   scroll-behavior: smooth;


// }



// .scrollable-tabs-container ul::-webkit-scrollbar {
//   display: none;
// }

// .scrollable-tabs-container a {
//   color: rgb(0, 0, 0);
//   text-decoration: none;
//   background-color: rgb(255, 255, 255);
//   padding: 4px 20px;
//   display: inline-block;
//   border-radius: 4px;
//   white-space: nowrap;
//   // / width: 130px; /

// }

// .scrollable-tabs-container a.active {
//   // background: #2e0000;
//   // color: rgb(250, 248, 248);
//   color: black;
//   border-bottom: 5px solid black;
//   background-color: #f0f0f0;
// }

// .scrollable-tabs-container .right-arrow,
// .scrollable-tabs-container .left-arrow {
//   position: absolute;
//   height: 60px;
//   width: 60px;
//   top: 0;
//   display: none;
//   align-items: center;
//   padding: 0px 10px;

// }

// .scrollable-tabs-container .right-arrow.active,
// .scrollable-tabs-container .left-arrow.active {
//   display: flex;
// }

// .scrollable-tabs-container .right-arrow {
//   right: 0;
//   background: linear-gradient(to left, rgb(35, 33, 33) 1%, transparent);
//   justify-content: flex-end;
// }

// .scrollable-tabs-container .left-arrow {
//   background: linear-gradient(to right, rgb(35, 33, 33) 1%, transparent);
// }

// .scrollable-tabs-container svg:hover {
//   background: #dcd9d9;
// }

// .signin-button-navbar {
//   padding: 1%;
//   background-color: transparent;
//   color: rgb(0, 0, 0);
//   font-size: 14px;
//   font-weight: 600;
//   border-radius: 5px;
//   border: 2px solid rgb(0, 0, 0);
//   padding: 1%;

//   @include mobile {
//     margin-top: -5px;
//     border: 2px solid transparent;
//   }
// }

// .signin-button-navbar:hover {
//   background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
//   color: rgb(0, 0, 0);
//   border: 2px solid rgb(0, 0, 0);

//   @include mobile {
//     background-image: none;
//     margin-top: -5px;
//     border: 2px solid transparent;
//   }
// }

// .main-header-upper {
//   span {
//     font-size: 17px;
//   }

// }

// @media screen and(max-width:768px) {
//   .main-header-upper {
//     span {
//       font-size: 13px;
//     }

//   }
// }

// @media screen and(max-width:820px) {
//   .main-header-upper {
//     span {
//       font-size: 13px;
//     }

//   }
// }

// .modal-head-searchbar-mob-pcbtn {
//   display: block;
//   border: 1px solid rgb(212, 212, 212);
//   width: 100%;
//   background-color: white;
//   text-align: left;
//   padding-left: 4%;
//   height: 44px;
//   margin-top: -2%;
//   padding-top: 2%;

//   @include mobile {
//     display: none;
//   }

//   span {
//     color: black;
//   }
// }

// .modal-head-searchbar-mob-pcbtn1 {
//   display: none;
//   border: 1px solid rgb(212, 212, 212);
//   width: 100%;
//   background-color: white;
//   text-align: left;
//   padding-left: 4%;
//   height: 44px;
//   padding-top: 2%;

//   @include mobile {
//     display: block;
//   }

//   span {
//     color: black;
//   }
// }

// .modal-head-searchbar-mob-pc {
//   height: 200px;
// }

// .modal-head-searchbar-mob-pc1 {
//   height: 1000px;
// }

// .loginnav-sub-menu-links {
//   width: 200%;
//   position: absolute;
//   left: -10%;
//   background-color: transparent;
//   padding-top: 8%;

//   @include tab {
//     left: -128%;
//   }

//   @include minitab {
//     left: -128%;
//   }


//   @include mobile {
//     width: 520%;
//     left: -280%;
//   }

// }

// //  .ant-dropdown-menu{
// //   position: fixed;
// //  }
// // .rc-virtual-list{
// //   border: 2px solid red;
// //   overflow: hidden;
// // }

// .main-header-upper {
//   .header-wid-logo-one-main {
//     .header-wid-logo-one2 {
//       .modal-head-searchbar-mob-pcbtn {
//         .anticon svg {
//           margin-top: -35% !important;
//           margin-left: 0% !important;
//         }
//       }
//     }
//   }

//   .header-wid-logo-one-main1-pho {
//     .header-wid-logo-one-pho {
//       .header-wid-logo-one2-pho {
//         .modal-head-searchbar-mob-pcbtn1 {
//           .anticon svg {
//             margin-top: -35% !important;
//             margin-left: 0% !important;
//           }
//         }
//       }
//     }
//   }
// }

// .for-elepsis-work-dropdownflight {
//   white-space: nowrap;
//   width: 180px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   // border: 1px solid #000000;
//   margin-left: 15% !important;

//   @include tab {
//     margin-left: 23% !important;
//   }

//   @include minitab {
//     margin-left: 26% !important;
//   }

// }

.forplace-wor-dropdownflight {
  position: absolute;
  font-size: 20px;
  color: #5969c7;
  // margin-right: 5px;
 // top: 38%;
  // padding-left: 10px;
}

// .for-elepsis-work-dropdownhotels {
//   white-space: nowrap;
//   width: 228px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   // border: 1px solid #000000;
//   margin-left: 13%;

//   @include tab {
//     margin-left: 19%;
//   }

//   @include minitab {
//     margin-left: 18%;
//   }

//   @include mobile {
//     width: 240px;
//   }
// }

// .checked11 {
//   color: rgb(255, 136, 0);

// }

// .allstarsame {
//   margin-left: 0.2%;
//   margin-right: 0.2%;

// }

// .top-nav-sec {
//   width: 60%;

//   span {
//     color: white;

//     a {
//       color: white;
//       margin-left: 1%;
//       margin-right: 1%;
//       font-size: 20px;
//     }
//   }
// }

// .top-nav-sec1 {
//   border: 2px solid transparent;
//   width: 40%;
//   text-align: left;

//   span {
//     color: white;
//   }
// }

// .flag-main-span {
//   .ant-select-selector {
//     span {
//       color: rgb(0, 0, 0);
//     }
//   }
// }

// .menu-work-css-allser {
//   border: 2px solid transparent;
//   justify-content: space-evenly;
//   display: flex;
//   flex-wrap: wrap;
//   width: 70%;
//   font-size: 17px;

//   @include tab {
//     width: 70%;
//     font-size: 14px;
//   }

//   li {
//     span {
//       color: rgb(0, 0, 0);
//     }
//   }
// }
@import "../../assets/variableStyles.scss";

.currency-flag-img {
  max-width: 15px;
  max-height: 12px;
  margin-right: 10px;
 
}

.selectedNav {
  color: #007bff !important;
  //border-bottom: 2px solid #bd0c21;
  font-weight: 600;
}

.nav-wallet-wrapper {
  padding: 5px 12px;

  .anticon {
    font-size: 16px;
  }
}

.my-acc-dropdown {
  padding-top: 5%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  cursor: pointer;


  @include tab {
    padding-top: 7%;
  }

  @include mobile {
    padding-top: 0%;
  }
}

.user-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .anticon {
    margin: 0 5px;
  }
}

.header-bg {
  background-color: #00164d;
}

.header-containertrans-nor {
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 70;
  width: 100%;
  padding-bottom: 5px;

  @include minitab {
    height: 65px;
  }

  @include mobile {
    background-color: transparent;
    box-shadow: none;
  }

  .wrapper {
    max-width: 1240px;
    background-color: transparent;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .header {
      width: 100%;
      background-color: transparent;
      display: flex;
      padding-bottom: 3px;
      max-height: 50px;


      .app-logo {
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;

          //max-width: 85px;
          @include minitab {
            // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
            background-color: transparent;
            justify-content: flex-start;

          }

          @include mobile {
            justify-content: start;
            max-width: 80px;
            background-image: linear-gradient(to right, #2f81ed00, #56cbf200);
          }

          @include smmobile {
            max-width: 70px;
          }

          img {
            height: 27px;
            width: auto;
            margin-top: -3px;
            margin-bottom: 7px;
            @include tab {
              height: 25px;
              margin:3px;
            }
          }
        }

        @include mobile {
          // width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }


      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @include tab {
          display: block;
        }

        @include mobile {
          align-items: flex-start;
        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          // border-left: 1px solid rgb(176, 176, 176);

          @include tab {
            width: 25%;
            // border: none;
            position: relative;
            top: 4%;
            right: 12%;
            border: none;
          }

          @include mobile {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
              border: 1px solid #ec8333;
              border-radius: 25px;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 14px;
            width: 50%;

            @include tab {
              width: 44%;
            }

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          //list-style: none;
          // overflow: hidden;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @include tab {
            width: 54%;
          }

          @include mobile {
            display: block;
          }

          li {
            padding: 6px;
            display: flex;
            align-items: center;
            height: 100%;
            //border-right: 0px solid #c7c7c7;
           //padding: 0px 5px 0px 50px;
            font-size: 18px;
            width: -1%;
            justify-content: space-evenly;

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 16px;
              // box-shadow: 1px 1px 2px #bd0c21;
             // border: 1px solid grey;
             // border-radius: 6px;
             // padding: 1px 10px;

              // border-radius: 4px;
              &:hover {
                color: #35459c;
                //box-shadow: 1px 2px 4px #35459c;
              }

              @include mobile {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }
              @include tab{
                font-size: 12px;
              }
              @include minitab{
                font-size: 10px;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 14px;
              color: #bd0c21;

              @include mobile {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @include tab {
          display: flex;
          flex-flow: inherit;
          margin-left: 4%;
        }

        @include minitab {

          margin-left: 7%;
          margin-top: -7%;
          margin-bottom: -8%;
        }

        @include mobile {
          margin-left: -14px;
          margin-top: -3%;
          // background-image: linear-gradient(to right, #bd0c21de, #35459ccc);
          background-color: white;
          width: 108%;
          padding-top: 8%;
        }
      }


      .app-links1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @include tab {
          display: block;
        }

        @include mobile {
          align-items: flex-start;

        }

        .menu1 {
          // border: 2px solid red !important;
          width: 25%;
          // border-left: 1px solid rgb(176, 176, 176);
          display: flex;
          justify-content: space-around;

          @include tab {
            width: 25%;
            // border: none;
            position: relative;
            top: 4%;
            right: 10%;
            border: none;
          }

          @include mobile {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 14px;
            width: 50%;

            @include tab {
              width: 44%;
            }

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;
          align-items: center;

          padding: 0;
          //list-style: none;
          // overflow: hidden;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @include tab {
            width: 54%;
          }

          @include mobile {
            display: block;
          }

          li {
            padding: 0px;
            display: flex;
            align-items: center;
            height: 100%;
            //border-right: 0px solid #c7c7c7;
            //padding: 0px 5px 0px 50px;
            font-size: 14px;
            //width: 18%;
            justify-content: space-evenly;

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 16px;
              // box-shadow: 1px 1px 2px #bd0c21;
              //border: 1px solid grey;
              //border-radius: 6px;
              padding: 2px 7px;

              // border-radius: 4px;
              &:hover {
                color: #35459c;
               // box-shadow: 1px 2px 4px #35459c;
              }
              @include ipadpro{
                font-size: 12px;
              }
              @include mobile {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
                font-size: 11px;
              }
             
               @include tab{
                font-size: 11px;
               }
               @include minitab{
                font-size: 11px;
               }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 16px;
              color: #000;
              @include ipadpro {
                font-size: 13px;
              }
              @include mobile {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @include tab {
          display: flex;
          flex-flow: inherit;
          position: absolute;
          top: 96%;
          background-color: white;
          left: 0%;
          padding-bottom: 1%;
          padding-left: 70px;
          
        }

        @include minitab {

          margin-left: 0%;
          margin-top: 1.5%;
          padding-bottom: 1%;
        }

        @include mobile {
          margin-left: 0px;
          margin-top: 0%;
          // background-image: linear-gradient(to right, #bd0c21de, #35459ccc);
          background-color: white;
          width: 108%;


        }
      }

    }

    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;

        @include mobile {
          padding: 28px 0 28px 20px;
        }

        .navicon {
          background: #000000;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;

          @include tab {
            background: transparent;
          }

          @include minitab {
            background: transparent;
          }

          @include mobile {
            background: #bd0c21;
          }
        }
      }

      .user-icon {
        display: flex;
        align-items: center;

        i {
          margin-right: 20px;
          font-size: 20px;
          color: #fff;
        }

        .menu-icon {
          margin-bottom: 0;
          padding: 22px 2px 28px 2px;
          margin-left: 18px;
        }

        .menu-text {
          color: #000000;
          font-size: 10px;
          position: absolute;
          top: 30px;
          left: -2px;
        }
      }
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #000000;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;

      @include tab {
        background: transparent;
      }

      @include minitab {
        background: transparent;
      }

      @include mobile {
        background: #bd0c21;
      }
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked~.menu {
      max-height: 430px;
    }

    .header .menu-btn:checked~.menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked~.menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked~.menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
      top: 0;
    }

    .iconText {
      margin-left: 5px;
    }

    .service-links {
      align-items: center;

      .nav-link {
        color: white !important;
        font-size: 16px;

        i {
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }

    @media (min-width: 992px) {

      // .header li {
      //   // float: left;
      // }
      // .header li a {
      //   // padding: 23px 30px;
      // }
      .header .menu {
        max-height: none !important;
      }

      .header .menu-icon {
        display: none;
      }
    }

    .header {
      @include minitab {
        padding: 0 0px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;

        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .header {
        padding: 0 15px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;


        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }

}

.header-container {
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 70;
  width: 100%;
  padding-bottom: 5px;

  @include minitab {
    height: 65px;
  }

  @include mobile {
    background-color: transparent;
    box-shadow: none;

  }

  .wrapper {
    max-width: 1240px;
    background-color: transparent;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .header {
      width: 100%;
      background-color: transparent;
      display: flex;
      padding-bottom: 3px;
      max-height: 50px;


      .app-logo {
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;

          //max-width: 85px;
          @include minitab {
            // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
            background-color: transparent;
            justify-content: flex-start;

          }

          @include mobile {
            justify-content: start;
            max-width: 80px;
            background-image: linear-gradient(to right, #2f81ed00, #56cbf200);
          }

          @include smmobile {
            max-width: 70px;
          }

          img {
            height: 70px;
            width: auto;
            margin-top: -3px;
            margin-bottom: 7px;

            @include tab{
              height: 40px;
            }
            @include minitab{
              height: 40px;
            }
          }
        }

        @include mobile {
          // width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }


      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @include tab {
          display: block;
        }

        @include mobile {
          align-items: flex-start;
        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          // border-left: 1px solid rgb(176, 176, 176);
          display: flex;
          justify-content: space-around;

          @include tab {
            width: 35%;
            // border: none;
            position: relative;
            top: 4%;
            right: 5%;
            border: none;
          }

          @include mobile {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
           // border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 10px;
            width: 50%;

            @include tab {
              width: 44%;
            }

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
          //list-style: none;
          // overflow: hidden;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @include tab {
            width: 54%;
          }

          @include mobile {
            display: block;
          }

          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            width: 18%;
            justify-content: space-evenly;

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 14px;
              // box-shadow: 1px 1px 2px #bd0c21;
              //border: 1px solid grey;
             // border-radius: 6px;
              padding: 1px 10px;

              // border-radius: 4px;
              &:hover {
                color: #35459c;
               // box-shadow: 1px 2px 4px #35459c;
              }

              @include mobile {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 14px;
              color: #bd0c21;

              @include mobile {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @include tab {
          display: flex;
          flex-flow: inherit;
          margin-left: -4%;
        }

        @include minitab {

          margin-left: 7%;
          margin-top: -12.5%;
        }

        @include mobile {
          margin-left: -14px;
          margin-top: -3%;
          // background-image: linear-gradient(to right, #bd0c21de, #35459ccc);
          background-color: white;
          width: 108%;
          padding-top: 8%;
        }
      }


      .app-links1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;

        @include tab {
          display: block;
        }

        @include mobile {
          align-items: flex-start;

        }

        .menu1 {
          // border: 2px solid red !important;
          width: 30%;
          // border-left: 1px solid rgb(176, 176, 176);

          @include tab {
            width: 35%;
            // border: none;
            position: relative;
            top: 4%;
            right: 9%;
            border: none;
          }

          @include mobile {
            display: block;
          }

          .currency-text {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              background-color: transparent !important;
            }
          }

          .goog-te-combo {
            background-color: transparent !important;
            border: none !important;
            color: white !important;
          }

          li {
            // padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 5px 0px 0px 10px;
            font-size: 14px;
            width: 50%;

            @include tab {
              width: 44%;
            }

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;

            }
          }

        }

        ul {
          display: flex;
          justify-content: space-around;

          padding: 0;
         // list-style: none;
          // overflow: hidden;
          margin-left: auto;
          // width: 60%;
          // width: 30%;

          @include tab {
            width: 54%;
          }

          @include mobile {
            display: block;
          }

          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 0px solid #c7c7c7;
            padding: 0px 5px 0px 50px;
            font-size: 14px;
            width: 18%;
            justify-content: space-evenly;

            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
              width: 100%;
              text-align: left;
              justify-content: left;

            }

            a {
              color: #444444;
              align-items: center;
              text-decoration: none;
              font-size: 16px;
              // box-shadow: 1px 1px 2px #bd0c21;
              // border: 1px solid grey;
              // border-radius: 6px;
              padding: 1px 10px;

              // border-radius: 4px;
              &:hover {
                color: #35459c;
               // box-shadow: 1px 2px 4px #35459c;
              }

              @include mobile {
                color: #000000;
                display: flex;
                box-shadow: none;
                border: none;
                border-radius: 0px;
                padding: 0;
              }

              .anticon {
                margin-left: 8px;
              }
            }

            span {
              font-size: 14px;
              color: #bd0c21;

              @include mobile {
                color: #ffffff;
              }
            }
          }

          .custom-drop-down {
            position: initial;

            a {
              color: #fff;
            }

            .dropdown-item {
              color: #ffffff !important;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }

        @include tab {
          display: flex;
          flex-flow: inherit;
          position: absolute;
          top: 96%;
          background-color: white;
          left: 0%;
          padding-bottom: 1%;
        }

        @include minitab {

          margin-left: 0%;
          margin-top: 1.5%;
          padding-bottom: 1%;
        }

        @include mobile {
          margin-left: 0px;
          margin-top: 0%;
          // background-image: linear-gradient(to right, #bd0c21de, #35459ccc);
          background-color: white;
          width: 108%;


        }
      }

    }

    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;

        @include mobile {
          padding: 28px 0 28px 20px;
        }

        .navicon {
          background: #000000;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;

          @include tab {
            background: transparent;
          }

          @include minitab {
            background: transparent;
          }

          @include mobile {
            background: #bd0c21;
          }
        }
      }

      .user-icon {
        display: flex;
        align-items: center;

        i {
          margin-right: 20px;
          font-size: 20px;
          color: #fff;
        }

        .menu-icon {
          margin-bottom: 0;
          padding: 22px 2px 28px 2px;
          margin-left: 18px;
        }

        .menu-text {
          color: #000000;
          font-size: 10px;
          position: absolute;
          top: 30px;
          left: -2px;
        }
      }
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #000000;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;

      @include tab {
        background: transparent;
      }

      @include minitab {
        background: transparent;
      }

      @include mobile {
        background: #bd0c21;
      }
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked~.menu {
      max-height: 430px;
    }

    .header .menu-btn:checked~.menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked~.menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked~.menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
      top: 0;
    }

    .iconText {
      margin-left: 5px;
    }

    .service-links {
      align-items: center;

      .nav-link {
        color: white !important;
        font-size: 16px;

        i {
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }

    @media (min-width: 992px) {

      // .header li {
      //   // float: left;
      // }
      // .header li a {
      //   // padding: 23px 30px;
      // }
      .header .menu {
        max-height: none !important;
      }

      .header .menu-icon {
        display: none;
      }
    }

    .header {
      @include minitab {
        padding: 0 0px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;

        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .header {
        padding: 0 15px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: white;
        // background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 1px solid #bcbcbc;


        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.dashboard-menu {
  max-width: 300px;
}

.menu {
  align-items: center;

  .currency-text {
    .ant-select-selector {
      background-color: transparent !important;
      border: 1px solid #ec8333  !important;
      border-radius: 25px;
      box-shadow: none !important;
    }

    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
  }
}

.curreny-dropdown {
  padding: 20px 0 20px 30px;
}

.ant-select-dropdown {
  z-index: 9999999999 !important;
  width: 10% !important;

  @include tab {
    width: 20% !important;
  }
}

.login-modal {
  width: 700px !important;

  .ant-modal-close-x {
    color: #000 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .close-modal-icon {
    padding-bottom: 15px;
    font-size: 20px;
    color: #000;
    text-align: end;
  }

  .ant-form-item {
    .ant-form-item-control {
      min-width: 100%;
    }
  }

  .ant-input-affix-wrapper::before {
    display: none;
  }

  .ant-form {
    padding: 10px 10px;

    @include mobile {
      padding: 10;
    }
  }

  .login-text {
    text-align: center;
  }

  .forgot-text {
    font-size: 14px;
    color: #bd0c21;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-col {
    margin-left: 0 !important;
    text-align: center;

    button {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .login-alternatives {
    display: flex;
    margin: 15px 0;

    .fb-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      margin-right: 10px;

      p {
        margin-bottom: 0;
      }

      img {
        height: 20px;
        text-align: center;
      }
    }

    .google-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);

      p {
        margin-bottom: 0;
      }

      img {
        height: 20px;
        text-align: center;
      }
    }
  }

  .or-text {
    p {
      font-size: 14px;
      color: #000;
      text-align: center;
    }
  }
}

@include mobile {
  .mob-none {
    display: none !important;
  }

  .company-logo img {
    margin-top: 4px !important;
    height: 40px !important;
  }

  .tab-content ul li {
    display: inline-grid !important;
  }

  .login-register-mob {
    display: none !important;
  }
}

.mobile-user {
  color: #fff;
  font-size: 20px;

  .mobile-user-icon {
    display: flex;
    align-items: center;

    .anticon {
      vertical-align: middle !important;
      padding: 0 5px;
      margin-right: 5px;
    }
  }
}

.responive-more {
  li {
    a {
      font-size: 16px;
      margin-left: 5px;
      text-decoration: none;
    }
  }
}

.supportvalue {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #fff !important;
}

.cre-amount {
  color: red !important;
  font-weight: 600;
}

.cre-amount-1 {
  font-weight: 600;
}

.cre-amount-14 {
  font-weight: 600;
  background-color: #bd0c21;
  color: #fff !important;
}

/*css--added*/
.all-top-menu {
  max-height: 0px;
  display: flex;
  float: right;
  padding-top: 2px;
  padding-bottom: 25px;
}

.all-top-menu li {
  padding: 0px 5px;
  font-size: 15px;
  @include minitab{
    margin-top: 34px;
  }
}

.last-mob-bdr .ant-select-selector {
  border: 0px solid #d9d9d9 !important;
}

.cart-nav-top {
  padding-top: 0px !important;
}

.cart-nav-top a {
  color: #000 !important;
}

.menu-bdr-nav .ant-dropdown-menu-item {
  border-bottom: 1px solid #000;

  // padding: 0;
  .ant-dropdown-menu-title-content {
    div {
      &:hover {
        color: #007bff;
      }
    }
  }
}

.ant-dropdown-menu {
  padding: 0;
}

.balannce-top-ss {
  padding-top: 4px !important;
}

@media screen and (max-width: 768px) {
  .currency-panel-text {
    position: absolute;
    /* z-index: 10; */
    top: -173px;
    right: 84px;
  }

  .nav-itmes-target {
    padding: 0px 10px 0px 0px;
    // .fa{
    //   padding-right: 5px;
    // }
  }

  #only-mob-user {
    //display: block !important;
  }

  .currency-panel-text .ant-select-selector {
    padding: 0px 4px !important;
  }
}

#only-mob-user {
  display: none;
}

.nav-itmes-target {
  display: grid !important;
  text-align: center;

  @media mobile {
    display: flex !important;
  }
}

.delas-taget-flight {
  display: none !important;
}

#delas-taget-flight {
  display: none !important;
}

.border-bottom-1 {
  border-bottom: 1px solid !important;
}

.Mega-Menus {
  .ant-dropdown-menu-item {
    font-size: 13px !important;
  }

  .ant-dropdown-menu-item-group-title {
    color: #bd0c21;
    font-weight: 600;
    font-size: 15px;
  }

  ul.ant-dropdown-menu-item-group-list {
    text-transform: capitalize;
  }
}

.sign-register-css-work {
  
  // border: 1px solid rgb(255, 255, 255);
  // padding: 3% !important;
  .ant-dropdown-trigger {
   // border: 1px solid #bd0c21;
   border: 1px solid #ec8333;
   white-space: nowrap;
    padding: 6px 20px;
    border-radius: 25px;
  }
}

.sign-register-css-work:hover {
  //border: 2px solid #ec8333;
 // border-radius: 6px;
   //border: 1px solid rgb(224, 184, 184);
  // padding: 3% !important;
  .ant-dropdown-trigger:hover {
    // background-color: rgb(255, 255, 255);
    // padding: 10% !important;
    border-radius: 6px;
    border: 1px solid #ec8333;
    padding: 5px 20px;
    border-radius: 25px;
    background: orange;
    color: white !important;
    // font-weight: 600;
  }
}

.sign-register-css-work1 {
  padding-top: 2.5% !important;
}

.top-nav-sec {
  width: 91%;

  @include tab {
    width: 100%;
  }

  @include mobile {
    width: 100%;
  }
}

.my-acc-dropdown {
  padding-top: 5%;

  @include tab {
    padding-top: 7%;
  }

  @include mobile {
    padding-top: 0%;
  }
}

.nor-viw {
  @include mobile {
    display: none !important;
  }
}

.mob-viw {
  @include mobile {
    display: block !important;
  }
}

.pcvi-wor-hid {
  display: none;

  @include mobile {
    display: block;
  }
}

.for-currency-border-workcss {
  border: 1px solid #bd0c21;

  @include mobile {
    border: 1px solid transparent;
    margin-left: -7%;
  }
}

.mov-viw-agent-head {
  background-color: white;
  position: fixed;
  top: 5.9%;
  width: 100%;
  display: flex;
  z-index: 9;
  justify-content: space-around;
}