@import "../../../assets/variableStyles.scss";

.hotels_results_page {
  @include mobile {
    .hide-icon-heading {
      display: block !important;
    }

    .accordion {
      margin-top: 40px;

      @include mobile {
        margin-top: 0;
      }

      .card {
        border: none;

        h2 {
          background: url(../../../assets/images/down-arrow-1.png) no-repeat calc(100% - 10px) center;
          background-size: 20px;
          cursor: pointer;
          font-size: 18px;
          color: #ffffff;

          &.collapsed {
            background-image: url(../../../assets/images/up-arrow-2.png);
          }
        }

        &-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .hotels-page-wrapper {
    .hotels_modify {
      //background-color: #00164d;
      height: 100%;
      width: 100%;
      // background-color: #2e0000;
      // background-image: linear-gradient(to right, #320063, #0f76bb);
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      padding-top: 11%;

      .bread-crumb-block {
        padding-top: 67px;

        ul.breadcrumb {
          padding: 10px 16px;
          list-style: none;
          background-color: transparent;
        }

        ul.breadcrumb li {
          display: inline;
          font-size: 12px;
        }

        ul.breadcrumb li+li:before {
          padding: 8px;
          color: #dcdee3;
          content: "/\00a0";
        }

        ul.breadcrumb li a {
          color: #dcdee3;
          text-decoration: none;
        }

        ul.breadcrumb li a:hover {
          color: #dcdee3;
          text-decoration: underline;
        }

        ul.breadcrumb li span {
          color: #fff;
          font-weight: 700;
        }
      }

      .hotel-results-text {
        h2 {
          color: #fff;
          font-size: 34px;
          text-align: center;

          .hotel-results-to-text {
            font-weight: 700;
          }
        }
      }

      // .search-modify-block {
      //   padding-bottom: 20px;
      // }
      .search-modify-block>.hotels_search_box {
        background-color: transparent;

        .hotels-body {
          .custom-column {
            label {
              color: #bcd5f5;
            }
          }
        }
      }
    }

    .hotels_list {
      background: rgb(232, 242, 250);
      margin-top: -2%;

      .hotels-list-wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px;

        .list-container {
          display: flex;

          .filters-box {
            width: 24%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 10px;

            @media screen and (max-width: 768px) {
              display: none;
            }

            .ant-card-body {
              padding: 15px;

              .ant-skeleton {
                .ant-skeleton-title {
                  width: 100% !important;
                }
              }
            }
          }

          .hotels-box {
            width: 77%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 0px;

            @include tab {
              width: 100%;
            }

            @include minitab {
              width: 100%;
            }


            .card-skeleton {
              .ant-card-body {
                padding: 0 15px;
              }

              margin-bottom: 12px;
            }

            .showmore-wrapper {
              text-align: center;
            }

            .hotels-sorting-block {
              .results-sort-block {
                display: flex;
                // justify-content: space-around !important;

                align-items: center;
                padding: 15px 0;

                .sort-header {
                  color: #505c75;
                  font-size: 15px;
                  font-weight: 600;
                  margin-bottom: 0;
                }

                .sort-body {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;

                  li {
                    margin: 0 10px;

                    a {
                      font-size: 16px;
                      color: #969dac;

                      &:hover {
                        color: #1c2b39;
                        text-decoration: none;
                      }
                    }
                  }

                  .active {
                    a {
                      color: #1c2b39;
                      border-top: 2px solid #2e0000;
                      padding: 5px 0;
                    }
                  }
                }
              }

              @media screen and (max-width: 768px) {
                display: none;
              }
            }

            @media screen and (max-width: 768px) {
              width: 100%;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}

.hotels-box {
  .add-title {
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;

    .add-hdr-det {
      display: inline-flex;
      justify-content: space-between;

      h4 {
        font-size: 20px !important;
        font-weight: 700;
      }

      p {
        margin-left: 5px;

      }
    }

  }
}

.hotel-images-modal {
  .modal-wrapper {
    padding: 20px 30px 20px 20px;
    background-color: #00164d;

    .modal-title {
      display: flex;
      align-items: center;

      p {
        color: #fff;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
      }

      sup.hotel-star-rating {
        top: 0;
        margin-left: 5px;

        li {
          margin-right: 0px !important;
        }
      }
    }

    button {
      padding: 0.5rem 1rem;

      span {
        font-size: 2.5rem;
        color: #bcd5f5;
        text-shadow: 0 1px 0 #bcd5f5;
      }
    }
  }
}

.hotel-modify-cont {
  max-width: 1100px;
  padding: 15px;
  margin: auto;
  height: 65px;
  margin-bottom: 30px;

  .modify-search-pan {
    width: 1170px;
    padding-top: -38px;
    margin-top: -34px;
    margin-left: -40px;
    // background: #febb02;
    //background: linear-gradient(to left, #333333, #5A2653);
    //box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    padding: 2px;

    // margin-bottom: 20px;
    @media only screen and (max-width: 575px) {
      display: none;
    }

    @include tab {
      width: auto;
      margin-left: auto;
    }

  }

  .ant-collapse {
    background: transparent;
    border: none;

    .ant-collapse-item {
      border: none;
      //text-align: right;
    }

    .ant-collapse-header {
      padding: 0;
      //display: none;

      @include mobile {
        display: inline-block;
      }
    }

    .ant-collapse-content {
      text-align: left;
      border: none;
      background: transparent;

      .ant-collapse-content-box {
        padding: 0;

        .hotels_search_box {
          background: transparent;
          padding: 0;
        }
      }
    }
  }

  .ant-form-item {
    flex-direction: column;
  }

  .ant-form-item-label {
    label {
      color: #bcd5f5 !important;
    }
  }

  .hotels_search_box {
    padding: 90px 15px 20px;
  }

  .ant-form-item-control {
    width: 100%;
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
  }

}

.hotel-modify-container {
  max-width: 1100px;
  padding: 15px;
  margin: auto;
  padding-top: 8px;
  @include ipadpro{
    padding-top:50px;
  }
  @include tab{
    padding-top: 100px;
  }
  @include minitab{
    padding-top: 100px;
  }
  // height: 65px;
  // margin-bottom: 20px;
  // .modify-search-pan{
  //   width: 1170px;
  //  padding-top: -38px;

  //   margin-left: -40px;
  //   background: #febb02;
  //   box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
  //   padding: 4px;
  //   // margin-bottom: 20px;
  // }

  .ant-collapse {
    background: transparent;
    border: none;

    .ant-collapse-item {
      border: none;
      //text-align: right;
    }

    .ant-collapse-header {
      padding: 0;
      //display: none;

      @include mobile {
        display: inline-block;
      }
    }

    .ant-collapse-content {
      text-align: left;
      border: none;
      background: transparent;

      .ant-collapse-content-box {
        padding: 0;

        .hotels_search_box {
          background: transparent;
          padding: 0;
        }
      }
    }
  }

  .ant-form-item {
    flex-direction: column;
  }

  .ant-form-item-label {
    label {
      color: #bcd5f5 !important;
    }
  }

  .hotels_search_box {
    padding: 90px 15px 20px;
  }

  .ant-form-item-control {
    width: 100%;
  }
}

/*********** Skeleton Styles ***********/
.hotel-skeleton-layout {
  background-color: transparent;

  .card-skeleton {
    margin-bottom: 15px;

    .ant-card-body {
      padding: 0 15px;
    }
  }

  .hotel-skeleton-layout-container {
    max-width: 1200px;
    padding: 30px;
    margin: auto;
  }

  .sort-skeleton {
    .ant-skeleton-title {
      width: 60% !important;
      height: 25px !important;
    }
  }
}

/*********** modal--start ***********/

.hotel-center-cls {
  text-align: center;
  display: inline-flex;
  margin: 0px 3px;
  background: #000;
  padding: 0px 8px;
  border-radius: 3px;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  color: #ffffff !important;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer>a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.hotel-modal-filters {
  display: block !important;
}

.fiters-value-hotel {
  display: none !important;

  @include ipadpro {
    display: block !important;
    position: fixed !important;
    left: 0;
    bottom: 0px;
    right: 0;
    z-index: 2;
    //background: #ef6614;
    text-align: center;
    color: white;
  }

  @include tab {
    display: block !important;
    color: white;
    position: fixed;
    bottom: 0%;
    
    z-index: 1111;
  }
  @include desktop {
    
      display: block !important;
      color: white;
      position: fixed;
      bottom: -2.5%;
      left: 45%;
      z-index: 1111;
    
  }
  h5 {
    color: white;
  }
}

@media screen and (max-width: 768px) {
  .fiters-value-hotel {
    display: inline !important;
    position: fixed;
    left: 0;
    bottom: 0px;
    right: 0;
    z-index: 2;
    //background: #ef6614;
    text-align: center;
  }
  
  .demo-filters-action {
    background: #000 !important;
    color: #ffffff !important;
    /* padding: 10px 10px !important; */
    margin: 0px 3px;
    /* background: #ef6614; */
    padding: 8px 20px !important;
  }

  .hotel-filters-jun {
    text-align: center;
    display: inline;
  }

  .hotel-sort-by {
    padding-top: 27px;
    font-size: 1rem;
    color: #ffffff;
  }

  .sortby-ul li {
    float: none;
    text-align: left;
    padding-bottom: 18px;
  }

  .sortby-ul li a {
    color: #000000;
  }

  //   .hotels_search_list{
  //   .results-wrapper{
  //     border: 1px solid #c6c6c6;
  //   }
  // }

  .hotels_search_list .results-wrapper .hotel-title .hotel-title-wrapper .hotel-star-rating li {
    margin-right: 0px !important;
  }

  .gif-icon {
    margin: 0 auto;
    display: block;
  }

  .offers-hotel {
    text-align: center;
    color: #e2076a;
    font-size: 18px;
    padding-top: 20px;
  }

  .car-body-res .hotel-modify-container .hotel-modify-cont {
    padding: 0px !important;
  }
}

/* dec-12-2020 */
.filters-box .ant-card-bordered {
  border-radius: 8px;
}

.hotels-hide-search {
  //margin: 10px 0;
  // float: right;
  //cursor: pointer;
  //color: #000000 !important;
  //background: #ffffff !important;
  //padding: 5px 5px;
  //border-radius: 5px;
  font-size: 0px !important;

}

.search-modify-block .hotels_search_box {
  padding: 0px 0px 0px !important;
}

@media screen and (max-width: 768px) {
  .modal-hotel-show12.sort-text {
    display: none;
  }

  .modal-hotel-show12 .hotel-sort-block {
    display: block !important;
  }

  .modal-hotel-show12 .price-type {
    font-size: 14px !important;
    align-items: center;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    box-shadow: 0 0 3px #000;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 0 8px 8px 0;
    padding: 6px 12px;
    position: relative;
    text-align: center;
  }

  .modal-hotel-show12 .flex-wrapper {
    display: block !important;
  }

  .modal-hotel-show12 .flex-wrapper {
    display: block !important;
  }

  .modal-hotel-show12 .sort-block .ant-card-bordered {
    border-radius: 0px !important;
  }

  .modal-hotel-show12 .ant-card-bordered {
    //border: 0px solid #f0f0f0 !important;
  }

  .modal-hotel-show12 .sort-text {
    display: none;
  }

  .modal-hotel-show12 .ant-radio-wrapper {
    margin-left: 0px !important;
    margin-bottom: 10px;
  }

  .modal-hotel-show12 .ant-input-group.ant-input-group-compact {
    display: flex;
  }

  .modal-hotel-show12 .sort-block .ant-radio-wrapper {
    border-right: 0px solid #ccc !important;
  }

  .modal-hotel-show12 .results-sort-block {
    display: block !important;
    padding: 2px !important;
  }
}

.sort-fil {

  display: flex;
  justify-content: space-evenly;
}

.sortbyh {
  @include mobile {
    position: fixed;
    margin-top: 30px;
  }
}

.results-sort-block {
  display: flex;
  justify-content: space-between;
}