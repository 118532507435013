.accounts-block {
  border: 1px solid #ccc;
  margin: 10px 0;
  .accounts-header {
    p {
      margin-bottom: 0;
      background-color: aliceblue;
      padding: 10px 15px;
    }
  }
  .accounts-block-body {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        padding: 5px;
        width: 25%;
        label {
          white-space: nowrap;
          input {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.modal-open {
  .modal {
    overflow-y: scroll !important;
  }
}
.custom-modal-width-90 {
  width: 60%;
  max-width: 100%;
}
.expand-cell,
.expand-cell-header {
  white-space: nowrap;
  cursor: pointer;
}
.access-type-header {
  h5 {
    font-weight: 600;
    font-size: 16px;
  }
  ul {
    display: flex;
  }
}
.permissions-modal {
  width: 860px !important;
}
.access-row-bc-21 .ant-row {
  row-gap: 2px !important;
}
.access-row-bc-21 .ant-form-item {
  margin-bottom: 3px;
}

.modal-header-bg12 {
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-header {
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    border-radius: 0px;
  }
}
