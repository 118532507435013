@import "../../assets/variableStyles.scss";

.agent-flight-wrapper {
  
  background-color: transparent;
  padding-bottom: 20px;
  margin-top: -39% !important;
  margin-bottom: 17%;
@include ipadpro{
  margin-top: -54% !important;
}
@include tab{
  margin-top: -65% !important;
}
@include minitab{
  margin-top: -65% !important;
}
@include mobile{
  margin-top: 24% !important;
  background-color: #026de9;
}

  .ant-form {
    padding: 15px 0;

    .ant-form-item-label {
      label {
        color: #fff;
        font-weight: 600;
      }
    }

    .ant-form-item {
      margin-bottom: 5px;

      input {
        color: #474747;
        font-weight: 600;
      }

      .ant-select-selector {
        height: 40px !important;
        font-weight: 600;
      }

      .ant-select-selection-item {
        line-height: 40px !important;
        font-weight: 600;
        color: #4a4a4a;
        font-size: 15px;
      }

      .ant-select-selection-placeholder {
        font-weight: 600;
        color: #000000;
        
      }
    }

    .ff-agent-search {
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      //background-image: linear-gradient(to right, #333333, );
      text-align: center;
      display: block;
      color: #fff;
      font-size: 16px;
      margin-left: auto;
      border-radius: 5px;
    }
  }

  .coolaspe-airline-op {
    margin-bottom: 15px;

    .ant-collapse {
      background-color: transparent;
      border: 0px solid #d9d9d9;
    }

    .ant-collapse-item {
      border-bottom: 0px solid #d9d9d9;
    }

    .ant-collapse-content {
      background: transparent;
      border-top: 0px solid;

      .ant-collapse-content-box {
        padding: 0;
      }
    }

    .ant-collapse-header {
      color: #ffffff !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-left: 0;
    }

    label {
      color: #333;
      font-weight: normal;
    }

    .restrict-select {
      color: #333;
      font-weight: 600;

      span {
        margin-right: 10px;
        color: #fff;
      }
    }
  }
}
.work-agent-baer{
  display: block;
  @include mobile{
    display: none;
  }
}

