@import "../../../assets/variableStyles.scss";

.visa-details {
  background-color: #fff;
  .visa-details-header {
    .details-header-container {
      .hotel-full-address {
        background-color: #35459c;
        color: #fff;
        font-weight: 600;
        .hotel-header-wrapper {
          max-width: 1100px;
          width: 100%;
          margin: 0 auto;
          padding: 12px 15px;

          .hotel-detail-header-row {
            justify-content: space-between;
            align-items: center;
            margin: 0 !important;
            .visa-details-header-right {
              .ant-skeleton-paragraph {
                li {
                  &:nth-child(1) {
                    height: 28px;
                    width: 50% !important;
                  }
                  &:nth-child(2) {
                    width: 60% !important;
                  }
                }
              }
              .show-moreskeleton-btn {
                text-align: end;
                .ant-skeleton-button {
                  @include mobile {
                    display: none;
                  }
                }
              }
            }
          }

          .redirect-to-hotels-list {
            a {
              text-decoration: none;
              color: #bcd5f5;
              font-size: 12px;
              &:hover {
                color: #bcd5f5;
              }
              span {
                padding-right: 10px;
                font-size: 14px;
              }
            }
          }
          .hotel-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .hotel-name-wrapper {
              h4 {
                color: #fff;
                margin-bottom: -4px;
                sup {
                  margin-left: 10px;
                }
                .starRating {
                  font-size: 12px;
                  color: rgb(246, 149, 45);
                  .ant-rate-star {
                    margin-right: 2px;
                  }
                }
                @include mobile {
                  margin-bottom: 0;
                }
              }
              p {
                color: #bcd5f5;
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                margin-bottom: 0;
                @include mobile {
                  line-height: 18px;
                }
              }
            }

            .show-rooms-btn {
              button {
                border-radius: 3px;
                background-color: #bd0c21;
                border-color: #bd0c21;
                color: #fff;
                font-weight: 600;
                i {
                  padding-left: 5px;
                }
              }
              @include mobile {
                display: none;
              }
            }
          }
          @include mobile {
            padding: 12px;
          }
        }
      }
      .sticky-links {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        background: #f5f5f5;
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 8px;
        ul {
          display: flex;
          margin-bottom: 0;
          padding: 15px 0;
          li {
            a {
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 700;
              border-right: 1px solid #eaf2fc;
              padding: 0 25px;
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }
        @include mobile {
          display: block;
        }
      }
    }
  }
  .hotel-detail-images {
    .light-box-wrapper {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      padding: 0 0px;
      padding-bottom: 15px;
      .container {
        padding: 0;
        .feedback-word {
          background-color: #f5f8fd;
          text-align: center;
          padding: 10px 20px;
          border-radius: 3px;
          margin-bottom: 10px;
          p {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 700;
          }
        }
        .map-bg-container {
          width: 260px;
          height: 84px;
          .map-bg {
            background-image: url(../../../assets/images/hotels/map_bg.png);
            width: 100%;
            height: 100%;
            position: relative;
            p {
              margin-bottom: 0;
              background-color: hsla(0, 0%, 100%, 0.8);
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              padding: 4px;
            }
            button {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -20%);
              background: #bd0c21;
              border: transparent;
              color: #fff;
              font-size: 12px;
              border-radius: 3px;
              padding: 8px 20px;
              font-weight: 600;
              &:hover {
                background-color: #7aabeb;
              }
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .visa-info-card {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 black;
    padding: 10px 10px;
    margin-bottom: 20px;
    .visa-information {
      background-color: #eaebee;
      border-radius: 5px;
      .visa-wrapper {
        background-color: #eaebee;
        p {
          margin: 0;
        }
        // max-width: 1100px;
        // width: 100%;
        // margin: 0 auto;
        // padding: 20px;

        .available-rooms-dates {
          margin-bottom: 15px;
        }
        .visa-embassy-description {
          .room-card-wrapper {
            .ant-card-body {
              padding: 10px;
              .room-number-span {
                background: #bd0c21;
                padding: 1px 10px;
                margin-bottom: 1px;
                border-radius: 10px;
                display: inline-block;
                color: #fff;
                font-weight: 600;
                font-size: 10px;
              }
              .button-wrapper {
                button {
                  background: #bd0c21;
                  color: #fff;
                  border: transparent;
                  padding: 5px 20px;
                  font-size: 12px;
                  font-weight: 600;
                  border-radius: 3px;
                  &:disabled {
                    cursor: not-allowed;
                  }
                  @include mobile {
                    order: 3;
                  }
                }
              }

              .room-card {
                // margin-bottom: 10px;
                // background-color: #fff;
                // border-radius: 3px;
                // -moz-border-radius: 3px;
                // box-shadow: 0 2px 3px 0 #eaebee;
                // -webkit-box-shadow: 0 2px 3px 0 #eaebee;
                // -moz-box-shadow: 0 2px 3px 0 #eaebee;
                // border-bottom: 2px solid #dcdee3;
                // padding: 16px;
                padding: 2px;
                border-radius: 5px;
                position: relative;
                padding-bottom: 0;

                .room-image-skel {
                  height: 100%;
                  .ant-skeleton-element,
                  .ant-skeleton-image {
                    width: 100%;
                    height: 100%;
                  }
                }
                .ant-skeleton-title {
                  margin-top: 0;
                }
                .choose-btn-s {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  align-items: flex-end;
                  justify-content: center;
                  .ant-skeleton-title {
                    margin: 0 0 5px auto;
                  }
                }
                .room-card-wrapper {
                  display: flex;
                  justify-content: space-between;
                  @include smmobile {
                    flex-direction: column;
                  }
                  .hotel-image-box {
                    .hotel-image {
                      .hotel_image_ {
                        max-width: 160px;
                        min-width: 160px;
                        position: relative;
                        img {
                          max-height: 120px;
                          object-fit: cover;
                        }
                        @include smmobile {
                          max-width: 100%;
                          height: 160px;
                          img {
                            height: 100%;
                          }
                        }
                        .gallery-icon {
                          position: absolute;
                          right: 6px;
                          bottom: 6px;
                          background: rgba(28, 43, 57, 0.6);
                          width: 30px;
                          height: 30px;
                          display: grid;
                          place-items: center;
                          border-radius: 3px;
                          cursor: pointer;
                          i {
                            color: #fff;
                            font-size: 18px;
                          }
                        }
                      }
                      img {
                        width: 100%;
                        border-radius: 3px;
                      }
                    }
                  }
                  .hotel-info-wrapper {
                    flex: 1;
                    display: flex;
                    @include smmobile {
                      flex-direction: column;
                    }
                    .hotel-room-details {
                      flex: 1;
                      display: flex;
                      justify-content: space-between;
                      flex-direction: column;
                      padding: 0 15px;
                      @include smmobile {
                        padding: 10px 0;
                      }
                      .hotel-room-type {
                        .room-title {
                          display: flex;
                          justify-content: space-between;
                          width: 90%;
                          .roomName {
                            color: #00164d;
                            font-weight: 700;
                            margin-bottom: 0;
                          }
                          .roomlastcancel {
                            font-size: 12px;
                            color: #e72727;
                            padding-left: 20px;
                            font-weight: 500;
                            margin-bottom: 0;
                          }
                        }

                        .pax-icons {
                          small {
                            padding-right: 5px;
                          }
                          span {
                            padding-right: 5px;
                            padding-left: 5px;
                          }
                        }
                      }
                      .amenities-box {
                        font-size: 12px;
                        ul {
                          li:first-child {
                            color: #bd0c21;
                          }
                          li {
                            padding-bottom: 4px;
                            i {
                              background: #f5f8fd;
                              -moz-border-radius: 80%;
                              border-radius: 80%;
                              font-size: 9px;
                              padding: 5px;
                              margin-right: 4px;
                            }
                          }
                        }
                      }
                      .description-modal {
                        font-size: 12px;
                        .tooltipWrapper {
                          display: flex;
                          align-items: center;
                          .anticon {
                            padding-right: 5px;
                          }
                        }
                        a {
                          color: #bd0c21;
                          &:hover {
                            text-decoration: none;
                          }
                          span {
                            font-size: 8px;
                          }
                        }
                      }
                    }

                    .select-room-btn {
                      text-align: right;
                      @include smmobile {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      }
                      .rooms-left {
                        background: #f6952d;
                        color: #fff;
                        padding: 3px 10px;
                        border-radius: 3px;
                        font-size: 10px;
                        display: inline-block;
                        margin-bottom: 0;
                        line-height: 1.5;
                        vertical-align: bottom;
                        @include mobile {
                          display: none;
                        }
                      }
                      .rooms-left-mobile {
                        @media screen and (min-width: 768px) {
                          display: none;
                        }
                      }
                      .hotel-room-price {
                        margin-bottom: 0;
                        span {
                          font-size: 22px;
                          font-weight: 700;
                          padding-left: 5px;
                        }
                        @include mobile {
                          order: 1;
                        }
                      }
                      .select-button {
                        background: #bd0c21;
                      }
                      .active {
                        background: #bd0c21 !important;
                      }
                      button {
                        background: #bd0c21;
                        color: #fff;
                        border: transparent;
                        padding: 5px 20px;
                        font-size: 12px;
                        font-weight: 600;
                        border-radius: 3px;
                        &:hover,
                        &:active {
                          background: #bd0c21 !important;
                        }
                        @include mobile {
                          order: 3;
                        }
                      }
                      button:disabled {
                        cursor: not-allowed;
                      }
                      .nightCount {
                        font-size: 12px;
                        color: #969dac;
                        margin-bottom: 6px;
                        @include mobile {
                          display: none;
                        }
                      }
                      // @include mobile {
                      //   display: flex;
                      //   justify-content: space-between;
                      //   background: #f5f8fd;
                      //   padding: 10px;
                      // }
                    }
                  }
                }

                @include mobile {
                  padding: 15px;
                }
              }
            }
          }
          .showroom-btn-wrapper {
            margin: 30px 0 20px;
            text-align: center;
            button {
              padding: 15px 30px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              border: 1px solid #bcd5f5;
              background-color: #f5f8fd;
              color: #bd0c21;
              cursor: pointer;
              -webkit-transition: background-color;
              -moz-transition: background-color;
              transition: background-color;
              font-weight: 700;
              &:hover {
                background-color: #eaf2fc;
                border-color: #7aabeb;
                color: #bd0c21;
              }
            }
          }
        }
        @include mobile {
          padding: 15px;
        }
      }
    }
  }

  .visa-embassy-description {
    .visa-embassy-description-wrapper {
      max-width: 1100px;
      width: 100%;

      .description-block {
        .embassyDetails {
          // max-width: 1100px;
          // margin: 0 auto;

          p {
            margin: 0;
          }
          .embassyAddresses {
            .addressColumns {
              padding: 0px 0px 0px 15px;
              margin: 0px 0px 15px 0px;
            }
          }
        }
        background-color: #eaebee !important;

        .description-title {
          flex-grow: 0;
          flex-basis: 23%;
          text-align: center;
          background: #f5f8fd;
          border-right: 5px solid #eaf2fc;
          font-weight: 700;
          padding-top: 37px;
        }
        .description-content {
          flex-basis: 77%;
          margin-left: 30px;
          padding-top: 37px;
          padding-bottom: 30px;
          word-spacing: 2px;
          border-bottom: 1px solid #eaf2fc;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          // width: initial;
        }
      }
      .facilities-list {
        .facilities-block {
          p {
            font-weight: 700;
          }
          ul {
            li {
              margin-bottom: 8px;
            }
          }
        }
      }
      .checking-timings {
        ul {
          li {
            font-weight: 700;
            i {
              padding-right: 20px;
              margin-bottom: 20px;
            }
            span {
              font-weight: initial;
            }
          }
        }
      }
    }
  }
  .locationWrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    .loc {
      display: flex;
      align-items: center;
      font-size: 16px;
      .anticon {
        padding-right: 8px;
        color: #bd0c21;
      }
    }
    .mapWrapper {
      height: 400px;
      width: 100%;
      .markerWrapper {
        font-size: 18px;
        .anticon {
          color: #bd0c21;
        }
      }
    }

    margin-bottom: 24px;
  }

  .visa-details-footer {
    .details-footer-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #002172;
      padding: 15px 0;
      color: #fff;
      p {
        margin-bottom: 0;
        font-size: 18px;
      }
      button {
        margin-left: 30px;
        background-color: #bd0c21;
        padding: 6px 25px;
      }
    }
    .details-footer-second {
      padding: 20px 0;
      .second-footer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
          margin-right: 40px;
        }
        button {
          background: transparent;
          color: #bd0c21;
          font-size: 13px;
          padding: 4px 20px;
          border-radius: 3px;
          font-weight: 600;
          margin-right: 30px;
          &:hover {
            background-color: #bd0c21;
            color: #fff;
          }
        }
      }
    }
  }
  .facilities-modal {
    .room-modal {
      .modal-content {
        padding: 30px 20px 40px 20px;
        border-radius: 0 !important;
        .modal-header {
          border: none;
          .custom-modal-header {
            display: flex;
            .header-img {
              max-width: 70px;
              img {
                width: 100%;
                border-radius: 3px;
              }
            }
            .header-text {
              padding-left: 20px;
              h4 {
                font-size: 24px;
                font-weight: 700;
                color: #00164d;
              }
              p {
                margin-bottom: 0;
                font-size: 12px;
                i {
                  font-weight: 500;
                  padding-right: 5px;
                }
              }
            }
          }
          button span {
            font-size: 200%;
            color: #505c75;
            &:hover {
              color: #1c2b39;
            }
          }
        }
        .modal-body {
          max-height: 160px;
          overflow-y: scroll;
          .custom-details-modal-body {
            p {
              color: #1c2b39;
              font-size: 14px;
              font-weight: 700;
            }
            ul {
              padding-left: 15px;
              li {
                list-style-type: disc;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .docList {
    ol li,
    ul li {
      list-style-type: disc;
    }
    justify-content: space-between;

    ul {
      padding: 0px 0px 0px 40px;
    }
  }

  .visaImage {
    overflow: hidden;
    height: 340px;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .novisaImage {
    height: 340px;
    width: 100%;
    object-fit: cover;
  }
}

/* oct--10--2020-changes */
@media screen and (max-width: 767px) {
  .visa-details .visa-details-header .details-header-container .sticky-links {
    display: none !important;
  }

  .visa-details
    .visa-details-header
    .details-header-container
    .sticky-links
    ul
    li
    a {
    padding: 0 9px !important;
  }

  .visa-details .hotel-detail-images .light-box-wrapper {
    height: auto !important;
  }
  .visa-details {
    .cp-rooms-guest {
      padding-left: 0px !important;
    }
    .cp-visa-inf0-top,
    .visa-info-card {
      max-width: 380px !important;
    }
    .light-box-wrapper {
      padding-bottom: 0px !important;
    }
  }
}

.tooltipWrapper {
  max-width: 300px;
  p {
    margin-bottom: 2px;
  }
}

/* dec-14--2020 */
@media screen and (max-width: 768px) {
  .visa-details {
    .visacarousel-wrapper .carousel.carousel-slider {
      height: 200px !important;
      margin-bottom: -5px !important;
    }
    .gallery-icon {
      bottom: 5px !important;
      right: 6px !important;
      height: 35px !important;
      width: 34px !important;
    }

    .sticky-links {
      display: none;
    }
  }
}
.visa-wrapper-heading{
  background: linear-gradient(to right, #35459c, transparent);
  padding: 4px 0 4px 3px;
  color: white;
}

// .visa-wrapper-heading::after {
//   content: "";
//   border-bottom: 2px solid #bd0c21;
//   width: 50px;
//   margin-top: 10px;
//   display: block;
// }
/*--24-01-2021--*/

.cp-visa-inf0-top {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0 / 10%);
  padding: 10px 10px;
  margin-bottom: 20px;

  .visa-details-carsl-img {
    .ant-skeleton,
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
  .visaGuide {
    font-size: 14px;
    text-align: right;
    padding: 0px 15px;
  }
}
.check-out-cp-panel {
  padding-left: 7px;
}

.cp-visa-inf0-top .carousel .slide img {
  border-radius: 8px;
}

.check-out-cp-panel h4 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 900;
}
.cp-details-check {
  display: flex;
}
.check-rating-cp {
  padding-left: 10px;
}
.check-rating-cp li {
  display: inline-block;
  padding: 0px 3px;
}
.cp-details-check p {
  margin-bottom: 0px;
}
.cp-location-address{
  margin-top: 12px;
  }
.cp-location-address p {
  margin-bottom: 2px;
  word-break: break-word;
  font-size: 13px;
  strong{
   // border-bottom: 1px solid rgb(234, 241, 255);
    color: #2465c7 !important;
    font-size: 16px;
  }
  span{
    color: black !important;
  }
}
.cp-location-address span {
  font-size: 14px;
  color: #4a58a8;
}
.book-cp-with {
  padding-top: 25px;
}
.book-cp-with p {
  margin-bottom: 0px;
  font-size: 14px;
}
.book-cp-with h5 {
  font-weight: bold;
  font-size: 18px;
}
.cp-bdr-info{
  // border: 1px solid #35459c;
  border-radius: 4px;
  padding: 4px;
  max-height: 300px;
  overflow: auto;
  .visa-hdr{
    background: linear-gradient(to right, #bd0c21, #35459c);
    padding: 1px 26px 1px 6px;
    max-width: fit-content;
    color: white;
    margin: -5px -5px;
    border-bottom-right-radius: 30px;
  }
}
.cp-bdr-info2 {
  background-color: #fff;
  border: 1px solid #dddddd00;
  padding: 6px 12px;
  margin-left: 80%;
  margin-top: -10%;
  @include tab{
    margin-left: 75%;
  margin-top: -14%;
  }
  @include minitab{
    margin-top: -8%;
  }
  @include mobile{
    margin-left: 35%;
  margin-top: 0;
  }
  .cp-book-this{
    .book-btn{
      color: #fff !important;
    }
    .book-btn:hover{
      // font-weight: 600;
        color: #fff !important;

    }
  }
}
/*--25-08-2021--*/
.cp-gift-img-text {
  display: flex;
  justify-content: space-between;
  background-color: #d7fae2;
  border: 1px solid #ddd;
  margin-top: 9px;
  border-radius: 8px;
  height: 204px;
}
.img-text-cp-level {
  text-align: center;
}
.img-text-cp-level img {
  width: 85px;
  height: 85px;
  margin-bottom: 5px;
}

.more-panel-cp {
  margin-bottom: 0px;
  font-weight: 700;
}
.login-bal-cp {
  margin-bottom: 0px;
  font-size: 13px;
}
.promo-tariff-cp {
  font-size: 14px;
}
.img-text-cp-level {
  width: 400px;
}
.cp-app-regions {
  padding-left: 14px;
}
.rates-best-regions {
  padding-top: 13px;
}
.cp-rooms-guest {
  padding-left: 10px;
}
.cp-location-date p {
  margin-bottom: 3px;
  font-size: 13px;
  color: #35459c;
}
.cp-location-date {
  padding-bottom: 0px;
}
.cp-location-date span {
  //font-weight: 600;
  color: #ea5167;
}
.data-book-with-us {
  margin-top: 12px;
}
.rooms-out-in-cp p {
  margin-bottom: 3px;
}
.rooms-out-in-cp {
  padding-bottom: 0px;
}
.cp-best-book-fee {
  font-weight: 700;
  color: #35459c;
}
.fees-cp {
  font-size: 14px;
}
.cp-visa-inf0-top .carousel .thumbs-wrapper {
  margin: -20px 0px -25px 0px !important;
}
.cp-book-this {
  // background-image: linear-gradient(96deg, #35459c, #bd0c21);
  background-color: #bd0c21 !important;
  border: 0;
  border-radius: 34px;
  //box-shadow: 0 1px 7px 0 rgb(0, 0, 0 / 20%);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  color: #ffff !important;
}
.cp-book-this:hover span{
  color: #ffff !important;
  // font-weight: 600;
}
.cp-book-this{
  .span:hover{
    color: #ffff !important;
  }
}
.cp-book-this .ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  // background-image: linear-gradient(96deg, #35459c, #bd0c21) !important;
  background: #bd0c21 !important;
  color: #fff;
}
.cp-other-rooms {
  display: flex;
  border-top: 1px solid #ddd;
  padding: 56px 15px;
}
.book-other-cp {
  color: #008cff;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: 600;
  padding-top: 10px;
  cursor: pointer;
}
.guest-bottom {
  padding-bottom: 5px;
}
.cp-best-book-fee-1 {
  font-weight: 700;
  color: #008cff !important;
  font-size: 15px !important;
}
.cp-book-this span {
  color: #ffffff !important;
}
@media screen and (max-width: 768px) {
  .cp-visa-inf0-top .carousel .thumbs-wrapper {
    margin: 5px 0px 0px 0px !important;
  }
  .thumbs-wrapper.axis-vertical {
    padding: 0px !important;
  }
  .img-text-cp-level {
    width: 100%;
  }
  .cp-rooms-guest {
    margin-top: 13px;
  }
  .sticky-links ul li a {
    font-size: 12px !important;
  }
}
.color-blue {
  color: #008cff;
}
.carousel li.thumb {
  height: 60px;
  .carousel-images {
    height: 100% !important;
  }
}
.carousel-wrapper .carousel.carousel-slider {
  height: auto !important;
  max-height: 243px !important;
  margin-bottom: 24px;
}
.cp-slider-hotel .carousel .slide {
  background: transparent !important;
}

@media screen and (max-width: 768px) {
  .visa-main-header-bar {
    .sticky-links ul {
      display: block !important;
    }
    li {
      display: inline-block !important;
    }
  }
}
