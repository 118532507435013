@import "../../assets/variableStyles.scss";

#home-page {
  background-color: rgb(240, 240, 240);

  .hero-banner {
    position: relative;
    z-index: 1;

  }

  .banner-links {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 15px 20px;
    box-shadow: 0 2px 4px 0 #dcdee3;

    .banner-links-wrapper {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;

        li {
          list-style-type: none;
          display: flex;
          padding: 0px 13px;

          p.banner-icon {
            font-size: 25px;
            margin-bottom: 0px;
            padding-right: 15px;
            display: table-cell;
          }

          .banner-box {
            label {
              display: block;
              text-transform: uppercase;
              margin-bottom: 0px;
              font-size: 11px;
              color: #272d38;
              font-weight: 700;
            }

            a {
              color: #2e0000;
              font-size: 13px;
              letter-spacing: 0.5px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .topflights_wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px;

    .domesticFlights,
    .intFlights {
      h2 {
        text-align: center;
        margin-bottom: 20px;

        @include mobile {
          font-size: 24px;
        }
      }
    }

    .intFlights {
      margin-top: 40px;
    }

    .popular-cards {
      .ant-card {
        border-radius: 5px;
      }

      .ant-card-body {
        padding: 0px !important;
        border-bottom: solid 2px #002273;
        border-radius: 5px;
      }

      .flights-travel-list {
        li {
          border-bottom: solid 1px #ccc;
          padding: 0px 15px 0px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background-color: #f6f6f6;

            a {
              color: #002273 !important;
              text-decoration: underline;
              text-decoration-color: #002273;
            }
          }

          a {
            padding: 10px 10px;
            color: #333 !important;
            font-size: 20px;

            @include mobile {
              font-size: 13px;
              font-weight: 600;
            }
          }

          p {
            margin-bottom: 0px !important;
            color: #002273;
            font-size: 15px;
          }
        }

        .list-with-img {
          display: flex;
          justify-content: space-between;
          text-decoration: none !important;
          padding: 8px 15px 8px 0px;

          @include mobile {
            padding: 10px;
          }

          .imageItem {
            padding-left: 25px;
            width: 90px;
            height: 40px;

            img {
              height: 100%;
            }
          }

          .airline-btn {
            color: #fff;
            background-color: #e2076a;
            padding: 10px 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 400;
            width: 275px;

            @include mobile {
              width: 166px;
              padding: 10px 20px;
              font-size: 12px;
              margin-left: 38px;
            }
          }
        }
      }

      .left-card {
        margin-right: 10px;

        @include mobile {
          margin-right: 0px;
        }
      }

      .right-card {
        margin-left: 10px;

        @include mobile {
          margin-left: 0px;
          margin-top: 15px;
        }
      }
    }
  }
}

/*  ===============  Styles for Need hotel Room ============ */
.need-hotel-room-section {
  background-color: #eaf2fc;
  background-image: url("../../assets/images/footer/stars_desktop.png");
  background-size: cover;
  background-repeat: no-repeat;

  @include mobile {
    background-image: url("../../assets/images/footer/stars_mobile.png");
    background-size: contain;
    background-position: center;
  }

  .hotel-room {
    max-width: 700px;
    margin: 0 auto;
    padding: 38px 15px 38px 50px;
    min-height: 180px;

    @include mobile {
      padding: 38px 15px;
    }

    .hotel-icon {
      float: left;
      position: relative;
      background-image: url("../../assets/images/footer/sprite_footer.png");
      background-position: 0 0;
      width: 105px;
      height: 99px;

      @include mobile {
        display: none;
      }
    }

    .hotel-room-text {
      margin-left: 190px;
      text-align: left;

      @include mobile {
        margin-left: 0px;
        text-align: center;
      }

      .room-title {
        font-size: 27px;
        line-height: 35px;
        font-weight: 400;
        margin: 0 auto 12px;
        color: #002172;

        a {
          color: #fff;
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          border-radius: 3px;
          min-height: 50px;
          padding: 10px 30px;
          font-size: 16px;
          text-align: center;
          display: inline-block;
          text-decoration: none;
          font-weight: 700;
        }
      }
    }
  }
}

.icon-info {
  margin-right: 9px;
  width: 41px;
  background: #002273;
  height: 41px;
  line-height: 41px;
  color: #ffffff;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
}

.top-fl-route {
  color: #000;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 35px;
}

.top-fl-route::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}

.tab-content ul {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 0;
}

.tab-content ul li {
  margin: 0;
  list-style: none;
  display: inline-block;
  padding: 4px 0;
  width: 100%;
  font-size: 15px;
  color: #676666;
  line-height: 32px;
  border-bottom: 1px dashed #dfe2e2;
}

.tab-content ul li:last-child {
  border-bottom: 0;
}

.hm_link {
  font-size: 15px;
  color: #676666;
  text-decoration: none;
  cursor: pointer;
}

.srch_btn {
  background: #fff;
  float: right;
  color: #320063;
  border-radius: 4px;
  border: 1px solid #320063;
  padding: 0 10px;
  line-height: 31px;
  position: relative;
  outline: 0;
  display: inline-block;
  min-height: 36px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.top-route-bb {
  background-color: #ffffff;
  margin-bottom: 0px;
}

.sec-bg-top {
  width: 100%;
  background: #fff;
  border: 1px solid #dfe2e2;
  border-bottom: 2px solid #dfe2e2;
  margin-top: 35px;
  border-radius: 3px;
  display: inline-block;
  padding: 1% 2%;
}

.gap-cols-sec {
  padding-left: 8px;
  padding-right: 8px;
}

.offers-bal-cart {
  display: flex;
  background: #fff;
  border: 1px solid #e5f1ee;
  border-radius: 4px;
  margin-top: 180px;
  color: #000;
  padding: 7px 6px;
  // position: relative;
  // z-index: -1;
  @include mobile{
    margin-top: 0;
  }
}

.deals-coloums-24 {
  height: 34px;
}

.price-deals-24 p {
  margin-bottom: 0px !important;
  font-size: 14px;
}

.price-deals-24 {
  padding-left: 10px;
}

.support-24 {
  padding-left: 13px;
  // margin-top: 180px;
}


@media screen and (max-width: 768px) {
  .support-24 {
    padding-left: 0px;
  }

  .step-img-value {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .step-img-tai {
    width: 100%;
  }

  .book-step-option {
    margin-bottom: 10px;
  }
}

.home-best-24 {
  padding-top: 25px;
}

.home-best-trip p {
  margin-bottom: 8px;
}

.home-best-trip {
  margin-bottom: 0px;
}

/*18-09-2021*/
.top-fl-route-email {
  color: #fff;
  font-size: 25px;
  text-align: center;
  margin: 0 0 15px;
  font-weight: 600;
  position: relative;
  margin-top: 16px;
}

.top-fl-route-email::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  margin: 10px auto;
  width: 10%;
  height: 3px;
  background: #fff;
}

.stay-best-email {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-top: 0px;
}

.news-sub-tt {
  height: 60px;
}

.sub-text-up {
  display: flex;
}

.date-news-travel {
  padding-top: 3px;
  padding-bottom: 10px;
  padding-left: 10px;

  p {
    color: rgb(255, 255, 255);
  }

  span {
    color: rgb(255, 255, 255);
  }
}

.sub-text-up p {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
}

.home-from-sub {
  padding-top: 25px;
}

.name-sub-date {
  height: 52px;
  font-size: 14px;
  border-radius: 6px;
  width: 98%;
}

.cribe-btn-email {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  text-align: center;
  display: block;
  color: #fff;
  font-size: 16px;
  outline: 0;
  border: 0;
  font-weight: 600;
  height: 52px;
  border-radius: 6px !important;
  width: 100%;

  @include tab {
    width: 125%;
  }

  @include minitab {
    width: 125%;
  }

  @include mobile {
    width: 100%;
  }
}

.cribe-btn-email:hover {
  background-color: #ffffff;
  border: 2px solid #2e0000;
  color: white;
}

.new-img-sec-c1 {
  border-top-left-radius: 40px;
}

.step-img-value {
  width: 630px;
  height: 350px;
  border-top-left-radius: 40px;
}

.step-img-tai {
  width: 96%;
  height: 350px;
}

.img-ft-img-sec {
  margin-top: 40px;
  margin-bottom: 20px;
}

.rate-btn-from {
  width: 96%;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}

.offers__info_rates {
  padding-bottom: 6px;
  padding-left: 5px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.offers__info_rates P {
  margin-bottom: 0px;
  color: #fff;
}

.st-flex {
  display: flex;
}

.sg-country-name {
  font-size: 18px;
  color: #320063 !important;
  font-weight: 600;
  padding-left: 10px;
}

.book-sg-country {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  text-align: center;
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  border: 0;
  width: 100%;
}

.hm_link:hover {
  color: #320063 !important;
}

.route-trip-3 {
  margin-bottom: 36px;
}

.recentSearchesRow {
  @include mobile {
    margin-top: 1%;
  }

  .ducarosuel {

    padding: 5px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    transition: all 0.5s ease;
    background: #fff;
  }

  .origin-item-sel {
    width: 100%;
    display: block;
    flex-wrap: nowrap;
    padding-left: 12px;
    border-left: 2px solid #dbe3ef;
    position: relative;
  }

  .origin-item-sel::before {
    content: " ";
    position: absolute;
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    top: 0;
    left: -4px;
  }

  .origin-item-sel::after {
    content: " ";
    background-color: #dbe3ef;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: -4px;
  }

  .city-due {
    display: inline-block;
    // width: 100%;
    font-size: 11px;
    text-transform: uppercase;
    color: #333;
    padding: 0px 0px 0px 5px;

    font-weight: 600;
  }

  .iconsDiv {
    color: #007bff !important;
  }

  .time-due {
    padding: 0px 0px 0px 5px;
    font-weight: 400;
    font-size: 11px;
  }
}

.home-best-trip {
  margin-bottom: 0px;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.container-holiday-cont {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  width: 1132px;
  margin: auto;
  border: 2px solid transparent;

  @include mobile {
    width: 100%;
  }
}

.container-holiday-cont1 {
  width: 50%;
  border-bottom: 1px solid #979797;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-right: 2%;

  @include mobile {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 0%;
    padding-right: 0%;
  }
}

.holiday-cont-heading {
  font-size: 16px;
  letter-spacing: 0.31px;
  font-weight: 700;
  color: black;
}

.holiday-cont-inner {
  font-size: 12px;
  font-style: lato, sans-serif;
  color: #4A4A4A;
}

.main-box-dis-work-section1 {
  margin-top: 7%;

  @include tab {
    margin-top: 52% !important;
  }

  @include minitab {
    margin-top: 52% !important;
  }

  @include ipadpro {
    margin-top: 36%;
  }
}

.main-box-dis-work-section {
  width: 80%;
  margin: auto;
  background-color: white;
  padding: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-radius: 10px;
  margin-bottom: 4%;
}

.main-box-dis-work {
  border: 1px solid rgb(195, 195, 195);
  width: 33%;
  margin: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @include mobile {
    width: 100%;
  }


  .main-box-dis-work1 {
    width: 100%;
    border: 2px solid transparent;
    padding: 1%;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;

    @include mobile {
      width: 100%;
    }
  }

}

.main-box-dis-work2 {
  border: 2px solid transparent;
  width: 64%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;

  @include mobile {
    width: 100%;
  }
}

.main-box-dis-work3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @include mobile {
    width: 100%;
  }
}

.liks-about-sigup {
  border: 2px solid transparent;
  text-align: right;
  width: 98%;
  margin-top: 1%;
}

.homepage-sigup-btn {
  background-color: #292929;
  color: white;
  padding: 1%;
  margin-left: 1.5%;
}

.homepage-aboutus-cot {
  color: black;
  text-decoration: underline;
}

.mainn-section-tophotels {
  .slick-initialized {

    .slick-prev:before,
    .slick-next:before {
      font-family: "slick";
      font-size: 20px;
      line-height: 1;
      color: #c4c4c4;
      opacity: 0.75;
      padding: 25%;
      border-radius: 50%;
      background-color: rgb(0, 57, 143);
    }
  }
}

.heading-for-over-banner {
  border: 2px solid transparent;
  position: absolute;
  top: 14.5%;
  left: 12%;
  //width: 50%;
  z-index: 11;
  text-align: center;

  @include ipadpro {
    top: 13.5%;
    left: -1%;
    display: none;
  }

  @include tab {
    top: 16.5%;
    left: -1%;
    display: none;
  }
  @include minitab{
    top: 16.5%;
    left: -1%;
    display: none;
  }

  @include mobile {
    width: 100%;
    top: 33%;
    left: 0%;
    display: none;
  }

  h1 {
    font-size: 57px;
    color: white;
    font-weight: bolder;
    font-family: "Baskervville", serif;
    line-height: 1.3;

    @include mobile {
      font-size: 25px;
    }
  }
}