@import "../../assets/variableStyles.scss";

#footer {
  .footer__links {
    padding-bottom: 30px;

    .antd-container {
      max-width: 1025px;
      margin: auto;

      @include mobile {
        padding: 0 15px;
      }
    }

    .footer-column {
      p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
        color: rgb(54, 68, 69);
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-bottom: 2px;

          a {
            color: #2e0000;
            font-size: 15px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer_links_wrapper {
    background-color: rgb(255, 255, 255);
    min-height: 118px;
    padding: 5px 30px;

    .links_wrapper {
      max-width: 995px;
      margin: 0 auto;
      padding: 30px 40px;

      @include mobile {
        text-align: center;
        padding: 40px;
      }

      .social_links {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;

        a {
          background: #505c75;
          padding: 8px 12px;
          border-radius: 50%;
          margin: 0 7px;
          color: #fff;

          &:hover {
            background-color: #1c2b39;
          }
        }
      }

      .social_fb_text {
        @include mobile {
          display: none;
        }

        * {
          margin-right: 3px;
        }

        button.btn {
          padding: 0px 10px;
          font-size: 12px;
          font-weight: 700;
        }

        small a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer_bottom_section {
    background-color: rgba(178, 178, 178, 0.379);
    // background-color: #1c2b39;
    // background-color: #2e0000;
    // background-image: linear-gradient(to right, #320063, #0f76bb);
    color: #fff;

    .footer_social_info {
      list-style-type: none;
      background-color: #263545;
      padding: 10px 10px;

      @include mobile {
        display: none;
      }


      ul {
        display: flex;
        justify-content: center;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px 0;
          margin-right: 50px;

          i {
            font-size: 40px;
            margin-right: 20px;
          }

          .cake-icon {
            font-size: 25px;
            border: solid 2px #969dac;
            border-radius: 50%;
            padding: 8px;
          }

          .hotels-icon {
            font-size: 28px;
            font-weight: 800;
            border: solid 2px #969dac;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 11px;
            font-style: normal;
          }

          p {
            margin-bottom: 0px;
            color: #dcdee3;
            line-height: 0.8;

            // padding-left: 20px;
            strong {
              display: block;
              font-family: Suisse Intl, Helvetica Neue, Helvetica, Arial, sans-serif;
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .footer_bottom_selection1 {
      background-color: white;
      padding: 20px;

    }

    .bottom_countries {
      .countries-expand-mobile {
        display: none;

        @include mobile {
          display: block;
          font-size: 16px;
          line-height: 1.2;
          font-weight: 400;
          text-align: center;
          padding: 34px 16px 16px;
        }
      }

      .countries_links {
        max-width: 1191px;
        margin: 0 auto;
        padding: 5px;
        text-align: center;

        @include mobile {
          padding: 16px 40px;
          text-align: left;
        }

        .footer-box {
          position: relative;
          width: 100%;
          padding-bottom: 28px;

          @include mobile {
            padding-bottom: 12px;
          }

          .footer-bg-img {
            .background {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-repeat: no-repeat;
              margin: -25px;

              @include mobile {
                display: none;
              }
            }
          }

          .country-heading {
            font-weight: 700;
            color: #fff;
            line-height: 1.2;

            @include mobile {
              font-size: 16px;
              margin-bottom: 4px;
            }
          }

          .countries_list {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            @include mobile {
              padding-top: 0px;
            }

            li {
              display: block;
              line-height: 8px;
              padding: 4px 14px 4px 0;

              a {
                color: #969dac !important;
              }
            }
          }
        }

        .footr-inter-sites {
          width: 100%;
          padding-top: 25px;
          padding-left: 20px;

          @include mobile {
            text-align: left;
          }

          p {
            font-weight: 600;
            text-align: left;
            color: rgb(46, 45, 45);
            line-height: 24px;
            margin-bottom: 2px;
            font-size: 20px;
            font-family: "Suisse Int'l", "Helvetica Neue", Helvetica, Arial, sans-serif;

            @include mobile {
              font-size: 15px;
              text-align: left;
            }
          }

          .footer-flex {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .cms-pages-links {
            display: block;
            flex-wrap: wrap;
            justify-content: start;
            text-align: justify;
            letter-spacing: 1px;



            @include mobile {
              justify-content: space-between;
            }

            li {
              display: block;
              line-height: 1;
              padding: 4px 10px 4px 0;



              a {
                color: rgb(77, 77, 77);
                font-size: 14px;
                font-weight: 400;
                transition: all 0.8s ease 0s;
                line-height: 20px;
              }
            }
          }
        }
      }

      .footer-logos {
        text-align: center;
        padding: 10px;
        margin-top: 20px;
        display: flex;
        justify-content: center;

        .logo1 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -95px;
          width: 78px;
          height: 30px;
        }

        .logo2 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -51px -164px;
          width: 48px;
          height: 30px;
        }

        .logo3 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -65px;
          width: 87px;
          height: 30px;
        }

        .logo4 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -255px -95px;
          width: 37px;
          height: 28px;
        }

        .logo5 {
          background: url("../../assets/images/footer/sprite_footer.png");
          background-position: -177px -35px;
          width: 103px;
          height: 30px;
        }
      }

      .footer-logos>div {
        margin-right: 0px;
        margin-left: 20px;

        @include mobile {
          margin-right: 0px;
        }
      }

      .copyright {
        font-size: 12px;
        line-height: 1.2em;
        text-align: center;
        padding: 5px 80px;
        border-top: 1px solid #adadad;
      }


      .social_wrapper {
        font-size: 20px;
        padding-bottom: 10px;
        text-align: left;

        a {
          color: rgb(0, 0, 0);

          .anticon {
            margin: 0 10px 10px 10px;
          }
        }
      }
    }
  }
}

.social_icons {
  a {
    padding-left: 15px;


  }

  img {
    margin-top: 20px;
    padding-top: 18px;

  }
}

@include mobile {
  .mobD-none {
    display: none;
  }
}

.footer-visa-images li {
  display: inline-block;
  padding: 0px 5px 5px 0px;
  margin-left: 4%;
}

// .iata-images li {
//   display: inline-block;
//   padding: 0px 10px 0px 10px;
//   margin-left:1%;
// }

// .footer-img{
//   padding: 10px;
//   margin-left: 10%;
// }

.cms-pages-links li a:hover {
  text-decoration: underline !important;
  // padding-left: 5px;
  // font-size: 18px !important;

}

.last-sec-down {
  padding-left: 0px;
}

.your-trip-app {
  font-size: 14px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 0.5px;
}

.g-store {
  margin: 15px 0;
  transform: perspective(1px) translateZ(0);
}

.g-store:hover {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

/*--address---*/
.footer-address-cc {
  display: none;
}

.footer-address-cc ul {
  list-style: none;
}

.footer-address-cc ul li {
  display: flex;
  margin-bottom: 14px;
}

.footer-address-cc ul i {
  margin-right: 7px;
  display: inline-block;
  padding-top: 0px;
  color: #320063;
}

.footer-address-cc ul li p {
  font-weight: normal !important;
  font-size: 15px !important;
}

.footer-address-cc {
  margin-top: 10px;
}

.traveller-family-c2 h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  transition: 0.3s;
  color: #ff6363;
}

.traveller-family-c2 p {
  margin-bottom: 0px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center !important;
}

.add-logos-ft {
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 10px;
  display: none;
}

.ft-col-1 {
  padding-top: 8px;
}

.ft-col-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bg-ft-col-22 {
  //background-image: linear-gradient(0deg,#15457b,#051423);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 94px;
  background: #fff;
  border-bottom: 1px solid;
  //border-top: 1px solid;
  display: none;
}

.bottom-postion-ft {
  position: relative;
}

.countries-top {
  padding-top: 5px;
}

.trv-img-cc-3 {
  height: 52px;
  width: 72px;
  display: block;
  margin: 0 auto;
  background-color: #fff;
  transition: all 0.5s ease;
  //box-shadow: 0 0px 1px #000;
}

.trv-img-cc-3:hover {
  transition: all 0.5s ease;
  transform: scale(1.1) perspective(1px);
  backface-visibility: hidden;
  cursor: pointer;
}

.our-affi-c1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  //text-transform: uppercase;
  text-align: center;
}

.our-affi-c1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  margin: 0px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}

.our-affi-c1 span {
  color: #ff6363;
}

.our-affi-c1-tr {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
  //text-transform: uppercase;
  text-align: center;
}

.our-affi-c1-tr::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 60%;
  margin: 0px auto;
  width: 10%;
  height: 3px;
  background: #505050;
}

.our-affi-c1-tr span {
  color: #ff6363;
}

@media screen and (max-width: 768px) {
  .footer-address-cc {
    position: relative;
    top: 0px;
    right: 0px;
  }

  .bg-ft-col-22 {
    position: relative;
  }

  .countries-top {
    padding-top: 0px;
  }

  .trv-img-cc-3 {
    margin-bottom: 5px;
  }
}

.img-fingures-css {
  width: 20px;
  margin-right: 2%;
}

.footer-icons-andpayments3 {
  border-top: 1px solid rgb(178, 178, 178);
  border-bottom: 1px solid rgb(178, 178, 178);
  width: 100%;
  display: inline-flex;
  white-space: nowrap;
  padding: 10px;

  // margin-top: 2%;
  @include tab {
    width: 86%;
    margin-left: -18%;
  }

  @include mobile {
    width: 100%;
    margin-left: 0;
  }

  p {
    display: inline-flex;
    position: absolute;
  }

}

.footer-logo-bottom {
  border-top: 1px solid rgb(178, 178, 178);
  border-bottom: 1px solid rgb(178, 178, 178);
  width: 80%;
  margin: auto;
  margin-top: 2%;
  justify-content: space-evenly;


}

.footer-icons-andpayments {
  width:88%;
  margin: auto;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;

  @include tab {
    width: 100%;
  }

  @include mobile {
    width: 100%;
  }
}

.footer-logo-bottom1 {
  flex-wrap: wrap;
  margin: auto;
  margin-top: 20px;
}

.pay-ment-img {
  padding-top: 35px;
}

.footer-icons-andpayments1 {
  border: 2px solid transparent;
  width: 34%;
  margin-left: 45%;

  p {
    font-size: 12px;
    font-weight: bold;
    margin-left: 80px;
    color: #2c2a2a;
  }

  @include tab {

    width: 100%;
    margin-left: 40%;
  }

  @include mobile {
    width: 100%;
    margin-left: -70%;
    margin-top: 30%;
  }
  @include xsmobile{
    margin-left: -108%;
    margin-top: 40%;
  }
}