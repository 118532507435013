@import "../../assets/variableStyles.scss";

.checkout-heading {
  //background: linear-gradient(to left, #333333, #2e0000);
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

  padding: 15px 0;
  // padding-top: 145px;
  padding-top: 90px;

  @include mobile {
    margin-top: 15%;
    padding-top: 0px;
  }

  @include tab {

    padding-top: 0px;
  }

  @include minitab {

    padding-top: 0px;
  }

  @include ipadpro {

    padding-top: 0px;
  }


  .checkout-heading-container {
    max-width: 1100px;
    margin: auto;
    padding: 0 15px;

    .goback {
      margin-bottom: 10px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .anticon-arrow-left {
        margin-right: 7px;
      }

      a {
        color: #000000;
        display: flex;
        align-items: center;
      }
    }
  }

  h3 {
    color: #000000;
    margin-bottom: 0px;

    @include mobile {
      font-size: 16px;
    }
  }
}

.checkout-section {

  .service-cahrge-table {
    .service-charge-header {
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));

      ul {
        display: flex;
        justify-content: space-between;
        padding: 15px;

        li {
          color: #fff;
        }
      }
    }

    .service-charge-body {
      ul {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border: 1px solid #969dac;
        border-top: none;
      }
    }
  }
}


.flight-checkout {
  width: 100%;
  height: 100%;


}

.checkout-body-sec {
  background: #e2f1ff;
  padding: 20px 35px;

  @include mobile {
    padding: 15px 0px;
  }

  .checkout-container {
    max-width: 1395px;
    margin: auto;
    padding: 0 15px;

    .checkout-bottom-part {
      width: 67%;

      @include tab {
        width: 100%;
      }

      @include ipadpro {
        width: 100%;
      }

      @include minitab {
        width: 100%;
      }

      @include mobile {
        width: 100%;
        order: 0;
      }

      .user-details {
        .trav-details {
          padding-bottom: 16px;
          box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
          background: white;
          border-radius: 6px;
          margin-top: 180px;

          .travhead {
            padding: 0 8px;
            background: linear-gradient(90deg, #c7dffe 0%, #d8f2ff 100%);
            display: flex;
            justify-content: space-between;
            z-index: 1;

            .heading-trav {
              display: inline-flex;
              padding-top: 6px;
              font-size: 16px;
              font-weight: 600;
              margin-top: 4px;
            }

            .end-card {
              display: inline-flex;
              padding-top: 6px;

              p {
                font-size: 13px;
                padding-top: 6px;
              }
            }
          }

          .pax-info-card {
            .pax-head-title {
              font-size: 16px;
              font-weight: 600;
              margin-left: 30px;
              margin-top: 10px;
            }

          }

          .ssr-in {
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .checkout-top-part {
      margin-top: 3.5%;
      width: 30%;

      @include tab {
        width: 100%;
        margin-top: 6%;
      }

      @include minitab {
        width: 100%;
      }

      @include mobile {
        width: 100%;
        order: 0;
      }

      @include ipadpro {
        width: 100%;
      }
    }

    .checkout-sticky-part {

      position: sticky;
      top: 18%;

      .ant-row {
        //border-bottom: 0.5px solid rgb(225, 225, 225);
      }

      .grand-total-card {

        p {
          margin-bottom: 0;
        }

        .pax-total-price {
          display: flex;
          justify-content: space-between;
          color: #d63b05;

          .pax-type {
            p {
              font-size: 20px;
              padding: 4px 17px;

              height: 35px;
              line-height: 35px;
              font-weight: 600;

            }
          }

          .total {
            display: flex;

            p {
              font-size: 18px;
              padding: 8px 18px 6px 0;
            }

            .amount {
              font-weight: 600;
            }
          }
        }
      }
    }


    .titlewrapper {

      margin-top: 30px !important;

      .info-1 {
        margin-bottom: 40px;



        .heading {
          color: #1c2b39;
          font-weight: 100;
          font-size: 20px;


          @include mobile {
            margin-bottom: 10px;
            font-size: 16px;
          }
        }

        .sub-heading {
          color: #5e6267b1;
          font-size: 14px;
          display: inline-flex;
          padding-left: 8px;
        }

      }

      .ant-input-group-wrapper {
        .ant-input-group-addon {
          width: 35%;
          padding: 0;

        }
      }

      .contactDetails-headr {

        padding: 0 18px 24px;
        background: white;
        box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
        border-radius: 6px;
        margin-top: 30px;

        .cntct-info {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          height: 45px;
          width: 862px;
          margin-left: -18px;
          font-size: 17px;
          font-weight: 600;
          padding-top: 6px;
          padding-left: 6px;
          margin-bottom: 16px;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;

          @include mobile {
            width: 112%;
          }

          .cntct-card-info {
            margin: 0;
            box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
          }
        }
      }
    }

    .checkout-custom-card {
      padding: 12px 18px;
      margin: 12px 18px 18px 18px;

      border: none;

      box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;

      .ant-card-body {
        padding: 0;
      }

      .card-adult-text {
        margin-bottom: 13px;

        @include mobile {
          flex-direction: row;
          flex-wrap: inherit;
        }

        .col-md-3,
        .col-md-9 {
          @include mobile {
            padding: 0px;
          }
        }
      }

      .pax-heading {
        font-weight: 600;
        margin-bottom: 4px;
      }

      .pax-head-hr {
        hr {
          border-top: 1px solid #bcd5f5;
          max-width: 100%;
        }
      }

      .pax-info-form {
        .form-group {
          margin-bottom: 25px !important;

          @include mobile {
            margin-bottom: 0px !important;
          }

          .plaintext {
            border: none !important;
          }

          input[type="text"] {
            border: solid 1px #bcd5f5 !important;

            @include mobile {
              font-size: 15px;
              height: 45px;
            }
          }

          label {
            color: #505c75;

            @include mobile {
              font-weight: 600;
            }

            span {
              color: red;
              font-size: 18px;
            }
          }

          select {
            border: solid 1px #bcd5f5;

            @include mobile {
              height: 45px;
              font-size: 15px;
            }
          }

          .title-select {
            max-width: 130px;
            border: solid 1px #bcd5f5;

            @include mobile {
              max-width: 100%;
            }
          }

          .dob-select {
            display: flex;

            @include mobile {
              flex-direction: column;
              align-items: flex-start;
            }

            .title-select {
              margin-right: 10px;

              @include mobile {
                margin-bottom: 18px;
                margin-right: 0px;
              }
            }

            input {
              max-width: 130px !important;

              @include mobile {
                max-width: 100%;
              }
            }
          }

          .Country-select {
            max-width: 200px !important;

            @include mobile {
              max-width: 100%;
            }
          }
        }

        .contact-details-text {
          p {
            font-size: 13px;
            color: #969dac;

            @include mobile {
              font-size: 12px;
              margin-top: 10px;
            }
          }
        }

        .register-check {
          display: flex;

          p {
            font-size: 15px;
          }

          span {
            color: #e2076a;
            font-weight: 700;
          }
        }
      }

      .hr-line {
        margin-top: 0px !important;

        @include mobile {
          display: none;
        }
      }

      .airline-bottom-section {
        .airline-bottom-section-left {
          .trip-timing {
            text-align: center;

            p {
              font-weight: 700;
            }

            .trip-row-mobile {
              @include mobile {
                flex-wrap: nowrap;
                justify-content: flex-start;
              }

              .col-md-2 {
                i {
                  @include mobile {
                    margin-left: -33px;
                  }
                }
              }

              .arrive-time {
                p {
                  @include mobile {
                    margin-left: -38px;
                  }
                }
              }
            }
          }

          .trip-duration {
            display: flex;

            span {
              color: #969dac;
              font-size: 13px;
            }

            p {
              font-size: 14px;
              padding-left: 4px;
            }
          }
        }

        .airline-bottom-section-right {
          text-align: right;

          button {
            background: none;
            color: #007bff;
            font-size: 13px;
            font-weight: 700;
            padding: 6px 16px;

            &:hover {
              background-color: #007bff;
              color: #fff;
            }
          }
        }
      }

      .ant-form-item-label {
        font-size: 14px !important;
        font-weight: 600;
        color: #494949;
        font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

      }
    }

    h3 {
      @include mobile {
        margin-bottom: 12px;
      }
    }

    .gst-req-form {
      border: 1px solid rgb(185, 185, 185);
      border-radius: 6px;
      box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377);
      margin-top: 30px;
      padding: 2px 16px 20px 16px;

      h6 {
        font-size: 16px;
        font-weight: 400;
        margin-top: 4px;

      }

      p {
        font-size: 12px;
        color: rgb(156, 154, 154);
        margin-top: -4px;
      }

      .heading {
        font-size: 16px;
        font-weight: 600;
        margin-left: 4px;
        color: #313131;
      }

      .gst-custom-details {
        margin-top: 5px;
        margin-bottom: 30px;
      }
    }
  }

  .flight-details-container {
    box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
    border-radius: 6px;
    margin-bottom: 70px;
    margin-top: 10px;

  }

  .change-flight-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    height: 50px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    // background: linear-gradient(to left, #333333, #2e0000);
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));



    @include mobile {
      width: 100%;
    }

    h3 {
      font-size: 20px;
      padding: 10px 10px 0;
      color: whitesmoke;
    }

    p {
      color: #fff;
      cursor: pointer;
      padding: 15px 10px 0;
    }
  }

  .flight-details-card {


    @include mobile {
      width: 100%;
    }
  }

  .book-pay-section {
    margin: 25px 0px;

    .ant-card-body {
      padding: 0;
    }

    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;

      @include mobile {
        padding: 16px;
      }

      .book-pay-tc {
        display: flex;

        span {
          color: red;
        }

        p {
          margin-bottom: 0;
        }
      }

      .book-pay-offers {
        display: flex;

        p {
          font-size: 15px;

          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }

      .book-fli-pay-btn {


        .ant-btn {
          padding-bottom: 32px;
          width: 335px;

          margin-left: 207px;
          border-radius: 4px;
        }

        button {
          background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          color: #fff;
          border: none;
          font-size: 20px;
          font-weight: 600;
          padding: 14px 20px;
          height: 100%;

          width: 335px;

          margin-left: 207px;
          border-radius: 4px;


          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }

        .book-and-content {
          padding-top: 20px;

          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }
  }

  .btn-continue {
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    color: white;
    border-radius: 4px;
    width: 50%;
    margin-left: 30% !important;

    font-size: 19px;
    height: 45px;

    @include mobile {
      width: 100%;
    }

    .ant-btn {
      cursor: pointer;
      background: #d35912;
    }

  }

}


.payer-radio-group {
  @include mobile {
    flex-wrap: inherit;
  }
}

.contact-number {
  display: flex;

  .dob-select {
    margin-right: 10px;
  }
}

.pax-count-card {
  border: none;

  .accordion {
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    padding: 5px 15px;

    @include mobile {
      margin-bottom: 0px;
    }
  }

  .mobile_acc {
    border-bottom: none !important;
    padding: 5px;
  }

  .all-taxes {
    font-size: 12px;
    color: #969dac;
    margin-bottom: 0px !important;
    margin-bottom: 10px !important;
  }

  .pax-total-price {
    display: flex;
    justify-content: space-between;

    .pax-type {
      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
    }

    .total {
      display: flex;

      i {
        @include mobile {
          font-size: 25px;
          padding-left: 7px;
        }
      }

      p {
        font-size: 20px;
        margin-bottom: 0px;
      }

      .amount {
        font-size: 20px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }

  .pax-pre-price {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;

    .pax-type {
      p {
        font-size: 16px;
        margin-bottom: 0;

        @include mobile {
          margin-bottom: 0px;
          font-weight: 600;
        }
      }
    }

    .total {
      display: flex;

      i {
        @include mobile {
          font-size: 25px;
          padding-left: 7px;
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 0px;
      }

      .amount {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }

  .service-price {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    .main-price {
      font-size: 14px;
      font-weight: 700;
      padding-left: 6px;
    }
  }

  .pax-count-card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 0px;
  }
}

.pax-count-header-top {
  display: flex;
  justify-content: space-between;
  line-height: 1;

  @include mobile {
    padding: 8px 0px;
  }

  cursor: pointer;

  .pax-header-left-sec {
    display: flex;

    p {
      padding-left: 6px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.border-line {
  border-top: solid 1px #ccc !important;
}

.pricepopup {
  .ant-popover-title {
    padding: 6px 12px;
    font-weight: bold;
  }

  .ant-popover-inner-content {
    padding: 7px 12px 12px 12px;

    .pax-count-acc-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      color: #505c75;
      padding-top: 5px;

      p {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
}

.details-modal {
  .details-header {
    p {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      font-size: 34px;
    }
  }

  .modal-body {
    padding: 0px 15px;
  }
}

.faresummary_mobile {
  display: none;

  @include mobile {
    display: block;
    margin-top: 23px;
  }
}

/********** Antd Styles *******/
.passenger-form {
  .ant-form-item-label {
    text-align: start;
  }
}

.flight-checkout-contact-form {
  height: 80px;
  margin-top: -38px;

  .ant-form-item-label {
    text-align: start;
  }
}

.book-fli-pay-btn {
  .btn {
    margin-left: 180px;
    border-radius: 4px;
    width: 60%;
    padding-bottom: 32px;

    @include mobile {
      margin-left: 60px;
    }
  }
}

/********** fare summary card **********/
.sticky-card-container {
  position: sticky;
  top: 0;
  margin-bottom: 0px;

  .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: #fff;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    background-color: #fff;
  }

  .ant-collapse-content-box {
    padding-top: 16px !important;

    p {
      margin-bottom: 0;
    }
  }
}

.airline-details-left {
  .airline-dte {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      order: 2;
    }

    h5 {
      font-weight: 700;
      margin-bottom: 0;

      @include mobile {
        font-size: 15px;
        margin-bottom: 5px !important;
      }
    }

    span {
      font-size: 14px;
      color: #969dac;
      margin-left: 10px;

      @include mobile {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }


  .airline-src-dest {
    display: flex;
    padding-top: 6px;
    align-items: center;

    p {
      font-weight: 600;
      margin-bottom: 0;
    }

    .airline-line {
      font-size: 12px;
      width: 100%;
      border-top: solid 1px #969dac;
      margin: 6px 9px;
    }
  }
}

.airline-details-right {
  text-align: right;
  padding-top: 10px;

  p {
    font-weight: 700;
    font-size: 14px;
  }
}

.direct-text {

  font-weight: 600;

  @include mobile {}

  p {
    margin-bottom: 0;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.airline-details {
  border-bottom: 1px solid #969dac;
  padding-bottom: 15px;

  @include mobile {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .airline-row-mobile {
    align-items: center;

    @include mobile {
      flex-wrap: nowrap !important;
      justify-content: space-between;
    }

    .col-md-5 {
      @include mobile {
        order: 1;
      }

      .airline-dte {
        @include mobile {
          padding: 2px 10px;
        }

        h5 {
          @include mobile {
            font-size: 13px;
          }
        }

        span {
          @include mobile {
            font-size: 10px;
          }
        }
      }
    }

    .col-md-3 {
      @include mobile {
        padding: 0px 2px;
        order: 0;
        max-width: 62px;
      }
    }

    .src-dest-mobile {
      border: 2px solid transparent;
      width: 100%;

      display: flex;


      @include mobile {
        display: none;
        margin-top: 0%;
      }
    }
  }

  .source-detailsof-flight {
    border: 2px solid transparent;
    padding: 1%;
  }

  .break-journey-city {
    background-color: transparent;
    border: 1px solid transparent;
    position: relative;
    margin: 0 0px;
    flex: 1;
    margin-top: 3%;

    .inner_wrapper {
      position: absolute;
      width: 100%;
      border-top: 2px solid #2e0000;
      text-align: center;

      .stop_points {
        display: inline-block;
        margin: 5px;
      }
    }
  }

  .code {
    margin: 0px 0px 0px;
  }

  .airline-details-left {
    .airline-dte {
      display: flex;
      align-items: center;

      h5 {
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .airline-src-dest {
      display: flex;
      padding-top: 6px;

      p {
        font-weight: bold;
        color: #000;
      }

      .airline-line {
        font-size: 12px;
        width: 100%;
        border-top: solid 1px #969dac;
        margin: 6px 9px;
      }
    }
  }

  .airline-details-right {
    text-align: right;
    padding-top: 10px;

    p {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.trip-details {
  padding: 15px 0 0;

  .ant-row {
    align-items: center;

    @include mobile {
      justify-content: space-between;
    }
  }

  .trip-timimg-details {
    ul {
      display: flex;

      li {
        margin-right: 15px;
        font-weight: bold;
      }
    }
  }

  .duration-timing {
    display: flex;
    font-size: 12px;

    @include mobile {
      display: none;
    }

    p {
      margin-bottom: 0;
      color: #969dac;
    }

    span {
      color: #000;
    }
  }

  .details-btn {
    text-align: end;
  }
}

.trip-duration-strip {
  background: #eaebee;

  .trip-time {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    padding-left: 40px;

    i {
      font-size: 20px;
    }

    p {
      margin-bottom: 0px;
      font-size: 15px;
      padding-left: 7px;
    }
  }
}

.flight-cards-details {
  background-color: #ffffff !important;
  border-radius: 8px !important;
  margin-top: -40px;

  @include mobile {
    margin-top: 0%;

  }




  box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
  font-family: GT Walsheim,
  Helvetica,
  Arial,
  sans-serif !important;

  .fareTitle {
    font-weight: 600;
  }

  .total-card {
    border-radius: 8px !important;
    width: 118%;
    margin-left: -9%;
    // box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;

    @include tab {
      width: 108%;
      margin-left: -4%;
    }

    @include ipadpro {
      width: 100%;
      margin-left: 0%;
    }

    @include mobile {
      width: 116%;
      margin-left: -8%;
    }



  }

  .price-tag {
    // background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    width: 100%;
    color: black;
    margin-top: -25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 50px;

    p {
      font-size: 20px;

      font-family: Open Sans, sans-serif !important;
      padding: 10px 20px;
    }
  }

  .grand_total_card_row {
    padding: 0;

    p {
      margin-bottom: 14px;
      font-size: 14px;
      padding: 6px 20px;

    }

    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .anticon-question-circle {
        margin: 0 0 14px 5px;
        color: #969dac;
        cursor: pointer;
      }
    }
  }
}

.flight-details-modal-container {
  .trip-body {
    padding-left: 40px;
    padding-top: 14px;

    .trip-strip {
      position: relative;

      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 10px;
        left: -13px;
        bottom: 24px;
        background: #f9b975;
        width: 1px;
      }
    }

    .trip-body-details {
      display: flex;
      position: relative;

      &::before {
        display: block;
        background-color: #f9b975;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        position: absolute;
        left: -15px;
        top: 9px;
        content: "";
      }

      p {
        font-size: 15px;

        &:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
  }

  .trip-bottom-duration {
    padding-left: 40px;
    padding-bottom: 20px;

    p {
      margin-bottom: 0px;
      font-size: 13px;
    }
  }

  .ant-modal-body {
    padding: 0 !important;
  }
}

.gst-details {
  .heading {
    color: #1c2b39;
    font-weight: 100;
    font-size: 20px;

    @include mobile {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}

.add-gst-text {
  display: flex;
  align-items: center;

  h4 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 20px;

    @include mobile {
      font-size: 15px;
    }
  }
}

.add-gst-block {
  .ant-collapse-borderless {
    background-color: white;
  }

  .ant-collapse-borderless>.ant-collapse-item {
    border-bottom: transparent;
  }
}

.promo-card {
  margin-top: 55px;

  .line {
    border-bottom: 1px solid rgba(128, 128, 128, 0.619);
    width: 116%;

  }

  @include mobile {
    margin-top: 20px;
  }

  .promo-card-header {
    // background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    width: 118%;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include ipadpro {
      width: 106%;
    }

    @include tab {
      width: 108%;
    }

    @include mobile {
      width: 116%;
    }

    .name {
      font-size: 16px !important;
      padding: 12px 20px;
      color: rgb(3, 3, 3);

    }
  }

  .ant-row {
    border-bottom: none !important;

  }

  .promo-input-box {
    width: 110%;
    margin-top: 10px;
    margin-left: -5px;
    margin-bottom: 10px;
    border: none !important;
    border-bottom: 1px solid rgb(128, 128, 128) !important;





    @include mobile {
      width: auto;
    }
  }
}

.ant-form {
  button {
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    color: #fff;
    border: none;
    font-size: 16px;
    margin-top: -1px;
    border-radius: 0px;

    @include mobile {
      margin-top: 1%;
    }
  }
}

.promo-input-btn {
  margin-right: 0 !important;
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  color: #ffffff;
  border: none;
  font-size: 16px;
  margin-top: 4px;

}

.inline-promo {
  display: flex;
  border-bottom: 1px solid rgba(128, 124, 124, 0.356);
  width: 110%;
  padding-bottom: 10px;
  margin-left: -10px;

}


.promo-input {
  .applied {
    color: rgb(58, 184, 0);
    text-transform: uppercase;
    font-size: 14px;

    p {
      padding-top: 10px;
    }
  }

  .apply-promo {
    margin-top: 8px;
    border-radius: 4px;
  }
}

.service-charge {
  margin-top: 25px;

  .service-charge-header {
    h4 {
      margin-bottom: 25px;
      font-size: 23px;

      @include mobile {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }

  .service-charge-table {
    .ant-table {
      overflow: hidden;
    }
  }
}

.responsive-departure {
  @include mobile {
    order: 2;
  }
}

.flight-review-row {
  @include mobile {
    flex-wrap: wrap-reverse !important;
  }
}

.passport-dates {
  .ant-picker {
    width: 100%;
  }
}

.discount-price {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;

  .total {
    display: flex;

    p {
      margin-right: 5px;
    }
  }
}

.flights-poins-sec .ant-card-bordered {
  // border-radius: 8px;
  // box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
}

.grand-total-card .ant-card-bordered {
  border-radius: 8px;
}

.flights-poins-sec .flight-syt-time {
  position: relative;
  left: 0px;
  top: 0px;
}

.flights-poins-sec .details-bag-icon {
  position: relative !important;
  top: 0px !important;
  right: 0px !important;
}

@media only screen and (max-width: 768px) {
  .margin-contact .ant-form-item {
    margin-bottom: 0px;
  }
}


.airline-img1 {
  border: 2px solid transparent;
  justify-content: space-evenly;
  flex-wrap: wrap;
  display: flex;
  width: 30%;
  text-align: left;
}

.main-box-of-flight-details {
  border: 2px solid transparent;
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.flight-journeydate {
  border: 2px solid transparent;
  text-align: center;
  width: 55%;
  font-weight: 600;
}

.flight-stops {
  border: 2px solid transparent;
  font-weight: 600;
  width: 15%;
  text-align: right;
}

.grand-total-card {
  .flight-cards-details {
    .ant-card-body {
      padding-bottom: 0px;
    }
  }
}

.promo-cp-coupons {
  padding: 4px 10px;

  .inline-promo {
    .promo-key {
      margin: 10px;
      font-size: 14px;
      line-height: 1.42857;
      color: #592653;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 32px;
      font-weight: 600;

    }

    .save-offer {

      font-size: 12px;
      position: absolute;
      margin-left: 30px;
      padding-top: 18px;
    }
  }

  .promo-percentage {
    position: absolute;
    margin-top: -40px;
    margin-left: 60%;

    p {
      color: rgba(128, 128, 128, 0.648);
    }
  }


}

.inner-card {
  //border: 1px solid gray;



  .type-flight {

    border-bottom-right-radius: 20px;
    width: 70px;
    background: rgb(98, 98, 98);
    margin-top: -24px;
    margin-left: -20px;


    p {
      padding-left: 5px;
      color: white;
    }
  }


  .inner-flight-card {
    .travel-info {
      font-size: 20px;
      //display: inline-flex;
      color: black;
      padding-left: 0px;
      letter-spacing: 1px;
      margin-left: -20px;

      img {
        width: 38px;
        margin-right: 10px;
        display: none;
      }

      .return-plane {
        rotate: -180deg;
      }
    }

    .travel-date {
      font-size: 14px;
      color: #000000;
      padding: 6px;
      display: block;

      @include mobile {
        display: none;
      }
    }

    .travel-date1 {
      font-size: 14px;
      color: #474646;
      padding: 6px;
      display: none;

      @include mobile {
        display: none;
        position: absolute;
        top: 8%;
        left: 55%;
      }
    }

    .flight-logo {
      margin: 20px 10px;
      display: inline-flex;
      margin-left: -15px;
    }

    .flight-name {
      display: inline-block;
      font-size: 14px;

      .airlinename {
        white-space: nowrap;
        width: 66px;
        //overflow: hidden;
        text-overflow: ellipsis;
      }

      .airlinenumber {
        color: #5b5959a5;
        font-size: 12px;
      }

    }

    .departure-start {
      // padding: 10px 8px;
      margin-left: -17px;

      .departure-time {
        font-size: 24px;
        font-weight: 600;
        color: #000;
      }

      .departure-time1 {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        margin-left: -33px;

        @include mobile {
          margin-left: 10px;
        }
      }


      .departure-info {
        font-size: 12px;
        color: #636262;


        h4 {
          font-size: 12px;
          font-weight: 800;
          margin-bottom: 0;
          color: rgb(0, 0, 0);
          max-width: 104px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          @include mobile {
            margin-left: 107px;
          }
        }
      }

      .departure-info1 {
        font-size: 12px;
        color: #636262;


        h4 {
          font-size: 12px;
          font-weight: 800;
          margin-bottom: 0;
          color: rgb(0, 0, 0);
          max-width: 104px;
          text-overflow: ellipsis;
          //overflow: hidden;
          white-space: nowrap;
          margin-left: -33px;

          @include mobile {
            margin-left: 107px;
            margin-bottom: 9px;
          }
        }
      }



    }
  }
}

.travel-duration {
  margin: 58px 0;

  .time {
    text-align: end;

    @include mobile {
      text-align: center;
    }

    p {
      color: grey;
      margin-bottom: 20px;
      padding-left: 18px;
    }

    .line-l {
      border-bottom: 1px solid rgba(167, 165, 165, 0.699);
      width: 235%;
      margin-bottom: 20px;
      margin-top: -12px;
      margin-left: 21px;

      .cir1,
      .cir2 {
        background: rgba(167, 165, 165, 0.998);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-bottom: 12px;
        margin-top: -12px;

        @include mobile {
          margin-top: -5px;
        }


      }

      .cir1 {
        left: -5px;
      }

      .cir2 {
        right: -111px;

        @include mobile {
          right: 0px;
        }
      }

      @include tab {
        width: 100%;
      }

      @include mobile {
        width: 83%;
      }



    }

    .status {
      border: 1px solid rgb(30, 204, 30);
      border-radius: 16px;
      color: rgb(60, 255, 0);
      display: inline-flex;
      font-size: 12px;
      padding: 0 20px;
      margin-left: 60px;
      margin-top: 4px;

      @include mobile {
        margin-left: 0px;
      }


    }
  }
}

.status {
  border: 1px solid rgb(30, 204, 30);
  border-radius: 16px;
  color: rgb(60, 255, 0);
  display: inline-flex;
  font-size: 12px;
  padding: 0 20px;
  margin-left: 30px;
  margin-top: 0px;

  @include mobile {
    margin-left: 0px;
  }


}

.arrival-col {
  margin-left: 115px;

  @include mobile {
    margin-left: 0px;

  }

  @include tab {
    margin-left: 75px;
  }
}

.layOver-time {
  background: linear-gradient(to right, #b2dcff, #d5d2d2);
  border-radius: 20px;
  margin-bottom: -11px;
  z-index: 1;
  margin-left: 37%;

  @include mobile {
    margin-left: 14%;
  }

  p {
    color: #000;
    margin-bottom: 0px;
    padding: 0px 15px;
    font-size: 12px;

    @include mobile {
      padding: 0 10px;
    }

  }
}

.line-layOver {
  border-bottom: 2px solid rgba(186, 186, 186, 0.6);
  width: 80%;
  margin-left: 15%;

}

.check-in-Rules {
  margin-top: 30px;

  box-shadow: 0 2px 5px 0 rgba(19, 42, 104, 0.377) !important;

  border-radius: 6px;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(19, 42, 104, 0.377) !important;
  }

  .check-in-hdr {
    background: linear-gradient(90deg, #c7dffe 0%, #d8f2ff 100%);
    padding-left: 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    // padding-top: 4px;
    h6 {
      font-size: 18px;
      padding-top: 6px;
    }

    p {
      font-size: 12px;
      margin-top: -4px;
      margin: -10px 0 0 29px;
      color: #4a4a4a;
    }
  }

  .check-in {
    background: rgba(255, 250, 250, 0.623);
    padding: 16px;
    margin: 0;
    border-radius: 6px;

    p {
      margin: 0;

      span {
        margin-right: 6px;
        color: #787676;
      }
    }
  }

}

.mobmobmobmobmob {
  // width: 20%;

  @include mobile {
    border: 2px solid transparent;
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }
}

.row-of-det-flight {
  @include mobile {
    margin-top: 8%;
  }
}

.col-icos-flight {
  @include mobile {
    width: 100%;
  }
}

.policy {
  .fares {

    h4 {
      font-size: 14px;
      color: #35459c;
      border: 2px solid #35459c00;
      // padding: 1px 3px;
      border-radius: 4px;
      max-width: max-content;

      &:hover {
        color: #bd0c21;
      }

      &:active {
        border: 1px solid #35459c;
        // padding: 1px 3px;
        border-radius: 4px;
        // transition: .2s;
        background: rgb(133, 145, 213);
        color: white;
      }

    }

  }
}

.baggage-table {
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 2px 3px 2px rgb(135, 132, 132);
  background: #f5f5f7;
  border-radius: 4px;
}

.baggage-info {
  margin: 10px 0;

  padding: 0 8px;

  .airtype {
    display: flex;
    justify-content: space-around;

    @include mobile {
      justify-content: start;
      position: absolute;
    }
  }

  .Check-in-Bag {
    display: flex;
    justify-content: space-around;

    @include mobile {
      justify-content: center;
    }
  }

  .Cabin-in-Bag {
    display: flex;
    justify-content: space-around;

    @include mobile {
      justify-content: end;
      position: relative;
      margin-top: -20px;
    }
  }
}

.headerbg {
  display: flex;
  justify-content: space-around;
  background: linear-gradient(to top, #5b004c, transparent);
  border-radius: 6px;
  padding-top: 5px;

  h4 {
    font-size: 15px;

    font-weight: 600;
    color: #fffbfb;
    text-shadow: 1px 2px 4px black;

    @include mobile {
      font-size: 12px;
    }
  }
}


.col-flight-det-pointsfromto {
  @include mobile {
    width: 100%;
    text-align: center;
  }
}

.email-flight-checkout-select {
  width: 500px;

  @include mobile {
    width: 100%;
  }
}

.flightcheckout-mob-elepsis {
  @include mobile {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sectionn-work-for-mob-only {
  display: none;

  @include mobile {
    display: block;
  }
}

.ssr-b {

  // display: flex;
  // max-width: 100%;
  // overflow: scroll;
  // max-height: 200px;
  // justify-content: space-evenly;
  // flex-wrap: wrap;
  // width: 100%;
  .ssr-a {
    border: 1px solid #35459c;
    border-radius: 4px;
    margin: 0 4px;
    max-height: 150px;
    position: relative;

    // background: #ffe8eb;
    // white-space: nowrap;
    // max-width: fit-content;
    // width: 100%;
    // .image-container {
    //   position: relative;
    //   overflow: hidden;
    // }

    .ssr-img {
      width: 100%;
      /* Set your desired dimensions here */
      height: auto;
      display: block;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #31313170, #000000d1);
      // background: linear-gradient(to bottom, rgba(49, 49, 49, 0.057), rgba(0, 0, 0, 0.655)); /* Adjust the gradient as needed */
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .rem-meal {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #313131a9, #000000d1);
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ovr-rem-sssr {

        position: absolute;
        top: 0;
        right: 0;
        padding-left: 8px;
        background: linear-gradient(256deg, #00000099, #bd0c21);
        border-bottom-left-radius: 12px;
      }
    }

    .rem-bagg {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #313131a9, #000000d1);
      color: white;
      /* Text color on the overlay */
      padding: 10px;
      /* Adjust the padding as needed */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .ovr-rem-sssr {

        position: absolute;
        top: 0;
        right: 0;
        padding-left: 8px;
        background: linear-gradient(256deg, #00000099, #bd0c21);
        border-bottom-left-radius: 12px;
      }
    }

    .pr-ssr-tag {
      font-size: 18px;
      /* Adjust the text size as needed */
      font-weight: bold;
      text-align: center;
      background: linear-gradient(90deg, transparent, #35459c, transparent);
    }
  }

  .ssr-c {
    // padding: 10px;
    text-align: center;

    .but-sel {
      background: #bd0c21;
      /* Background color for the button */
      color: white;
      padding: 2px 12px;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      display: inline-block;

      &:active {
        background: transparent;
        color: #bd0c21;

      }

    }

    .desc-ssr {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
      max-height: 60px;
    }

    .rem-meal {
      .rem-ssr {
        background: #ad061a;
        color: white;
        padding: 2px 12px;
        cursor: pointer;
        text-align: center;
        border-radius: 4px;
        // display: inline-block;
        margin-left: 5px;
      }
    }
  }

}

.tab1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  overflow: overlay;
  scroll-behavior: smooth;

  .brd {
    border: 1px solid #35459c;
    border-radius: 4px;
    margin: 0 10px;
    max-width: fit-content;
    min-width: 98px;
    background: linear-gradient(to right, #35459c59, #bd0c2154);
  }

  .we {
    color: rgb(0, 197, 0);
    font-size: 18px;
    font-weight: 600;
  }

  .cont {
    // background: #ffb8c0;
    display: block;

    .hd {
      color: #35459c;
    }

    .amntb {
      color: #bd0c21;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 13px;
      }
    }
  }
}

.user-details1 {
  // border: 1px solid #35459c;
  // border-radius: 4px;
  // padding: 4px;

  .extraser {
    background: linear-gradient(to right, #bd0c21, #35459c);
    color: white;
    border-radius: 4px;
    width: fit-content;
    padding: 0 4px;
    margin-top: -9px;
  }

  .ssr-div {
    display: flex;
    justify-content: space-between;
    max-width: 50%;

    @include mobile {
      max-width: 100%;
      display: block;
    }

    @include minitab {
      max-width: 72%;
    }

    @include tab {
      max-width: 72%;
    }

    .ssr-block {
      color: #ededed;
      border: 1px solid #35459c;
      border-radius: 4px;
      padding: 1px 4px;
      background: linear-gradient(45deg, #5a2653, #0f34ff8a);
      cursor: pointer;

      @include mobile {
        margin-bottom: 10px;
      }

      @include minitab {
        margin-right: 10px;
      }

      @include tab {
        margin-right: 10px;
      }
    }

  }

  .inf-ssr {
    display: flex;
    margin-top: 4px;

    .ssr-inf-1 {
      color: #35459c;


    }

    .ssr-inf-p {
      color: #7e7e7e;
      font-size: 12px;
      padding: 2px;

    }
  }
}

.mdl-footer {
  display: flex;
  justify-content: space-between;

  .ssr-block {
    cursor: pointer;
    //     border: 1px solid #35459c;
    //     border-radius: 4px;
    //     padding: 0 4px;
    //     background: linear-gradient(231deg, #bd0c21, #ffceceb8);
    //     color: #fff;
  }
}

.ssr-body {
  .ssr-part {
    border: 1px solid #bd0c21;
    border-radius: 6px;
    padding: 4px;
    background: linear-gradient(45deg, #e3e7ff, #ffefef);
    margin-bottom: 4px;

    .hdr-type {
      display: flex;
      justify-content: space-between;

      .seg-ssr {
        background: linear-gradient(280deg, #31b0ff, transparent);
        padding: 0 6px 0 0;
        border-radius: 0px 15px 15px 0;
      }
    }

    .ssr-pax-hdr {
      .ssr-pax-block {
        display: flex;
        justify-content: space-between;
        // border-left: 2px solid #bd0c21;
        padding: 2px 0;

        .ssr-pax-type {
          width: 30%;
          border-left: 2px solid #bd0c21;
          padding-left: 4px;
          background: linear-gradient(94deg, #bdbdbd8c, transparent);
          max-width: max-content;
          padding-right: 10px;
        }

        .seg-ssr-part {
          border: 1px solid #35459c;
          padding: 0 4px;
          border-radius: 8px;
          background: #c1cafa;
          cursor: pointer;

          &:hover {
            background: #ffff;
            color: #35459c;
          }
        }
      }
    }
  }
}

.desktop_crou {
  .slide-wrapper {
    // height: 98%;
    // width: 98%;
    padding: 4px;
  }

  @include mobile {
    display: none;
  }

  // @include tab {
  //   display: none;
  // }
}


.mobile_crou {
  .slide-wrapper {
    // height: 98%;
    // width: 98%;
    padding: 4px;

    .carousel-img {
      width: 100%;
      height: 200px;
    }
  }

  .text-flight {
    h5 {
      font-size: 18px;
    }

    .slick-arrow {
      width: 40px !important;
      height: 40px !important;
    }

    .slick-prev {
      left: 8px !important;
    }

    .slick-next {
      right: 8px !important;
    }
  }

  display: none;

  @include mobile {
    display: block;
  }

  // @include tab {
  //   display: block;
  // }
}

.tot-fare-tab {
  padding-top: 0;

  .tab {
    display: flex;
    justify-content: space-between;
  }
}

// body {
//   font-family: Arial, Sans-Serif;
// }

// .flightbox {
//   display: table;
//   margin: 0 auto;
//   clear: both;
//   position: relative;
// }

// .flightbody {
// }

// .flightdetails {
//   color: #2567a1;
//   display: block;
//   font-size: 16px;
//   text-align: center !important;
// }

//   .flightdetails span {
//       color: #efa410;
//       font-size: 11px;
//   }

// .flgtrow {
//   display: table;
//   width: 100%;
// }

// .innerrow {
//   display: block;
//   /* background: #FCB040; */
//   padding: 0 2px;
//   margin-top: 2px;
// }

// .flgtrow h2 {
//   display: block;
//   margin: 0;
//   padding: 2px 20px;
//   text-align: center;
//   background: #e7e7e7;
//   font-size: 14px;
//   font-weight: normal;
//   vertical-align: middle;
// }

// .leftwing, .rightwing {
//  // width: 25px;
//   height: auto;
//   display: table-cell;
// }

// .leftwing {
//   border-right: 2px solid rgb(15, 106, 238);
// }

// .rightwing {
//   border-left: 2px solid rgb(15, 106, 238);
// }

// .seatrobox {
//   display: table-cell;
//   background: #666;
//   padding: 0px;
//   color: #fff;
//   text-align: center;
//   vertical-align: middle;
//   margin-right: 20px;
// }

// .first-gallery {
//   margin-right: 25px;
// }

// .mainrow {
//   float: left;
//   width: calc(100% - 15%);
// }

// .leftwing, .rightwing {
//   width: 50px;
// }

// .windowrow .leftwing {
//   background: linear-gradient(270deg, #fff, rgb(15, 106, 238));
// }

// .windowrow .rightwing {
//   background: linear-gradient(90deg, rgb(15, 106, 238), #fff);
// }

// .emergencydoor {
//   border-radius: 20px;
//   color: #fff;
//   display: table;
//   font-size: 11px;
//   text-align: center;
//   width: 100%;
//   z-index: 100;
//   background: #fff;
// }

// .emergencytext {
//   background: red;
//   display: inline-block;
//   padding: 2px 5px;
// }

// .rightdoor, .leftdoor {
//   background: red;
//   border-radius: 5px;
//   display: table-cell;
//   font-size: 12px;
//   height: 20px;
//   padding: 0 2px;
//   width: 17px;
// }

// .seatrow {
//   list-style: none;
//   background: #fff;
//   display: table;
//   clear: both;
//   margin: 0;
//   padding: 0px;
//   border-left: 2px solid #fff;
//   border-right: 2px solid #fff;
// }

//   .seatrow li, .seathint {
//       float: left;
//       height: 23px;
//       border-radius: 3px;
//       border: 1px solid #c4c4c4;
//       padding: 0 3px 1px;
//       background: #fff;
//       margin-left: 16px;
//   }

//       .seatrow li span, .seathint span {
//           background: #f7f7f7 none repeat scroll 0 0;
//           border: 1px solid #c5c5c5;
//           border-radius: 0 0 3px 3px;
//           content: "";
//           float: left;
//           height: 16px;
//           /* margin: 0 1px; */
//           width: 25px;
//           font-size: 9px;
//           line-height: 11px;
//           text-align: center;
//           /*   padding: 3px 0;*/
//           /* margin-right: unset; */
//       }

//       .seatrow li.poor, .quitezone {
//           background: #F4D4FC;
//       }

//           .seatrow li.poor span, .quitezone span {
//               background: #DD7FF4;
//               border: 1px solid transparent;
//               color: #fff;
//           }

//       .seatrow li.good, .hintgood {
//           background: #68bd56;
//       }

//           .seatrow li.good span, .hintgood span {
//               background: #509c44;
//               border: 1px solid transparent;
//               color: #fff;
//           }

//       .seatrow li.drawback, .hintdrawback {
//           background: #f1f160;
//       }

//           .seatrow li.drawback span, .hintdrawback span {
//               background: #c4c14e;
//               border: 1px solid transparent;
//               color: #fff;
//               cursor: pointer;
//           }

//       .seatrow li.booked, .hintbooked {
//           background: #eaebed;
//       }

//           .seatrow li.booked span, .hintbooked span {
//               background: #bfbdbe;
//               border: 1px solid transparent;
//           }

//       .seatrow li.selected, .hintselected {
//           background: #68bd56;
//       }

//           .seatrow li.selected span, .hintselected span {
//               background: #509c44;
//               border: 1px solid transparent;
//           }

// .blankrow li, li.blankseat {
//   visibility: hidden;
//   width: 31px;
// }

//   .blankrow li span, li.blankseat span {
//       display: none;
//   }

// .recline {
//   padding-top: 10px;
// }



// .seatnumrow {
//   display: table-cell;
//   list-style: none;
//   vertical-align: top;
// }

//   .seatnumrow ul {
//       position: absolute;
//       margin-top: 28px;
//       right: 530px;
//       width: 20px;
//       list-style: none;
//   }

//   .seatnumrow li {
//       background: #444;
//       color: #fff;
//       padding: 3px 2px;
//       float: left;
//       margin: 5px 0 0;
//       font-size: 11px;
//       height: 15px;
//       width: 15px;
//       line-height: 9px;
//   }

// .blanknum {
//   background: none;
//   height: 10px !important;
//   margin: 0 !important;
//   background: transparent !important;
// }

// .hintbox {
//   float: left;
//   width: 100%;
//   background: #fff;
//   border: 1px solid #c7c7c7;
//   font-size: 14px;
//   margin-top: 15px;
// }

// .hintrow {
//   float: left;
//   width: 100%;
//   border: 1px solid #e7e7e7;
//   border-left: none;
//   border-right: none;
//   margin-top: -1px;
// }

//   .hintrow span {
//       padding: 3px;
//       float: left;
//   }

// .hintseat {
//   border-right: 1px solid #d7d7d7;
// }

// .seatrow.quitejone {
//   background: #f4d4fc;
// }

// .bulkhead::after {
//   border-top: 2px solid #000;
//   content: "";
//   height: 2px;
//   margin-left: -26px;
//   margin-top: -5px;
//   position: absolute;
//   width: 9%;
// }


// .popbg {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0,0,0,.8);
//   z-index: 100;
// }

// .popclose {
//   background: #fff none repeat scroll 0 0;
//   border: 3px solid #dee4f6;
//   border-radius: 50%;
//   box-sizing: border-box;
//   color: #ee3d3d;
//   cursor: pointer;
//   font-size: 15px;
//   font-weight: bold;
//   height: 25px;
//   line-height: 17px;
//   padding: 2px;
//   position: absolute;
//   right: -10px;
//   text-align: center;
//   top: -17px;
//   width: 25px;
// }

//   .popclose:hover {
//       border-color: #F8CB75;
//   }

// .seatlayoutpop {
//   position: absolute;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
//   height: 600px;
//   width: 1000px;
//   z-index: 101;
//   background: white;
// }

// .seatlayoutbox {
//   width: 70%;
//   float: right;
//   max-height: 670px;
//   overflow-y: auto;
//   margin-right: 5px;
//   box-shadow: -1px 2px 3px #e7e7e7;
//   direction: rtl; /* Set the direction to right-to-left */
// }

//   .seatlayoutbox > div {
//      // direction: ltr;
//   }



// .seatdetailbox {
//   float: left;
//   width: 25%;
//   padding: 15px;
//   height: 50px;
//   /* overflow-y: scroll;*/
// }

// .seatinfobox {
//   float: right;
//   width: 100%;
//   padding: 10px;
//   /*background: #f7f7f7;*/
// }

//   .seatinfobox h2 {
//       background: #dee4f6 none repeat scroll 0 0;
//       color: #115895;
//       font-size: 13px;
//       margin: 0;
//       padding: 5px;
//       width: 100%;
//   }

// .seatrowdtl {
//   float: left;
//   width: 100%;
//   padding: 8px;
//   background: #fff;
//   border: 1px solid #e7e7e7;
//   margin-top: 5px;
//   position: relative;
// }

//   .seatrowdtl b {
//       font-weight: bold;
//       color: #2567a1;
//       display: block;
//   }

//       .seatrowdtl b a {
//           float: right;
//           font-weight: normal;
//           color: #2567a1;
//           text-decoration: underline;
//       }

//           .seatrowdtl b a:hover {
//               color: #efa410;
//           }

// .seatno {
//   display: block;
//   color: #efa410;
//   padding: 5px 0;
//   font-weight: bold;
// }

// .price {
//   display: block;
// }

//   .price label {
//       float: left;
//       width: 20%;
//   }

//   .price b {
//       float: right;
//   }

// .seatdetails {
//   position: absolute;
//   /* padding: 5px; */
//   margin-top: 23px;
//   width: 45%;
//   display: none;
//   z-index: 10;
//   border: 1px solid #e7e7e7;
//   border-radius: 5px;
//   display: none;
// }

// .seatdetailsclick {
//   background: #efa410;
//   color: #fff;
//   display: none;
//   font-size: 12px;
//   left: 0;
//   padding: 2px;
//   position: absolute;
//   top: -4px;
//   width: 100%;
//   cursor: pointer;
// }

// .seatinfo {
//   /*background: #f7f7f7;*/
//   float: right;
//   list-style: outside none none;
//   width: 100%;
// }

// li:hover .seatdetails {
//   display: block;
// }

// .seatinfo > li {
//   float: left;
//   width: 105%;
//   padding: 3px 5px;
//   margin: 0;
//   border: none;
//   height: auto;
//   margin-right: px;
// }

// #topwing > span.leftwing::after {
//   border-color: #fff transparent transparent #fff;
//   border-style: solid;
//   border-width: 22px 30px 13px 20px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.leftwing::after {
//   border-color: transparent #fff #fff transparent;
//   border-style: solid;
//   border-width: 11px 30px 8px 20px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// #topwing > span.rightwing::after {
//   border-color: #fff #fff transparent transparent;
//   border-style: solid;
//   border-width: 25px 12px 9px 38px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.rightwing::after {
//   border-color: transparent transparent #fff #fff;
//   border-style: solid;
//   border-width: 10px 24px 9px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// .windowrow {
//   position: relative
// }

// .editable_seat {
//   float: left;
//   width: 46%;
//   min-height: 54px;
//   background: #f4f4f4;
//   border: 1px solid #ccc;
//   text-align: center;
//   margin-left: 5px;
//   padding: 5px 0px;
//   margin-bottom: 5px;
// }

//   .editable_seat a {
//       color: #efa410;
//       text-align: center;
//       font-size: 12px;
//       font-weight: bold;
//   }

//   .editable_seat b {
//       font-size: 11px;
//   }

//   .editable_seat.seatactive {
//       background: #dee4f6;
//   }

// .pass_name {
//   padding: 5px 0px 5px 5px;
// }
// .seatrow .selected{
//   background: rgb(82, 237, 82) !important;
//   color: rgb(120, 247, 120);
// }
.seatshow {
  display: flex;
  justify-content: space-around;

  @include mobile {
    display: flow;
  }

  .passengerpaxdet {
    margin: 22px 62px;

    @include minitab {
      margin: 14px 0px;
    }

    @include tab {
      margin: 16px 32px;
    }

    @include mobile {
      margin: 0px;
      display: flex;
    }
  }

  // .origin-destination-btn{
  //   width: 100px;
  //   height: 60px;
  //   border-radius: 20px;
  //   background: transparent;
  // }
  .passenger-pax-details {
    .passenger-section {
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 2px 3px 6px grey;

      h3 {
        margin-bottom: 15px;
      }

      .flight-segment {
        margin: 4px;


        .origin-destination-btn {
          background-color: #007bff;
          box-shadow: 2px 2px 6px rgb(189, 189, 189);
          color: #fff;
          border: none;
          padding: 10px 15px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      .seatbutton {
        background-color: #28a745;
        color: #fff;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #218838;
        }
      }

      .city-seat-lay {
        display: flex;
        justify-content: space-between;
      }
    }

  }

  .seattypes {
    margin-top: 20px;

    h6 {
      margin-bottom: 10px;
      font-weight: 600;
      /* border-bottom: 1px solid grey; */
      justify-items: auto;
      /* display: flex; */
      max-width: max-content;
      box-shadow: 2px 3px 6px grey;
      padding: 2px 8px;
      border-radius: 7px;
    }

    .seat-desc {
      display: flex;
      flex-direction: column;

      .seat-category {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .seat-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 10px;
        }

        span {
          margin-right: 10px;
        }
      }
    }
  }

  .seatbutton {
    border: 1px solid;
    background: transparent;
    margin-bottom: 19px;
    margin-top: 5px;
    border-radius: 12px;
    width: 140px;
    height: 30px;
  }

  .seatsss {
    display: flex;
  }

  .seatinner {
    margin: 22px 62px;

    @include mobile {
      margin: 0px;
    }
  }
}

body {
  font-family: Arial, Sans-Serif;
}

.flightbox {
  display: table;
  margin: 0 auto;
  clear: both;
  position: relative;
}

.flightbody {}

.flightdetails {
  color: #2567a1;
  display: block;
  font-size: 16px;
  text-align: center !important;
}

.flightdetails span {
  color: #efa410;
  font-size: 11px;
}

.flgtrow {
  display: table;
  width: 100%;

  @include minitab {
    width: 100%;
  }

  @include tab {
    width: 101%;
  }
}

.innerrow {
  display: block;
  padding: 0 2px;
  margin-top: 2px;
}

.flgtrow h2 {
  display: block;
  margin: 0;
  padding: 2px 20px;
  text-align: center;
  background: #e7e7e7;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
}

.leftwing,
.rightwing {
  width: 50px;
  height: auto;
  display: table-cell;
}

// .leftwing {
//     border-right: 2px solid rgb(15, 106, 238);
//   }
  
// .rightwing {
//    border-left: 2px solid rgb(15, 106, 238);
// }

// .seatrobox {
//   display: table-cell;
//   background: #666;
//   padding: 3px;
//   color: #fff;
//   text-align: center;
//   vertical-align: middle;
//   margin-right: 20px;
// }

.first-gallery {}

.mainrow {
  float: left;
  width: calc(100% - 15%);
}

.windowrow .leftwing {
  background: linear-gradient(270deg, #fff, #c5c5c5);
}

.windowrow .rightwing {
  background: linear-gradient(270deg, #c5c5c5, #fff);
}

.emergencydoor {
  border-radius: 20px;
  color: #fff;
  display: table;
  font-size: 11px;
  text-align: center;
  width: 95%;
  z-index: 100;
  background: #fff;
}

.emergencytext {
  background: red;
  display: inline-block;
  padding: 2px 5px;
}

.rightdoor,
.leftdoor {
  background: red;
  border-radius: 5px;
  display: table-cell;
  font-size: 12px;
  height: 20px;
  padding: 0 2px;
  width: 17px;
}

.seatrow {
  list-style: none;
  background: #fff;
  display: table;
  clear: both;
  margin: 0;
  padding: 0px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.seatrow li,
.seathint {
  float: left;
  height: 23px;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  padding: 0 3px 1px;
  background: #fff;
  margin-left: 16px;

  @include minitab {
    margin-left: 10px;
  }

  @include tab {
    margin-left: 10px;
  }
}

.seatrow li span,
.seathint span {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #c5c5c5;
  border-radius: 0 0 3px 3px;
  content: "";
  float: left;
  height: 16px;
  width: 25px;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
}

.seatrow li.poor,
.quitezone {
  background: #F4D4FC;
}

.seatrow li.poor span,
.quitezone span {
  background: #DD7FF4;
  border: 1px solid transparent;
  color: #fff;
}

.seatrow li.good,
.hintgood {
  background: #68bd56;
}

.seatrow li.good span,
.hintgood span {
  background: #509c44;
  border: 1px solid transparent;
  color: #fff;
}

.seatrow li.drawback,
.hintdrawback {
  background: #f1f160;
}

.seatrow li.drawback span,
.hintdrawback span {
  background: #c4c14e;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
}

.seatrow li.booked,
.hintbooked {
  background: #eaebed;
}

.seatrow li.booked span,
.hintbooked span {
  background: #bfbdbe;
  border: 1px solid transparent;
}

.blankrow li,
li.blankseat {
  visibility: hidden;
  width: 26px;
}
blankrow li,
li.blankseat ,li.blankseat.gallery {
  margin-left: 31px;
}

.blankrow li span,
li.blankseat span {
  display: none;
}

.recline {
  padding-top: 10px;
}

.seatnumrow {
  display: table-cell;
  list-style: none;
  vertical-align: top;
}

.seatnumrow ul {
  position: absolute;
  margin-top: 28px;
  right: 530px;
  width: 20px;
  list-style: none;
}

.seatnumrow li {
  background: #444;
  color: #fff;
  padding: 3px 2px;
  float: left;
  margin: 5px 0 0;
  font-size: 11px;
  height: 15px;
  width: 15px;
  line-height: 9px;
}

.blanknum {
  background: none;
  height: 10px !important;
  margin: 0 !important;
  background: transparent !important;
}

.hintbox {
  float: left;
  width: 100%;
  background: #fff;
  border: 1px solid #c7c7c7;
  font-size: 14px;
  margin-top: 15px;
}

.hintrow {
  float: left;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-left: none;
  border-right: none;
  margin-top: -1px;
}

.hintrow span {
  padding: 3px;
  float: left;
}

.hintseat {
  border-right: 1px solid #d7d7d7;
}

.seatrow.quitejone {
  background: #f4d4fc;
}

.bulkhead::after {
  border-top: 2px solid #000;
  content: "";
  height: 2px;
  margin-left: -26px;
  margin-top: -5px;
  position: absolute;
  width: 9%;
}







.seatlayoutbox {
  width: 70%;
  float: right;
  max-height: 670px;
  overflow-y: auto;
  margin-right: 5px;
  box-shadow: -1px 2px 3px #e7e7e7;
  direction: rtl;
  /* Set the direction to right-to-left */
}



.seatdetailbox {
  float: left;
  width: 25%;
  padding: 15px;
  height: 50px;
}

.seatinfobox {
  float: right;
  width: 100%;
  padding: 10px;
}

.seatinfobox h2 {
  background: #dee4f6 none repeat scroll 0 0;
  color: #115895;
  font-size: 13px;
  margin: 0;
  padding: 5px;
  width: 100%;
}

.seatrowdtl {
  float: left;
  width: 100%;
  padding: 8px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-top: none;
  margin-top: -1px;
  box-sizing: border-box;
}

.seatrowdtl:hover {
  background: #dee4f6;
}

.seatrowdtl h3 {
  float: left;
  font-size: 13px;
  color: #115895;
  margin: 0;
  padding: 0;
  width: 100%;
}

.seatrowdtl p {
  float: left;
  font-size: 13px;
  width: 100%;
  margin: 2px 0 0;
}

.seatrowdtl span {
  color: #efa410;
}

.seatno {
  display: block;
  color: #efa410;
  padding: 5px 0;
  font-weight: bold;
}

.price {
  display: block;
}

.price label {
  float: left;
  width: 20%;
}

.price b {
  float: right;
}

.seatdetails {
  position: absolute;
  margin-top: 23px;
  width: 45%;
  display: none;
  z-index: 10;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

.seatdetailsclick {
  background: #efa410;
  color: #fff;
  display: none;
  font-size: 12px;
  left: 0;
  padding: 2px;
  position: absolute;
  top: -4px;
  width: 100%;
  cursor: pointer;
}

.seatinfo {
  float: right;
  list-style: outside none none;
  width: 100%;
}

li:hover .seatdetails {
  display: block;
}

.seatinfo>li {
  float: left;
  width: 105%;
  padding: 3px 5px;
  margin: 0;
  border: none;
  height: auto;
}

// #topwing > span.leftwing::after {
//   border-color: #fff transparent transparent #fff;
//   border-style: solid;
//   border-width: 22px 30px 13px 20px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.leftwing::after {
//   border-color: transparent #fff #fff transparent;
//   border-style: solid;
//   border-width: 11px 30px 8px 20px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// #topwing > span.rightwing::after {
//   border-color: #ffffff #fff transparent transparent;
//   border-style: solid;
//   border-width: 0px 12px 1px 38px;
//   content: "";
//   top: 0;
//   position: absolute;
//   z-index: 1;
// }

// #bottomwing > span.rightwing::after {
//   border-color: transparent transparent #fff #fff;
//   border-style: solid;
//   border-width: 10px 24px 9px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
// }

// #topwing>span.leftwing::after {
//   border-color: #fff transparent transparent #fff;
//   border-style: solid;
//   border-width:29px 19px;
//   content: "";
//   left: 432px;
//   top: -81px;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(90deg);
//   /* Added rotation */
//   transform-origin: top left;
//   /* Adjust origin */
// }

// #bottomwing>span.leftwing::after {
//   border-color: transparent #fff #fff transparent;
//   border-style: solid;
//   //border-width: 
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(40deg);
//   /* Added rotation */
//   transform-origin: bottom left;
//   /* Adjust origin */
// }

// #topwing>span.rightwing::after {
//   border-color: #ffffff #fff transparent transparent;
//   border-style: solid;
//   border-width: 78px  30px 78px 29px;
//   top: -50px;
//     left: -56px;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(deg);
//   /* Added rotation */
//   transform-origin: top right;
//   /* Adjust origin */
// }

// #bottomwing>span.rightwing::after {
//   border-color: transparent transparent #fff #fff;
//   border-style: solid;
//   border-width: 10px 24px 9px;
//   content: "";
//   bottom: 0;
//   position: absolute;
//   z-index: 1;
//   transform: rotate(-40deg);
//   /* Added rotation */
//   transform-origin: bottom right;
//   /* Adjust origin */
// }

.windowrow {
  position: relative;
}



.pass_name {
  padding: 5px 0px 5px 5px;
}

.seatrow .selected {
  background: rgb(82, 237, 82) !important;
  color: rgb(120, 247, 120);
}

@media (max-width: 1200px) {


  .seatlayoutbox {
    width: 60%;
  }

  .seatdetailbox {
    width: 35%;
  }

  .seatnumrow ul {
    right: 450px;
  }
}

@media (max-width: 992px) {
  .seatlayoutbox {
    width: 100%;
    float: none;
    margin-right: 0;
  }

  .seatdetailbox {
    width: 100%;
    float: none;
  }

  .seatnumrow ul {
    right: 400px;
  }
}

@media (max-width: 768px) {


  .seatlayoutbox {
    width: 100%;
    max-height: 60%;
    overflow-y: auto;
  }

  .seatdetailbox {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  .seatnumrow ul {
    position: static;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .seatlayoutbox {
    max-height: 50%;
  }

  .seatnumrow ul {
    position: static;
    margin-top: 5px;
    right: auto;
  }

  .flgtrow h2 {
    font-size: 12px;
    padding: 2px 10px;
  }

  .flightdetails {
    font-size: 14px;
  }

  .seatrow li,
  .seathint {
    height: 20px;
    margin-left: 10px;
  }

  .seatrow li span,
  .seathint span {
    width: 20px;
    font-size: 8px;
  }
}

@media (max-width: 480px) {


  .seatlayoutbox {
    max-height: 40%;
  }

  .seatnumrow ul {
    margin-top: 0;
  }

  .seatrow li,
  .seathint {
    height: 18px;
    margin-left: 5px;
  }

  .seatrow li span,
  .seathint span {
    width: 18px;
    font-size: 7px;
  }
}
