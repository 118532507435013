@import "../../../../assets/variableStyles.scss";

.modalHeader {
  .ant-modal-header {
    background-color: #374d85 !important;

    .ant-modal-title {
      color: #fff;
    }
  }
}

.hotelImgModal {
  .starRating {
    font-size: 12px;
    color: rgb(246, 149, 45);

    .ant-rate-star {
      margin-right: 2px;
    }
  }
}

/* Iternary page */
.slider-img-holidays {
  border: 1px solid rgba(200, 199, 199, 0.651);
  // margin: 2px;
  padding: 4px;
}
.price-tag-info{
  font-weight: bold;
  color: #ff3300;
  font-size: 20px;
  .fare-line {
    color: grey;
    font-size: 12px;
    font-weight: 100 !important;
    margin-top: 6px;
    margin-bottom: 14px;
  }

}

.tour-info-side {
  margin-left: 20px;
  padding: 5px 0 0 10px;
  border: 1px solid rgba(171, 171, 171, 0.516);
  box-shadow: 0 1px 4px #9b9da1;
  background: linear-gradient(to bottom, rgba(136, 205, 255, 0.621),rgba(255, 255, 255, 0.758)) !important;
  
  p {
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
  }

  .desc-line {
    border-bottom: 1px solid rgba(128, 128, 128, 0.511);
    width: 95%;
    padding-bottom: 10px;
  }
  

  .price-tag {
    color: #026de9;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    span{
      color: #373131;
      font-size: 14px;
      font-weight: bold;
      margin-left: 50px;
    }
    
  }

  .btn-book {
    border-top: 1px solid rgba(154, 154, 154, 0.623);
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    color: whitesmoke;
    font-weight: 500;
    // margin-left: 50%;
    margin: 5px 30px 10px 5px;
    &:active{
      background: #63acff !important;
    color: rgb(28, 28, 28);

    }
    &:hover{
      color: rgb(28, 28, 28);
    }

    @media screen and (max-width: 768px) {
      margin: 0 40px 10px 0px;
    }
  }

  .btn-querry {
    border-top: 1px solid rgba(154, 154, 154, 0.623);
    background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
    color: whitesmoke;
    font-weight: 500;
    &:hover, &:active{
      background: #ffffff;
    color: rgb(28, 28, 28);
    
    }

    @media screen and (max-width: 768px) {
      margin: 0 0 10px 0;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-top: 30px;


  }




}

.tour {
  border-bottom: 1px solid rgba(171, 171, 171, 0.516);
  width: 95%;
  justify-content: center;
  padding-left: 0px;
  margin-bottom: 10px;

  h4 {
    font-weight: 600;
    font-size: 20px;
  }

  p {
    color: grey;
  }

}

// p {
//   margin: 2px;
// }


.tour-info-details {
  border: 1px solid rgba(171, 171, 171, 0.516);
  margin: 30px 0 20px 20px;
  padding-left: 10px;
  background: linear-gradient(to bottom, rgba(136, 205, 255, 0.621),rgba(255, 255, 255, 0.758)) !important;

&:hover{
  box-shadow: 0 1px 4px #9b9da1;
}
  .tour-details {
    h4 {
      font-size: 18px;
      font-weight: 600;
      padding-top: 10px;
    }

    border-bottom: 1px solid rgba(171, 171, 171, 0.516);
    width: 90%;

  }

  .why {
    border-bottom: 1px solid rgba(171, 171, 171, 0.516);
    width: 90%;

    h4 {
      font-size: 13px;
      padding: 10px 0 0 5px;
      color: #000;
    }

  }

  p {
    // border: 2px solid green;
    margin: 5px;
    font-size: 13px;
    color: rgb(128, 119, 119);
    padding: 5px 0;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 30 10px 30 10px;
  }

}

.details-block {
  display: inline-flex;

  span {
    font-size: 12px;
    font-weight: 600;
    // border: 2px solid blue;


  }

  p {
    display: block;
    color: grey;
    font-size: 12px;
    // margin-left: 4px;
    // align-items: end;
    letter-spacing: 8px;
    padding-right: 6px;
  }

  // border: 2px solid green;
  padding: 0 5px;


  .details-adult {
    // display: block;

  }

  .details-child {
    // display:;
    // position: relative;
    margin: 0 60px;
    justify-content: center;

  }

  .details-infant {
    // display:block;
    // position: relative;
  }

  .package-info {
    display: inline;
    margin-top: 60px;
    // border: 2px solid red;
    display: block;


    h6 {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.package-price {
  // border: 2px solid yellow;
  margin-top: 38px;
  position: absolute;
  color: #1788b8 !important;
  font-weight: 800;

  p {
    color: #000;
    display: inline-flex;
    font-weight: 600;
    font-weight: 18px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 38px;
    p{
      font-size: 16px;
    }
  }
}

.tour-step {

  // border: 2px solid black;
  .tour-content {
    border: 1px solid rgba(171, 171, 171, 0.516);
    padding-left: 10px;
    padding-right: 11px;
    padding-top: 10px;
    font-family: Verdana, Arial, sans-serif !important;
    word-spacing: 0;
    font-size: 14px;

    @media screen and (max-width: 768px) {
      width: 95%;
      margin: 0 5px 0 10px;
    }

    @media screen and (max-width: 768px) {
      width: 70%;
      margin-left: 28%;
      font-size: 12px;
      position: relative;
      margin-top: -178px;
    }
  }

  .tour-plan {
    Button {
      margin: 2px 0;
      border-radius: 6px 0 0 6px;
      color: white;
      background: #2a6496;

      &:hover,
      &:active {
        // border-right: none !important;
        color: #0060b4;
        background: white;
      }
    }

    @media screen and (max-width: 768px) {
      width: 28%;
      padding-left: 2px;
      display: inline-block;

    }
  }

}

.activitys_search_list {
  margin-bottom: 20px;
 
  .activity-card {
    border: 1px solid rgba(183, 183, 183, 0.781);
    // box-shadow: 0 2px 7px #9b9da1;
    margin-top: 10px;
    margin-bottom: 4px;
    // border-radius: 8px;
    // background-color: #fff;
    overflow: hidden;
    height: 300px;
    @include tab{
    overflow: inherit;
    }
    @include minitab{
    overflow: inherit;
    height: auto;
    }
    @include mobile{
      height: max-content;
    }

    &:hover {
      box-shadow: 0 2px 7px #9b9da1;
    }

    @media screen and (max-width: 766px) {
      
      max-width: 95%;
      margin: 5px 10px 5px 10px;
      // box-shadow: 0 2px 7px #9b9da1;
      // border: 1px solid red;
    }
   

    .results-wrapper {
      display:flex;
      // grid-template-columns: 1fr 2fr 1fr;
      // background:linear-gradient(72deg, #b2e1ff, #eaeaeab6);
      background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
      .package-title {
        color: #061d35;
        font-weight: 700;
        font-size: 18px;
      //  border: 1px solid red;
       position: absolute;
       font-family:cursive;
       z-index: 1;
        margin: 4px 0 1px 10px;

        &:hover {
          text-shadow:  0 1px 4px #9b9da1;
          
        }
      }

      .holiday-card-info {
        margin: 36px 20px 0px 10px;
      //  border: 1px solid rgb(179, 179, 179);
        // border-radius: 6px;
        max-height: 250px;
        padding: 5px;
        max-width: 188PX;
        &:hover{
        box-shadow: 0 2px 7px #9b9da1;
        border: 1px solid rgb(179, 179, 179);
        }
        // background: #c3e8ff;
        @include minitab{
          max-width: 100%;
        }

        @include mobile{
          margin: 0;
          max-width:-webkit-fill-available;
        }

        div {
          color: rgba(66, 66, 66, 0.84);
          font-size: 12px;
          font-weight: 800;
          margin: 4px 0; 
         
        }

        .package-title {
          color: #007bff;
          font-weight: 700;
          font-size: 18px;
         border: 1px solid red;
         position: absolute;

          &:hover {
            color: #265aa7;
          }
        }

        .pack-desc {
          // border: 1px solid grey;
          margin: 4px;
          padding: 2px;
          border-radius: 4px;
          box-shadow: 0 1px 4px #9b9da1;
          font-size: 14px;
          color: #6c6c6c !important;
          font-weight: 400;
          max-height: 70px;
          overflow: hidden;
          @include mobile{
            color: #ffd6f8 !important;
          }
        }
        .incl{
          color: #2e0000;
        }
        .choose-btn{
          .ant-btn{
            background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
          color: whitesmoke;
          border-radius: 6px;
          &:hover{
            background: rgb(141, 140, 140) !important;
          }
        }
        }
        .price-btm{
          // border: 1px solid red;
        
        }
      }

      .activity-image {
        grid-column: 1;
        grid-row: 1 / span 3;
        width: 680px;
        position: relative;

        .activity_image_ {
          height:300px;
          margin-top: 6px;
          margin-left: 4px;
         
          
          img {
            // border-radius: 6px;
            margin-top: 30px;
            width: 70%;
            height:260px;
            object-fit: cover;
         

          
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 5px 5px;
            // display: inline-flex;
            grid-column: 1;
            grid-row: 2 / 1;
            
            position: relative;
          }

          .gallery-icon {
            bottom: 0px;
            position: absolute;
            right: 0px;
            width: 35px;
            height: 35px;
            background: rgba(28, 43, 57, 0.6);
            border-radius: 4px;
            text-align: center;
            cursor: pointer;

            i {
              font-size: 20px;
              line-height: 35px;
              color: #fff;
            }
          }
        }
      }

      .activity-title {
        grid-column: 2 / span 2;
        grid-row: 1;
        padding: 10px;

        .activity-title-wrapper {
          display: flex;
          align-items: baseline;

          .activity-name {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 700;
            color: #2e0000;
            padding-right: 5px;
            text-transform: capitalize;
            cursor: pointer;
          }

          .activity-star-rating {
            li {
              margin-right: 2px !important;
            }
          }
        }

        .activity-star {
          .starRating {
            font-size: 12px;
            color: rgb(246, 149, 45);
            display: block;

            .ant-rate-star {
              margin-right: 2px;
            }
          }
        }

        .activity-address,
        .activity-duration {
          color: #505c75;
          font-size: 12px;
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-top: 5px;

          .anticon {
            color: #ec8333;
            margin-right: 5px;
          }

          .activity-address-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .recommended-badge {
            border: 1px solid #ec8333;
            border-radius: 999px;
            padding: 4px 10px;
            align-items: center;
            color: #525252;
            font-size: 11px;
            font-weight: 600;
            margin: 5px;
          }

          .service-btn {
            border-color: #ec8333;
            color: #525252;
            background-image: -webkit-linear-gradient(45deg,
                #ec8333 50%,
                transparent 50%);
            background-image: linear-gradient(45deg,
                #ec8333 50%,
                transparent 50%);
            background-position: 100%;
            background-size: 400%;
            -webkit-transition: background 300ms ease-in-out;
            transition: background 300ms ease-in-out;
            padding: 2px 10px;
            font-size: 12px;
            cursor: initial;
          }

          .service-btn:hover {
            background-position: 0;
            color: #fff;
          }
        }

        .activity-desc {
          color: #333;
          font-weight: 600;
          margin: 5px 0;
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          div {
            p {
              margin: 0 !important;
            }
          }
        }

        .tripadvisor-rating {
          display: flex;
          justify-content: space-between;
          background: #f5f8fd;
          border-radius: 5px;
          cursor: pointer;

          .rating-wrapper {
            display: flex;
            align-items: center;

            .rating-number {
              font-size: 14px;
              padding: 10px;
              font-weight: 600;
              letter-spacing: 3px;

              .sec {
                font-size: 12px;
                font-weight: normal;
                color: #969dac;
              }
            }

            .traveller-count {
              padding: 10px;

              p {
                margin-bottom: 3px;
                font-size: 10px;
                color: #969dac;
                font-weight: 400;
                font-family: "Noto Sans Bold", sans-serif;
                text-transform: capitalize;

                @media screen and (max-width: 768px) {
                  display: none;
                }
              }

              .rating-count-value {
                display: flex;
                align-items: center;

                .tripAd {
                  font-size: 14px;
                  color: #000;
                  margin-right: 5px;
                }

                .tripRating {
                  font-size: 10px;
                  color: #00aa6c;

                  .ant-rate-star {
                    margin-right: 2px;
                  }
                }
              }
            }

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }

        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }

      .activity-facilities {
        grid-column: 2 / span 2;
        grid-row: 2;
        padding: 0 15px;

        .amenity-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          p {
            font-size: 13px;
            margin-bottom: 5px;
            margin-right: 10px;

            img {
              margin-right: 5px;
            }

            .fa {
              margin-right: 8px;
            }
          }

          .anticon {
            margin-bottom: 5px;
          }
        }

        .showmore-am {
          font-size: 13px;
          padding: 0;
          border: none;
          height: auto;
          color: #2e0000;
        }
      }

      .activity-price-box {
        grid-column: 4;
        grid-row: 1 / span 3;
        background-color: #f6f7f8;
        text-align: center;
        width: 210px;
        display: grid;
        align-items: center;
        border-left: 1px solid rgba(128, 128, 128, 0.619);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        .activity-price-box-wrapper {
          .activity-price {
            color: #969dac7b;
            font-size: 18px;
            margin-bottom: 15px;
            


            span {

              color: #265aa7 !important;
              font-weight: normal;
              line-height: 30px;


              @media screen and (max-width: 768px) {
                font-size: 18px;
                font-weight: 600;
                margin-right: 60px;
                margin-top: -4px;


              }
            }

            .netfare {
              color: #010101;
            }

            @media screen and (max-width: 768px) {
              position: absolute;
              right: 40px;

            }

            .activity-price {
              margin-bottom: 0px;

            }
          }

          .activity-choose-btn {
            padding: 0 30px;
            background: orange;
            border-radius: 6px;

            .web-choose-btn {
              background-color: #ff234f;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 4px;
              padding: 5px 0;
              height: auto;

              @media screen and (max-width: 768px) {
                display: none;
                padding: 0px 7px !important;
                font-size: 14px;


              }
            }

            .mobile-choose-btn {
              width: 40px;
              height: 40px;
              background-color: #0071c2;
              border-color: transparent;
              color: white;

              @media screen and (min-width: 768px) {
                display: none;
              }
            }

            .select-btn {
              background-color: #ec8333;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 3px;
              padding: 5px 0;
              height: auto;
              width: 100%;

              &:focus,
              &:active,
              &:hover {
                background-color: #ec8333 !important;
                color: #fff;
              }
            }

            .dark-choose-btn {
              width: 100%;
              background-color: #505c75;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 3px;
              padding: 5px 0;
              height: auto;

              &:focus,
              &:active,
              &:hover {
                background-color: #505c75 !important;
                color: #fff;
              }
            }

            @media screen and (max-width: 768px) {
              padding: 0;

            }
          }

          .pax-info {
            margin-top: 10px;

            p {
              color: #505c75;
              max-width: 120px;
              margin: auto;
              font-size: 13px;

              @media screen and (max-width: 768px) {
                max-width: initial;
                // justify-content: left;

              }
            }

            @media screen and (max-width: 768px) {
              position: absolute;
              top: 25px;
              right: 60px;
            }
          }

          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
            position: relative;
            padding: 10px;
          }
        }

        @media screen and (max-width: 768px) {
          width: 100%;


        }
      }

      @media screen and (max-width: 768px) {
        .activity-image {
          grid-column: 1;
          grid-row: 1;
        }

        .activity-title {
          grid-column: 2 / span 3;
          grid-row: 1;
        }

        .activity-facilities {
          grid-column: 1 / span 4;
          grid-row: 2;
        }

        .activity-price-box {
          grid-column: 1 / span 4;
          grid-row: 3;
        }
      }

    }

    .activities-list {
      background-color: #f6f7f8;
      padding: 10px;

      .new-sec-1key {
        background-color: transparent !important;

        .table {
          background-color: #fff;
          margin-bottom: 10px;

          tbody {
            td {
              padding: 0.5rem;
            }
          }
        }
      }
    }
  }
}

/* oct--09-2020--css-added*/

@media screen and (max-width: 768px) {
  .activitys_search_list .results-wrapper {
    display: block !important;
  }

  .activitys_search_list .results-wrapper .activity-image {
    grid-column: 1;
    grid-row: 1 / span 3;
    width: 100% !important;
    position: relative;
  }

  .activitys_search_list .results-wrapper .activity-price-box .activity-price-box-wrapper .activity-price {
    position: absolute;
    right: auto !important;
    margin-bottom: 0px !important;
  }

  .activitys_search_list .results-wrapper .activity-price-box .activity-price-box-wrapper .pax-info {
    right: auto !important;
  }

  .activitys_search_list .results-wrapper .activity-price-box .activity-price-box-wrapper .activity-choose-btn .mobile-choose-btn {
    float: right;
  }

  .cp-wrapper-activity {
    display: flex !important;

  }

  .activity-choose-btn {
    display: block !important;
  }

  .activity-cp-price {
    position: relative !important;

  }
}
.activity-price{
.price-tag{
  margin-left: 50px;
  color: rgb(74, 72, 72);
  font-size: 14px;
  span{
    font-weight: 600;
    color: red;
  }
}
@include mobile{
  text-align: center;
}
}

.holiday-overview-list {
  display: flex;
  justify-content: space-between;

  .holiday-itenerary-heading-span {
    border: 1px solid #ec8333;
    border-radius: 999px;
    padding: 2px 10px;
    align-items: center;
    color: #525252;
    font-size: 10px;
    font-weight: 600;
    margin: 0 4px;
  }
}

.holiday-lightbox {
  .image-gallery-image {
    height: 250px;
    object-fit: cover !important;
  }
}

/* Query form */
.details-header {

  h6 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .header-pck {
    color: #007bff;
  }

  .cust-suppport {
    display: inline-flex;
    color: rgba(127, 127, 127, 0.749);

    .enqury {
      color: #6c757d;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        margin-left: 30px;
        color: #393939ce;;
      }
    }
    @media screen and (max-width: 768px) {
      display: inline-block;
      // margin-left: 25px;
      
    }
  }
}

.query-input {


  .gutter-row {
    // margin: 0 5px 0 5px;
    padding: 0 10px 0 10px
  }
}
.holiday-res-col-wor{
  max-width: 99%;
}
.holiday-bar-panel{
  background-color: #e8f2fa;
}