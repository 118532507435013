@import "../../assets/variableStyles.scss";

#changePwd {
  background-color: #f6f7f8;

  @include mobile {
    margin-top: 35px;
  }

  .change-pwd-container {
   
    padding: 25px 0px;
     font-family: Ubuntu, sans-serif;

    .ant-form-vertical .ant-form-item-label>label {
      font-weight: 800;
      font-size: 18px;
    }

    h2 {
      font-weight: 700;
      text-align: center;

      @include mobile {
        font-size: 20px;
      }
    }

    .ant-card {
      width: 600px;
      margin: auto !important;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      margin: 25px 0px;

      @include mobile {
        width: 350px;
      }
    }

    .change-pwd-btn {
      text-align: right;

      button {
        background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 15px;
        height: 40px;
      }
    }
  }
}

.change-password-user {
  width: 600px !important;
}