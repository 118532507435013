.contact_wrapper {
  background-image: linear-gradient(to left, #00aaff, rgb(120, 255, 129));
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #dddd;
  .inner_wrapper {
    padding: 8px;

    .contact_header {
      text-align: center;
      margin-bottom: 16px;
      h2 {
        font-size: 24px;
        color: white;
      }
    }
    .dateStyle {
      width: 100%;
    }
    .isdCode {
      width: 30%;
    }
    .mobile {
      width: 70%;
    }
    .center {
      text-align: center;
    }
    Button {
      margin-top: 10px;
    }
  }
}
.from-contact-24 .ant-form-item {
  margin-bottom: 6px;
}
