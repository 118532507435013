.payment-btn {
  height: auto;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  // background-color: #330165 !important;
  background-image: linear-gradient(to right, #320063, #0f76bb);
  color: #fff;
  border: none;
  border-radius: 8px;
}
.payment-btn:hover {
  height: auto;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  // background-color: #330165 !important;
  background-image: linear-gradient(to right, #320063, #0f76bb);
  color: #fff;
  border: none;
  border-radius: 8px;
}
